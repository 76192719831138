import {
    HelperSelection
} from './../helpers/helper_selection/helper_selection';

export const selectionActions = (action, myC) => {
    const {
        images,
        set_images,
        index,
        analysis_images,
        set_analysis_images,
        indexAnalysis,
        setIndexAnalysis
    } = myC;

    switch (action.action) {
        default:
            break;
        case "MARK-AS-REFERENCE": {
            const { new_indexAnalysis, new_analysis_images } = HelperSelection.setReference(analysis_images, indexAnalysis, "set", images[index]);
            set_analysis_images(new_analysis_images);
            setIndexAnalysis(new_indexAnalysis);
            return;
        }
        case "UNMARK-AS-REFERENCE": {
            const { new_indexAnalysis, new_analysis_images } = HelperSelection.setReference(analysis_images, indexAnalysis, "unset", images[index]);
            set_analysis_images(new_analysis_images);
            setIndexAnalysis(new_indexAnalysis);
            return;
        }
        case "OPTION-SELECTED": {
            if (action.type === "view") {
                const { new_indexAnalysis, new_analysis_images } = HelperSelection.setView(analysis_images, indexAnalysis, action.value, images[index]);
                set_analysis_images(new_analysis_images);
                setIndexAnalysis(new_indexAnalysis);
            } else if (action.type === "type") {
                const { new_indexAnalysis, new_analysis_images, new_images } = HelperSelection.setType(analysis_images, indexAnalysis, action.value, action.extra, images, index);
                set_analysis_images(new_analysis_images);
                setIndexAnalysis(new_indexAnalysis);
                set_images(new_images);
            }
            return;
        }
        case "REMOVE-SPECIFIC-TAG": {
            const { new_indexAnalysis, new_analysis_images } = HelperSelection.removeField(analysis_images, indexAnalysis, images, index, action.field);
            set_analysis_images(new_analysis_images);
            setIndexAnalysis(new_indexAnalysis);
            return;
        }
        case "REMOVE-TAGS": {
            const { new_indexAnalysis, new_analysis_images } = HelperSelection.removeTag(analysis_images, indexAnalysis, images, index, action.value);
            set_analysis_images(new_analysis_images);
            setIndexAnalysis(new_indexAnalysis);
            return;
        }
        case "QC-ALL-GOOD": {
            const { new_images, new_analysis_images } = HelperSelection.generateAllGoodScore(analysis_images, indexAnalysis, images, index);
            set_analysis_images(new_analysis_images);
            set_images(new_images);
            return;
        }
        case "QC-ACCEPT": {
            const { new_images, new_analysis_images } = HelperSelection.acceptImage(analysis_images, indexAnalysis, images, index, action.value);
            set_analysis_images(new_analysis_images);
            set_images(new_images);
            return;
        }
    }
}