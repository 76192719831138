import React from 'react';

import { ImageMenuIcons } from './../icons_component/image_menu_icons';
import { ModalityIcons } from './../icons_component/modality_icons';
import { GeneralIcons } from './../icons_component/general_icons';

// Helpers
import { HelperConfig } from './../../../helpers/helper_config/helper_config';

// AUX functions
import { findWhere } from 'underscore';
import classNames from 'classnames';

export const ImageGridComponent = ({
        site,
        images,
        analysis_images,
        image_to_show,
        image_types,
        images_selected,
        on_image_selected,
        on_action_to_perform,
        on_hover
    }) => {

    const modalities = HelperConfig.getImageModalities("all");
    
    const renderImages = () => {
        return images.map((image,i) => {
            const style_img = {};
            const style_box = {};
            const modality_name = !!image.metadata.modality? image.metadata.modality : image.modality? image.modality : false;
            const modality = findWhere(modalities, { type: modality_name });
            const color = modality? modality.color : HelperConfig.getStyle("selection","default_color");
            if (["segmentation","cycletiming"].includes(site)) {
                style_box.outlineColor = color
            } else if (["selection","overview"].includes(site)) {
                style_img.outlineColor = color;
            }
            const url = site === "preview"? image.urls.big_icon : image.urls.small_icon;
            const active = site === "preview"? images_selected.includes(image.filename) : image===image_to_show;
            return (
                <div key={image.filename} className={classNames({"grid-selected":active},"grid-image")} style={style_box}>
                    <img className="image" style={style_img} src={url} alt="" />
                    {renderModalityIcon(modality)}
                    {renderExtras(image,i)}
                    <div className="hover"
                        onMouseEnter={() => on_hover(i)}
                        onMouseLeave={() => on_hover(null)}
                        onClick={() => on_image_selected(i)}>
                    </div>
                    {renderFooter(image,i,color)}
                </div>
            );
        });
    }

    const renderModalityIcon = (mod) => {
        if (mod && site !== "preview") {
            return <ModalityIcons type={mod.type} background={true} />;
        }
    }

    const renderExtras = (image,i) => {
        if ((site === "cycletiming" && image.is_annotated) || (site === "segmentation" && image.is_segmented)) {
            return (
                <div className="check-icon">
                    <GeneralIcons type={"check"} width="10" height="10" />
                </div>
            );
        } else if (site === "selection") {
            return (
                <React.Fragment>
                    {renderSelectionReference(image.filename)}
                    {renderSelectionTag(image.filename,i)}
                    {renderSelectionComments(image.filename)}
                </React.Fragment>
            );
        }
    }

    const renderFooter = (image,i,color) => {
        if (["segmentation","cycletiming"].includes(site)) {
            return <div className="footer" style={{ backgroundColor: color }}>{HelperConfig.getImageTypeNameToShow(image.type)}</div>;
        } else if (site === "selection") {
            return <div className="footer">{i}</div>;
        }
    }

    const renderSelectionReference = (filename) => {
        const image = findWhere(analysis_images, { filename });
        if (image?.reference) {
            return (
                <div className="reference-icon">
                    <ImageMenuIcons type={"checked"} />
                </div>
            );
        }
    }

    const renderSelectionTag = (filename,i) => {
        const image = findWhere(analysis_images, { filename });
        if (image?.type || image?.view) {
            const image_type = findWhere(image_types, { type: image.type });
            let tag = "";
            let tag_long = "";
            if (image_type) {
                tag = image_type.abbreviation;
                tag_long = image_type.name_to_show;
            } else {
                tag = image.view;
                tag_long = image.view;
            }
            const mod = findWhere(modalities, { type: image.modality });
            const style = mod? { borderColor: mod.color } : {};
            return (
                <div className="tag" style={style}
                    onMouseEnter={() => on_hover(i)}
                    onMouseLeave={() => on_hover(null)}
                    onClick={() => on_image_selected(i)}
                >
                    {tag}
                    <div className="delete-button" onClick={() => { on_action_to_perform({ action: "REMOVE-TAGS", value: image }) }}>+</div>
                    <div className="tag-long">{tag_long}</div>
                </div>
            );
        }
    }

    const renderSelectionComments = (filename) => {
        const image = findWhere(images, { filename });
        const text = image?.comments? (image.comments.filter(c => c.type === "score-text").length > 0? image.comments.filter(c => c.type === "score-text").length : "") : "";
        const score = image?.score? true : image?.accepted? true : false;
        if (text !== "" || score) {
            return (
                <div className={classNames({"scored":score},"bullet-icon")}>
                    {text}
                </div>
            );    
        }
    }

    const style = {};
    if (site === "preview") {
        style.height = window.innerHeight - 60 + "px";
    }
    return (
        <div className={site+" grid"} style={style}>
            <div className="grid-images">
                {renderImages()}
            </div>
        </div>
    );
}