import React from 'react';

// Components
import { MenuComponent } from './menu_component';
import { ModalityIcons } from './../icons_component/modality_icons';

// AUX functions
import classNames from 'classnames';

export const TopBarComponent = ({
        image,
        image_analysis,
        image_types,
        views,
        modalities,
        on_action_to_perform
    }) => {

    const renderModality = () => {
        return (
            <div className="column">
                <div className="header-text">Modality</div>
                <div className="content">{image? image.metadata.modality : ""}</div>
            </div>
        );
    }

    const renderView = () => {
        const current_value = image_analysis?.view? image_analysis.view : "None";
        return (
            <div className={classNames({"alert":image_analysis?.type&&!image_analysis.view},"column", "view")}>
                <div className="header-text">View</div>
                <MenuComponent
                    options={views}
                    selected_value={current_value}
                    current_source={image}
                    column_name={"view"}
                    column_type={"view"}
                    on_action_to_perform={on_action_to_perform}
                />
            </div>
        );
    }

    const renderImageTypes = () => {
        return modalities.map(mod => {
            const this_mod = image_analysis?.modality === mod.type;
            const options = image_types.filter(t => t.modalities.some(m => m.modality === mod.type));
            return (
                <div key={mod.type} className="column modality">
                    <div className="header">
                        <ModalityIcons type={mod.type} />
                        <span>{mod.type}</span>
                    </div>
                    <MenuComponent
                        options={options}
                        selected_value={this_mod? image_analysis.type : false}
                        current_source={image}
                        column_name={mod.type}
                        column_type={"type"}
                        on_action_to_perform={on_action_to_perform}
                    />
                </div>
            );
        });
    }

    return (
        <div className="top-bar">
            {renderModality()}
            {renderView()}
            {renderImageTypes()}
        </div>
    );
}