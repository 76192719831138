import React from 'react';

// Helpers
import { HelperConfig } from './../../../helpers/helper_config/helper_config';

export const SegmentationEventPickerComponent = ({
    image,
    state,
    on_action_to_perform,
    site
}) => {

    const renderTimeEvents = () => {
        let ED = "End diastole";
        let ES = "End systole";
        let sep = <br />;
        if (site === "validation") {
            ED = "ED";
            ES = "ES";
            sep = " ";
        }
        if (image.modality === "2D" && image.cardiac_events.length > 0) {
            return image.cardiac_events.map((cycle, i) => {
                const style_d = { borderColor: HelperConfig.getStyle("cycletiming", "end_diastole").color };
                const style_s = { borderColor: HelperConfig.getStyle("cycletiming", "end_systole").color };
                if (state.current_frame === cycle.end_diastole) {
                    style_d.backgroundColor = style_d.borderColor;
                    style_d.color = "#000";
                } else if (state.current_frame === cycle.end_systole) {
                    style_s.backgroundColor = style_s.borderColor;
                }
                return (
                    <React.Fragment key={cycle.end_diastole}>
                        <div style={style_d} onClick={() => { on_action_to_perform({ action: "CHANGE-TIME-EVENT", value: { image, cycle: i, event: "end_diastole", frame: cycle.end_diastole } }) }}>{ED}{sep}<b>CC {i + 1}</b></div>
                        <div style={style_s} onClick={() => { on_action_to_perform({ action: "CHANGE-TIME-EVENT", value: { image, cycle: i, event: "end_systole", frame: cycle.end_systole } }) }}>{ES}{sep}<b>CC {i + 1}</b></div>
                    </React.Fragment>
                )
            });
        }
    }

    return (
        <React.Fragment>
            <div className={"time-events " + site}>
                {renderTimeEvents()}
            </div>
        </React.Fragment>
    );
}