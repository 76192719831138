import { fromPixelsToReal } from './geometry_module';

export function getSpline(points, type) {

    let filtered_points = points.filter(p => p.x !== false && p.y !== false);
    let spline = [];

    if (type === "cardinal") {

        spline = getCardinalSpline(filtered_points);
        
    } else if (type === "discontinuous") {

        spline = getSplineDiscontinuous(filtered_points);
        
    } else if (type === "monotone") {

        var sortedControlPoints = sortControlPoints(filtered_points);
        var interpolationmethod = 'FritschButland'; // Linear, FiniteDifference, Cardinal, FritschCarlson, FritschButland, Steffen
        var interpolationtension = 0.5;
        var xx = linspace(sortedControlPoints.xmin, sortedControlPoints.xmax, sortedControlPoints.xmax - sortedControlPoints.xmin);
        var yy = interpolateCubicHermite(xx, sortedControlPoints.x, sortedControlPoints.y, interpolationmethod, interpolationtension);

        for (var i = 0; i < xx.length; i++) {
            let point = {};
            point["x"] = xx[i];
            point["y"] = yy[i];
            spline.push(point);
        }
    }

    return spline;
}

function getSplineDiscontinuous(points) {
    let spline = [];
    let start = 0;
    if (points.length > 1) {
        for (let i=1 ; i<points.length ; i++) {
            if (points[i].forced_to_zero || i === points.length-1) {
                const segment = points.slice(start,i+1);
                const segment_spline = getSpline(segment, "monotone");
                spline = spline.concat(segment_spline);
                start = i;
            }
        }
    }
    return spline;
}

export function getSplineRealValues(cps, zero_line, image, y_mode) {
    let spline = [];
    if (cps.length > 0) {
        if (y_mode === "Y") {
            let start = 0;
            let px_spline = [];
            if (cps.length > 1) {
                for (let i = 1; i < cps.length; i++) {
                    if (cps[i].forced_to_zero || i === cps.length - 1) {
                        let segment = cps.slice(start, i + 1);
                        let segment_spline = getSpline(segment, "discontinuous");
                        px_spline = px_spline.concat(segment_spline);
                        start = i;
                    }
                }
                px_spline.map((p) => {
                    let new_point = {
                        x: fromPixelsToReal(p.x, "X", zero_line, image),
                        y: fromPixelsToReal(p.y, y_mode, zero_line, image)
                    }
                    spline.push(new_point)
                })
            }
        } else {
            let real_points = []
            cps.map((p) => {
                let new_point = {
                    x: fromPixelsToReal(p.x, "X", zero_line, image),
                    y: fromPixelsToReal(p.y, y_mode, zero_line, image)
                }
                real_points.push(new_point)
            })
            spline = getSpline(real_points, "cardinal");
        }
    }
    return spline;
}

export function getNextEvent(events, point, events_of_interest) {
    const next = { x: 10000, type: false };
    for (const event of events) {
        Object.keys(event).map(key => {
            if (events_of_interest.includes(key) && event[key] < next.x && event[key] > point) {
                next.x = event[key];
                next.type = key;
            }
        });
    }
    return next;
}

export function mouseIsOverCurve(lines, mouse, tool_config, curve, cycle) {
    let { nearest, line, dist, line_index } = getNearestPoint(lines, mouse);
    if (dist < tool_config.selection_threshold_cp) { // Over a control point
        return { point: nearest, line, line_index: cycle? cycle : line_index, type: nearest.type === "mid_control_point"? "mid" : "cp" };
    } else {
        let splines = [];
        for (let line of lines) {
            if (line.points.length > 1) {
                splines.push({ name: line.name, points: getSpline(line.points, curve) });
            }
        }
        ({ nearest, line, dist, line_index } = getNearestPoint(splines, mouse));
        if (dist < tool_config.selection_threshold_mid) { // Over the spline
           return { point: nearest, line, line_index: cycle? cycle : line_index, type: "spline" };
        } else { // Not over
            return false;
        }
    }
}

export function getNewPointPosition(points, mouse, curve) {
    let index = 0;
    if (curve === "cardinal") {
        const spline = getSpline(points, curve);
        const nearest = getNearestPoint([{ points: spline }], mouse).nearest;
        spline.some (point => {
            if (points[index].x === point.x && points[index].y === point.y) {
                index++;
            }
            if (point === nearest) {
                return true;
            }
        });
    } else {
        points.some (point => {
            if (point.x <= mouse.x) {
                index++;
            } else {
                return true;
            }
        });
    }
    return index;
}

export function getSplineIndexByCoordinate(spline, axis, value) {
    let diffs = spline.map(p => Math.abs(p[axis]-value));
    let ind = diffs.indexOf(Math.min(...diffs));
    return ind;
}

function getNearestPoint(lines, mouse) {
    let nearest = lines[0].points[0];
    let dist = 1000;
    let line_name = lines[0].name;
    let line_index = 0;
    for (let i in lines) {
        for (let point of lines[i].points) {
            const point_dist = Math.sqrt(Math.pow(mouse.x-point.x,2) + Math.pow(mouse.y-point.y,2));
            if (point_dist < dist) {
                dist = point_dist;
                nearest = point;
                line_name = lines[i].name;
                line_index = parseInt(i);
            }
        }
    }
    return { nearest, line: line_name, dist, line_index };
}

function getCardinalSpline(coord_array, t, f, c) {

    // Conversion of the income from Rocket format
    let h = [];
    for (let point of coord_array) {
        h.push(point.x,point.y);
    }

    t = typeof t === "number" ? t : 0.5;
    f = f ? f : 25;
    var j,
        d = 1,
        e = h.length,
        n = 0,
        m = (e - 2) * f + 2 + (c ? 2 * f : 0),
        k = new Float32Array(m),
        a = new Float32Array((f + 2) * 4),
        b = 4;
    j = h.slice(0);
    if (c) {
        j.unshift(h[e - 1]);
        j.unshift(h[e - 2]);
        j.push(h[0], h[1]);
    } else {
        j.unshift(h[1]);
        j.unshift(h[0]);
        j.push(h[e - 2], h[e - 1]);
    }
    a[0] = 1;
    for (; d < f; d++) {
        var o = d / f,
            p = o * o,
            r = p * o,
            q = r * 2,
            s = p * 3;
        a[b++] = q - s + 1;
        a[b++] = s - q;
        a[b++] = r - 2 * p + o;
        a[b++] = r - p;
    }
    a[++b] = 1;
    g(j, a, e);
    if (c) {
        j = [];
        j.push(h[e - 4], h[e - 3], h[e - 2], h[e - 1]);
        j.push(h[0], h[1], h[2], h[3]);
        g(j, a, 4);
    }

    function g(G, z, B) {
        for (var A = 2, H; A < B; A += 2) {
            var C = G[A],
                D = G[A + 1],
                E = G[A + 2],
                F = G[A + 3],
                I = (E - G[A - 2]) * t,
                J = (F - G[A - 1]) * t,
                K = (G[A + 4] - C) * t,
                L = (G[A + 5] - D) * t;
            for (H = 0; H < f; H++) {
                var u = H << 2,
                    v = z[u],
                    w = z[u + 1],
                    x = z[u + 2],
                    y = z[u + 3];
                k[n++] = v * C + w * E + x * I + y * K;
                k[n++] = v * D + w * F + x * J + y * L;
            }
        }
    }

    e = c ? 0 : h.length - 2;
    k[n++] = h[e];
    k[n] = h[e + 1];

    // Conversion of the outcome to Rocket format
    let spline = [];
    for (let i=0 ; i<k.length/2 ; i++) {
        spline.push({ x: k[i*2], y: k[i*2+1] });
    }

    return spline;
}

function sortControlPoints(control_points) {
    var len = control_points.length;
    control_points.sort(function (a, b) {
        return a.x - b.x;
    });
    var x = [], y = [], xvis = [], yvis = [], xmin = Infinity, xmax = -Infinity;
    for (var i = 0; i < len; i++) {
        if (control_points[i].type !== 'spawn') {
            x.push(control_points[i].x);
            y.push(control_points[i].y);
            xmin = control_points[i].x < xmin ? control_points[i].x : xmin;
            xmax = control_points[i].x > xmax ? control_points[i].x : xmax;
        }
        if (control_points[i].type !== 'hidden') {
            xvis.push(control_points[i].x);
            yvis.push(control_points[i].y);
        }
    }
    return { x: x, y: y, xvis: xvis, yvis: yvis, xmin: xmin, xmax: xmax };
}

function linspace(start, end, n) {
    n = typeof n === "undefined" ? 500 : n;
    if (n <= 0) return [];
    var arr = Array(n-1);
    for (var i=0; i<=n-1; i++) {
        arr[i] = ((n - 1 - i) * start + i * end) / (n - 1);
    }
    return arr;
}

function interpolateCubicHermite(xeval, xbp, ybp, method, tension) {
    // first we need to determine tangents (m)
    var n = xbp.length;
    var obj = calcTangents(xbp, ybp, method, tension);
    var m = obj.m;          // length n
    var delta = obj.delta;  // length n-1
    var c = new Array(n - 1);
    var d = new Array(n - 1);
    for (var k = 0; k < n - 1; k++) {
        if (method.toLowerCase() === 'linear') {
            m[k] = delta[k];
            c[k] = d[k] = 0;
            continue;
        }
        var xdiff = xbp[k + 1] - xbp[k];
        c[k] = (3 * delta[k] - 2 * m[k] - m[k + 1]) / xdiff;
        d[k] = (m[k] + m[k + 1] - 2 * delta[k]) / xdiff / xdiff;
    }

    var len = xeval.length;
    var f = new Array(len);
    var k = 0;
    for (var i = 0; i < len; i++) {
        var x = xeval[i];
        if (x < xbp[0] || x > xbp[n - 1]) {
            throw "interpolateCubicHermite: x value " + x + " outside breakpoint range [" + xbp[0] + ", " + xbp[n - 1] + "]";
        }
        while (k < n - 1 && x > xbp[k + 1]) {
            k++;
        }
        var xdiff = x - xbp[k];
        f[i] = ybp[k] + m[k] * xdiff + c[k] * xdiff * xdiff + d[k] * xdiff * xdiff * xdiff;
    }
    return f;
}

function calcTangents(x, y, method, tension) {
    method = typeof method === 'undefined' ? 'fritschbutland' : method.toLowerCase();
    var n = x.length;
    var delta = new Array(n - 1);
    var m = new Array(n);
    for (var k = 0; k < n - 1; k++) {
        var deltak = (y[k + 1] - y[k]) / (x[k + 1] - x[k]);
        delta[k] = deltak;
        if (k === 0) {   // left endpoint, same for all methods
            m[k] = deltak;
        } else if (method === 'cardinal') {
            m[k] = (1 - tension) * (y[k + 1] - y[k - 1]) / (x[k + 1] - x[k - 1]);
        } else if (method === 'fritschbutland') {
            var alpha = (1 + (x[k + 1] - x[k]) / (x[k + 1] - x[k - 1])) / 3;  // Not the same alpha as below.
            m[k] = delta[k - 1] * deltak <= 0 ? 0 : delta[k - 1] * deltak / (alpha * deltak + (1 - alpha) * delta[k - 1]);
        } else if (method === 'fritschcarlson') {
            // If any consecutive secant lines change sign (i.e. curve changes direction), initialize the tangent to zero.
            // This is needed to make the interpolation monotonic. Otherwise set tangent to the average of the secants.
            m[k] = delta[k - 1] * deltak < 0 ? 0 : (delta[k - 1] + deltak) / 2;
        } else if (method === 'steffen') {
            var p = ((x[k + 1] - x[k]) * delta[k - 1] + (x[k] - x[k - 1]) * deltak) / (x[k + 1] - x[k - 1]);
            m[k] = (Math.sign(delta[k - 1]) + Math.sign(deltak)) *
                Math.min(Math.abs(delta[k - 1]), Math.abs(deltak), 0.5 * Math.abs(p));
        } else {    // FiniteDifference
            m[k] = (delta[k - 1] + deltak) / 2;
        }
    }
    m[n - 1] = delta[n - 2];
    if (method !== 'fritschcarlson') {
        return { m: m, delta: delta };
    }

    /*
    Fritsch & Carlson derived necessary and sufficient conditions for monotonicity in their 1980 paper. Splines will be
    monotonic if all tangents are in a certain region of the alpha-beta plane, with alpha and beta as defined below.
    A robust choice is to put alpha & beta within a circle around origo with radius 3. The FritschCarlson algorithm
    makes simple initial estimates of tangents and then does another pass over data points to move any outlier tangents
    into the monotonic region. FritschButland & Steffen algorithms make more elaborate first estimates of tangents that
    are guaranteed to lie in the monotonic region, so no second pass is necessary. */

    // Second pass of FritschCarlson: adjust any non-monotonic tangents.
    for (var k = 0; k < n - 1; k++) {
        var deltak = delta[k];
        if (deltak === 0) {
            m[k] = 0;
            m[k + 1] = 0;
            continue;
        }
        var alpha = m[k] / deltak;
        var beta = m[k + 1] / deltak;
        var tau = 3 / Math.sqrt(Math.pow(alpha, 2) + Math.pow(beta, 2));
        if (tau < 1) {      // if we're outside the circle with radius 3 then move onto the circle
            m[k] = tau * alpha * deltak;
            m[k + 1] = tau * beta * deltak;
        }
    }
    return { m: m, delta: delta };
}