export const DataProvider_ADMIN = (uri, type, parameters, token) => {
    let options = false;
    let url = false;
    let response_type = "json";

    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('x-api-key', token);
    myHeaders.append("Access-Control-Allow-Origin", "*");
    myHeaders.append("Access-Control-Allow-Headers", "Origin, X-Requested-With, Content-Type, Accept");
    switch (type) {
        case "GET_USER_PROJECTS":
            if ("id" in parameters) {
                //create url
                url = uri + "/admin/users/get/" + parameters["id"]
                //create options 
                options = {
                    method: 'GET',
                    headers: myHeaders,
                    cache: 'default'
                };
            }
            break;

        case "ACCEPT_TERMS_CONDITIONS":
            if ("user" in parameters && "version" in parameters) {
                //create url
                url = uri + "/admin/users/accept_terms"
                //create options 
                options = {
                    method: 'POST',
                    headers: myHeaders,
                    body: JSON.stringify(parameters),
                    cache: 'default'
                };
            }
            break;
    
        case "GET_CONFIG":
            if ("platform" in parameters && "config" in parameters) {
                //create url
                url = uri + "/admin/config/get"
                //create options
                options = {
                    method: 'POST',
                    headers: myHeaders,
                    body: JSON.stringify(parameters),
                    cache: 'default'
                };
            }
            break;

        case "GET_PROJECT":
            if ("id" in parameters) {
                //create url
                url = uri + "/admin/projects/get/" + parameters["id"]
                //create options
                options = {
                    method: 'GET',
                    headers: myHeaders,
                    cache: 'default'
                };
            }
            break;

        default:
            throw new Error(`Unsupported Data Provider request type ${type}`);
    }

    if (options !== false && url !== false) {
        let request = new Request(url, options);
        return fetch(request).then((response) => {
            if (response.status === 200) {

                if (response_type === "json") {

                    return response.json();

                } else if (response_type === "blob") {

                    return response.blob();

                } else if (response_type === "image") {

                    let blob = response.blob();
                    return URL.createObjectURL(blob);

                }

            } else {

                if (response.statusText === "UNAUTHORIZED") {
                    return {
                        "error": "UNAUTHORIZED"
                    }
                } else {
                    return {
                        "error": "UNAUTHORIZED"
                    }
                }
            }

        }).catch((error) => {
            console.error('Error:', error);
        });;

    } else {
        throw new Error(`Unsupported Data Provider request parameters ${parameters}`);
    }
}