import React, { Component } from 'react';

// Components
import { LoadingComponent } from '../../components/loading_component/loading_component';
import { ImageMenuIcons } from '../../components/icons_component/image_menu_icons';

// Helpers
import { HelperConfig } from './../../../helpers/helper_config/helper_config'
import { HelperUser } from '../../../helpers/helper_user/helper_user'
import { HelperStudies } from '../../../helpers/helper_studies/helper_studies'

export class PrismaDataPage extends Component {

    constructor() {
        super();
        this.state = {
            data_to_show: [],
            loaded: false
        }
        this.user = {};
    }

    componentDidMount() {
        if (!HelperUser.validateUserAuthentication()) {
            this.onActionToPerform("GENERAL", { action: "LOGOUT", value: "" });
        } else {
            HelperConfig.init().then(() => {
                this.user = HelperUser.getUser();
                HelperStudies.setCurrentProject("fedoc-arc");
                HelperStudies.loadPrismaDataManagement(this.user.user_token).then(([data, url]) => {
                    this.file_url = url;
                    this.setState({
                        data_to_show: data,
                        loaded: true
                    });
                });
            });
        }
    }

    goToStudies() {
        var site = window.location.href;
        var new_path = site.replace("prisma-data", "user-page?stay=true")
        window.location.href = new_path;
    }

    downloadFile() {
        window.location.href = this.file_url;
    }

    renderPageContent() {
        if (this.state.loaded) {
            let stats = this.state.data_to_show;
            return (
                <div className="data-management">
                    <div className="header">
                        <div>Last update: <span>{stats.date}</span><span style={{fontWeight:"normal",fontSize:"14px"}}>(UTC+0)</span></div>
                        <div className="download"><span onClick={() => this.downloadFile()}>Download data ⬇</span></div>
                    </div>
                    <div className="page-content">
                        {Object.keys(stats.stats).map(title => {
                            return (
                                <div className="section">
                                    <div><span>{title}</span></div>
                                    <div className="list">{stats.stats[title].map(value => {
                                        return <>{value}<br/></>;
                                    })}</div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            );
        } else {
            return (
                <LoadingComponent
                    site={"prisma-pages"}
                />
            );
        }
    }

    render() {
        return (
            <div className="grid-frame vertical prisma-page">
                <div className="back-icon"> 
                    <div style={{display:"flex"}} onClick={() => { this.goToStudies() }}>
                        <svg viewBox="0 0 24 24" fill="none" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" />
                            <line x1="5" y1="12" x2="19" y2="12" />
                            <line x1="5" y1="12" x2="11" y2="18" />
                            <line x1="5" y1="12" x2="11" y2="6" />
                        </svg>
                        <div className="back-text">Back to user page</div>
                    </div>
                </div>
                <div className="container" style={{height: window.innerHeight - 78}}>
                    {this.renderPageContent()}
                </div>
            </div>
        );
    }
}