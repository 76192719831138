import {
    HelperImagesCloud
} from './helper_images_cloud';
import {
    HelperImagesCornerstone
} from './helper_images_cornerstone';
import {
    HelperStudies
} from './../helper_studies/helper_studies';
import {
    HelperConfig
} from './../helper_config/helper_config';
import {
    findIndex
} from 'underscore';

export class HelperImages {

    // CLOUD
    static downloadDICOMFromURL(urls, user_token) {
        return HelperImagesCloud.downloadDICOMFromURL(urls, user_token);
    }
    static createDICOMsnapshot(filename, frame, user_token) {
        return HelperStudies.getStudyInfo().then(study_info => {
            let study_date = study_info.study_id.replace(study_info.patient_id+"_","");
            let project = HelperStudies.getCurrentProjectObj();
            return HelperImagesCloud.createDICOMsnapshot(project.bucket, study_info.patient_id, study_date, filename, frame, user_token);   
        });
    }
    // CORNERSTONE
    static load(blob) {
        return HelperImagesCornerstone.load(blob).then((img) => { return img; });
    }
    static display(element, image) {
        HelperImagesCornerstone.display(element, image);
    }
    static loadAndDisplay(element, blob) {
        return HelperImagesCornerstone.loadAndDisplay(element, blob).then(result => {
            return result;
        });
    }
    static getImageMetadata(blob) {
        return HelperImagesCornerstone.getImageMetadata(blob);
    }
    static stopDicomClip(element) {
        HelperImagesCornerstone.stopDicomClip(element);
    }
    static playDicomClip(element, velocity) {
        HelperImagesCornerstone.playDicomClip(element, velocity);
    }
    static initMultiframeDicom(element, metadata, myC) {
        HelperImagesCornerstone.initMultiframeDicom(element, metadata, myC);
    }
    static parseDicom(dicomFileAsBuffer) {
        return HelperImagesCornerstone.parseDicom(dicomFileAsBuffer);
    }
    static updateImage(element) {
        HelperImagesCornerstone.updateImage(element);
    }
    static pixelToCanvas(element, point) {
        return HelperImagesCornerstone.pixelToCanvas(element, point);
    }
    static canvasToPixel(canvas, point, metadata) {
        return HelperImagesCornerstone.canvasToPixel(canvas, point, metadata);
    }
    // GENERAL
    static imageHasCycles(image) {
        return !(image.modality === "2D" && image.metadata.number_of_frames === 0);
    }
    static getFilteredImages(images, site) {
        const modalities = HelperStudies.getCurrentProjectObj()["modalities"];
        let filtered = [];
        if (site === "cycletiming") {
            filtered = images.filter(img => {
                if (img.view && img.type && img.reference && modalities.includes(img.modality) && this.imageHasCycles(img)) {
                    // const patterns = HelperConfig.getPossiblePatterns(img.type, img.modality);
                    // if (patterns[0].shapes.length === 0) {
                    //     return false;
                    // } else {
                        return true;
                    // }
                } else {
                    return false;
                }
            });
        } else if (site === "segmentation") {
            filtered = images.filter(img => {
                if (img.view && img.type && img.reference && modalities.includes(img.modality)) {
                    // const patterns = HelperConfig.getPossiblePatterns(img.type, img.modality);
                    if (img.is_annotated) { // && !(img.modality === "2D" && patterns[0].shapes.length === 0)) {
                        return true;
                    } else if (img.modality === "2D" && !img.urls.big_icon.includes(".gif")) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            });
        }
        return filtered;
    }
    static getImageToShow(images, param, default_value) {
        if (images.length > 0) {
            let image_to_show = findIndex(images, param);
            let image_in_url = HelperStudies.getUrlParam("image");
            if (image_in_url) { image_to_show = findIndex(images, { filename: image_in_url }); }
            if (image_to_show === -1) { image_to_show = default_value; }
            return image_to_show;
        } else {
            return null;
        }
    }
}