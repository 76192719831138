import React from 'react';

export const TdiDopplerIcon = ({
        className,
        style,
        width,
        height
    }) => {
    
    return (
        <svg className={className} width={width} height={height} style={style} viewBox="0 0 50 50" fill="none">
            <path d="M46.68 1H3.32C2.0387 1 1 2.0387 1 3.32V46.68C1 47.9613 2.0387 49 3.32 49H46.68C47.9613 49 49 47.9613 49 46.68V3.32C49 2.0387 47.9613 1 46.68 1Z" stroke="#F7931E" strokeWidth="2" strokeMiterlimit="10" />
            <path d="M3.35001 25.81L9.13001 10.4C9.17452 10.2822 9.25386 10.1807 9.35747 10.1091C9.46108 10.0375 9.58405 9.99909 9.71001 9.99909C9.83596 9.99909 9.95893 10.0375 10.0625 10.1091C10.1662 10.1807 10.2455 10.2822 10.29 10.4L15.29 25.4C15.3304 25.5212 15.4085 25.6263 15.5128 25.7C15.6171 25.7738 15.7423 25.8123 15.87 25.81H19.5C19.6346 25.808 19.7659 25.8512 19.8729 25.9329C19.9799 26.0145 20.0564 26.1297 20.09 26.26L24 39.92C24.0342 40.0504 24.1107 40.1658 24.2174 40.2481C24.3242 40.3304 24.4552 40.3751 24.59 40.3751C24.7248 40.3751 24.8558 40.3304 24.9626 40.2481C25.0693 40.1658 25.1458 40.0504 25.18 39.92L28.88 26.28C28.9133 26.1487 28.9894 26.0322 29.0962 25.9489C29.203 25.8656 29.3345 25.8203 29.47 25.82H31.55C31.6855 25.8203 31.817 25.8656 31.9238 25.9489C32.0306 26.0322 32.1067 26.1487 32.14 26.28L35.72 39.79C35.7542 39.9204 35.8307 40.0358 35.9375 40.1181C36.0442 40.2004 36.1752 40.2451 36.31 40.2451C36.4448 40.2451 36.5758 40.2004 36.6826 40.1181C36.7893 40.0358 36.8658 39.9204 36.9 39.79L40.45 26.28C40.4853 26.1479 40.5633 26.0312 40.6718 25.948C40.7803 25.8648 40.9133 25.8198 41.05 25.82H47" stroke="#F7931E" strokeWidth="2" strokeMiterlimit="10" />
        </svg>
    );
}