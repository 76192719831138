export const DataProvider_MONGO = (uri, type, parameters, token) => {
    let options = false;
    let url = false;
    let response_type = "json";

    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('x-api-key', token);
    myHeaders.append("Access-Control-Allow-Origin", "*");
    myHeaders.append("Access-Control-Allow-Headers", "Origin, X-Requested-With, Content-Type, Accept");
    switch (type) {
        // Patients
        case "GET_PATIENTS":

            if ("project" in parameters) {
                //create url
                url = uri + "/patients/get"
                //create options
                options = {
                    method: 'POST',
                    headers: myHeaders,
                    body: JSON.stringify(parameters),
                    cache: 'default'
                };
            }

            break;

        // Studies
        case "GET_STUDIES":
            if ("search_query" in parameters && "db_name" in parameters) {
                //create url
                url = uri + "/studies/get"
                //create options
                options = {
                    method: 'POST',
                    headers: myHeaders,
                    body: JSON.stringify(parameters),
                    cache: 'default'
                };
            }
            break;
        case "SET_STUDY_OPEN":
            if ("open" in parameters && "db_name" in parameters && "study_id" in parameters) {
                //create url
                url = uri + "/studies/set_open"
                //create options
                options = {
                    method: 'POST',
                    headers: myHeaders,
                    body: JSON.stringify(parameters),
                    cache: 'default'
                };
            }
            break;
        case "GET_STUDY_OPEN":
            if ("db_name" in parameters && "study_id" in parameters) {
                //create url
                url = uri + "/studies/get_open"
                //create options
                options = {
                    method: 'POST',
                    headers: myHeaders,
                    body: JSON.stringify(parameters),
                    cache: 'default'
                };
            }
            break;
    
        // Preview files --> files selected to load
        case "GET_FILES_TO_LOAD":
            url = uri + "/files/get"
            options = {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify(parameters),
                cache: 'default'
            };
            break;
        case "UPDATE_FILES_TO_LOAD":
            url = uri + "/files/update"
            options = {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify(parameters),
                cache: 'default'
            };
            break;

        // Analysis
        case "GET_STUDY_STATUS":
            url = uri + "/status/get_study_status"
            options = {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify(parameters),
                cache: 'default'
            };
            break;
        case "GET_ANALYSIS_STATUS_LIST":
            //create url
            url = uri + "/status/get_all"
            //create options
            options = {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify(parameters),
                //    mode: 'no-cors',
                cache: 'default'
            };

            break;
        case "GET_ANALYSIS":

            if ("id" in parameters && "db_name" in parameters && "editor" in parameters) {

                //create url
                url = uri + "/analysis/get"
                //create options
                options = {
                    method: 'POST',
                    headers: myHeaders,
                    body: JSON.stringify(parameters),
                    cache: 'default'
                };
            }

            break;
        case "UPDATE_ANALYSIS":
            if ("db_name" in parameters && "id" in parameters && "images" in parameters && "validated" in parameters && "editor" in parameters) {
                //create url
                url = uri + "/analysis/update"
                //create options
                options = {
                    method: 'POST',
                    headers: myHeaders,
                    body: JSON.stringify(parameters),
                    // mode: 'no-cors',
                    cache: 'default'
                };
            }
            break;

        case "GET_ANALYSIS_LAST_UPDATE":
            if ("db_name" in parameters && "id" in parameters && "editor" in parameters) {
                //create url
                url = uri + "/analysis/last_update"
                //create options
                options = {
                    method: 'POST',
                    headers: myHeaders,
                    body: JSON.stringify(parameters),
                    cache: 'default'
                };
            }
            break;

        // Reviews
        case "SAVE_REVIEW":
            url = uri + "/reviews/create"
            options = {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify(parameters),
                cache: 'default'
            };
            break;
        case "GET_REVIEW":
            url = uri + "/reviews/get"
            options = {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify(parameters),
                cache: 'default'
            };
            break;
        case "VALIDATE":
            url = uri + "/reviews/validate"
            options = {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify(parameters),
                cache: 'default'
            };
            break;
        case "UNVALIDATE":
            url = uri + "/reviews/unvalidate"
            options = {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify(parameters),
                cache: 'default'
            };
            break;
        case "VALIDATED-TO-CHECK":
            url = uri + "/reviews/validated_to_check"
            options = {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify(parameters),
                cache: 'default'
            };
            break;
        case "UPDATE-REVIEW-STATE":
            url = uri + "/reviews/update_state"
            options = {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify(parameters),
                cache: 'default'
            };
            break;
                    
        // Comments
        case "SAVE_COMMENT":
            url = uri + "/comments/create"
            options = {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify(parameters),
                cache: 'default'
            };
            break;
        case "REMOVE_COMMENT":
            url = uri + "/comments/remove"
            options = {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify(parameters),
                cache: 'default'
            };
            break;
        case "REMOVE_IMAGE_COMMENTS":
            url = uri + "/comments/remove_type"
            options = {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify(parameters),
                cache: 'default'
            };
            break;
        case "GET_COMMENTS":
            url = uri + "/comments/get"
            options = {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify(parameters),
                cache: 'default'
            };
            break;
        case "GET_ALL_SCORES":
            url = uri + "/scores/get_all"
            options = {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify(parameters),
                cache: 'default'
            };
            break;
            
        default:
            throw new Error(`Unsupported Data Provider request type ${type}`);
    }

    if (options !== false && url !== false) {
        let request = new Request(url, options);
        return fetch(request).then((response) => {
            if (response.status === 200) {

                if (response_type === "json") {

                    return response.json();

                } else if (response_type === "blob") {

                    return response.blob();

                } else if (response_type === "image") {

                    let blob = response.blob();
                    return URL.createObjectURL(blob);

                }

            } else {

                if (response.statusText === "UNAUTHORIZED") {
                    return {
                        "error": "UNAUTHORIZED"
                    }
                } else {
                    return {
                        "error": "UNAUTHORIZED"
                    }
                }
            }

        }).catch((error) => {
            console.error('Error:', error);
        });;

    } else {
        throw new Error(`Unsupported Data Provider request parameters ${parameters}`);
    }
}