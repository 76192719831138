import React, { useState, useEffect } from 'react';

// Components
import { ImageMenuIcons } from './../icons_component/image_menu_icons';

// Helpers
import { HelperUser } from './../../../helpers/helper_user/helper_user';
import { HelperStudies } from './../../../helpers/helper_studies/helper_studies';

export const ChatComponent = ({
        image,
        image_analysis,
        attribute,
        comment_type,
        handle_exit,
        user_type
    }) => {

    const [comments, setComments] = useState(image[attribute]? image[attribute] : []);

    useEffect(() => {
        const handleKeyboard = (evt) => {
            if (evt.key === "Escape" && handle_exit) {
                handle_exit();
            } else if (evt.key === "Enter" && user_type !== "Viewer") {
                sendMessage();
            }
        }
        window.addEventListener("keyup", handleKeyboard);
        return () => window.removeEventListener("keyup", handleKeyboard);
      }, [comments]);

    const sendMessage = () => {
        const comment_section = document.getElementById("comment");
        const comment_value = comment_section.value.replace("\n","");
        if (comment_value !== "") {
            const user = HelperUser.getUser();
            const comment_obj = {
                date: String(new Date()),
                user: user.user_email,
                initials: user.user_name.charAt(0) + user.user_family_names.charAt(0)
            };
            if (attribute === "comments") {
                comment_obj.content = comment_value;
                comment_obj.type = comment_type;
                comment_obj.study_id = HelperStudies.getStudyId();
                comment_obj.image_filename = image.filename;
            } else {
                comment_obj.text = comment_value;
            }
            // const comments_copy = JSON.parse(JSON.stringify(comments));
            // comments_copy.push(comment_obj);
            const comments_copy = [...comments, comment_obj];
            setComments(comments_copy);
            image[attribute] = comments_copy;
            if (image_analysis) {
                image_analysis[attribute] = comments_copy;
            }
            if (attribute === "comments") {
                HelperStudies.saveComment(comment_obj, false);
            }
        }
        comment_section.value = "";
    }

    const removeMessage = (comment, ind) => {
        // const comments_copy = JSON.parse(JSON.stringify(comments));
        // comments_copy.splice(ind, 1);
        const comments_copy = comments.filter((com,i) => i !== ind);
        setComments(comments_copy);
        image[attribute] = comments_copy;
        if (image_analysis) {
            image_analysis[attribute] = comments_copy;
        }
        if (attribute === "comments") {
            HelperStudies.removeComment(comment);
        }
    }

    const renderComments = () => {
        if (comments.length > 0) {
            return comments.map((comment,i) => {
                if (comment.type === comment_type) {
                    return (
                        <div className="comment" key={i}>
                            <div className="initials">{comment.initials}</div>
                            <div>
                                <span>{(comment.content && comment.content) || comment.text}</span>
                                <span className="date">{String(comment.date)}</span>
                            </div>
                            {renderTrash(comment, i)}
                        </div>
                    );    
                }
            });    
        } else {
            return <span style={{ color: "#aaa", fontSize: "14px", fontStyle: "italic" }}>There are no comments yet</span>;
        }
    }

    const renderTrash = (comment, i) => {
        if (HelperUser.getUser().user_email === comment.user) {
            return (
                <div className="trash-button" onClick={() => { removeMessage(comment, i) }}>
                    <ImageMenuIcons type={"remove"} style={{ fill: "white" }} width="22" height="22" />
                </div>
            );
        }
    }

    const renderNewComment = () => {
        if (user_type !== "Viewer") {
            const text = attribute === "comments"? "All users with access to this study will see your comments" : "";
            return (
                <div className="new-comment">
                    <textarea id="comment" placeholder={text}></textarea>
                    <div className="send-button" onClick={() => { sendMessage() }}>Add comment</div>
                </div>
            );    
        }
    }

    return (
        <div className="chat component">
            {/* <div className="title">Image comments panel</div> */}
            <div className="comments-box">
                {renderComments()}
            </div>
            {renderNewComment()}
        </div>
    );
}