import React from 'react';

export const CWDopplerIcon = ({
        className,
        style,
        width,
        height
    }) => {
    
    return (
        <svg className={className} width={width} height={height} style={style} viewBox="0 0 50 50" fill="none">
            <path d="M46.68 49H3.32C2.04 49 1 47.96 1 46.68V3.32C1 2.04 2.04 1 3.32 1H46.68C47.96 1 49 2.04 49 3.32V46.68C49 47.96 47.96 49 46.68 49Z" stroke="#5BFF52" strokeWidth="2" strokeMiterlimit="10" />
            <path d="M5.35001 25C5.35001 25 5.53001 29 6.11001 32.06C6.37001 33.38 7.24001 40.89 8.00001 42C9.52001 44.23 12.85 43.15 14 42C15 41 15.41 36.8 16 34C16.43 31.94 17.3 25.42 17.3 25.42C17.38 25.17 17.62 25 17.88 25H21.51C21.78 25 22.02 24.82 22.1 24.56L26.04 10.9C26.21 10.3 27.06 10.31 27.22 10.91L30.92 24.55C30.99 24.82 31.23 25 31.51 25H33.59C33.87 25 34.11 24.81 34.18 24.54L37.76 11.03C37.92 10.42 38.78 10.42 38.94 11.03L42.49 24.54C42.56 24.81 42.8 25 43.08 25H49" fill="#5BFF52" />
            <path d="M5.35001 25C5.35001 25 5.53001 29 6.11001 32.06C6.37001 33.38 7.24001 40.89 8.00001 42C9.52001 44.23 12.85 43.15 14 42C15 41 15.41 36.8 16 34C16.43 31.94 17.3 25.42 17.3 25.42C17.38 25.17 17.62 25 17.88 25H21.51C21.78 25 22.02 24.82 22.1 24.56L26.04 10.9C26.21 10.3 27.06 10.31 27.22 10.91L30.92 24.55C30.99 24.82 31.23 25 31.51 25H33.59C33.87 25 34.11 24.81 34.18 24.54L37.76 11.03C37.92 10.42 38.78 10.42 38.94 11.03L42.49 24.54C42.56 24.81 42.8 25 43.08 25H49" stroke="#5BFF52" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M2 25H48" stroke="#5BFF52" strokeWidth="2" strokeMiterlimit="10" />
        </svg>
    );
}