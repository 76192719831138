export class HelperUserLocalStorage{
    static setUser(token, name, family_names, email, date){
        localStorage.setItem('token', token);
        localStorage.setItem("name", name);
        localStorage.setItem("family_names", family_names)
        localStorage.setItem("email", email)
        localStorage.setItem("createdAt", date)
    }
    static getUserType() {
        return localStorage.getItem("user_type");
    }
    static setUserType(rol) {
        return localStorage.setItem("user_type",rol);
    }
    static getUserRoles() {
        return localStorage.getItem("user_roles");
    }
    static setUserRoles(roles) {
        return localStorage.setItem("user_roles",roles);
    }
    static removeUser(){
        localStorage.removeItem("token");
        localStorage.removeItem("name");
        localStorage.removeItem("family_names");
        localStorage.removeItem("email")
        localStorage.removeItem("createdAt")
        localStorage.removeItem("user_type")
        localStorage.clear()
        try {
            window.indexedDB.databases().then((dbs)=>{
                console.log(dbs)
                dbs.forEach(db => {
                    window.indexedDB.deleteDatabase(db.name)
                })
            })
            
        } catch {
            console.log("PRoblems removing indexed dbs")
        }
        sessionStorage.clear()
    }
    static getUser(){
        return {
            user_type: localStorage.getItem("user_type"),
            user_token: localStorage.getItem("token"),
            user_name: localStorage.getItem("name"),
            user_family_names: localStorage.getItem("family_names"),
            user_email: localStorage.getItem("email")
        }
    }
}