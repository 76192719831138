import { findWhere } from "underscore";
import { getThickness, calculateAreaLengthVolumes, calculateAreas } from "../../modules/geometry_module";
import { getDopplerRegion } from "../../modules/dicom_module";

export class HelperMeasurementsBMode {

    static get2DMeasurements(measurements, segmentation, image) {
        let measures2render = [];
        if (measurements) {
            measurements.map((measure) => {
                measure.value = [];
                if (measure.type === "thickness") {
                    let data = getThickness(measure.shape, measure.cardiac_event, segmentation, "", image);
                    measure.value = data.mean;
                    measure.array = data.all;
                } else if (measure.type === "oval circ") {
                    if (segmentation[0]?.lines[0].points.length === 3) {
                        let a = segmentation[0].lines[0].points[0];
                        let b = segmentation[0].lines[0].points[1];
                        let center = { x: (b.x+a.x)/2, y: (b.y+a.y)/2 }
                        let major = Math.sqrt(Math.pow(center.x-a.x,2) + Math.pow(center.y-a.y,2));
                        let c = segmentation[0].lines[0].points[2];
                        let minor = Math.sqrt(Math.pow(center.x-c.x,2) + Math.pow(center.y-c.y,2));
                        // let meta = getDopplerRegion(image.dicom.metadata.doppler_regions, image.modality);
                        let meta = image.metadata.flow_region;
                        let ratio_x = meta.physical_delta_x? meta.physical_delta_x : meta.spacing_x;
                        let circ = Math.PI*(3*(major+minor)-Math.sqrt((3*major+minor)*(major+3*minor)))*ratio_x;
                        measure.value.push(circ);
                    }
                } else if (measure.type === "oval diam") {
                    if (segmentation[0]?.lines[0].points.length === 3) {
                        let diam = false;
                        let a = segmentation[0].lines[0].points[0];
                        let b = segmentation[0].lines[0].points[1];
                        let c = segmentation[0].lines[0].points[2];
                        // let meta = getDopplerRegion(image.dicom.metadata.doppler_regions, image.modality);
                        let meta = image.metadata.flow_region;
                        let ratio_x = meta.physical_delta_x? meta.physical_delta_x : meta.spacing_x;
                        let ratio_y = meta.physical_delta_y? meta.physical_delta_y : meta.spacing_y;
                        if (measure.formula === "long") {
                            let delta_x = Math.abs(b.x-a.x)*ratio_x;
                            let delta_y = Math.abs(b.y-a.y)*ratio_y;
                            diam = Math.sqrt(Math.pow(delta_x,2) + Math.pow(delta_y,2));    
                        } else if (measure.formula === "short") {
                            let center = { x: (b.x+a.x)/2, y: (b.y+a.y)/2 }
                            let delta_x = Math.abs(center.x-c.x)*ratio_x;
                            let delta_y = Math.abs(center.y-c.y)*ratio_y;
                            diam = (Math.sqrt(Math.pow(delta_x,2) + Math.pow(delta_y,2)))*2;
                        }
                        measure.value.push(diam);
                    }
                } else if (measure.type === "length") {
                    if (segmentation[0]?.lines[0].points.length === 2) {
                        let a = segmentation[0].lines[0].points[0];
                        let b = segmentation[0].lines[0].points[1];
                        // let meta = getDopplerRegion(image.dicom.metadata.doppler_regions, image.modality);
                        let meta = image.metadata.flow_region;
                        let ratio_x = meta.physical_delta_x? meta.physical_delta_x : meta.spacing_x;
                        let ratio_y = meta.physical_delta_y? meta.physical_delta_y : meta.spacing_y;
                        let delta_x = Math.abs(b.x-a.x)*ratio_x;
                        let delta_y = Math.abs(b.y-a.y)*ratio_y;
                        let length = Math.sqrt(Math.pow(delta_x,2) + Math.pow(delta_y,2));
                        measure.value.push(length);
                    }
                } else if (measure.type === "volume") {
                    let volumes = calculateAreaLengthVolumes(measure.shape, measure.cardiac_event, segmentation, "", image);
                    measure.value = volumes;
                } else if (measure.type === "area") {
                    let areas = calculateAreas(measure.shape, measure.cardiac_event, segmentation, image);
                    measure.value = areas;
                } else if (measure.type === "SV") {
                    let ESV_array = calculateAreaLengthVolumes(measure.shape, "end_systole", segmentation, "", image);
                    let EDV_array = calculateAreaLengthVolumes(measure.shape, "end_diastole", segmentation, "", image);
                    if (ESV_array.length === EDV_array.length) {
                        let values = [];
                        ESV_array.map((ESV, i) => {
                            values.push(this.calculateStrokeVolume(EDV_array[i], ESV))
                        })
                        measure.value = values;
                    }
                } else if (measure.type === "EF") {
                    let ESV_array = calculateAreaLengthVolumes(measure.shape, "end_systole", segmentation, "", image);
                    let EDV_array = calculateAreaLengthVolumes(measure.shape, "end_diastole", segmentation, "", image);
                    if (ESV_array.length === EDV_array.length) {
                        let values = [];
                        ESV_array.map((ESV, i) => {
                            let SV = this.calculateStrokeVolume(EDV_array[i], ESV);
                            let EF = this.calculateEjectionFraction(EDV_array[i], SV);
                            values.push(EF);
                        })
                        measure.value = values;
                    }
                }
                measures2render.push(measure);
            });
        }
        if (image.measurements && image.cardiac_events.length > 0) {
            let LV_vol = image.measurements.LV_vol;
            let LA_vol = image.measurements.LA_vol;
            if (LV_vol && LA_vol) {
                measures2render.push({
                    "name": "LV vol (t)",
                    "value": LV_vol,
                    "type": "plot",
                    "shape": "LV"
                })
                measures2render.push({
                    "name": "LA vol (t)",
                    "value": LA_vol,
                    "type": "plot",
                    "shape": "LA"
                })
            } else {
                let lv_vol = findWhere(image.measurements, { name: "LV vol (t)" });
                let la_vol = findWhere(image.measurements, { name: "LA vol (t)" });
                if (lv_vol) {
                    lv_vol["shape"] = "LV"
                    measures2render.push(lv_vol)
                } else {
                    measures2render.push({
                        "name": "LV vol (t)",
                        "value": [],
                        "type": "plot",
                        "shape": "LV"
                    })
                }
                if (la_vol) {
                    lv_vol["shape"] = "LA"
                    measures2render.push(la_vol)
                } else {
                    measures2render.push({
                        "name": "LA vol (t)",
                        "value": [],
                        "type": "plot",
                        "shape": "LA"
                    })
                }
            }
        }
        return measures2render;
    }

    static calculateStrokeVolume(EDV, ESV) {
        return EDV - ESV;
    }

    static calculateEjectionFraction(EDV, SV) {
        return (SV / EDV) * 100;
    }

}