import {
    HelperSegmentation
} from "../helpers/helper_segmentation/helper_segmentation";
import {
    HelperSelection
} from "../helpers/helper_selection/helper_selection";
import {
    HelperStudies
} from "../helpers/helper_studies/helper_studies";
import {
    onActionToPerform
} from "./actions";

export const validationActions = (action, myC) => {
    const image = action.value.image;
    let state = {};

    switch (action.action) {
        case "INIT":
            if (image.is_segmented) {
                state = HelperSegmentation.init(image);
            }
            break;
        case "INIT-VALIDATION":
            const initial_images = [];
            for (let i = 0; i < 4; i++) {
                if (myC.state.images && myC.state.images[i]) {
                    initial_images.push(myC.state.images[i]);
                }
            }
            state.images_to_show = initial_images;
            break;
        case "VALIDATE":
            image.validated = action.value.type === undefined ? !image.validated : action.value.type;
            HelperStudies.saveReview();
            break;
        case "VALIDATE-PAGE":
            for (let img of myC.state.images_to_show) {
                img.validated = true;
            }
            HelperStudies.saveReview();
            break;
        case "VALIDATE-ANALYSIS":
            HelperStudies.saveReview(true);
            HelperStudies.validateStudy(false);
            onActionToPerform("GENERAL", { action: "GO_TO_PAGE", value: { page: "review" } }, myC);
            break;
        case "ADD-CHECK-POINT":
            HelperStudies.saveReview();
            HelperStudies.validateStudy(1);
            onActionToPerform("GENERAL", { action: "GO_TO_PAGE", value: { page: "review" } }, myC);
            break;
        case "REOPEN-ANALYSIS":
            HelperStudies.setValidated(false);
            HelperStudies.unvalidateStudy().then(() => {
                onActionToPerform("GENERAL", { action: "GO_TO_PAGE", value: { page: "validation" } }, myC);
                window.location.reload();    
            });
            break;
        case "EDIT":
            state.image_to_edit = action.value.image;
            state.edit_option = action.value.type;
            break;
        case "CHANGE-LABEL":
            if (action.type === "type") {
                HelperSelection.checkAnalysis(image, action.value.value);
                image.type = action.value.value.type;
                image.view = action.value.value.view;
                image.modality = action.extra;
            } else if (action.type === "view") {
                image.view = action.value.value.type;
            }
            break;
        case "QC-ACCEPT":
            image.accepted = action.value.type;
            HelperStudies.saveQuickQC(image);
            HelperStudies.saveReview();
            break;
        case "CLOSE-EDITING":
            state.image_to_edit = false;
            state.edit_option = false;
            HelperStudies.checkStudyState();
            HelperStudies.saveReview();
            break;
        case "REMOVE":
            state.images = myC.state.images;
            let index = state.images.indexOf(action.value.image);
            state.images.splice(index, 1);
            state.images_to_show = myC.state.images_to_show;
            index = state.images_to_show.indexOf(action.value.image);
            state.images_to_show.splice(index, 1);
            HelperStudies.saveReview();
            break;
        case "DRAW-CANVAS":
            const element = action.value.element;
            const context = action.value.canvas.getContext('2d');
            const height = action.value.canvas.height;
            context.clearRect(0, 0, action.value.canvas.width, action.value.canvas.height);
            if (image && (image.is_annotated || (image.modality === "2D" && image.segmentation.length > 0))) {
                context.beginPath();
                HelperSegmentation.drawOnsets(image, height, context, element, myC.state);
                HelperSegmentation.drawSegmentation(image, context, myC.state, element);
                HelperSegmentation.drawMeasurements(image, context, myC.state, element, height);
                context.closePath();
            }
            break;
        case "GO-BACK":
            state.start = myC.state.start - action.value.step;
            const previous_images = [];
            for (let i = state.start; i < state.start + action.value.step; i++) {
                if (myC.state.images[i]) {
                    previous_images.push(myC.state.images[i]);
                }
            }
            state.images_to_show = previous_images;
            break;
        case "GO-NEXT":
            state.start = myC.state.start + action.value.step;
            const next_images = [];
            for (let i = state.start; i < state.start + action.value.step; i++) {
                if (myC.state.images[i]) {
                    next_images.push(myC.state.images[i]);
                }
            }
            state.images_to_show = next_images;
            break;
        default:
            break;
    }

    state.image = image;
    myC.setState(state);
}

export const validationKeyboardActions = ({
    event,
    value
}, myC) => {
    let action;

    if (event.ctrlKey && event.key === 'v') { // VALIDATE PAGE
        event.preventDefault();
        action = {
            action: "VALIDATE-PAGE",
            value: {
                image: myC.state.image
            }
        };
    }

    if (action) {
        validationActions(action, myC);
    }

}