import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    toolboxUser: {
        position: "absolute",
        right: "0px",
        zIndex: "288888",
        backgroundColor: "white",
        overflow: "visible",
        padding: "7px",
        paddingBottom: "0px",
        top: "55px",
        color: "grey"
    },
    row: {
        padding: "7px",
        borderBottom: "1px solid #cccccc80"
    },
    text: {
        paddingTop: "5px",
        paddingLeft: "10px",
        paddingBottom: "5px"
    },
    icon: {
        width: "25px",
        height: "25px",
        fill: "#2d2e2e"
    },
    buttonText: {
        paddingTop: "5px",
        paddingLeft: "10px"
    },
    button: {
        backgroundColor: "#b71c1c",
        color: "white",
        border: "1px solid white",
        borderTop: "none",
        padding: "7px",
        marginLeft: "-7px",
        marginRight: "-7px",
        cursor: "pointer",
        "&:hover": {
            color: "#b71c1c",
            backgroundColor: "white"
        }
    },
    tab: {
        color: "white",
        paddingRight: "10px",
        paddingLeft: "10px",
        paddingTop: "13px",
        fontSize: "14px",
        borderTop: "7px solid #b71c1c",
        height: "55px",
        overflowY: "hidden",
        cursor:"pointer",
        "&:hover":{
            backgroundColor: "#272822ad"
        }
    },
    currentTab: {
        color: "white",
        paddingRight: "10px",
        paddingLeft: "10px",
        fontSize: "14px",
        paddingTop: "13px",
        borderTop: "7px solid #272822",
        backgroundColor: "#272822",
        height: "55px",
        overflowY: "hidden"
    },
    header: {
        backgroundColor: "#b71c1c",
        paddingLeft: "10px",
        height: "55px",
        maxHeight: "55px",
        overflowY: "hidden",
    },
    userIcon: {
        width: "42px",
        height: "42px",
        backgroundColor: "#272822",
        color: "#b71c1c",
        borderRadius: "100%",
        // padding: "2px",
        display: "flex",
        textAlign: "center",
        alignContent: "center",
        alignItems: "center",
        marginTop: "7px",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "white"
        }

    },
    userAbbr: {
        display: "flex",
        textAlign: "center",
        flexDirection: "column",
        fontWeight: "bold"
    },
    headerSeparator: {
        borderLeft: "1px solid #152935",
        paddingRight: "0px",
        marginRight: "5px",
        marginTop: "-3px",
        height: "60px"  
    },
    commentIconDiv: {
        "&:focus": {
            "background-color": "transparent",
            "border": "0px",
            outline: "none",
            "box-shadow": "none"
        },
        cursor: "pointer",
        maxWidth: "42px",
        justifyContent: "center"
    },
    icon: {
        width: "42px",
        height: "42px",
        stroke: "white",
        cursor: "pointer",
        paddingTop: "12px",
        "&:focus": {
            backgroundColor: "transparent",
            color: "#c62828",
            border: "0px",
            outline: "none",
            boxShadow: "none"
        }
    },
        toolboxUserIcons: {
            // width: "25px",
            height: "25px",
            fill: "#2d2e2e",
            cursor: "pointer"
        }
}));