import React from 'react';

export const PseudoDopplerIcon = ({
        className,
        style,
        width,
        height
    }) => {
    
    return (
        <svg className={className} width={width} height={height} style={style} viewBox="0 0 50 50" fill="none">
            <path d="M46.68 1H3.32C2.0387 1 1 2.0387 1 3.32V46.68C1 47.9613 2.0387 49 3.32 49H46.68C47.9613 49 49 47.9613 49 46.68V3.32C49 2.0387 47.9613 1 46.68 1Z" stroke="#781B6F" strokeWidth="2" strokeMiterlimit="10" />
            <path d="M7 12.0966C14.9032 28.5639 24.5 12.0966 24.5 12.0966C29.7193 1.10555 42 15.7109 42 15.7109" stroke="url(#paint0_angular)" strokeWidth="3" />
            <path d="M7 22.9948C14.9032 39.462 24.5 22.9948 24.5 22.9948C29.7193 12.0037 42 26.6091 42 26.6091" stroke="url(#paint1_angular)" strokeWidth="3" />
            <path d="M7 34.6812C14.9032 51.1485 24.5 34.6812 24.5 34.6812C29.7193 23.6901 42 38.2955 42 38.2955" stroke="url(#paint2_angular)" strokeWidth="3" />
            <defs>
                <radialGradient id="paint0_angular" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(24.5 13.7077) rotate(90) scale(5.7077 17.5)">
                    <stop offset="0.204004" stopColor="#BF0D35" />
                    <stop offset="0.404521" stopColor="#EC9718" />
                    <stop offset="0.520833" stopColor="#FDF300" />
                    <stop offset="0.667474" stopColor="#29BBDB" />
                    <stop offset="0.75" stopColor="#61BA5F" />
                    <stop offset="0.9999" stopColor="#4850D1" />
                </radialGradient>
                <radialGradient id="paint1_angular" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(24.5 24.6058) rotate(90) scale(5.7077 17.5)">
                    <stop offset="0.204004" stopColor="#BF0D35" />
                    <stop offset="0.404521" stopColor="#EC9718" />
                    <stop offset="0.520833" stopColor="#FDF300" />
                    <stop offset="0.667474" stopColor="#29BBDB" />
                    <stop offset="0.75" stopColor="#61BA5F" />
                    <stop offset="0.9999" stopColor="#4850D1" />
                </radialGradient>
                <radialGradient id="paint2_angular" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(24.5 36.2923) rotate(90) scale(5.7077 17.5)">
                    <stop offset="0.204004" stopColor="#BF0D35" />
                    <stop offset="0.404521" stopColor="#EC9718" />
                    <stop offset="0.520833" stopColor="#FDF300" />
                    <stop offset="0.667474" stopColor="#29BBDB" />
                    <stop offset="0.75" stopColor="#61BA5F" />
                    <stop offset="0.9999" stopColor="#4850D1" />
                </radialGradient>
            </defs>
        </svg>
    );
}