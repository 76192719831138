import Store from './../../models/store';

export class HelperUserStore {
    static setUser(token, name, family_names, email) {
        Store.setUser(token, name, family_names, email)
    }
    static setUserType(type) {
        Store.setUserType(type);
    }
    static getUser(){
        return Store.getUser();
    }
    static removeUser(){
        Store.removeUser()
    }
}