export const DataProvider_MINIO = (uri, type, parameters, token) => {
    let options = false;
    let url = false;
    let response_type = "json";
    let data = false;

    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('x-api-key', token);
    myHeaders.append("Access-Control-Allow-Origin", "*");
    myHeaders.append("Access-Control-Allow-Headers", "Origin, X-Requested-With, Content-Type, Accept");
    switch (type) {
        case "GET_PRESIGNED_URLS_FROM_MINIO":
            data = {}
            if ("bucket" in parameters && "object" in parameters) {
                data = parameters
            }
            //create url
            url = uri + "/minio/get_urls"
            //create options
            options = {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify(data),
                cache: 'default'
            };
            break;
        case "DELETE_TEAM_REVIEW_MINIO":
            url = uri + "/minio/delete_team_review"
            options = {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify(parameters),
                cache: 'default'
            };
            break;
        case "CREATE_DICOM_SNAPSHOT":
            url = uri + "/minio/create_snapshot"
            options = {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify(parameters),
                cache: 'default'
            };
            break;

        default:
            throw new Error(`Unsupported Data Provider request type ${type}`);
    }

    if (options !== false && url !== false) {
        let request = new Request(url, options);
        return fetch(request).then((response) => {
            if (response.status === 200) {

                if (response_type === "json") {

                    return response.json();

                } else if (response_type === "blob") {

                    return response.blob();

                } else if (response_type === "image") {

                    let blob = response.blob();
                    return URL.createObjectURL(blob);

                }

            } else {

                if (response.statusText === "UNAUTHORIZED") {
                    return {
                        "error": "UNAUTHORIZED"
                    }
                } else {
                    return {
                        "error": "UNAUTHORIZED"
                    }
                }
            }

        }).catch((error) => {
            console.error('Error:', error);
        });;

    } else {
        throw new Error(`Unsupported Data Provider request parameters ${parameters}`);
    }
}