import React from 'react';

export const NolegendIcon = ({
        className,
        style,
        width,
        height
    }) => {
    
    return (
        <svg className={className} width={width} height={height} style={style} viewBox="0 0 24 24">
            <path d="M7.33,17.78l-.33.3v-.3ZM7,9.78v4h4.74l4.41-4Zm-3,8H0v4H2.92L4,20.8Zm3,4H24v-4H9.57L7,20.11Zm17-20H7v4H20.56L24,2.66Zm-20,0H0v4H4Zm20,4V4.68l-1.21,1.1Zm-20,4H0v4H4Zm20,4v-4H18.39l-4.41,4ZM24,0,0,21.77v2H.77L24,2.7Z"/>
        </svg>
    );
}