import config_help from './../../config/config_help.json';
import config_tabs from './../../config/config_tabs.json';
import config_styles from './../../config/config_styles.json';
import config_tools from './../../config/config_tools.json';
import config_user_roles from './../../config/config_user_roles.json';
import template_image_analysis from './../../templates/template_image_analysis.json';
import template_segmentation from './../../templates/template_segmentation.json';
import { findWhere, findIndex } from 'underscore';
import { HelperConfigStore } from './helper_config_store';
import { HelperStudies } from './../helper_studies/helper_studies';

export class HelperConfig {
    // CONFIG API
    static getAPIConfig() {
        return JSON.parse(JSON.stringify(HelperConfigStore.getConfigAPIs()));
    }
    static getCurrentCloud() {
        return HelperConfigStore.getCurrentCloud();
    }
    // CONFIG HELP
    static getHelp(type) {
        let config = JSON.parse(JSON.stringify(config_help));
        return config[type];
    }
    // CONFIG USERS
    static getTestUsers() {
        let config = JSON.parse(JSON.stringify(config_user_roles));
        return config.tester_users;
    }
    static getInactivityPeriod() {
        return 10; // in minutes
    }
    static getInactivityCheck() {
        return 2*60; // in seconds
    }
    static getInactivityTimeout() {
        return 30; // in seconds
    }
    // CONFIG IMAGE TYPES
    static getImageTypes(params) {
        let config = JSON.parse(JSON.stringify(HelperConfigStore.getConfigImageTypes().image_types));
        if (params === "all") {
            return config;
        } else if (params.data_type){
            return config.filter((type) => type.modalities.some(mod => mod.data_types.includes(params.data_type)));
        } else if (params.modality) {
            return config.filter((type) => type.modalities.some(mod => mod.modality === params.modality));
        }
    }
    static getProjectImageTypes(data_type) {
        const imtypes = this.getImageTypes({ data_type });
        const current_project = HelperStudies.getCurrentProjectObj();
        if (current_project.image_types) {
            return imtypes.filter(im => current_project.image_types.includes(im.type));
        } else {
            return imtypes;
        }
    }
    static getImageModalities(data_type) {
        let config = JSON.parse(JSON.stringify(HelperConfigStore.getConfigImageTypes().modalities));
        if (data_type === "all") {
            return config;
        } else {
            return config.filter((mod) => mod.data_types.includes(data_type));
        }
    }
    static getProjectModalities(data_type) {
        const modalities = this.getImageModalities(data_type);
        const current_project = HelperStudies.getCurrentProjectObj();
        return modalities.filter(mod => current_project.modalities.includes(mod.type));
    }
    static getImageViews(data_type) {
        let config = JSON.parse(JSON.stringify(HelperConfigStore.getConfigImageTypes().views));
        if (data_type === "all") {
            return config;
        } else {
            return config.filter((view) => view.data_types.includes(data_type));
        }
    }
    static getAnatomies() {
        let config = JSON.parse(JSON.stringify(HelperConfigStore.getConfigImageTypes()));
        return config.anatomies;
    }
    static getImageTypeObject(type) {
        let config = JSON.parse(JSON.stringify(HelperConfigStore.getConfigImageTypes().image_types));
        return findWhere(config, { type: type });
    }
    static getImageTypeNameToShow(type) {
        const type_obj = this.getImageTypeObject(type);
        return type_obj? type_obj.name_to_show : "No label selected";
    }
    static getPossiblePatterns(type, modality) {
        let image_type = this.getImageTypeObject(type);
        let image_modality = findWhere(image_type.modalities, { modality: modality });
        return image_type.patterns.filter(pat => image_modality.patterns.includes(pat.type));
    }
    static getDefaultPattern(type, modality) {
        let patterns = this.getPossiblePatterns(type, modality);
        return patterns[0].type;
    }
    static getPattern(type, pattern, modality) {
        let image_type = this.getImageTypeObject(type);
        let pattern_obj = findWhere(image_type.patterns, { type: pattern });
        if (!pattern_obj) {
            let pattern_default = this.getDefaultPattern(type, modality);
            if (pattern_default) {
                pattern_obj = findWhere(image_type.patterns, { type: pattern_default });
            }
        }
        return pattern_obj;
    }
    static getShapes(type, pattern, modality) {
        return this.getPattern(type, pattern, modality).shapes;
    }
    static getLines(type, pattern, modality) {
        return this.getPattern(type, pattern, modality).lines;
    }
    static getControlPoints(type, pattern) {
        let image_type = this.getImageTypeObject(type);
        let cps = image_type.control_points.filter(cp => cp.patterns.includes(pattern));
        cps.map((cp,i) => cp.id = i);
        return cps;
    }
    static getAbbreviationFromControlPoint(type, point) {
        let image_type = this.getImageTypeObject(type);
        let cp = findWhere(image_type.control_points, { type: point });
        return cp? cp.abbreviation : "";
    }
    static getBiometryTypes() {
        return ["Head butterfly","Abdominal","Femur"];
    }
    // CONFIG MEASUREMENTS
    static getMeasurements(type){
        let config = JSON.parse(JSON.stringify(HelperConfigStore.getConfigMeasurements().images));
        let measurements_config = findWhere(config, { type: type });
        if (measurements_config) {
            return measurements_config.measurements;
        } else {
            return false;
        }
    }
    static getMeasurementObject(type, name) {
        let config = JSON.parse(JSON.stringify(HelperConfigStore.getConfigMeasurements().images));
        let measurements_config = findWhere(config, { type: type });
        return findWhere(measurements_config.measurements, { name: name });
    }
    static getMeasurementAbbreviation(type, name){
        return this.getMeasurementObject(type, name).abr;
    }
    static getMeasurementNormalRange(type, name){
        return this.getMeasurementObject(type, name).normal_range;
    }
    static getMeasurementsPreference(name) {
        let config = JSON.parse(JSON.stringify(HelperConfigStore.getConfigMeasurements().preferences));
        return config[name];
    }
    static getProjectMeasurements(project) {
        let config = JSON.parse(JSON.stringify(HelperConfigStore.getConfigMeasurements().projects));
        let project_config = findWhere(config, { project: project });
        if (project_config) {
            return project_config.summary;
        } else {
            return [];
        }
    }
    static getSummaryModalities(project) {
        let config = JSON.parse(JSON.stringify(HelperConfigStore.getConfigMeasurements().projects));
        let project_config = findWhere(config, { project: project });
        if (project_config) {
            return project_config.modalities;
        } else {
            return [];
        }
    }
    // CONFIG CALIPER
    static getCaliperMeasurementTypes(view) {
        let config = JSON.parse(JSON.stringify(HelperConfigStore.getConfigCaliper()));
        if (config.views[view]) {
            return config.measurement_types.filter(meas => config.views[view].includes(meas.type));
        } else {
            return [];
        }
    }
    // CONFIG IMAGE QUALITY
    static getImageScoreTemplate(type) {
        let config = JSON.parse(JSON.stringify(HelperConfigStore.getConfigImageQuality().templates));
        let template = config.filter(temp => temp.image_types.includes(type));
        return template.length > 0? template[0].evaluation : false;
    }
    // CONFIG TABS
    static getImageSettings(tab) {
        let config = JSON.parse(JSON.stringify(config_tabs));
        let tab_object = findWhere(config.tabs, { name: tab });
        return tab_object["image-settings"];
    }
    static getTabs(user_type,mode) {
        if (mode === "studies" || mode === "review") {
            return []
        } else {
            let config = JSON.parse(JSON.stringify(config_tabs));
            let tabs = config.tabs.filter(tab => tab.roles.includes(user_type));
            if (tabs.map(t => t.name).includes("clinicaldata")) {
                let project = HelperStudies.getCurrentProjectObj();
                if (!project.clinical_data) {
                    let idx = findIndex(tabs, { name: "clinicaldata" });
                    tabs.splice(idx,1);
                }
            }
            return tabs;
        }
    }
    static getTabUrl(params) {
        let config = JSON.parse(JSON.stringify(config_tabs));
        let tab_object;
        if (params.name) {
            tab_object = findWhere(config.tabs, { name: params.name });
        } else if (params.status) {
            tab_object = findWhere(config.tabs, { status: params.status });
        }
        return tab_object.url;
    }
    static getTabColumns(tab) {
        let config = JSON.parse(JSON.stringify(config_tabs));
        let tab_object = findWhere(config.tabs, { name: tab });
        return tab_object.columns;
    }
    static getStatisticsColor(value){
        let config = JSON.parse(JSON.stringify(config_tabs));
        if (value.includes("segmented")) {
            let tab = findWhere(config.tabs, { name: "segmentation" });
            return tab.color[tab.status.indexOf(value)];
        } else {
            let status = findWhere(config.tabs, { status: value });
            return status? status.color : "white";    
        }
    }
    static getStatisticsUrl(value){
        let config = JSON.parse(JSON.stringify(config_tabs));
        let status = findWhere(config.tabs,{"status": value});
        if(!status){
            return ""
        }
        return status.url;
    }
    // CONFIG STYLES
    static getStyle(mode, type) {
        return JSON.parse(JSON.stringify(config_styles[mode][type]));
    }
    static getControlPointColor(cp) {
        let config = JSON.parse(JSON.stringify(config_styles.segmentation.cp_colors));
        let color = findWhere(config, { type: cp });
        return color? color.color : false;
    }
    // CONFIG TOOLS
    static getToolConfig(mode, tool) {
        return JSON.parse(JSON.stringify(config_tools[mode][tool]));
    }
    // TEMPLATES
    static getImageAnalysisTemplate() {
        return JSON.parse(JSON.stringify(template_image_analysis));
    }
    static getSegmentationTemplate(mode, element) {
        return JSON.parse(JSON.stringify(template_segmentation[mode][element]));
    }

    // ---
    static init(){
        return HelperConfigStore.init();
        // let config_copy = JSON.parse(JSON.stringify(config_adult));
        // config_global = config_copy;

        // let image_templates = HelperImages.init(config_adult);

        // this.images_templates = image_templates;
        // this.logStore("init from config file");
    }
}