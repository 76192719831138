import React from 'react';

// Helpers
import { HelperConfig } from './../../../helpers/helper_config/helper_config';
import { HelperStudies } from './../../../helpers/helper_studies/helper_studies';

// AUX functions
import { where } from 'underscore';

export const ReferenceGrid = ({
        images,
        missing_images,
        showColumns,
        on_action_to_perform,
        handle_image_missing
    }) => {

    const modalities = HelperConfig.getImageModalities(HelperStudies.getDataType());
    const requirements = HelperStudies.getCurrentProjectObj().image_requirements;
    const types_required = requirements? requirements.map(r => r.type) : [];
    const qc_config = HelperStudies.getCurrentProjectObj().qc;
    const maxHeight = HelperConfig.getImageSettings("reference").max_height + "px";
    const notcompleted_images = [];
    for (const img of images) {
        if (img.reference && (!img.view || !img.type)) {
            notcompleted_images.push(img);
        }
    }
    // let image_types = HelperConfig.getImageTypes({ data_type: HelperStudies.getDataType() });
    let image_types = HelperConfig.getProjectImageTypes(HelperStudies.getDataType());
    for (const view of image_types) {
        view.referenceImages = where(images, { type: view.type, reference: true });
    }
    const first_priority = image_types.filter(t => types_required.includes(t.type));
    const second_priority = image_types.filter(t => !first_priority.includes(t) && t.referenceImages.length > 0);
    const nopriority = image_types.filter(t => !first_priority.includes(t) && !second_priority.includes(t));
    image_types = first_priority.concat(second_priority).concat(nopriority);

    const renderImageStatus = (image) => {
        let show_state = true;
        let show_ctiming = true;
        let show_qc = qc_config? qc_config.show_status : false;
        if (image.modality === "2D") {
            // const patterns = HelperConfig.getPossiblePatterns(image.type, image.modality);
            // if (patterns[0].shapes.length === 0) {
            //     show_state = false;
            // }
            if (!image.urls.big_icon.includes(".gif")) {
                show_ctiming = false;
                image.is_annotated = true;
            }
        }
        if (image === "required-empty") {
            return <div className="status-icons empty"></div>
        } else if (show_state) {
            let style = types_required.includes(image.type)? 
                (HelperStudies.imageMeetsRequirements(requirements, image)? { borderColor: "#0b0" } : { borderColor: "red" } ) : {};
            let qc_req = HelperStudies.attributeIsRequired(requirements, image.type, "qc");
            let ct_req = HelperStudies.attributeIsRequired(requirements, image.type, "cycletiming");
            let seg_req = HelperStudies.attributeIsRequired(requirements, image.type, "segmentation");    
            return (
                <div className="status-icons" style={style} >
                    {renderStatusIcon("#FFFFFF", show_qc, image.score || image.accepted, qc_req, "selection", true, image.filename, "Quality control")}
                    {renderStatusIcon("#0099FF", show_ctiming, image.is_annotated, ct_req, "cycletiming", true, image.filename, "Cycle timing")}
                    {renderStatusIcon("#FF5F00", true, image.is_segmented, seg_req, "segmentation", image.is_annotated, image.filename, "Segmentation")}
                </div>
            );
        }
    }

    const renderStatusIcon = (color, show, state, required, mode, selectable, data, title) => {
        if (show) {
            const style = { borderColor: color };
            if (state) { style.backgroundColor = color }
            if (selectable) { style.cursor = "pointer" }
            const onclick = selectable? () => on_action_to_perform("GENERAL", { action: "GO_TO_PAGE", value: { page: mode, image: data } }) : () => {};
            const text = required && !state? "!" : "";
            return (
                <div className="status-icon" style={style} onClick={onclick} title={title}>{text}</div>
            );
        }
    }

    const renderImage = (view, modality) => {
        return images.map((image) => {
            if ((image.type === view.type && image.modality === modality.type && image.view && image.reference)) {
                return (
                    <div key={image.filename} className="view-image">
                        <img src={image.urls.big_icon} style={{ maxHeight }} alt="" />
                        {renderImageStatus(image)}
                        <div className="view-tag"
                            onClick={() => on_action_to_perform("GENERAL", { action: "GO_TO_PAGE", value: { page: "selection", image: image.filename } })}
                            style={{ borderColor: modality.color }}
                        >
                            <span className="view-tag-short">{view.abbreviation}</span>
                            <span className="view-tag-long">{view.name_to_show}</span>
                        </div>
                    </div>
                );
            } else {
                return null;
            }
        });
    }

    const renderNotCompletedImages = () => {
        return notcompleted_images.map((image) => {
            const tag = image.view? image.view : (image.type? HelperConfig.getImageTypeNameToShow(image.type) : "Unlabelled");
            return (
                <div className="view">
                    <div className="view-image">
                        <img src={image.urls.big_icon} style={{ maxHeight }} alt="" />
                        <div className="view-tag" onClick={() => on_action_to_perform("GENERAL", { action: "GO_TO_PAGE", value: { page: "selection", image: image.filename } })}>
                            <span className="view-tag-short">{tag}</span>
                        </div>
                    </div>
                </div>
            );
        });
    }
    
    const renderViewContent = (view, modality) => {
        if (view.referenceImages.some(img => img.modality === modality.type)) {
            return renderImage(view, modality);
        } else if (missing_images.includes(view.type)) {
            return (
                <div className="required-empty">
                    <div className="view-long">{view.name_to_show} is missing</div>
                </div>
            );
        } else if (types_required.includes(view.type)) {
            return (
                <div className="view-image">
                    <div className="required-empty">
                        <div className="view-long">{view.name_to_show}</div>
                        <div style={{ marginBottom: "15px" }}>is required</div>
                        <div className="choose-button"
                            style={{ background: modality.color }}
                            onMouseEnter={(e) => e.target.style.background = 'white'}
                            onMouseLeave={(e) => e.target.style.background = modality.color}
                            onClick={() => on_action_to_perform("GENERAL", { action: "GO_TO_PAGE", value: { page: "selection" } })}
                        >
                            Choose one
                        </div>
                        <div style={{ margin: "10px 0", fontWeight: "normal", fontSize: "13px" }}>unless</div>
                        <div className="choose-button"
                            style={{ background: modality.color }}
                            onMouseEnter={(e) => e.target.style.background = 'white'}
                            onMouseLeave={(e) => e.target.style.background = modality.color}
                            onClick={() => handle_image_missing(view.type)}
                        >
                            Image is missing in study
                        </div>
                    </div>
                    {renderImageStatus("required-empty")}
                </div>
            );
        } else {
            return (
                <React.Fragment>
                    <div className="view-short">{view.abbreviation}</div>
                    <div className="view-long">{view.name_to_show}</div>
                    <div className="choose-button"
                        style={{ background: modality.color }}
                        onMouseEnter={(e) => e.target.style.background = 'white'}
                        onMouseLeave={(e) => e.target.style.background = modality.color}
                        onClick={() => on_action_to_perform("GENERAL", { action: "GO_TO_PAGE", value: { page: "selection" } })}
                    >
                        Choose one
                    </div>
                </React.Fragment>
            );
        }
    }

    const renderViews = (modality) => {
        return image_types.map((view) => {
            if (view.modalities.some(m => m.modality === modality.type)) {
                return (
                    <div key={view.type} className="view" style={{ borderColor: modality.color }}>
                        {renderViewContent(view, modality)}
                    </div>
                );
            } else {
                return null;
            }
        });
    }

    const renderModalityColumns = () => {
        return modalities.map(modality => {
            if (showColumns.includes(modality.type)) {
                return (
                    <div key={modality.type} id={modality.type} className="box">
                        <div className="header" style={{ color: modality.color, borderColor: modality.color }}>{modality.type}</div>
                        <div className="column">
                            {renderViews(modality)}
                        </div>
                    </div>
                );
            } else {
                return null;
            }
        });
    }

    const renderNotCompletedColumn = () => {
        if (images.length > 0 && showColumns.includes("Not completed")) {
            return (
                <div id="Not completed" className="box">
                    <div className="header">Not completed</div>
                    <div>
                        {renderNotCompletedImages()}
                    </div>
                </div>
            );
        }
    }

    return (
        <div className="reference grid">
            {renderNotCompletedColumn()}
            {renderModalityColumns()}
        </div>
    );
}