import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        fontSize: "14px",
        alignContent: "center",
        textAlign: "center",
        marginTop: "10px",
        width: "calc(100vw - 670px)",
        // marginRight:"10vw",
        // marginLeft:"10vw",
        display: "flex",
        alignItems: "center",
        height: "75px",
        overflowX: "auto",
        overflowY: "hidden"
    },
    project: {
        backgroundColor: "#b71c1c",
        padding: "5px",
        borderRadius: "3px",
        marginTop: "-10px",
        margin: "7px",
        cursor: "pointer",
        display: "flex",
        textAlign: "center",
        "&:hover": {
            "color": "#b71c1c",
            backgroundColor: "white"
        }
    },
    currentProject: {
        color: "#b71c1c",
        backgroundColor: "#dadada",
        padding: "5px",
        borderRadius: "3px",
        marginTop: "-10px",
        margin: "7px",
        display: "flex",
        textAlign: "center",
        fontWeight: "bold"
    },
    icon: {
        marginRight: "7px",
        paddingRight: "7px",
        width: "30px",
        height: "30px",
        fill: "#333333",
        "&:focus": {
            "background-color": "transparent",
            "border": "0px",
            outline: "none",
            "box-shadow": "none"
        },
    },
    text: {
        marginTop: "7px"
    }
}))
function renderProject(classes, props) {
    return props.projects.map((project) => {
        let className = classes.project;
        if (project._id === props.currentProject) {
            className = classes.currentProject;
            return (
                <div key={project._id} className={" " + className}>
                    <svg className={classes.icon} focusable="false" viewBox="0 0 24 24" aria-hidden="true" tabIndex="-1"><path d="M20 6h-8l-2-2H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm0 12H4V8h16v10z"></path></svg>
                    <div className={classes.text}>{project.name_to_show}</div>
                </div>
            )
        } else {
            return (
                <div key={project._id} className={" " + className} onClick={() => { props.onactiontoperform("GENERAL", { action: "CHANGE_PROJECT", value: project._id, from: props.from, change: true }) }}>
                    <svg className={classes.icon} focusable="false" viewBox="0 0 24 24" aria-hidden="true" tabIndex="-1"><path d="M20 6h-8l-2-2H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm0 12H4V8h16v10z"></path></svg>
                    <div className={classes.text}>{project.name_to_show}</div>
                </div>
            )
        }
    });
}
export function ProjectPicker(props) {
    const classes = useStyles();
    if (props.projects !== undefined && props.projects.length > 0) {
        return (
            <div className={" " + classes.root}>
                {renderProject(classes, props)}
            </div>
        )

    }

    else {
        return (<div className={"grid-block"}></div>)
    }

}