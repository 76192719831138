import React from 'react';
import { ToolboxUserIcons } from './toolbox_user_icons';

// Helpers
import { HelperUser } from '../../../../helpers/helper_user/helper_user';
import {useStyles} from './../toolbox_styles';
export const ToolboxUser = (props) => {

    const classes = useStyles();

    let user = props.user;
    let string = HelperUser.getName2Show(user);
    let userRole = HelperUser.getUserType();
    
    const renderUserInfo = (input) => {
        if (input === "name") {
            return (
                <div className={"grid-block shrink" + classes.row}>
                    <ToolboxUserIcons icon="user" />
                    <div className="grid-block shrink vertical" style={{ overflow: "hidden" }}>
                        <div className={"grid-block " + classes.text} style={{ overflow: "hidden", fontWeight: "bold" }}>{string}</div>
                        <div className={"grid-block " + classes.text} style={{ fontSize: "12px", overflow: "hidden" }}>{userRole}</div>
                    </div>
                </div>
            )
        } else if (input === "email") {
            return (
                <div className={"grid-block shrink " + classes.row}>
                    <ToolboxUserIcons icon="email" />

                    <div className={"grid-block " + classes.text}>{user.user_email}</div>
                </div>
            )
        }
    }
    const renderButton = (input) => {
        let style = {}, icon, onclick, text;
        if (input === "change-role") {
            icon = "roles";
            onclick = () => { props.on_action_to_perform("GENERAL",{ action: "GO_TO_PAGE", value: { page: "user-page", from: "studies" } }) };
            text = "Change user role";
        } else if (input === "user-manual") {
            style = { backgroundColor: "#a3a3a3" };
            icon = "book";
            let link = "https://drive.google.com/file/d/1UrxJxl7jfJvDTaYWa3MmEWVAbPs5O34u/view?usp=drive_link";
            onclick = () => { window.open(link, '_blank') };
            text = "Open user manual";
        } else if (input === "terms-of-use") {
            style = { backgroundColor: "#a3a3a3" };
            icon = "document";
            let link = "https://drive.google.com/file/d/1T_jm-uWbSTMKQxebAnbh6FwRe8yN7sVd/view?usp=drive_link";
            onclick = () => { window.open(link, '_blank') };
            text = "Read Terms of use";
        } else if (input === "logout") {
            icon = "logout";
            onclick = () => { props.on_action_to_perform("GENERAL",{ action: "LOGOUT", value: false }) };
            text = "Logout";
        }
        return (
            <div className={"grid-block align-center " + classes.button} style={style}>
                <ToolboxUserIcons icon={icon} />
                <div className={"grid-block shrink " + classes.buttonText} onClick={() => onclick()}>{text}</div>
            </div>
        );
    }
    if (props.open) {
        return (
            <div className={classes.toolboxUser}
                onMouseEnter={function () { props.handleUserToolbox(true); }}
                onMouseLeave={function () { props.handleUserToolbox(false); }}
                onMouseOver={function () { props.handleUserToolbox(true); }}
                onClick={function () { props.handleUserToolbox(false); }}
            >
                {renderUserInfo("name")}
                {renderButton("change-role")}
                {renderUserInfo("email")}
                {renderButton("user-manual")}
                {renderButton("terms-of-use")}
                {renderButton("logout")}
            </div>
        );
    } else {
        return (<div></div>)
    }

}