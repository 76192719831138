import { createTheme,  ThemeProvider} from '@material-ui/core/styles';

export const defaultTheme = createTheme({
    overrides: {
      MuiTable:{
        root:{
          backgroundColor: "#272822",
          color: "rgb(253 253 253)"
        },
        body:{
          color: "rgb(253 253 253)"
        }
      },
      MuiTableCell:{
        root:{
          color: "rgb(253 253 253)",
          
        },
        body:{
          color: "rgb(253 253 253)",
        }
      },
      MuiTableRow:{
        body:{
          color: "rgb(253 253 253)",
        }
      },
      MuiToolbar:{
        root:{
          backgroundColor: "#272822",
        }
      },
      MuiSvgIcon:{
        root:{
          // backgroundColor: "white",
        }
      },
      MuiSelect:{
        icon:{
          color: "white",
        }
      }
    }
  });