import React, { Component } from 'react';
import { HelperUser } from './../../../helpers/helper_user/helper_user';
import { HelperConfig } from './../../../helpers/helper_config/helper_config';

// components
import { PlatformNameComponent } from './../../components/platform_name/platform_name_component';
import { PlatformBigIcon } from './../../components/icons_component/platform_big_icon';

export class LoginPage extends Component {

    componentDidMount() {
        HelperConfig.init().then(() => {
            if (HelperUser.isTokenSaved()) {
                console.log("Login succeed")
                this.redirectUser();
            } else {
                localStorage.clear();
                if (window.location.href.indexOf("token") !== -1) {
                    HelperUser.setUser();
                    this.redirectUser();
                }
            }
        });
    }

    redirectUser() {
        return HelperUser.checkUserAcceptedTermsConditions().then(accepted_terms => {
            if (accepted_terms) {
                this.setUrl("user-page");
            } else {
                this.setUrl("accept-terms");
            }
        });
    }

    setUrl(url) {
        if (url !== false) {
            var browserHistory = this.props.history;
            browserHistory.push("/" + url);
        } else {
            alert("URL not compatible");
        }
    }

    onClickLoginButton() {
        HelperUser.login();
    }

    render() {
        return (
            <div className="grid-frame vertical" style={{ width: "100vw", height: "100vh", display: "flex", alignContent: "center", alignItems: "center" }}>
                <div className="login-page">
                    <PlatformBigIcon />
                    <div className="grid-block vertical" style={{ display: "flex", alignContent: "center", alignItems: "center" }}>
                        <PlatformNameComponent type={"big"}/>
                        <div className="login-button" onClick={this.onClickLoginButton.bind(this)}>
                            <svg viewBox="0 0 24 24" strokeWidth="1.5" stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M7 4v16l13 -8z" />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}