export class FileModule {
    static read(file, callback) {
        var reader = new FileReader();
        reader.onload = function (file) {
            var arrayBuffer = reader.result;
            var byteArray = new Uint8Array(arrayBuffer);
            callback(byteArray);
        }
        reader.readAsArrayBuffer(file);
    }
    static readJson(url) {
        return fetch(url).then(res => res.json()).then((out) => { return out; });
    }
}