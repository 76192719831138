import React from 'react';

// Helpers
import { HelperConfig } from './../../../helpers/helper_config/helper_config';

export const HelpMenuComponent = ({
        site,
        modality
    }) => {

    const mode = site === "cycletiming" && modality === "2D" ?
                    "cycletiming2D"
                : site === "cycletiming" ?
                    "cycletimingNormal"
                : site === "segmentation" && modality === "2D" ?
                    "segmentation2D"
                : site === "segmentation" && modality === "M-Mode" ?
                    "segmentationM-Mode"
                : site === "segmentation" ?
                    "segmentationDoppler"
                : "";

    const options = HelperConfig.getHelp(mode);

    return options?.length > 0?
        (
            <div id="help-box">
                {options.map((op,i) => {
                    return (
                        <React.Fragment key={i}>
                            <div className="help-left">{op.shortcut}</div>
                            <div className="help-right">{op.description}</div>
                        </React.Fragment>
                    );
                })}
            </div>
        )
        : null;
}