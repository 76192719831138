import { HelperCycleTiming } from "../helpers/helper_cycletiming/helper_cycletiming";
import { HelperStudies } from "../helpers/helper_studies/helper_studies";

export const cycletimingActions = (action, myC) => {
    const image = action.value.image;
    if (image) {
        let value = myC.state.current_action;
        let state = {};
        switch (action.action) {
            case "INIT":
                value = "init";
                if (myC.drawingCanvas) {
                    const context = myC.drawingCanvas.getContext('2d');
                    context.clearRect(0, 0, myC.drawingCanvas.width, myC.drawingCanvas.height);
                }
                break;
            case "CHECK-IF-FINISHED":
                HelperCycleTiming.checkIfFinished(image);
                break;
            case "CHANGE-ONSET-TYPE":
                image.onset_type = action.value.type;
                break;
            case "SELECT-ONSET":
                if (myC.state.current_action === "editing") {
                    image.cardiac_events.push({
                        onset_start: image.cardiac_events[image.cardiac_events.length-1].onset_end,
                        onset_end: false
                    });
                }
                HelperCycleTiming.addEvent(image, "onset", action.value.value, myC);
                const max_events = HelperStudies.getCurrentProjectMaxCycles();
                if (myC.state.current_action === "editing") {
                    image.cardiac_events.pop();
                } else if (image.cardiac_events.length > max_events) {
                    action.action = "FINISH";
                    cycletimingActions(action, myC);
                }
                break;
            case "SELECT-ENDSYSTOLE":
                value = image.modality==="2D"? myC.state.current_frame : action.value.value;
                HelperCycleTiming.addEvent(image, "end_systole", value, myC);
                break;
            case "SELECT-ENDDIASTOLE":
                value = image.modality==="2D"? myC.state.current_frame : action.value.value;
                HelperCycleTiming.addEvent(image, "end_diastole", value, myC);
                break;
            case "UNDO":
                if (!["finished","editing","selecting-cycles"].includes(myC.state.current_action)) {
                    HelperCycleTiming.removeLastEvent(image, myC.state.current_action);
                }
                break;
            case "FINISH":
                if (myC.state.current_action === "selecting-cycles") {
                    let selection = myC.state.cycles_selected;
                    let sum = selection.filter(cc => cc === true).length;
                    if (sum === myC.state.max_events || window.confirm("Are you sure to keep " + sum + " cycles? All other cycles will be removed.")) {
                        image.cardiac_events = image.cardiac_events.filter((cc,i) => selection[i]);
                        image.segmentation = image.segmentation.filter((cc,i) => selection[i]);
                        value = "finished";    
                    }
                } else if (myC.state.current_action !== "annotating-onsets") {
                    image.is_annotated = HelperCycleTiming.checkIfFinished(image);
                    value = "finished";
                } else {
                    if (image.cardiac_events.length > 1) {
                        image.cardiac_events.pop();
                        image.is_annotated = image.modality !== "M-Mode";
                    }
                }
                break;
            case "EDIT":
                value = "editing";
                break;
            case "REMOVE-ALL":
                value = "remove";
                image.cardiac_events = [];
                image.is_annotated = false;
                // HARDCODE FOR FEDOC AI CORRECTION GABRIEL
                // image.segmentation = [];
                // image.is_segmented = false;
                // image.measurements = [];
                // image.is_measured = false;
                break;
            case "MOVE-SELECTED":
                HelperCycleTiming.moveSelectedOnset(image, myC);
                break;
            case "DRAW-CANVAS":
                if (image.modality !== "2D") {
                    const element = action.value.element;
                    const context = action.value.canvas.getContext('2d');
                    const height = action.value.canvas.height;
                    const width = action.value.canvas.width;
                    context.clearRect(0, 0, action.value.canvas.width, action.value.canvas.height);
                    context.beginPath();
                    if (myC.state.current_action === "selecting-cycles") {
                        HelperCycleTiming.drawCycleRegions(image.cardiac_events, myC.state.cycles_selected, height, width, context, myC.state.mouse.canvas.x, element);
                    } else {
                        HelperCycleTiming.drawMouse(myC.state.current_action, image.cardiac_events, myC.state.element_selected, myC.state.mouse, height, context);    
                    }
                    HelperCycleTiming.drawOnsets(image.cardiac_events, height, context, myC.state.mouse.pixel.x, element, myC.state.current_action);
                    context.closePath();
                }
                break;
            case "MOUSE-DOWN":
                if (image.modality !== "2D") {
                    if (myC.state.current_action !== "finished") {
                        if (action.value.event.which === 1) {
                            if (myC.state.current_action === "editing") {
                                if (myC.state.element_selected) {
                                    myC.setState({ element_selected: false });
                                } else {
                                    const hover_onset = HelperCycleTiming.isOnsetHovered(image.cardiac_events, myC.state.mouse.pixel.x);
                                    const max_events = HelperStudies.getCurrentProjectMaxCycles();
                                    if (hover_onset) {
                                        myC.setState({ element_selected: hover_onset });
                                    } else if (image.modality.includes("Doppler") && max_events > image.cardiac_events.length) {
                                        action.action = "SELECT-ONSET";
                                    }
                                }
                            } else if (myC.state.current_action === "selecting-cycles") {
                                HelperCycleTiming.clickOnSelectCycles(image, myC);
                            } else if (myC.state.current_action === "annotating-onsets") {
                                action.action = "SELECT-ONSET";
                            } else if (myC.state.current_action === "annotating-diastole") {
                                action.action = "SELECT-ENDDIASTOLE";
                            } else if (myC.state.current_action === "annotating-systole") {
                                action.action = "SELECT-ENDSYSTOLE";
                            }
                        } else if (action.value.event.which === 3 && myC.state.current_action === "annotating-onsets") {
                            action.action = "FINISH";
                        }
                    }
                    if (action.action !== "MOUSE-DOWN") {
                        action.value.value = Math.round(myC.state.mouse.pixel.x);
                        cycletimingActions(action, myC);
                    }
                }
                break;
            default:
                break;
        }
        state = HelperCycleTiming.getState(image, value);
        state.image = image;
        myC.setState(state);
    }
}

export const cycletimingKeyboardActions = (event, myC) => {
    let action;
    if (myC.is_mounted) {
        if (event.key === ' ' && myC.state.current_action === "annotating-onsets") { // SET ONSET
            action = { action: "SELECT-ONSET", value: { image: myC.state.image }};
            action.value.value = Math.round(myC.state.mouse.pixel.x);

        } else if (event.key === 'd' && myC.state.current_action === "annotating-diastole") { // SET END DIASTOLE
            action = { action: "SELECT-ENDDIASTOLE", value: { image: myC.state.image }};
            action.value.value = Math.round(myC.state.mouse.pixel.x);

        } else if (event.key === 's' && myC.state.current_action === "annotating-systole") { // SET END SYSTOLE
            action = { action: "SELECT-ENDSYSTOLE", value: { image: myC.state.image }};
            action.value.value = Math.round(myC.state.mouse.pixel.x);

        } else if (event.key === 'f' && myC.state.current_action !== "finished") { // FINISH ANNOTATION
            action = { action: "FINISH", value: { image: myC.state.image }};

        } else if (event.ctrlKey && event.key === 'z') { // UNDO
            event.preventDefault();
            action = { action: "UNDO", value: { image: myC.state.image }};

        } else if (event.ctrlKey && event.key === 'r' && myC.state.current_action !== "selecting-cycles") { // REMOVE ALL
            event.preventDefault();
            action = { action: "REMOVE-ALL", value: { image: myC.state.image }};

        } else if (event.key === 'e' && myC.state.current_action === "finished" && myC.state.image.modality !== "2D") { // EDIT
            action = { action: "EDIT", value: { image: myC.state.image }};
        }

        if (action) {
            cycletimingActions(action, myC);
        }

        //-------- DICOM

        if (event.key === ' ' && myC.state.image.modality === "2D") { // PLAY-PAUSE
            if (myC.state.is_playing) {
                action = { action: "STOP-DICOM", value: {} };
            } else {
                action = { action: "PLAY-DICOM", value: { velocity: myC.state.velocity } };
            }

        } else if (event.key === 'ArrowRight' && myC.state.image.modality === "2D") { // MOVE TO NEXT FRAME
            action = { action: "CHANGE-DICOM-FRAME", value: { frame: myC.state.current_frame + 1 } };

        } else if (event.key === 'ArrowLeft' && myC.state.image.modality === "2D") { // MOVE TO PREVIOUS FRAME
            action = { action: "CHANGE-DICOM-FRAME", value: { frame: myC.state.current_frame - 1 } };
        }

        if (action) {
            myC.onActionDicom(action);
        }
    }
}