import React, { useState, useEffect } from 'react';

// Helpers
import { HelperConfig } from './../../../helpers/helper_config/helper_config';

// Components
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
// import TextField from '@material-ui/core/TextField';

// Actions
import { onActionToPerform } from './../../../actions/actions';

// AUX functions
import classNames from 'classnames';
import { findWhere } from 'underscore';

export const CaliperComponent = ({
        image,
        tool_state,
        show_options,
        state,
        on_action_to_perform
    }) => {

    const measures = HelperConfig.getCaliperMeasurementTypes(image.view);
    const [measureSelected, setMeasureSelected] = useState();

    useEffect(() => {
        on_action_to_perform({ action: "SET-CALIPER-FUNCTION", value: { image, callback: handleSelect }});
    }, [])

    useEffect(() => {
        if (state.loaded && measures.length > 0) {
            handleSelect(image, measures[0], measures);
        } else {
            handleSelect(image, undefined, []);
        }
    }, [state.loaded, state.current_event, state.current_cycle]);

    onActionToPerform("GENERAL", { action: "DRAG-ELEMENT", value: document.getElementById("draggable-box") }, this);

    const handleSelect = (img, value, list) => {
        setMeasureSelected(value);
        on_action_to_perform({ action: "INIT-CALIPER", value: { image: img, template: value, measures: list }});
    }

    const renderContent = () => {
        if (measureSelected) {
            return (
                <>
                    <div className="type-select">
                        Type of measurement:
                        <Select id="measure" value={measureSelected.type}>
                            {measures.map(value => <MenuItem value={value.type} key={value.type} onClick={() => { handleSelect(image, value, measures) }}>{value.type}</MenuItem>)}
                        </Select>
                    </div>
                    {renderMeasureValues(measureSelected.type)}
                </>
            );
        } else {
            return <div className="notice">There are no measurements available yet for this view</div>
        }
    }

    const renderMeasureValues = (measure_type) => {
        let measures_event = [];
        if (image.measurements_caliper) {
            measures_event = image.measurements_caliper.filter(m => m.cycle === state.current_cycle && m.event === state.current_event);
        }
        const done = findWhere(measures_event, { type: measure_type });
        const variable = tool_state.template?.angles? "angles" : "dists", units = tool_state.template?.angles? " º" : " cm";
        const values = done? done[variable] : tool_state[variable];
        const button_text = done? "Remove" : "Save";
        const button_hidden = done? false : tool_state.points.length !== measureSelected.points.length;
        const button_function = done? () => { on_action_to_perform({ action: "REMOVE-CALIPER-MEASURE", value: { image, measure: done }}); }
            : () => { on_action_to_perform({ action: "SAVE-CALIPER-MEASURE", value: { image }}); };
        return (
            <>
                {measureSelected.points.map((point,i) => {
                    if (values && i < measureSelected.points.length-1 && (!measureSelected[variable] || measureSelected[variable][i])) {
                        let text = measureSelected[variable]? measureSelected[variable][i] : point + " - " + measureSelected.points[i+1];
                        return (
                            <>
                                <span style={{ marginBottom: "10px" }}>{text}<br/></span>
                                <span style={{ marginBottom: "25px", fontWeight: "bold" }}>
                                    &nbsp;&nbsp;&nbsp;{values[i]? values[i].toFixed(2) + units : "" }
                                </span>
                            </>
                        );
                    }
                })}
                <div className={classNames({ "hidden": button_hidden }, "save-button")} onClick={button_function}>{button_text}</div>
            </>
        );
    }

    return (
        <div className="grid-block vertical" id="draggable-box">
            <span style={{ fontWeight: "bold", marginBottom: "30px", textAlign: "center" }}>CALIPER</span>
            <div style={{ display: "grid", gridTemplateColumns: "50% 50%" }}>
                <span className="option-checkbox" style={{ gridColumn: "span 2" }}>
                    <input type="checkbox" id="show_caliper_measures" checked={show_options.measures} onChange={() => { on_action_to_perform({ action: "SHOW-CALIPER-OPTIONS", value: { image, type: "measures" }}); }} />
                    Show saved measurements
                </span>
                <span className="option-checkbox">
                    <input type="checkbox" id="show_caliper_labels" checked={show_options.labels} onChange={() => { on_action_to_perform({ action: "SHOW-CALIPER-OPTIONS", value: { image, type: "labels" }}); }} />
                    Show titles
                </span>
                <span className="option-checkbox">
                    <input type="checkbox" id="show_caliper_cursors" checked={show_options.cursors} onChange={() => { on_action_to_perform({ action: "SHOW-CALIPER-OPTIONS", value: { image, type: "cursors" }}); }} />
                    Show cursors
                </span>
            </div>
            {renderContent()}
        </div>
    );

}