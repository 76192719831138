import React from 'react';

export const CheckIcon = ({
        className,
        style,
        width,
        height
    }) => {
    
    return (
        <svg className={className} width={width} height={height} style={style} fill="white" viewBox="0 0 10 8">
            <path d="M8.498 0L10 1.502 3.672 7.83 0 4.158l1.502-1.502 2.17 2.17z"></path>
        </svg>
    );
}