import { HelperConfig } from './../helpers/helper_config/helper_config';
import { findWhere } from 'underscore';
import { Point, LineSegment, Polygon } from 'ts-2d-geometry';
import { pointIsBetweenPoints, getPointsAtGivenDistanceAndSlope } from './geometry_module';
import { euclideanDistance } from './math_module';

export function getEpiPredictionFromEndo(endo_points, epi_points, prediction_type, scale) {
    const config = HelperConfig.getToolConfig("2D","normal");
    const thickness = config.default_epi_thickness;
    const endo_pol = Polygon.fromPoints(endo_points.map(point => new Point(point.x, point.y)));

    let prediction = [];
    endo_points.map((point,i) => {

        let dist = thickness;
        if (prediction_type !== "default") {
            let original_epi_point = findWhere(epi_points, { id: point.id });
            if (original_epi_point) {
                let points_dist = euclideanDistance(original_epi_point, point);
                if (prediction_type === "auto") {
                    dist = point.id === scale.id? scale.distance : points_dist;
                } else {
                    dist = points_dist*scale;
                }
            }
        }

        let sum = false;
        if (i === 0 || i === endo_points.length-1) {
            let idx = i === 0? i+1 : i-1;
            let a = LineSegment.fromValues(endo_points[i].x, endo_points[i].y, endo_points[idx].x, endo_points[idx].y).asVector().normed();
            sum = a.clockwisePerpendicular();
        } else if (i > 0 && i < endo_points.length-1) {
            let a = LineSegment.fromValues(endo_points[i].x, endo_points[i].y, endo_points[i-1].x, endo_points[i-1].y).asVector().normed();
            let b = LineSegment.fromValues(endo_points[i].x, endo_points[i].y, endo_points[i+1].x, endo_points[i+1].y).asVector().normed();
            sum = (a.plus(b)).isNullVector()? a.clockwisePerpendicular() : a.plus(b);
        }
        let endo_point = new Point(point.x, point.y);
        let epi_point = endo_point.plus(sum.normed().scale(dist));
        if (i === 0 || i === endo_points.length-1) {
            let new_epi_point = endo_point.plus(sum.normed().scale(-dist));
            let dist2 = euclideanDistance(new_epi_point, endo_points[0]) + euclideanDistance(new_epi_point, endo_points[endo_points.length-1]);
            let dist1 = euclideanDistance(epi_point, endo_points[0]) + euclideanDistance(epi_point, endo_points[endo_points.length-1]);
            if (dist2 > dist1) {
                epi_point = new_epi_point;
            }
        } else if (i > 0 && i < endo_points.length-1) {
            if (endo_pol.containsPoint(epi_point)) {
                epi_point = endo_point.plus(sum.normed().scale(-dist));
            }
            // if (endo_pol.lineSegments.some(segment => segment.intersect(LineSegment.fromValues(prediction[i-1].x, prediction[i-1].y, epi_point.x, epi_point.y)).value)) {
            //     epi_point = endo_point.plus(sum.normed().scale(-dist));
            // }
        }
        prediction.push({
            id: point.id,
            x: epi_point.x,
            y: epi_point.y,
            type: point.type
        });
    });

    return prediction;
}

export function getNextCyclesPrediction(segmentation, cardiac_events, current_cycle) {
    if (cardiac_events.length > 1 && current_cycle < cardiac_events.length-1) {
        const onsets = cardiac_events[current_cycle];
        const cycle_length = cardiac_events[current_cycle].onset_end - cardiac_events[current_cycle].onset_start;
        for (let i=current_cycle+1; i<cardiac_events.length; i++) {
            const points = JSON.parse(JSON.stringify(segmentation[current_cycle].lines[0].points));
            const new_onsets = cardiac_events[i];
            const new_cycle_length = new_onsets.onset_end - new_onsets.onset_start;
            const ratio = new_cycle_length / cycle_length;
            for (const point of points) {
                point.x = new_onsets.onset_start + Math.round((point.x - onsets.onset_start)*ratio);
            }
            if (points.length > 1 && points[0].type === points[points.length-1].type) {
                const previous_flow = segmentation[i-1].lines[0].points;
                points[0].x = previous_flow[previous_flow.length-1].x;
                points[0].y = previous_flow[previous_flow.length-1].y;
            }
            segmentation[i].lines[0].points = points;
            segmentation[i].lines[0].finished = true;
            segmentation[i].lines[0].pattern = segmentation[current_cycle].lines[0].pattern;
        }
    }
}

export function predictLastControlPoint(image, points) {
    const current_cycle_length = image.cardiac_events[0].onset_end - image.cardiac_events[0].onset_start;
    const next_cycle_length = image.cardiac_events[1].onset_end - image.cardiac_events[1].onset_start;
    const ratio = next_cycle_length / current_cycle_length;
    const current_start = image.cardiac_events[0].onset_start;
    const next_start = image.cardiac_events[1].onset_start;
    const last_cp = { pixel: { y: points[0].y }};
    last_cp.pixel.x = next_start + Math.round((points[0].x - current_start) * ratio);
    return last_cp;
}