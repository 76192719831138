import React, { useState, useEffect } from 'react';

// Helpers
import { HelperStudies } from './../../../helpers/helper_studies/helper_studies';
import { HelperUser } from './../../../helpers/helper_user/helper_user';

export const StudyStateComponent = ({
        site,
        allow_manual
    }) => {

    const new_state = HelperStudies.getStudyState();
    const [studyState, setStudyState] = useState("");
    const div_class = studyState === "Exclude / rejected"? "bad" : (["Complete", "Include / optimal"].includes(studyState)? "good" : "none");

    useEffect(() => {
        let text = new_state;
        let user_type = HelperUser.getUserType();
        if (user_type === "Reviewer") {
            text = new_state === "Valid"? "Include / optimal" : ( new_state === "Not valid"? "Exclude / rejected" : new_state );
        } else {
            text = new_state === "Incomplete"? new_state : "Complete";
        }
        setStudyState(text);
    },[new_state]);

    // const handleManualButton = () => {
    //     setStudyState("Manually accepted");
    //     HelperStudies.setStudyState("Manually accepted");
    //     let user_type = HelperUser.getUserType();
    //     if (["Ground-truth generator","Editor"].includes(user_type)) {
    //         HelperStudies.saveStudy(true);
    //     } else if (user_type === "Reviewer") {
    //         HelperStudies.saveReview(true);
    //     }
    // }

    // const renderManualButton = () => {
    //     if (studyState === "Not valid" && allow_manual) {
    //         return <div className={"manual"} onClick={() => handleManualButton()}>Manually accept</div>;
    //     }
    // }
    
    return (
        <div className={site + " study-state"}>
            Study state:
            <div className={div_class}>{studyState}</div>
            {/* {renderManualButton()} */}
        </div>
    );
}