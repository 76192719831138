
export function meanValue(array) {
    var total = 0;
    var count = 0;
    array.forEach(function (item, index) {
        total += item;
        count++;
    });
    return roundValue(total / count);
}

export function roundValue(value) {
    const roundedValue = Math.round(value * 100) / 100;
    return !isNaN(roundedValue) && roundedValue !== Infinity? roundedValue : "-";
}

export function euclideanDistance(p1, p2) {
    var dx = p1.x - p2.x;
    var dy = p1.y - p2.y;
    return Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2));
}

export function isValueInRange(value, range) {
    if (value >= range[0] && value <= range[1]) {
        return true;
    } else if (value === "-") {
        return undefined;
    } else {
        return false;
    }
}