import React, { useState } from 'react';

// Components
import { ToggleMenuComponent } from './../toggle_menu_component/toggle_menu_component';
import { StudyStateComponent } from './../study_state_component/study_state_component';
import { ReferenceGrid } from './reference_grid';

// Helpers
import { HelperConfig } from "./../../../helpers/helper_config/helper_config";
import { HelperStudies } from './../../../helpers/helper_studies/helper_studies';

export const ReferenceComponent = ({
        images,
        missing_images,
        on_action_to_perform
    }) => {

    const [typesMissing, setTypesMissing] = useState(missing_images);
    const [showColumns, setShowColumns] = useState(HelperConfig.getImageModalities(HelperStudies.getDataType()).map(a => a.type));
    
    const allColumns = HelperConfig.getImageModalities(HelperStudies.getDataType()).map(a => a.type);
    allColumns.unshift("Not completed");

    const onActionToPerform = (action) => {
        const columns = JSON.parse(JSON.stringify(showColumns));
        switch (action.action) {
            case "SELECT_TAG":
                if (!columns.includes(action.value)) {
                    columns.push(action.value);
                }
                setShowColumns(columns);
                break;
            case "UNSELECT_TAG":
                if (columns.length > 0) {
                    const index = columns.indexOf(action.value);
                    columns.splice(index, 1);
                    setShowColumns(columns);
                }
                break;
            default:
                break;
        }
    }

    const handleImageTypeMissing = (type) => {
        if (!typesMissing.includes(type)) {
            let new_array = JSON.parse(JSON.stringify(typesMissing));
            new_array.push(type);
            missing_images.push(type);
            setTypesMissing(new_array);
            HelperStudies.checkStudyState();
        }
    }

    const renderMenu = () => {
        return (
            <ToggleMenuComponent
                site={"reference"}
                data={allColumns}
                data_hidden={[]}
                keys_selected={showColumns}
                on_action_to_perform={onActionToPerform}
            />
        );
    }

    const renderStudyState = () => {
        return (
            <StudyStateComponent
                site={"reference"}
                allow_manual={false}
            />
        );
    }

    const renderGrid = () => {
        return (
            <ReferenceGrid
                showColumns={showColumns}
                images={images}
                missing_images={typesMissing}
                on_action_to_perform={on_action_to_perform}
                handle_image_missing={handleImageTypeMissing}
            />
        );
    }

    return (
        <div>
            {renderMenu()}
            {renderStudyState()}
            {renderGrid()}
        </div>
    );
}