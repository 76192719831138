import React from 'react';

export const NomeasureIcon = ({
        className,
        style,
        width,
        height
    }) => {
    
    return (
        <svg className={className} width={width} height={height} style={style} viewBox="0 0 24 24">
            <path d="M16.51,10.85,24,18.34,18.35,24l-7.77-7.77,1.48-1.34,6.28,6.28,2.83-2.83-1-1L18,19.46l-.71-.71,2.13-2.12L18,15.21,16.6,16.63l-.71-.71,1.41-1.41-1.41-1.42-1.41,1.42-.71-.71,1.41-1.42L15,12.21ZM2.83,5.66,5.66,2.83l1,1.07L4.58,6l.7.71L7.41,4.6,8.82,6,7.41,7.43l.7.71L9.53,6.73l1.41,1.41L9.53,9.55l.7.71,1.42-1.41.91.92,1.5-1.36L5.66,0,0,5.66l8.13,8.13,1.48-1.35Zm19.83-5-8.6,7.8-1.5,1.36-3,2.67L8.13,13.79,0,21.16l1.34,1.48L24,2.09Z"/>
        </svg>
    );
}