import {
    HelperConfig
} from "../helpers/helper_config/helper_config";

export const data_provider_api = (type, parameters, token) => {
    let api_config = HelperConfig.getAPIConfig();
    let data = {};
    let api_admin_uri = api_config.api_admin_uri;
    let uri = api_config.api_custom_uri;
    let url_jobs = api_config.api_jobs_uri;
    let options = false;
    let url = false;
    let response_type = "json";

    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('x-api-key', token);
    myHeaders.append("Access-Control-Allow-Origin", "*");
    myHeaders.append("Access-Control-Allow-Headers", "Origin, X-Requested-With, Content-Type, Accept");
    // console.log(token)

    switch (type) {
        // Jobs methods
        case "GET_JOB":
            data = {
                "id": parameters.id
            }

            // url = uri + "get_job"
            url = uri + "/get_job"
            //create options
            options = {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify(data),
                cache: 'default'
            };

            break;
        case "HEALTZ":

            data = {}

            //create url
            url = uri + "/healtz"
            //create options
            options = {
                method: 'GET',
                headers: myHeaders,
                //body: JSON.stringify(data),
                cache: 'default'
            };

            break;
        case "GET_EXPORT":
            if ("id" in parameters) {
                //create url
                url = uri + "/get_export"
                //create options

                options = {
                    method: 'POST',
                    headers: myHeaders,
                    body: JSON.stringify(parameters),
                    cache: 'default'
                };
            }


            break;
            // Admin uri
        // case "GET_USER_PROJECTS":
        //     if ("id" in parameters) {
        //         //create url
        //         url = api_admin_uri + "/admin/users/get/" + parameters["id"]
        //         //create options 
        //         options = {
        //             method: 'GET',
        //             headers: myHeaders,
        //             // body: JSON.stringify(parameters),
        //             cache: 'default'
        //         };
        //     }


        //     break;
        
        // // COMMENTS
        // case "GET_COMMENT":
        //     if ("id" in parameters) {
        //         //create url
        //         url = uri + "/get_comment"
        //         //create options
        //         options = {
        //             method: 'POST',
        //             headers: myHeaders,
        //             body: JSON.stringify(parameters),
        //             cache: 'default'
        //         };
        //     }
        //     break;
        // case "GET_ALL_COMMENTS":
        //     //create url
        //     url = uri + "/get_all_comments"
        //     //create options
        //     options = {
        //         method: 'POST',
        //         headers: myHeaders,
        //         body: JSON.stringify(parameters),
        //         cache: 'default'
        //     };
        //     break;
        // case "ADD_COMMENT":
        //     if ("id" in parameters && "msg" in parameters && "user" in parameters) {
        //         //create url
        //         url = uri + "/add_comment"
        //         //create options
        //         options = {
        //             method: 'POST',
        //             headers: myHeaders,
        //             body: JSON.stringify(parameters),
        //             cache: 'default'
        //         };
        //     }
        //     break;
        // case "GET_IMAGE_COMMENT":
        //     if ("id" in parameters && "image_id" in parameters) {
        //         //create url
        //         url = uri + "/get_imagecomment"
        //         //create options
        //         options = {
        //             method: 'POST',
        //             headers: myHeaders,
        //             body: JSON.stringify(parameters),
        //             cache: 'default'
        //         };
        //     }
        //     break;
        // case "ADD_COMMENT2IMAGE":
        //     if ("id" in parameters && "msg" in parameters && "user" in parameters && "image_id" in parameters) {
        //         //create url
        //         url = uri + "/add_comment2image"
        //         //create options
        //         options = {
        //             method: 'POST',
        //             headers: myHeaders,
        //             body: JSON.stringify(parameters),
        //             cache: 'default'
        //         };
        //     }
        //     break;

        // CONFIGS
        // case "GET_CONFIG":
        //     if ("platform" in parameters && "config" in parameters) {
        //         //create url
        //         url = api_admin_uri + "/admin/config/get"
        //         //create options
        //         options = {
        //             method: 'POST',
        //             headers: myHeaders,
        //             body: JSON.stringify(parameters),
        //             cache: 'default'
        //         };
        //     }
        //     break;

        // case "GET_PROJECT":
        //     if ("id" in parameters) {
        //         //create url
        //         url = api_admin_uri + "/admin/projects/get/" + parameters["id"]
        //         //create options
        //         options = {
        //             method: 'GET',
        //             headers: myHeaders,
        //             // body: JSON.stringify(parameters),
        //             cache: 'default'
        //         };
        //     }

        //     break;


        // case "GET_STUDIES":

        //     if ("search_query" in parameters && "db_name" in parameters) {
        //         //create url
        //         url = uri + "/studies/get"
        //         //create options
        //         options = {
        //             method: 'POST',
        //             headers: myHeaders,
        //             body: JSON.stringify(parameters),
        //             cache: 'default'
        //         };
        //     }


        //     break;

        // case "GET_PATIENTS":

        //     if ("project" in parameters) {
        //         //create url
        //         url = uri + "/patients_list"
        //         //create options
        //         options = {
        //             method: 'POST',
        //             headers: myHeaders,
        //             body: JSON.stringify(parameters),
        //             cache: 'default'
        //         };
        //     }


        //     break;
        case "GET_PATIENT_STUDIES":

            if ("id" in parameters) {
                //create url
                url = uri + "/patient_studies"
                //create options
                options = {
                    method: 'POST',
                    headers: myHeaders,
                    body: JSON.stringify(parameters),
                    cache: 'default'
                };
            }


            break;

        // case "GET_ANALYSIS_STATUS_LIST":
        //     //create url
        //     url = uri + "/status/get_all"
        //     //create options
        //     options = {
        //         method: 'POST',
        //         headers: myHeaders,
        //         body: JSON.stringify(parameters),
        //         //    mode: 'no-cors',
        //         cache: 'default'
        //     };

        //     break;

        case "GET_PRESIGNED_URL_FROM_MINIO":

            data = {}

            if ("bucket" in parameters && "object" in parameters) {
                data = parameters
            }

            //create url
            url = uri + "/minio_get_presigned_url"
            //create options
            options = {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify(data),
                //    mode: 'no-cors',
                cache: 'default'
            };
            break;

        // case "GET_PRESIGNED_URLS_FROM_MINIO":

        //     data = {}

        //     if ("bucket" in parameters && "object" in parameters) {
        //         data = parameters
        //     }

        //     //create url
        //     url = uri + "/minio/get_urls"
        //     //create options
        //     options = {
        //         method: 'POST',
        //         headers: myHeaders,
        //         body: JSON.stringify(data),
        //         //    mode: 'no-cors',
        //         cache: 'default'
        //     };
        //     break;

        case "GET_IMAGES_FROM_MINIO":

            if ("url" in parameters) {

                url = parameters.url;
                //url= "https://services.simbiosys.upf.edu/hhasseltleuven/H3RH6D00?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAIOSFODNN7EXAMPLE%2F20200429%2F%2Fs3%2Faws4_request&X-Amz-Date=20200429T080847Z&X-Amz-Expires=432000&X-Amz-SignedHeaders=host&X-Amz-Signature=a060dccdf31c3c93547e400c67ba036a3809f5af686a900fcbb5328f250f4919"
                response_type = "blob";
                //create options
                options = {
                    method: 'GET',
                };
            }

            break;

        case "GET_ANALYSIS_LIST":

            data = {}
            //create url
            url = uri + "/status/get_all"
            //create options
            options = {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify(data),
                //    mode: 'no-cors',
                cache: 'default'
            };
            break;

        case "GET_ANALYSIS_LIST_COMPLETE":

            //create url
            url = uri + "/analysis_get_list_complete"
            //create options
            options = {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify({}),
                //    mode: 'no-cors',
                cache: 'default'
            };

            break;

        case "GET_ANALYSIS":

            if ("id" in parameters && "db_name" in parameters && "editor" in parameters) {

                //create url
                url = uri + "/analysis/get"
                //create options
                options = {
                    method: 'POST',
                    headers: myHeaders,
                    body: JSON.stringify(parameters),
                    cache: 'default'
                };
            }

            break;

        case "CREATE_ANALYSIS":

            if ("id" in parameters && "editor" in parameters) {

                data = {
                    id: String(parameters.id),
                    editor: String(parameters.editor)
                }
                //create url
                url = uri + "/analysis_create"
                //create options
                options = {
                    method: 'POST',
                    headers: myHeaders,
                    body: JSON.stringify(data),
                    cache: 'default'
                };
            }

            break;

        // case "UPDATE_ANALYSIS":
        //     // if ("id" in parameters && "images" in parameters && "measurements" in parameters && "validated" in parameters && "editor" in parameters) {
        //     if ("db_name" in parameters && "id" in parameters && "images" in parameters && "validated" in parameters && "editor" in parameters) {

        //         // data = {
        //         //     id: parameters.id,
        //         //     images: parameters.images,
        //         //     // measurements: parameters.measurements,
        //         //     validated: parameters.validated,
        //         //     editor: parameters.editor,
        //         //     project: parameters.project
        //         // }
        //         // console.log(data)
        //         //create url
        //         url = uri + "/analysis/update"
        //         //create options
        //         options = {
        //             method: 'POST',
        //             headers: myHeaders,
        //             body: JSON.stringify(parameters),
        //             // mode: 'no-cors',
        //             cache: 'default'
        //         };
        //     }

        //     break;

        case "REMOVE_ANALYSIS":

            if ("id" in parameters) {

                data = {
                    id: parameters.id
                }
                //create url
                url = uri + "/analysis_delete"
                //create options
                options = {
                    method: 'POST',
                    headers: myHeaders,
                    body: JSON.stringify(data),
                    cache: 'default'
                };
            }

            break;

            // FILES
        // case "GET_FILES_TO_LOAD":
        //     url = uri + "/files/get"
        //     options = {
        //         method: 'POST',
        //         headers: myHeaders,
        //         body: JSON.stringify(parameters),
        //         cache: 'default'
        //     };
        //     break;
        // case "UPDATE_FILES_TO_LOAD":
        //     url = uri + "/files/update"
        //     options = {
        //         method: 'POST',
        //         headers: myHeaders,
        //         body: JSON.stringify(parameters),
        //         cache: 'default'
        //     };
        //     break;

            // REVIEWS
        case "GET_OVERLAYS":
            url = uri + "/get_overlays"
            options = {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify({}),
                cache: 'default'
            };
            break;
        // case "SAVE_REVIEW":
        //     url = uri + "/reviews/create"
        //     options = {
        //         method: 'POST',
        //         headers: myHeaders,
        //         body: JSON.stringify(parameters),
        //         cache: 'default'
        //     };
        //     break;
        // case "GET_REVIEW":
        //     url = uri + "/reviews/get"
        //     options = {
        //         method: 'POST',
        //         headers: myHeaders,
        //         body: JSON.stringify(parameters),
        //         cache: 'default'
        //     };
        //     break;
        case "GET_REVIEWS":
            url = uri + "/get_review"
            options = {
                method: 'POST',
                headers: myHeaders,
                body: JSON.stringify(parameters),
                cache: 'default'
            };
            break;

            //  VALIDATION --------------------------
        // case "VALIDATE":
        //     url = uri + "/reviews/validate"
        //     options = {
        //         method: 'POST',
        //         headers: myHeaders,
        //         body: JSON.stringify(parameters),
        //         cache: 'default'
        //     };
        //     break;

            // LOGS
        case "ADD_LOG":
            if ("id" in parameters) {
                //create url
                url = uri + "/add_logs"
                //create options
                options = {
                    method: 'POST',
                    headers: myHeaders,
                    body: JSON.stringify(parameters),
                    cache: 'default'
                };

            }
            break;
        case "GET_LOG":
            if ("id" in parameters) {
                //create url
                url = uri + "/get_logs"
                //create options
                options = {
                    method: 'POST',
                    headers: myHeaders,
                    body: JSON.stringify(parameters),
                    cache: 'default'
                };
            }
            break;
        case "CREATE_LOG":
            if ("id" in parameters) {
                //create url
                url = uri + "/create_logs"
                //create options
                options = {
                    method: 'POST',
                    headers: myHeaders,
                    body: JSON.stringify(parameters),
                    cache: 'default'
                };
            }
            break;
        case "REMOVE_LOG":
            if ("id" in parameters) {
                //create url
                url = uri + "/remove_logs"
                //create options
                options = {
                    method: 'POST',
                    headers: myHeaders,
                    body: JSON.stringify(parameters),
                    cache: 'default'
                };
            }
            break;
        default:
            throw new Error(`Unsupported Data Provider request type ${type}`);
    }

    if (options !== false && url !== false) {
        let request = new Request(url, options);
        // console.log(request)
        return fetch(request).then((response) => {
            // console.log(response)
            if (response.status === 200) {

                if (response_type === "json") {

                    return response.json();

                } else if (response_type === "blob") {

                    return response.blob();

                } else if (response_type === "image") {

                    let blob = response.blob();
                    return URL.createObjectURL(blob);

                }

            } else {

                if (response.statusText === "UNAUTHORIZED") {
                    return {
                        "error": "UNAUTHORIZED"
                    }
                } else {
                    return {
                        "error": "UNAUTHORIZED"
                    }
                }
            }

        }).catch((error) => {
            console.error('Error:', error);
        });;

    } else {
        throw new Error(`Unsupported Data Provider request parameters ${parameters}`);
    }
}