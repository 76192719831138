import React from 'react';

import { capitalizeFirstLetter } from './../../../modules/string_module';

export const EmptyViewerComponent = ({
        on_action_to_perform,
        redirect_to
    }) => {
    
    if (redirect_to) {
        setTimeout(() => on_action_to_perform("GENERAL", { action: "GO_TO_PAGE", value: { page: redirect_to }}), 3000);
    }
    
    const renderRedirectMessage = () => {
        if (redirect_to) {
            return <span><br/>Redirecting to {capitalizeFirstLetter(redirect_to)}...</span>;
        }
    }

    return (
        <div className="empty viewer">
            <div className="child">
                <svg className="camera-icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                    <circle cx="12" cy="12" r="3.2"></circle>
                    <path d="M9 2L7.17 4H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2h-3.17L15 2H9zm3 15c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z"></path>
                </svg>
                <span>Please load/select image to display</span>
                {renderRedirectMessage()}
            </div>
        </div>
    );
}