import {
    HelperImages
} from "../helpers/helper_images/helper_images";
export const dicomActions = (action, myC) => {
    const element = action.value.element;
    switch (action.action) {
        default:
            break;
        case "PLAY-DICOM":
            HelperImages.playDicomClip(element, action.value.velocity);
            myC.setState({ is_playing: true });
            break;
        case "STOP-DICOM":
            HelperImages.stopDicomClip(element);
            myC.setState({ is_playing: false });
            break;
        case "CHANGE-DICOM-FRAME":
            if (element && action.value.frame >= 0 && action.value.frame < myC.state.number_of_frames) {
                HelperImages.loadAndDisplay(element,myC.state.stack.imageIds[action.value.frame]);
                myC.setState({ current_frame: action.value.frame });
            }
            break;
    }
}