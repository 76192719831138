import React from 'react';

import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';

export const ImageMetadataComponent = ({
        image,
        show,
        set_show
    }) => {

    const renderMetadata = (image) => {
        if (image && image.full_metadata) {
            const object = image.full_metadata;
            return Object.keys(object).map(key => {
                if (object[key] instanceof Object) {
                    return <li><span className="key">{key}</span> : <ul>{renderMetadata(object[key])}</ul></li>;
                } else {
                    return <li><span className="key">{key}</span> : {object[key]}</li>;
                }
            });
        } else {
            return "Image not loaded yet. Please close and open again.";
        }
    }

    return show?
        (
            <div className="metadata-box">
                <div className="metadata-content">
                    <ul className="rows">
                        {renderMetadata(image)}
                    </ul>
                </div>
                <div style={{ height: "40px", width: "40px", cursor: "pointer", zIndex: "1000" }}
                    onClick={() => { set_show(false) }}>
                    <CancelOutlinedIcon fontSize="large" sx={{ fontSize: "40px" }} />
                </div>
            </div>
        )
        : null;
}