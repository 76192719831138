import React, { Component } from 'react';

// Helpers
import { HelperUser } from './../../../helpers/helper_user/helper_user';
import { HelperConfig } from './../../../helpers/helper_config/helper_config';

// Actions file
import { onActionToPerform } from './../../../actions/actions';

// AUX functions
import classNames from 'classnames';

export class AcceptTermsPage extends Component {
    constructor() {
        super();
        this.state = {
            accept_only_research: false,
            accept_terms_conditions: false
        }
    }

    componentDidMount() {
        HelperConfig.init();
    }

    acceptButton() {
        if (this.state.accept_only_research && this.state.accept_terms_conditions) {
            HelperUser.acceptTermsConditions().then(result => {
                if (result.includes("accepted_terms_of_use")) {
                    this.setUrl("user-page");
                }
            });
        }
    }

    setUrl(url) {
        if (url !== false) {
            var browserHistory = this.props.history;
            browserHistory.push("/" + url);
        } else {
            alert("URL not compatible");
        }
    }

    render() {
        let accept_enabled = this.state.accept_only_research && this.state.accept_terms_conditions;
        return (
            <div className="grid-frame vertical" style={{ width: "100vw", height: "100vh", display: "flex", alignContent: "center", alignItems: "center" }}>
                <div className="accept-terms-page">
                    <div className="title">LEGAL NOTICE</div>
                    <div className="text-box">
                        <div>
                            <p>Welcome to the TransCOR platform (hereinafter referred to as "TransCOR platform" or “web platform” or “platform”), a web-based service hosted and maintained by the Physense research group at Universitat Pompeu Fabra, Barcelona, Spain (hereinafter referred to as “UPF” or “WE/US”) domiciled at Carrer de la Mercè, 12, Barcelona, Spain, with tax number Q-5850017-D. For more information about the UPF, please see <a href="https://www.upf.edu/home" target="_blank">https://www.upf.edu/home</a> and legal information at <a href="https://www.upf.edu/avis-legal" target="_blank">https://www.upf.edu/avis-legal</a>. The platform can be accessed through the following URLs: [ <a href="#">https://iap.bcn-medtech-apps.upf.edu</a>, <a href="#">https://fedocapp.org</a> ] (hereinafter referred to as “the website”).</p>
                            <p>For any inquiries or assistance, regarding TransCOR, the website, our service, or these terms, users can contact TransCOR's support team via email at <a href="#">rocketdev@upf.edu</a> (hereinafter referred to as “TransCOR's email address”).</p>
                            <table>
                            <thead>
                                <tr style={{backgroundColor:"#400"}}>
                                    <td><b>Legal text</b></td>
                                    <td><b>Friendly version</b></td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="table-title">
                                        General use of the web platform
                                    </td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>
                                        The TransCOR platform is provided to you by the Physense research group at Universitat Pompeu Fabra, for the purpose of viewing and analyzing pseudonymized data from clinical studies. For the purpose of improving these services and functionalities, and analyzing and researching the platform, the Physense team analyses use of the web application by registered users. For medical auditing purposes and to ensure compliance with regulatory standards, the Physense team maintains a comprehensive log of all actions performed by users on patient data. This includes but is not limited to viewing and analyzing pseudonymized data from clinical studies.
                                    </td>
                                    <td>
                                        TransCOR is provided to you for the purpose of viewing and analyzing pseudonymized data from clinical studies.
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Use of the TransCOR platform and corresponding services by any user (“you” or “User”) entails full acceptance of these terms and any specific conditions published on the web platform. Accordingly, users are recommended to carefully read these terms and conditions and any instructions shown on the web platform each time they access the site.
                                    </td>
                                    <td>
                                        By accessing the web platform, you agree to these terms.
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        We reserve the right to modify, add or delete at any time and without notice any content, functionality or information presented in this web platform, as well as limit or modify these terms and conditions. Any modification will take effect as from the date of release and will bind any user accessing the web platform after publication. These terms may be saved and printed by the user using the "Print" function of the browser. Registered Users will be provided 30 days prior written notice of any change in these conditions, which will go into effect on the date stated in the notice, and they may cancel their user account at any time by writing to our TransCOR's email address.
                                    </td>
                                    <td>
                                        We may change these terms from time to time. We will notify registered users of any change.
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        UPF complies with the ALLEA-The European Code of Conduct for Research Integrity. By using the TransCOR platform, you agree not to any of the following:
                                        <br/>a) Upload any data or images that are knowingly false, misleading, inaccurate or cannot be substantiated (e.g., fake patient images, or non-medical images).
                                        <br/>b) Upload any data or images that are unlawful, harmful, or otherwise objectionable (e.g., non-anonymized data).
                                        <br/>c) Avoid, bypass, remove, deactivate, impair, descramble, or otherwise circumvent any technological measure implemented by UPF or any of our providers or any other third party (including another user) to protect the platform. 
                                        <br/>d) Access the platform by any means other than through interfaces provided by UPF and as otherwise expressly authorized under these Terms of Service. 
                                    </td>
                                    <td>
                                        UPF complies with the ALLEA-The European Code of Conduct for Research Integrity. By using the TransCOR platform, you agree to this use policy. 
                                    </td>
                                </tr>
                                <tr>
                                    <td className="table-title">
                                        Intellectual Property Rights
                                    </td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>
                                        All the elements of the TransCOR platform and its content are covered by intellectual property rights. Authorship and the right to use these works in any manner, including reproduction, modification, distribution, or public communication, are protected by Spanish or international regulations governing intellectual property.
                                    </td>
                                    <td>
                                        The web platform and materials on TransCOR are protected by copyright laws.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="table-title">
                                        User Account
                                    </td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>Registration.</b> The Physense research group grants each registered user a personal, non-transferable license to access and use the platform. To use the platform, you must login using a TransCOR account registered in our system and agreeing to these Terms. The passwords are confidential, and it is therefore your responsibility to safeguard and ensure their correct use. The implementation of double-factor authentication at login ensures maximal security against unauthorized use. However, you must immediately notify US upon learning of any unauthorized use of your Account or any other breach of security known to you, by writing to our TransCOR's email address. On confirmation of the account, you are granted a personal, limited, revocable, non-exclusive and non-transferable license to use the TransCOR services in accordance with these Terms. The privacy policy applicable to personal data collected and processed by TransCOR is set out <a href="#privacy">here</a>.
                                    </td>
                                    <td>
                                        To use TransCOR, you must log in using a registered TransCOR account. Please read our privacy policy, which you must accept for registration.
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>Term.</b> Your account will be activated and remain in force unless terminated by either US or you hereunder. We can cancel your account at any time. All cancellation requests should be addressed to our TransCOR e-mail address. We may suspend or cancel your registration immediately in case of breach of these Terms, with written notice. In any other circumstance, we may provide you with 7 days' notice of termination. Upon termination for any reason, your access to the TransCOR platform will be disabled. User account data, which mainly consists of information on when you accessed the platform, preferences, etc., will be deleted, except for information crucial for auditing or any other legal duty. Clinical data, however, is governed by a Data Management Plan (DMP) signed with the sending Institution and is not covered by these Terms and Conditions. This data will be managed according to the terms specified in the DMP.
                                    </td>
                                    <td>
                                        Your account remains active, but we may suspend it if you breach these rules, and your content may be removed.
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>Responsibility and Use.</b> The TransCOR platform is not certified as a medical device for clinical use. You may only use TransCOR as a reviewing and scientific software, not for primary diagnosis or any other clinical use. You are responsible for all activities undertaken under your account. You agree not to share your TransCOR account, nor to use it for any illegal or unauthorized purpose. You must not use the platform in any manner that may deteriorate or violates any laws (including but not limited to copyright laws). You agree to indemnify and hold US harmless to the fullest extent allowed by law regarding all matters related to your use of your TransCOR Account.
                                    </td>
                                    <td>
                                        You are responsible for activity under your account, and you agree to comply with these Terms and the law.
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>Security.</b> You understand that the TransCOR platform entails transferring information from your Institution and your web browser to our servers. We use encrypted communications (HTTPS). Please also note that your profile and generated content may be visible by authorized users.
                                    </td>
                                    <td>
                                        Transmissions to TransCOR over the Internet are encrypted.
                                    </td>
                                </tr>
                                <tr>
                                    <td className="table-title">
                                        Interactive services and User content
                                    </td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>
                                        The TransCOR platform provides services to users that allow them to create new analyses on the uploaded data from clinical studies (e.g. image classification, and segmentation of relevant anatomical structures, calculation of risk scores stablished by clinical guidelines, etc.). The clinical data and analyses uploaded and generated with the platform are not moderated as regards their quality and appropriateness. Quality assessment is a procedure the user may perform using our platform. However, WE do not control or supervise contributions made by users in the platform, except when required by a specific project that designates members of the Physense team of UPF as research advisors. 
                                    </td>
                                    <td>
                                        Users can create new analyses on the uploaded data from clinical studies on TransCOR. The content generated by the user is not moderated nor supervised by us.
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Users may not use the TransCOR platform in breach of any Intellectual and Industrial property rights (copyright, trademarks, trade secrets, etc.). Without limitation, you agree not to use such services to disclose any confidential or misleading information that may damage the reputation and interests of UPF, its licensees or its users.
                                    </td>
                                    <td>
                                        You agree that your behavior on the platform will comply with these terms, and the law.
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        We reserve the right to exclude any user from the services provided within TransCOR and to suspend or close his/her account without prior warning should the user breach any of these terms.
                                    </td>
                                    <td>We may also exclude users who breach these rules.</td>
                                </tr>
                                <tr>
                                    <td className="table-title">
                                        Intellectual Property Rights in the Interactive Services and User Content
                                    </td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>
                                        By default, UPF (as the operator of TransCOR) is granted the right to use and exploit any information derived from analyses on the clinical data conducted with TransCOR under the terms of the Creative Commons - BY 4.0 license. This right remains in effect unless any kind of agreement (e.g., a Data Transfer Agreement (DTA)) signed with the sending Institution specifies otherwise. In such cases, intellectual property rights over the information derived using the TransCOR platform will be governed by the terms established in such agreement.
                                    </td>
                                    <td>When not established in the DMP and/or DTA, you provide us all information derived from your analyses under the CC-By 4.0 content license.</td>
                                </tr>
                                <tr>
                                    <td>
                                        When clinical data or images are sent to the TransCOR servers, as a general rule and as otherwise stipulated in a DTA or a Data Management Plan (DMP), the User grants UPF (as operator of TransCOR), the right to store this information for the purpose of providing the TransCOR services; and use it internally for research purposes.
                                    </td>
                                    <td>You allow UPF to store and display your data.</td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>Claims:</b> Any claims notified to Us by users in relation to infringements of intellectual or industrial property rights relating to any of the content contained on this software platform must be sent to us via our TransCOR e-mail address.
                                    </td>
                                    <td>Any claims should be addressed to our email address.</td>
                                </tr>
                                <tr>
                                    <td className="table-title">
                                        Liabilities
                                    </td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>
                                        The TransCOR platform is a research tool, which is still in progress. UPF does not guarantee the user the continued availability of the platform or of the various services offered in it. As a result, users will not hold Us liable for any damage or expense in the event of interruptions in the service, delays, malfunctions and, in general, any inconvenience whose origin lies in causes beyond the control of TransCOR.
                                    </td>
                                    <td>We don't warrant the continued availability of the platform.</td>
                                </tr>
                                <tr>
                                    <td>
                                        UPF does not assume any liability arising from (a) use made by the user of the content of TransCOR's website and services; (b) any activity that constitutes breach by the user or third parties of these terms; or (c) for any damages that may be caused to the users' equipment due to possible computer viruses contracted by the user in browsing the website or for any other damages incurred due to their browsing. 
                                        TransCOR is a research software, not suitable for primary diagnosis or any other clinical use. Based on user interactions with TransCOR, UPF does not assume any liability for decisions made based on medical data and derived analyses. It is important to note that medical responsibility does not lie with us. Users are responsible for exercising their professional judgment and verifying the accuracy and appropriateness of the information obtained from the platform for medical decision-making purposes.
                                    </td>
                                    <td>
                                        We are not liable for third party users' behavior on the site. Likewise, we do not assume any liability for clinical decisions derived from the use of our platform.
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        The User shall hold UPF harmless in the event of damages caused to third-parties as a result of the user's rightful or wrongful use of the services and tools available on the platform.
                                    </td>
                                    <td>
                                        You will cover our costs caused by your incorrect behaviour.
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        UPF, as operator of TransCOR, will only be liable for loss or damages incurred by the user as a consequence of accessing the website or using the tools on the platform when such damages are attributed to fraud or willful misconduct on the part of UPF.
                                    </td>
                                    <td>
                                        We will only be liable for fraud or misconduct on our part.
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        We reserve the right to modify, replace, update, renew, impede or restrict access, interrupt, block, temporarily suspend or cancel definitively any service or supply of information either with regard to a specific user or in general, in particular when notified by any third party or administrative or judicial authority of any actual or potential breach of third-party rights. Any potential breach should be notified using our TransCOR e-mail address.
                                    </td>
                                    <td>
                                        We can change the platform at our own convenience.
                                    </td>
                                </tr>
                            </tbody>
                            </table>
                            <p id="privacy" className="title" style={{textAlign:"center", marginTop:"30px"}}>Privacy Policy</p>
                            <p>The TransCOR web platform can be accessed through the following URLs: [ <a href="#">https://iap.bcn-medtech-apps.upf.edu</a>, <a href="#">https://fedocapp.org</a> ] (hereinafter referred to as “the Website”). This web platform is hosted and maintained by the Physense research group at Universitat Pompeu Fabra, Barcelona, Spain. For all purposes in relation to the processing of your data you may contact TransCOR's support team via email at <a href="#">rocketdev@upf.edu</a> (hereinafter referred to as “TransCOR's email address”).</p>
                            <p>All personal data gathered by our platform will be exclusively used for the purposes expressed below, and will be processed in accordance with Regulation (EU) 2016/679 of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data (hereinafter, ‘GDPR') and Spanish Organic Law 3/2018, on Personal Data Protection and Digital Rights Guarantee (hereinafter, ‘LOPDGDD').</p>
                            <p>Unless expressly mentioned, any personal data collected by this web platform will be processed by:</p>
                            <p><b>Data Controller:</b>	UNIVERSITAT POMPEU FABRA</p>
                            <p><b>Address:</b>	Carrer de la Mercè, 12 - 08002 Barcelona - SPAIN</p>
                            <p><b>Contact:</b>	(+34) 935 422 000 - <a href="#">gerencia@upf.edu</a></p>
                            <p><b>Data Protection Officer:</b>	<a href="#">dpo@upf.edu</a></p>
                            <p><b>Rights exercise:</b>	<a href="https://www.upf.edu/web/proteccio-dades/drets" target="_blank">https://www.upf.edu/web/proteccio-dades/drets</a></p>
                            <p className="title" style={{fontSize:"17px"}}>Cookies</p>
                            <p>This site may be using cookies and other techniques to store and retrieve information from user devices. Should you need further information, please refer to our Cookie Policy <a href="#cookies">here</a>.</p>
                            <p className="title" style={{fontSize:"17px"}}>User accreditation and Activity log</p>
                            <p>We may collect and process personal data to grant user access to protected resources, as well as to monitor usage, including but not limited to tracking IP or other device identification numbers, to ensure users' compliance with the platform's Legal Terms and Conditions and other applicable regulations.</p>
                            <p className="title" style={{fontSize:"17px"}}>Privacy Terms</p>
                            <p>Before accessing or using our platform, please ensure that you have read and understood our collection, storage, use, and disclosure of your personal information as described in this privacy policy.</p>
                            <table>
                            <thead>
                                <tr style={{backgroundColor:"#400"}}>
                                    <td><b>Legal text</b></td>
                                    <td><b>Friendly version</b></td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <b>Responsibility and contact.</b> The entity responsible for processing your data, as data controller, is the Universitat Pompeu Fabra (UPF). The personal data provided will be stored in our servers. You may use TransCOR's email address for all purposes in relation to the processing of your data. You may also contact the UPF's Data Protection Officer at <a href="#">dpo@upf.edu</a>.
                                    </td>
                                    <td>The UPF is responsible for the personal data collected on TransCOR.</td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>Data Collection.</b> We collect personal data relating to you on creating your user account, and during the course of your use of the site and communications with us. In Annex 1 we detail the data we collect and the specific purposes of processing. The table also lists the legal basis which we rely on to process the personal information, the categories of any recipients of your personal data, and information as to how we determine applicable retention periods.
                                        <br/><br/>We also automatically collect personal information about you in an indirect way - about how you access and use the Service and information about the device you use to access the Service – this is set out in Annex 2.
                                        <br/><br/>Our cookie policy sets out how some of your personal data may be collected by cookies on our site, in particular third-party analytical cookies.
                                    </td>
                                    <td>We expressly collect the data in the web forms and usage data. You must not upload sensitive personal data.</td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>Purposes.</b> The data is processed for the general purposes relating to the TransCOR platform indicated in our Terms of Use, and more specifically for the purposes set out in the tables in Annexes 1 and 2 below. In summary, for providing the TransCOR platform services and for conducting research, development and innovation in the management and analysis of data from clinical studies, and for managing any results of this project. Thus, the data is processed for the purpose of providing and managing the TransCOR services, communicating with TransCOR users regarding updates on the site, managing user accounts, providing electronic communications, and for any administrative and legal / judicial purposes. We also use your data for research.
                                    </td>
                                    <td>Data is processed for R+D purposes, to manage your account and to contact you. Please see the tables in Annex for more details.</td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>Anonymisation.</b> We may anonymise user data and aggregate any of the personal information we collect (so that it does not directly identify you). We may use this anonymised information for purposes that include testing our IT systems, research, data analysis, improving our Service and developing new features for our site.
                                        <br/><br/>We may also share anonymised, aggregated information with others for scientific (non-commercial) research purposes. We may also publish anonymised datasets with our research publications, in accordance with European Research guidelines and open access requirements.
                                    </td>
                                    <td>
                                        We may use your anonymized information for further development and for research purposes.
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>Contacting you.</b> From time to time we may contact you regarding the Site and your use of the Site and our research.
                                    </td>
                                    <td>
                                        We may contact you to gather feedback regarding our service.
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>Disclosure.</b> These data may be disclosed on a pseudonymous basis (i.e., only we retain information identifying you, while the recipient of the dataset does not) to third parties solely for compatible research purposes in the same or closely related research field. For any further purposes, disclosure will only be made with your specific and express previous consent.
                                        <br/><br/>By using the TransCOR account to generate information, you hereby expressly agree that any personal data associated with that information may be viewed and shared with other TransCOR users and third parties.
                                        <br/><br/>Your personal data may also be disclosed in cases provided for by law.
                                    </td>
                                    <td>
                                        Your name will not be associated with any data shared. Sharing will only occur for research related purposes on a pseudonymous basis.
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>Confidentiality.</b> Technical and organizational measures have been adopted to preserve and protect users' personal information from unauthorized use or access and from being altered, lost, or misused, considering the technological state of art, the features of the information stored and the risks to which information is exposed.
                                        <br/><br/>All personal information we collect will be stored on our secure servers. All electronic transaction entered into via our Service will be protected by SSL encryption technology. We will never send you unsolicited emails or contact you by phone requesting your account ID, password, credit or debit card information or national identification numbers
                                    </td>
                                    <td>We apply security measures to keep your data confidential.</td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>International Transfer.</b> We do not transfer any personal data relating to your uses outside the EEA.
                                    </td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>User rights.</b> Your rights to access, correct, cancel, restrict or object to the processing of your personal data may be exercised by applying in writing, including a copy of your identity card or equivalent to: General Manager, Universitat Pompeu Fabra. C. de la Mercè, 12. 08002 - Barcelona. You may also email us with the same information at our TransCOR's email account. You also have the right to lodge a complaint to the data protection officer at <a href="#">dpo@upf.edu</a>. You may also resort to the Agència Catalana de Protecció de Dades, as indicated at <a href="http://apdcat.gencat.cat/en/drets_i_obligacions/reclamar_i_denunciar/" target="_blank">http://apdcat.gencat.cat/en/drets_i_obligacions/reclamar_i_denunciar/</a>.
                                    </td>
                                    <td>You may access and modify or delete your data by contacting us.</td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>Children.</b> Our Service is not directed at persons under 18 and we do not knowingly collect personal information from children. All clinical data of an individual (adult or children) are strictly pseudonymized before being sent to our servers.
                                    </td>
                                    <td>Children should not register as users.</td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>Changes to this policy.</b> We may update this privacy policy from time to time and so you should review this page periodically. When we change this privacy policy in a material way, we will update the “last modified” date at the end of this privacy policy. Changes to this privacy policy are effective when they are posted on this page. We will notify registered users by email of any changes.
                                    </td>
                                    <td>We may update this policy from time to time and we will notify you.</td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>Consent.</b> By registering as a user, users expressly agree to this policy, including the sending of electronic communications related to the platform. For auditability reasons, we store the date at which the user accepts the terms and conditions, cookies policy and data privacy policy associated to this platform.
                                    </td>
                                    <td>You agree to this processing of your data.</td>
                                </tr>
                            </tbody>
                            </table>
                            <p className="title" style={{fontSize:"17px", marginTop:"20px"}}>Data Annex 1 - Personal data we collect</p>
                            <table style={{textAlign:"left"}}>
                            <thead>
                                <tr style={{backgroundColor:"#400"}}>
                                    <td><b>Category of personal information</b></td>
                                    <td><b>How we use it</b></td>
                                    <td><b>Legal basis for the processing</b></td>
                                    <td><b>Categories of recipients</b><br/><br/>As required in accordance with how we use it, we will share your personal information with the following</td>
                                    <td><b>Retention period</b></td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><b>Contact and profile information.</b><br/><br/>Personal information: name and surname, e-mail address, occupation.</td>
                                    <td>We use this information to:<br/><br/>
                                        <ul>
                                            <li>• operate, maintain and provide to you the features and functionality of the Service</li>
                                            <li>• maintain the safety of the Service</li>
                                            <li>• communicate with you including sending service-related communications</li>
                                            <li>• deal with enquiries and complaints made by or about you relating to the Service</li>
                                        </ul>
                                    </td>
                                    <td>The processing is necessary for:<br/><br/>
                                        <ul>
                                            <li>• the performance of a contract and to take steps prior to entering into a contract; and our legitimate interests, namely administering the Service, and communicating with users</li>
                                        </ul>
                                    </td>
                                    <td><b>Other users of the Service.</b><br/><br/>Other users of the Service that you interact with or that we share information with.</td>
                                    <td>For no longer than necessary for the purposes set out and in accordance with our legal obligations and legitimate research interests.</td>
                                </tr>
                                <tr>
                                    <td><b>Activity</b><br/><br/>Your actions in the platform, and user-generated content</td>
                                    <td>
                                        Understanding how users use the platform and what type of information they generate. This generated information is used for research purposes. For auditing purposes, we maintain a comprehensive log of all actions performed by users on patient data. See terms and conditions for IP rights over user-generated content.
                                    </td>
                                    <td>Your express consent and performance of the Terms of Use contract</td>
                                    <td>Share pseudonymised data of all registered users for research purposes (rather than anonymized under Spanish law)</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td><b>All personal information set out above.</b></td>
                                    <td>We will use all the personal information we collect to:<br/><br/>
                                        <ul>
                                            <li>• operate, maintain and provide to you the features and functionality of the Service</li>
                                            <li>• communicate with you, to monitor and improve our Service</li>
                                            <li>• to help us develop new products and services</li>
                                            <li>• to detect and prevent fraud and defend ourselves from any legal claims</li>
                                            <li>• comply with any applicable legal obligations</li>
                                        </ul>
                                    </td>
                                    <td>The processing is necessary for:<br/><br/>
                                        <ul>
                                            <li>• our legitimate interest, namely to administer and improve the Service</li>
                                            <li>• comply with legal obligations</li>
                                        </ul>
                                    </td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tbody>
                            </table>
                            <p className="title" style={{fontSize:"17px", marginTop:"20px"}}>Annex 2 - Personal data we collect automatically</p>
                            <table style={{textAlign:"left"}}>
                            <thead>
                                <tr style={{backgroundColor:"#400"}}>
                                    <td><b>Category of personal information</b></td>
                                    <td><b>How we use it</b></td>
                                    <td><b>Legal basis for the processing</b></td>
                                    <td><b>Categories of recipients</b><br/><br/>As required in accordance with how we use it, we will share your personal information with the following</td>
                                    <td><b>Retention period</b></td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <b>Information about how you access and use the Service</b>, for example, how frequently you access the Service, the time you access our Service and how long you use it for, whether you open supporting information such as user manuals or video tutorials, and other actions you take on the Service.
                                    </td>
                                    <td>We use this information to monitor and improve our Service, and to help us develop new products and services.</td>
                                    <td>
                                        The processing is necessary for our legitimate interests, namely: to tailor our Service to the user and to improve our Service generally; to monitor and resolve issues; to communicate with users; to contact users; and for the detection and prevention of illegal activities.
                                    </td>
                                    <td>
                                        <b>Law enforcement, regulators and other parties for legal reasons.</b> Third parties as required by law or if we reasonably believe that such action is necessary to (a) comply with the law and the reasonable requests of law enforcement; (b) to enforce our Terms of Service or to protect the security or integrity of our Service; and/or (c) to exercise or protect the rights, property, or personal safety of our users.
                                    </td>
                                    <td>For no longer than necessary for the purposes set out and in accordance with our legal obligations and legitimate research interests.</td>
                                </tr>
                                <tr>
                                    <td>
                                        <b>Information about your device.</b><br/><br/>
                                        We also collect information about the computer, tablet, smartphone or other electronic device you use to connect to our Service. This information can include details about the type of device, operating systems, browsers, and your IP address.
                                    </td>
                                    <td>We use information about how you use and connect to our Service to present our Service to you on your device.</td>
                                    <td>Idem</td>
                                    <td></td>
                                    <td>Idem</td>
                                </tr>
                            </tbody>
                            </table>
                            <p id="cookies" className="title" style={{textAlign:"center", marginTop:"30px"}}>Cookie Policy</p>
                            <p className="title" style={{fontSize:"17px"}}>What are cookies and similar technologies?</p>
                            <ul>
                                <li>• Cookies: Small text files sent from a website to your device, where they are stored by your web browser and later retrieved by the website.</li>
                                <li>• Tracking Technologies: Web Beacons, Pixels, Tags, Scripts and other technologies used for purposes similar to those intended by using cookies.</li>
                                <li>• E-mails and mobile applications can contain small, transparent image files or lines of code to record how you interact with them.</li>
                            </ul>
                            <p className="title" style={{fontSize:"17px"}}>What are these technologies used for?</p>
                            <p>They may be used for different purposes:</p>
                            <ul>
                                <li>• Technical: to allow website functionalities. (E.g.: To maintain data across different pages of the same form)</li>
                                <li>• Personalization: to store user preferences. (E.g.: user's preferred language)</li>
                                <li>• Analytical: to understand user's activity. (E.g.: to count user views of an email)</li>
                                <li>• Behavioral advertising: to profile user behavior. (E.g.: to allow personalized advertising)</li>
                            </ul>
                            <p className="title" style={{fontSize:"17px"}}>How do we use cookies in our web platform?</p>
                            <ul>
                                <li>• Our technological infrastructure or content management platform require the use of <u>technical cookies</u> for operational purposes.</li>
                                <li>• We utilize <u>personalization cookies</u> to effectively maintain your preferences as you engage with our platform.</li>
                                <li>• We employ third-party <u>analytical cookies</u> to gain insights into user interactions with our platform, aiding us in identifying areas for enhancement and optimization.</li>
                                <li>• <u>We do not use behavioral advertising cookies.</u></li>
                            </ul>
                            <p className="title" style={{fontSize:"17px"}}>What cookies do we use?</p>
                            <p>Our web platform allows the use of in-house technical and personalization cookies as well as third-party cookies for analytical purposes. We remind you that our entity does not exercise any control over the terms and conditions and privacy policy of third-party cookies. Access to third-party cookies terms and conditions will be provided through a clickable link in our platform. We recommend careful reading of them, and if you do not agree with the terms, we recommend disabling these cookies. Disabling these cookies will not prevent navigation and use of this platform.</p>
                            <p>By using analytical cookies, our entity obtains statistical information about the number of visits, demographic data, operating system, internet service provider, type of device, and source of access. The purpose of the processing carried out using analytical cookies is to obtain data to adapt and optimize the services we offer to our users.</p>
                            <p className="title" style={{fontSize:"17px"}}>Consent and retention period</p>
                            <p>The legal basis for the processing of your personal data associated with the use of third-party cookies is your explicit consent when clicking the "Accept" button on the cookie policy banner and/or pop-up. You can consult the retention period in the terms and conditions (accessible through a clickable link provided in our platform).</p>
                            <p>You may withdraw your consent to this cookie policy at any time. To do so, you should delete the cookies stored on your device using the settings and configurations of your internet browser, as explained in the following section.</p>
                            <p className="title" style={{fontSize:"17px"}}>Cookie settings modification</p>
                            <p>Users can always restrict, block, or delete cookies from any website using their browser. Each browser operates differently, but you can check the various procedures at the following links:</p>
                            <ul>
                                <li>• <b>Internet Explorer:</b> <a href="http://windows.microsoft.com/es-xl/internet-explorer/delete-manage-cookies#ie=ie-11" target="_blank">http://windows.microsoft.com/es-xl/internet-explorer/delete-manage-cookies#ie=ie-11</a></li>
                                <li>• <b>Firefox:</b> <a href="https://support.mozilla.org/es/kb/Borrar%20cookies" target="_blank">https://support.mozilla.org/es/kb/Borrar%20cookies</a></li>
                                <li>• <b>Chrome:</b> <a href="https://support.google.com/chrome/answer/95647?hl=es" target="_blank">https://support.google.com/chrome/answer/95647?hl=es</a></li>
                                <li>• <b>Safari:</b> <a href="http://www.apple.com/legal/privacy/es/cookies/" target="_blank">http://www.apple.com/legal/privacy/es/cookies/</a></li>
                                <li>• <b>Opera:</b> <a href="http://help.opera.com/Linux/10.50/en/cookies.html" target="_blank">http://help.opera.com/Linux/10.50/en/cookies.html</a></li>
                                <li>• <b>Safari for iOS (iPhone + iPad):</b> <a href="http://help.opera.com/Linux/10.50/en/cookies.html" target="_blank">http://help.opera.com/Linux/10.50/en/cookies.html</a></li>
                            </ul>
                            <p>For more information on deleting, disabling, or blocking cookies, you can visit:<br/><a href="http://www.aboutcookies.org/Default.aspx?page=2" target="_blank">http://www.aboutcookies.org/Default.aspx?page=2</a></p>
                            <p className="title" style={{fontSize:"17px"}}>Communications and international data transfers</p>
                            <p>Our entity will not disclose your data to third parties. To learn about data communications by third-party providers, please consult the provider's privacy policy (accessible through a clickable link provided in our platform).</p>
                            <p className="title" style={{fontSize:"17px"}}>Cookie policy update</p>
                            <p>This web platform may modify the current cookie policy, so we recommend reviewing this policy every time you access our services to update the information.</p>
                        </div>
                    </div>
                    <div className="option-checkbox">
                        <input type="checkbox" id="accept_terms_conditions" checked={this.state.accept_terms_conditions} onChange={() => { this.setState({ accept_terms_conditions: !this.state.accept_terms_conditions }) }} />
                        <span>I accept the TransCOR Terms of Use.</span>
                    </div>
                    <div className="option-checkbox">
                        <input type="checkbox" id="accept_only_research" checked={this.state.accept_only_research} onChange={() => { this.setState({ accept_only_research: !this.state.accept_only_research }) }} />
                        <span>THE TransCOR PLATFORM IS NOT CERTIFIED AS A MEDICAL DEVICE FOR CLINICAL USE. I MAY ONLY USE TransCOR AS A REVIEWING AND SCIENTIFIC SOFTWARE, NOT FOR PRIMARY DIAGNOSIS OR ANY OTHER CLINICAL USE.</span>
                    </div>
                    <div>
                        <button onClick={() => { onActionToPerform("GENERAL", { action: "LOGOUT", value: false }, this) }} className="exit">Exit</button>
                        <button onClick={() => this.acceptButton()} className={classNames({ "active": accept_enabled }, "accept")}>Accept</button>
                    </div>
                </div>
            </div>
        );
    }
}