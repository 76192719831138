import { Point, Line } from '@flatten-js/core';

export function drawVerticalLine(line, height, context) {
    context.beginPath();
    context.strokeStyle = line.color;
    context.lineWidth = line.width;
    if (line.dash) {
        context.setLineDash(line.dash);
    } else {
        context.setLineDash([]);
    }
    context.moveTo(line.x, 0);
    context.lineTo(line.x, height);
    context.stroke();
    context.setLineDash([]);
}

export function drawLine(a, b, screen_size, style, context) {
    let aa = new Point(a.x, a.y), bb = new Point(b.x, b.y);
    if (!aa.equalTo(bb)) {
        const line = new Line(aa, bb);
        const top = new Line(new Point(0, 0), new Point(100, 0));
        if (line.intersect(top)[0]) {
            const bottom = new Line(new Point(0, screen_size.height), new Point(100, screen_size.height));
            drawSegment(line.intersect(top)[0], line.intersect(bottom)[0], style, context);                
        } else {
            const left = new Line(new Point(0, 0), new Point(0,100));
            const right = new Line(new Point(screen_size.width, 0), new Point(screen_size.width, 100));
            drawSegment(line.intersect(left)[0], line.intersect(right)[0], style, context);                
        }
    }
}

export function drawSegment(a, b, style, context) {
    context.beginPath();
    context.strokeStyle = style.color;
    context.lineWidth = style.width;
    if (style.dash) {
        context.setLineDash(style.dash);
    } else {
        context.setLineDash([]);
    }
    context.moveTo(a.x, a.y);
    context.lineTo(b.x, b.y);
    context.stroke();
    context.setLineDash([]);
}

export function drawPoint(point, sAngle, eAngle, color, style, context) {
    context.beginPath();
    context.arc(point.x, point.y, style.radius, sAngle, eAngle);
    context.lineWidth = style.width;
    context.fillStyle = color;
    context.strokeStyle = color;
    context.fill();
    context.stroke();
}

export function drawPlusSign(point, style, context) {
    context.beginPath();
    context.strokeStyle = style.color;
    context.lineWidth = style.width;
    context.moveTo(point.x, point.y - style.size);
    context.lineTo(point.x, point.y + style.size);
    context.moveTo(point.x - style.size, point.y);
    context.lineTo(point.x + style.size, point.y);
    context.fill();
    context.stroke();
}

export function drawLabel(point, color, text, style, context, location_above) {
    context.font = style.label_font;
    context.fillStyle = color;
    context.strokeStyle = color;
    if (location_above) {
        context.fillText(text, point.x-5, point.y-15);
    } else {
        context.fillText(text, point.x-5, point.y+25);
    }
}

export function drawArc(center, radius, sAngle, eAngle, style, context) {
    context.beginPath();
    context.strokeStyle = style.color;
    context.lineWidth = style.width;
    if (style.dash) {
        context.setLineDash(style.dash);
    } else {
        context.setLineDash([]);
    }
    let noclockwise = eAngle-sAngle > Math.PI;
    context.arc(center.x, center.y, radius, sAngle, eAngle, noclockwise);
    context.stroke();
    context.setLineDash([]);
}

export function drawFilledRegion(x1, y1, x2, y2, style, context) {
    context.beginPath();
    context.moveTo(x1, y1);
    context.lineTo(x2, y1);
    context.lineTo(x2, y2);
    context.lineTo(x1, y2);
    context.fillStyle = style.color;
    context.globalAlpha = style.opacity;
    context.fill();
    context.globalAlpha = 1;
    if (style.stroke) {
        context.strokeStyle = style.color;
        context.lineWidth = style.width;
        context.stroke();
    }
}

export function getRandomColor() {
    var randomColor = Math.floor(Math.random()*16777215).toString(16);
    return "#"+randomColor;
}