import { DataProvider } from "../../dataprovider/dataprovider";
import { HelperImages } from "./../helper_images/helper_images";
import { HelperStudies } from "./../helper_studies/helper_studies";
import { FileModule } from "./../../modules/file_module";
import { findIndex, findWhere, sortBy } from 'underscore';

import { HelperUser } from "./../helper_user/helper_user";
import { HelperConfig } from "./../helper_config/helper_config";

export class HelperStudiesBackend{
    static getStudies(currentproject, user_token) {     
        return DataProvider("MONGO", "GET_STUDIES", {
            search_query: {"medical_data_type":"echo"},
            db_name: currentproject.db_name
        }, user_token).then((studies) => {
            return studies;
        });
    }

    static getStudies_GroundTruthUser(currentproject, user_token) {
        let search_query = { "medical_data_type": "echo" };
        if ([false, true].includes(currentproject.active_studies_by_default)) {
            search_query.active = true;
        }
        let studies_list = [];      
        return DataProvider("MONGO", "GET_STUDIES", {
            search_query,
            db_name: currentproject.db_name
        }, user_token).then((studies) => {
            if (studies) {
                studies_list = studies;
                return DataProvider("MONGO", "GET_ANALYSIS_STATUS_LIST", {
                    db_name: currentproject.db_name,
                    medical_data_type:"echo"
                }, user_token)
            }

        }).then((analysis_list) => {
            console.log("GET_STUDIES API");
            return {analysis_list: analysis_list, studies: studies_list} ;
        })
    }

    static getFilesSelectedToLoad(study_id, editor, user_token) {
        return DataProvider("MONGO", "GET_FILES_TO_LOAD", {
            "id": study_id + "_" + editor,
            "db_name": HelperStudies.getCurrentProjectObj().db_name,
            "medical_data_type":"echo"
        }, user_token).then((result) => {
            return result;
        });
    }

    static updateFilesSelectedToLoad(study_id, user, images) {
        const image_names = getFilenameFromURLs(images);
        return DataProvider("MONGO", "UPDATE_FILES_TO_LOAD", {
            "id": study_id,
            "editor": user.user_email,
            images: image_names,
            "medical_data_type":"echo",
            "db_name": HelperStudies.getCurrentProjectObj().db_name
        }, user.user_token).then((result) => {
            return result;
        })
    }

    static loadIcons(study_id, patient_id, user_token, currentproject) {
        const minio_object = getStudyMinioPrefix(study_id,patient_id)
        // get project from db --> get miniobucket from project
        return this.getProject(currentproject, user_token).then((project_db) => {
            let params = {
                bucket: String(project_db[0]["bucket"]),
                object: minio_object
            }
            // get presigned URLs
            return this.getPresignedUrlsMinio(params, user_token).then((all_urls) => {
                // classify URLs in: small & big icons and DICOM
                let classified_urls = classifyUrls(all_urls);
                let filenames = getFilenameFromURLs(classified_urls.DICOM);

                // read info from study json file
                return FileModule.readJson(classified_urls.json).then((json) => {
                    // create image array
                    let all_images = createImageArray(json, classified_urls, filenames, []);
                    let study_images = {
                        analysis_images: false,
                        all_images: all_images,
                        loaded_images: false
                    }
                    return study_images;
                });
            });
        });
    }

    static loadAnalysis(all_images, study_id, user_email, user_token, user_role) {
        return this.getStudyAnalysis(study_id, user_email, user_token, user_role).then((analysis) => {
            let analysis_images = analysis? analysis["images"] : [];
            let [all_images_merge, analysis_images_merge] = mergeImageInformation(all_images, analysis_images);
            let study_images = {
                analysis_images: analysis_images_merge,
                all_images: all_images_merge,
                missing_images: analysis && analysis["missing_images"]? analysis["missing_images"] : [],
                loaded_images: all_images_merge.filter(img => img.active)
            }
            if (HelperUser.getUserType() === "Reviewer") {
                study_images.analysis_images = study_images.analysis_images.filter(im => im["reference"]);
            }
            if (analysis) {
                HelperStudies.setStudyState(analysis.study_state);
            }
            return study_images;
        });
    }

    static loadStudy(study_id, patient_id, user_token, currentproject, images2load, user_email, user_role) {
        const minio_object = getStudyMinioPrefix(study_id, patient_id);
        // get project from db --> get miniobucket from project
        return this.getProject(currentproject, user_token).then((project_db) => {
            let params = {
                bucket: String(project_db[0]["bucket"]),
                object: minio_object
            }
            // get presigned URLs
            // console.log("PARAMS",params)
            return this.getPresignedUrlsMinio(params, user_token).then((all_urls) => {
                // classify URLs in: small & big icons and DICOM
                let classified_urls = classifyUrls(all_urls);
                let filenames = getFilenameFromURLs(classified_urls.DICOM);
                let urls2load = getURLsToLoad(classified_urls.DICOM, images2load);

                // read info from study json file
                return FileModule.readJson(classified_urls.json).then((json) => {
                    // create image array
                    let all_images = createImageArray(json, classified_urls, filenames, urls2load);
                    // loadDICOMS
                    if (urls2load.length > 0) {
                        HelperImages.downloadDICOMFromURL(urls2load, user_token);
                    }
                    // get images comments
                    return this.loadImageComments(all_images, study_id, user_token, user_email, user_role).then((final_images) => {
                        // get study analysis
                        return this.loadAnalysis(final_images, study_id, user_email, user_token, user_role);
                    });
                });
            });
        });
    }

    static loadImages(study_id, patient_id, user_token, currentproject, images2load, missing_images) {
        const minio_object = getStudyMinioPrefix(study_id, patient_id);
        // get project from db --> get miniobucket from project
        return this.getProject(currentproject, user_token).then((project_db) => {
            let params = {
                bucket: String(project_db[0]["bucket"]),
                object: minio_object
            }
            // get presigned URLs
            // console.log("PARAMS",params)
            return this.getPresignedUrlsMinio(params, user_token).then((all_urls) => {
                // classify URLs in: small & big icons and DICOM
                const classified_urls = classifyUrls(all_urls);
                const filenames = getFilenameFromURLs(classified_urls.DICOM);
                images2load.map(img => {
                    const ind = filenames.indexOf(img.filename);
                    img.urls = { DICOM_url: classified_urls.DICOM[ind] };
                });
                const study_images = {
                    all_images: images2load,
                    analysis_images: images2load,
                    missing_images: missing_images? missing_images : [],
                    loaded_images: images2load
                }
                HelperStudies.setStudyImages(study_images);
                return study_images;
            });
        });
    }

    static loadImageComments(all_images, study_id, user_token, user_email, user_role) {
        let filenames = all_images.map(im => im.filename);
        let requests = [];
        filenames.map((image) => requests.push(this.getComments(study_id, image, user_token)) );
        return Promise.all(requests).then((comments) => {
            comments.map((comment_list,i) => {
                const test_users = HelperConfig.getTestUsers();
                let conditions = (author, user_email, user_role) => { 
                    return (!test_users.includes(author) && user_role === "Viewer") || author === user_email;
                }
                let comments = comment_list.filter(com => !["score","score-accept"].includes(com.type) && conditions(com.user, user_email, user_role));
                if (comments.length > 0) {
                    all_images[i].comments = comments;
                }
                let scores = comment_list.filter(com => com.type === "score" && conditions(com.user, user_email, user_role));
                if (scores.length > 0) {
                    let dates = scores.map(s => Date.parse(s.date));
                    all_images[i].score = scores[dates.indexOf(Math.max(...dates))].content;
                }
                let accepts = comment_list.filter(com => com.type === "score-accept" && conditions(com.user, user_email, user_role));
                if (accepts.length > 0) {
                    let dates = accepts.map(s => Date.parse(s.date));
                    all_images[i].accepted = accepts[dates.indexOf(Math.max(...dates))].content;
                }
            });
            return all_images;
        });
    }

    static saveStudy(study_id, images, missing_images, editor, user_token, validated, measurements) {
        return DataProvider("MONGO","UPDATE_ANALYSIS", {
            id: study_id,
            images: images,
            missing_images: missing_images,
            validated: validated,
            // measurements: measurements,
            editor: editor,
            project: HelperStudies.getCurrentProject(),
            db_name: HelperStudies.getCurrentProjectObj().db_name,
            medical_data_type: "echo",
            study_state: HelperStudies.getStudyState()
        }, user_token).then((study_updated) => {
            console.log("UPDATE RESULT",study_updated)
            return study_updated;
        }).catch((error)=>{
            console.log("ERROR updating analysis",error)
            return false;
        });
    }

    static validatedToCheckpoint(study_id, reviewer, editor, user_token) {
        return DataProvider("MONGO","VALIDATED-TO-CHECK", {
            id: study_id,
            reviewer: reviewer,
            editor: editor,
            project: HelperStudies.getCurrentProject(),
            db_name: HelperStudies.getCurrentProjectObj().db_name,
            medical_data_type: "echo"
        }, user_token).then((review_updated) => {
            // console.log("Updating review",review_updated)
            return review_updated;
        }).catch((error)=>{
            console.log("ERROR updating review",error)
            return false;
        });
    }

    static updateReviewState(study_id, reviewer, editor, new_state, user_token) {
        return DataProvider("MONGO","UPDATE-REVIEW-STATE", {
            id: study_id,
            reviewer: reviewer,
            editor: editor,
            new_state: new_state,
            project: HelperStudies.getCurrentProject(),
            db_name: HelperStudies.getCurrentProjectObj().db_name,
            medical_data_type: "echo"
        }, user_token).then((review_updated) => {
            // console.log("Updating review",review_updated)
            return review_updated;
        }).catch((error)=>{
            console.log("ERROR updating review",error)
            return false;
        });
    }

    static unvalidateStudy(study_id, editor, user_token, reviewer) {
        return DataProvider("MONGO","UNVALIDATE", {
            id: study_id,
            reviewer: reviewer,
            editor: editor,
            project: HelperStudies.getCurrentProject(),
            db_name: HelperStudies.getCurrentProjectObj().db_name,
            medical_data_type:"echo"
        }, user_token).then((study_validated) => {
            console.log("Unvalidating study",study_validated)
            return study_validated;
        }).catch((error)=>{
            console.log("ERROR unvalidating analysis",error)
            return false;
        });
    }

    static validateStudy(study_id, images, missing_images, editor, user_token, reviewer, control) {
        return DataProvider("MONGO","VALIDATE", {
            id: study_id,
            review: images,
            reviewer: reviewer,
            missing_images: missing_images,
            editor: editor,
            control: control,
            project: HelperStudies.getCurrentProject(),
            db_name: HelperStudies.getCurrentProjectObj().db_name,
            medical_data_type:"echo"
        }, user_token).then((study_validated) => {
            console.log("Validating study",study_validated)
            return study_validated;
        }).catch((error)=>{
            console.log("ERROR validating analysis",error)
            return false;
        });;
    }

    static saveReview(study_id, images, missing_images, editor, user_token, reviewer) {
        return DataProvider("MONGO","SAVE_REVIEW", {
            id: study_id,
            review: images,
            missing_images: missing_images,
            reviewer: reviewer,
            editor: editor,
            project: HelperStudies.getCurrentProject(),
            db_name: HelperStudies.getCurrentProjectObj().db_name,
            validated: HelperStudies.getValidated() === true,
            medical_data_type: "echo",
            study_state: HelperStudies.getStudyState()
        }, user_token).then((review_saved) => {
            console.log("Saving review...",review_saved)
            return review_saved;
        }).catch((error)=>{
            console.log("ERROR saving review",error)
            return false;
        });;
    }

    static getReview(study_id, editor, user_email, user_token, user_role) {
        // if (user_role === "Reviewer") {
            return DataProvider("MONGO","GET_REVIEW", { 
                id: study_id, editor, 
                reviewer: user_email,
                db_name: HelperStudies.getCurrentProjectObj().db_name,
                medical_data_type: "echo"
            }, user_token).then((review_list) => {
                if (review_list.length > 0) {
                    return review_list[0];
                }
                return false;
            });
        // }
    }

    static getStudyAnalysis(study_id, user_email, user_token, user_role) {
        // if (user_role === "Ground-truth generator") {
            return DataProvider("MONGO","GET_ANALYSIS", { 
                id: study_id,
                editor: user_email,
                medical_data_type: "echo",
                db_name: HelperStudies.getCurrentProjectObj().db_name
            
            }, user_token).then((analysis_list) => {
                if (analysis_list !== "Analysis doesn't exist") {
                    return analysis_list[0];
                }
                return false;
            });
        // }
    }

    static getStudyStatus(study_id, user_token) {
        return DataProvider("MONGO","GET_STUDY_STATUS", { 
            study_id,
            medical_data_type: "echo",
            db_name: HelperStudies.getCurrentProjectObj().db_name
        }, user_token).then((status) => {
            return status;
        });
    }

    static getAnalysisLastUpdate(study_id, editor, user_token) {
        return DataProvider("MONGO","GET_ANALYSIS_LAST_UPDATE", { 
            id: study_id,
            editor: editor,
            medical_data_type: "echo",
            db_name: HelperStudies.getCurrentProjectObj().db_name
        }, user_token).then((last_update) => {
            return last_update;
        }).catch((error)=>{
            console.log("ERROR getting last update",error)
            return false;
        });
    }

    static setStudyOpen(study_id, open, user, user_token) {
        return DataProvider("MONGO","SET_STUDY_OPEN", {
            study_id: study_id,
            user: user,
            open: open,
            db_name: HelperStudies.getCurrentProjectObj().db_name,
            medical_data_type: "echo"
        }, user_token).then((study_open_updated) => {
            return study_open_updated;
        }).catch((error)=>{
            console.log("ERROR updating open study",error)
            return false;
        });
    }

    static getStudyOpen(study_id, user_token) {
        return DataProvider("MONGO","GET_STUDY_OPEN", {
            study_id: study_id,
            db_name: HelperStudies.getCurrentProjectObj().db_name,
            medical_data_type: "echo"
        }, user_token).then((study_open) => {
            return study_open;
        }).catch((error)=>{
            console.log("ERROR getting study open",error)
            return false;
        });
    }

    static getComments(study_id, image_filename, user_token) {
        return DataProvider("MONGO","GET_COMMENTS", {
            study_id: study_id,
            image_filename: image_filename,
            db_name: HelperStudies.getCurrentProjectObj().db_name,
        }, user_token).then((result) => {
            // console.log(result)
            return result;
        }).catch((error)=>{
            console.log("ERROR getting comments",error)
            return false;
        });
    }

    static getAllScores(user_token) {
        return DataProvider("MONGO","GET_ALL_SCORES", {
            db_name: HelperStudies.getCurrentProjectObj().db_name,
        }, user_token).then((result) => {
            // console.log(result)
            return result;
        }).catch((error)=>{
            console.log("ERROR getting scores",error)
            return false;
        });
    }

    static saveComment(comment, update, user_token) {
        return DataProvider("MONGO","SAVE_COMMENT", {
            comment: comment,
            update: update,
            db_name: HelperStudies.getCurrentProjectObj().db_name,
        }, user_token).then((result) => {
            console.log("CREATE COMMENT",result)
            return result;
        }).catch((error)=>{
            console.log("ERROR creating comment",error)
            return false;
        });
    }

    static removeComment(content, user, date, user_token) {
        return DataProvider("MONGO","REMOVE_COMMENT", {
            text: content,
            user: user,
            // date: date,
            db_name: HelperStudies.getCurrentProjectObj().db_name,
        }, user_token).then((result) => {
            console.log("REMOVE COMMENT",result)
            return result;
        }).catch((error)=>{
            console.log("ERROR removing comment",error)
            return false;
        });
    }

    static removeImageComments(study, filename, user, comment_types, user_token) {
        return DataProvider("MONGO","REMOVE_IMAGE_COMMENTS", {
            image_filename: filename,
            study_id: study,
            types: comment_types,
            user: user,
            db_name: HelperStudies.getCurrentProjectObj().db_name,
        }, user_token).then((result) => {
            console.log("REMOVE IMAGE COMMENTS",result)
            return result;
        }).catch((error)=>{
            console.log("ERROR removing comment",error)
            return false;
        });
    }

    static getProject(currentproject, user_token) {
        return DataProvider("ADMIN","GET_PROJECT", { id: currentproject }, user_token).then((project_db) => {
            return project_db
        });
    }

    static getPresignedUrlsMinio(params, user_token) {
        return DataProvider("MINIO","GET_PRESIGNED_URLS_FROM_MINIO", params, user_token).then((urls) => {
            if (Array.isArray(urls)) {
                return urls;
            } 
            // else {
            //     return this.getPresignedUrlsMinio(params, user_token);
            // }
        });
    }

    // ONLY FOR THE PRISMA TEAM REVIEW FUNCTIONALITY
    static deleteTeamReviewFromMinio(params, user_token) {
        return DataProvider("MINIO","DELETE_TEAM_REVIEW_MINIO", params, user_token);
    }

    static getClinicalDataFromPatient(patient_id, server_type, server_name, user_token) {
        return DataProvider("CLINICAL","GET_CLINICAL_DATA_FROM_PATIENT", {
            patient_id: patient_id,
            server_type: server_type,
            server_name: server_name
        }, user_token).then((result) => {
            return result;
        }).catch((error)=>{
            console.log("ERROR getting clinical data",error)
            return false;
        });
    }
}

function getURLsToLoad(urls, images2load) {
    if (images2load && images2load.length > 0) {
        let urls2load = [];
        let all_names = getFilenameFromURLs(urls);
        all_names.map((name,index) => {
            if (images2load.includes(name)) {
                urls2load.push(urls[index]);
            }
            return true;
        });
        return urls2load;
    } else {
        return urls;
    }
}

function getStudyMinioPrefix(study_id, patient_id) {
    return patient_id +"/" + study_id.replace(patient_id + "_","") + "/";
}

function createImageArray(json, urls, filenames, urls2load) {
    let images = [];
    json.map((img) => {
        let i = filenames.indexOf(img.filename);
        let ic = urls.small_icons.findIndex(u => u.includes("/"+img.filename+".png"));
        if (ic === -1) {
            ic = urls.small_icons.findIndex(u => u.includes("/"+img.filename+".gif"));
        }
        let image_object = {
            urls: {
                small_icon: urls.small_icons[ic],
                big_icon: urls.big_icons[ic],
                DICOM_url: urls.DICOM[i]
            },
            filename: img.filename,
            instance_id: img.instance_id,
            metadata: {
                measurements_in_image: img.measurements_in_image,
                modality: img.modality,
                number_of_frames: img.number_of_frames
            },
            active: urls2load.includes(urls.DICOM[i])
        };
        images.push(image_object);
        return true;
    });
    return images;
}

function mergeImageInformation(all, analysis) {
    analysis.map(img => {
        let all_img = findWhere(all, { filename: img.filename });
        if (all_img) {
            all_img.metadata.modality = img.modality;
            img.urls = all_img.urls;
            img.comments = all_img.comments;
            img.score = all_img.score;
            img.accepted = all_img.accepted;    
        }
        return true;
    });
    return [all, analysis];
}

function classifyUrls(urls) {
    let small_icons = [];
    let big_icons = [];
    let DICOM = [];
    let json = false;
    urls.map((url) => {
        if (url.includes("DICOM")) {
            DICOM.push(url);
        } else if (url.includes("small")) {
            small_icons.push(url);
        } else if (url.includes("big")) {
            big_icons.push(url);
        } else if (url.includes("json")) {
            json = url;
        }
        return true;
    });
    return {
        small_icons: small_icons,
        big_icons: big_icons,
        DICOM: DICOM,
        json
    }
}

function getFilenameFromURLs(urls) {
    let names = [];
    urls.map((url) => {
        url = url.split("?");
        url = url[0];
        url = url.split("/");
        let name = url[url.length - 1];
        name = name.replace(".gif","");
        name = name.replace(".png","");
        names.push(name);
        return true;
    });
    return names;
}