import React from 'react';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

export const ClinicalDataComponent = ({
        data
    }) => {

    const renderClinicalData = () => {
        if (data.includes("Problems")) {
            return "ERROR: Clinical data could not be retrieved";
        } else {
            return Object.keys(data).map(section => {
                return (
                    <>
                        <div className="section-title">{section}</div>
                        <div className="section-content">
                            {renderSectionContent(data[section])}
                        </div>
                    </>
                );
            });    
        }
    }

    const renderSectionContent = (content) => {
        if (content.length > 0) {
            return (
                <Paper>
                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                            {renderTableHeader(Object.keys(content[0]))}
                            {renderTableBody(content)}
                        </Table>
                    </TableContainer>
                </Paper>
            );    
        }
    }

    const renderTableHeader = (columns) => {
        return (
            <TableHead>
                <TableRow>
                    {columns.map((column) => (
                        <TableCell align={"center"} style={{ color: "white", fontWeight: "bold", backgroundColor: "#21211ff0" }}>
                            {column}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        )
    }

    const renderTableBody = (rows) => {
        return (
            <TableBody>{
                rows.map((row, i) => {
                    return (
                        <TableRow role="checkbox" tabIndex={-1}>
                            {Object.keys(row).map((column) => {
                                return (renderTableRowCell(row[column]))
                            })}
                        </TableRow>
                    );
                })}
            </TableBody>
        )
    }

    const renderTableRowCell = (value) => {
        return (
            <TableCell align={"center"} className={"table-cell"}>
                {value}
            </TableCell>
        );
    }

    return (
        <div className="clinicaldata component">
            {renderClinicalData()}
        </div>
    );
}
