import React from 'react';

// Components
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

export const MenuComponent = ({
        selected_value,
        column_name,
        column_type,
        current_source,
        options,
        on_action_to_perform
    }) => {
    
    const id = current_source? column_name + current_source.instance_id : null;
    
    const handleChange = (value, event) => {
        if (value && value !== "None") {
            on_action_to_perform({ action: "OPTION-SELECTED", value, extra: column_name, type: column_type });
        } else {
            on_action_to_perform({ action: "REMOVE-SPECIFIC-TAG", field: column_type });
        }
    };

    return current_source? (
        <Select
            labelId={id}
            id={id}
            name={id}
            value={selected_value}
            className={"menu-option"}
        >
            {options.map((value) => (
                <MenuItem
                    value={value.type}
                    key={value.type}
                    onClick={() => { handleChange(value) }}
                    onMouseEnter={(e) => e.target.style.color = '#c62828'}
                    onMouseLeave={(e) => e.target.style.color = 'black'}
                >
                    {column_name === "view"? value.type : value.abbreviation}
                    <span style={{ fontStyle: "italic", fontSize: "12px", paddingLeft: "3px" }}>{column_name === "view"? "" : "(" + value.name_to_show + ")"}</span>
                </MenuItem>
            ))}
            <MenuItem
                value={"None"}
                key={"None"}
                onClick={() => { handleChange("None") }}
                onMouseEnter={(e) => e.target.style.color = '#c62828'}
                onMouseLeave={(e) => e.target.style.color = 'black'}
            >
                <em>None</em>
            </MenuItem>
        </Select>
    ) : <div className="menu-option">-</div>;
}