import {
    HelperConfig
} from './../helper_config/helper_config';
import {
    HelperUser
} from './../helper_user/helper_user';
import {
    HelperStudies
} from './../helper_studies/helper_studies';
import { 
    getImageMetadataFromDicom 
} from './../../modules/dicom_module';
import {
    findIndex,
    findWhere
} from 'underscore';

export class HelperSelection {

    static getImageAnalysisTemplate(image, modality) {
        let template = HelperConfig.getImageAnalysisTemplate();
        template.dicom = image.dicom;
        template.filename = image.filename;
        template.id = image.instance_id;
        template.measurements_in_image = image.metadata.measurements_in_image;
        if (image.dicom) { template.metadata = getImageMetadataFromDicom(image.dicom.metadata, modality); }
        if (image.accepted) { template.accepted = image.accepted; }
        if (image.comments) { template.comments = image.comments; }
        if (image.score) { template.score = image.score; }
        template.modality = modality;
        template.urls = image.urls;
        return template;
    }

    static setReference(images, i, action, img) {
        if (action === "set") {
            if (checkReferenceAllowed(images[i], images)) {
                if (i !== null && i >= 0) {
                    images[i].reference = true;
                } else {
                    const image = this.getImageAnalysisTemplate(img, false);
                    image.reference = true;
                    images.push(image);
                    i = images.length - 1;
                }    
            } else {
                alert("Only one reference per image type is allowed");
            }
        } else if (action === "unset") {
            if (!images[i].view && !images[i].type) {
                images.splice(i,1);
                i = null;
            } else {
                images[i].reference = false;
            }
        }
        return { new_indexAnalysis: i, new_analysis_images: images };
    }

    static setView(images, i, view, img) {
        const previous_view = typeof i === "number"? images[i].view : false;
        if (i !== null && i >= 0) {
            images[i].view = view.type;
        } else {
            const image = this.getImageAnalysisTemplate(img, false);
            image.view = view.type;
            images.push(image);
            i = images.length - 1;
        }
        checkReferences(images[i], images, false, previous_view, images[i].type);
        images[i].onset_type = HelperStudies.getDataType() === "fetal"? "Flow" : "R-wave";
        return { new_indexAnalysis: i, new_analysis_images: images };
    }

    static setType(images, i, type, modality, imgs, j) {
        const previous_type = typeof i === "number"? images[i].type : false;
        const previous_view = typeof i === "number"? images[i].view : false;
        const type_details = findWhere(type.modalities, { modality: modality });
        if (previous_type && previous_type !== type.type) {
            deleteImageQCs(images[i], imgs[j]);
        }
        if (i !== null && i >= 0) {
            images[i].type = type.type;
            images[i].view = type_details.view;
            // images[i].view = type_details.view? type_details.view : previous_type? type_details.view : images[i].view;
            images[i].modality = modality;
            images[i].metadata = getImageMetadataFromDicom(images[i].dicom.metadata, modality);
        } else {
            const image = this.getImageAnalysisTemplate(imgs[j], modality);
            image.type = type.type;
            image.view = type_details.view;
            // image.view = type_details.view? type_details.view : previous_type? type_details.view : image.view;
            image.modality = modality;
            images.push(image);
            i = images.length - 1;
        }
        imgs[j].metadata.modality = modality;
        checkReferences(images[i], images, false, previous_view, previous_type);
        this.checkAnalysis(images[i], type);
        images[i].onset_type = HelperStudies.getDataType() === "fetal"? "Flow" : "R-wave";
        const study_images = HelperStudies.getStudyImages();
        if (study_images.missing_images.includes(type.type)) {
            study_images.missing_images = study_images.missing_images.filter(m => m !== type.type);
        }
        HelperStudies.checkStudyState();
        return { new_indexAnalysis: i, new_analysis_images: images, new_images: imgs };
    }

    static checkAnalysis(image, type) {
        if (image.modality === "2D") {
            for (const cc of image.segmentation) {
                cc.end_diastole.lines = cc.end_diastole.lines.filter(line => type.patterns[0].lines.includes(line.name));
                cc.end_systole.lines = cc.end_systole.lines.filter(line => type.patterns[0].lines.includes(line.name));
            }
        }
    }

    static removeField(images, i, imgs, idx, field) {
        const previous_type = images[i].type;
        const previous_view = images[i].view;
        images[i][field] = false;
        if (field === "type") {
            deleteImageQCs(images[i], imgs[idx]);
        }
        if (!images[i].view && !images[i].type && !images[i].reference) {
            images.splice(i,1);
            i = null;
        }
        checkReferences(images[i], images, true, previous_view, previous_type);
        return { new_indexAnalysis: i, new_analysis_images: images };
    }

    static removeTag(images, i, imgs, idx, img) { // at this moment, the whole analysis is removed if the tag is removed
        const j = findIndex(images, { filename: img.filename });
        const previous_type = images[j].type;
        const previous_view = images[j].view;
        const k = findIndex(imgs, { filename: img.filename });
        deleteImageQCs(images[j], imgs[k]);
        let filename;
        if (idx !== null) {
            filename = imgs[idx].filename;
        }
        images.splice(j,1);
        if (idx !== null) {
            i = filename === img.filename? null : findIndex(images, { filename });
        }
        checkReferences(img, images, true, previous_view, previous_type);
        return { new_indexAnalysis: i, new_analysis_images: images };
    }

    static generateAllGoodScore(analysis_images, idxan, images, idx) {
        if (HelperUser.getUserType() !== "Viewer") {
            const score = HelperConfig.getImageScoreTemplate(analysis_images[idxan].type);
            if (score) {
                const user = HelperUser.getUser();
                const comment_obj = {
                    date: String(new Date()),
                    user: user.user_email,
                    initials: user.user_name.charAt(0) + user.user_family_names.charAt(0),
                    content: score,
                    type: "score",
                    study_id: HelperStudies.getStudyId(),
                    image_filename: images[idx].filename,
                    image_label: analysis_images[idxan].type
                };
                images[idx].score = score;
                delete images[idx].accepted;
                analysis_images[idxan].score = score;
                delete analysis_images[idxan].accepted;
                HelperStudies.saveComment(comment_obj, false);  
            } else {
                alert("Sorry, no quality assessment template exists for this image type yet");
            }
        } else {
            alert("Sorry, you do not have permissions to create QCs");
        }
        return { new_analysis_images: analysis_images, new_images: images };    
    }

    static acceptImage(analysis_images, idxan, images, idx, value) {
        if (HelperUser.getUserType() !== "Viewer") {
            const done = images[idx].accepted !== undefined;
            images[idx].accepted = value;
            analysis_images[idxan].accepted = value;
            const user = HelperUser.getUser();
            const comment_obj = {
                date: String(new Date()),
                user: user.user_email,
                initials: user.user_name.charAt(0) + user.user_family_names.charAt(0),
                content: value,
                type: "score-accept",
                study_id: HelperStudies.getStudyId(),
                image_filename: images[idx].filename,
                image_label: analysis_images[idxan].type
            };
            HelperStudies.saveComment(comment_obj, done);
        }
        return { new_analysis_images: analysis_images, new_images: images };
    }
}

function deleteImageQCs(analysis_image, image) {
    if (HelperUser.getUserType() === "Ground-truth generator") {
        delete image.accepted;
        delete image.score;           
        if (analysis_image) {
            delete analysis_image.accepted;
            delete analysis_image.score;
        }
        HelperStudies.deleteImageQCs(image["filename"]);
    }
}

function checkReferences(image, images, is_removing, previous_view, previous_type) {
    let files_same_tag = [];
    let files_same_previous_tag = [];
    if (image.view && image.type) {
        for (let source of images) {
            if (image.type === source.type && image.view === source.view && image.modality === source.modality && image.filename !== source.filename) {
                files_same_tag.push(source);
            }
            if (previous_type !== image.type || previous_view !== image.view) {
                if (previous_view && previous_type && source.type === previous_type && source.view === previous_view) {
                    files_same_previous_tag.push(source);
                }
            }
        }
    } else if (is_removing) {
        for (let source of images) {
            if (previous_type !== image.type || previous_view !== image.view) {
                if (previous_view && previous_type && source.type === previous_type && source.view === previous_view) {
                    files_same_previous_tag.push(source);
                }
            }
        }
    }
    if (is_removing === true) {
        if (image) { image.reference = false; }
        if (files_same_tag.length === 1 && files_same_tag[0].type && files_same_tag[0].view) {
            files_same_tag[0].reference = true;
        }
        if (files_same_previous_tag.length === 1) {
            files_same_previous_tag[0].reference = true;
        }
    } else {
        if (files_same_tag.length === 0 && image.view && image.type) {
            image.reference = true;
            if (files_same_previous_tag.length === 1) {
                files_same_previous_tag[0].reference = true;
            }
        } else if (files_same_tag.length === 1) {
            image.reference = false;
            files_same_tag[0].reference = false;
            if (files_same_previous_tag.length === 1) {
                files_same_previous_tag[0].reference = true;
            }
        }
    }
}

function checkReferenceAllowed(image, images) {
    const unique_reference = HelperStudies.getCurrentProjectObj().unique_reference;
    if (unique_reference) {
        const other_reference = images.some(im => im.type === image.type && im.reference && !(im.filename === image.filename));
        return other_reference? false : true;
    } else {
        return true;
    }
}