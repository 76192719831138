export function stringToDate(str) {
    const date = new Date();
    date.setHours(0,0,0,0);
    date.setFullYear(parseInt(str.substr(0,4)),parseInt(str.substr(4,2))-1,parseInt(str.substr(6,2)));
    return date;
}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function codeString(data, action) { // Rot47
    // data = data.replaceAll("%3E",">").slice(); // bug solving
    let salt_key = "!n@wR)Be>4:Y*hyE"
    if (action === "decode") {
        const myDecipher = decipher(salt_key)
        data = myDecipher(data)
    } else if (action === "encode") {
        let myCipher = cipher(salt_key)
        data = myCipher(data);
    }
    // let x = "";
    // if (action === "encode") { // for extra "security" we can implement an additional own coding rule to the string
    //     x = data;
    // } else if (action === "decode") {
    //     x = data;
    // }
    // var s = [];
    // for (var i=0; i<x.length; i++) {
    //     var j = x.charCodeAt(i);
    //     if (j>=33 && j<=126) {
    //         s[i] = String.fromCharCode(33+((j+14)%94));
    //     } else {
    //         s[i] = String.fromCharCode(j);
    //     }
    // }
    return data; //s.join('');
}
function cipher (salt) {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);

    return text => text.split('')
        .map(textToChars)
        .map(applySaltToChar)
        .map(byteHex)
        .join('');
}
function decipher(salt) {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const applySaltToChar = code => textToChars(salt).reduce((a, b) => a ^ b, code);
    return encoded => encoded.match(/.{1,2}/g)
        .map(hex => parseInt(hex, 16))
        .map(applySaltToChar)
        .map(charCode => String.fromCharCode(charCode))
        .join('');
}