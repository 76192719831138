import React from 'react';

export const TextIcon = ({
        text,
        className,
        style,
        width,
        height
    }) => {
    
    return (
        <div className={className} style={style}>{text}</div>
    );
}