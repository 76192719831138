import React from 'react';

export const UnlockIcon = ({
        className,
        style,
        width,
        height
    }) => {
    
    return (
        <svg className={className} width={width} height={height} style={style} viewBox="0 0 24 24">
            <path d="M21.658 3.786l-3.658 3.318v-1.104c0-3.313-2.687-6-6-6s-6 2.687-6 6v4h-3v10.707l-2 1.813 1.346 1.48 20.654-18.734-1.342-1.48zm-16.658 15.107v-6.893h7.625l-7.625 6.893zm11-9.975l-1.194 1.082h-6.806v-4c0-2.205 1.795-4 4-4s4 1.795 4 4v2.918zm5 1.082v14h-16.391l2.204-2h12.187v-10h-1.176l2.191-2h.985z"/>
        </svg>
    );
}