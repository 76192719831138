import React, { useMemo } from 'react';

import { useTable } from 'react-table';

const Table = ({ columns, data, style }) => {
    
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data
    })
  
    return (
        <table {...getTableProps()} style={style}>
            <thead>
                {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                    ))}
                </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                prepareRow(row)
                return (
                    <tr {...row.getRowProps()}>
                    {row.cells.map(cell => {
                        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    })}
                    </tr>
                )
                })}
            </tbody>
        </table>
    );
}

export const ComplexTableComponent = ({
        columns,
        data,
        style
    }) => {

    const columns_object = useMemo(() => columns, []);
    const data_object = useMemo(() => data, []);
    
    return (
        <Table columns={columns_object} data={data_object} style={style} />
    );

}

