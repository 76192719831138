import React from 'react';

import { TextIcon } from './pattern_icons/text_icon';

export const PatternIcons = ({
        type,
        className,
        style,
        width,
        height
    }) => {
    
    let icon;
    switch (type) {
        case "normal":
            icon = <TextIcon text={"N"} className={className} style={style} width={width} height={height} />
            break;
        case "fusion":
            icon = <TextIcon text={"F"} className={className} style={style} width={width} height={height} />
            break;
        case "total fusion":
            icon = <TextIcon text={"TF"} className={className} style={style} width={width} height={height} />
            break;
        case "l type":
            icon = <TextIcon text={"L"} className={className} style={style} width={width} height={height} />
            break;
        case "monophasic":
            icon = <TextIcon text={"M"} className={className} style={style} width={width} height={height} />
            break;
        case "monophasic normal":
            icon = <TextIcon text={"MN"} className={className} style={style} width={width} height={height} />
            break;
        case "monophasic fused":
            icon = <TextIcon text={"MF"} className={className} style={style} width={width} height={height} />
            break;
        case "biphasic":
            icon = <TextIcon text={"B"} className={className} style={style} width={width} height={height} />
            break;
        case "biphasic normal":
            icon = <TextIcon text={"BN"} className={className} style={style} width={width} height={height} />
            break;
        case "biphasic fused":
            icon = <TextIcon text={"BF"} className={className} style={style} width={width} height={height} />
            break;
        case "reversal":
            icon = <TextIcon text={"R"} className={className} style={style} width={width} height={height} />
            break;
        default:
            icon = null;
            break;
    }

    return icon;
}