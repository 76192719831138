import React from 'react';

export const BModeIcon = ({
        className,
        style,
        width,
        height
    }) => {
    
    return (
        <svg className={className} width={width} height={height} style={style} viewBox="0 0 50 50" fill="none">
            <path d="M25 6.39001L6.25999 38.84C6.25999 38.84 16 43.61 25 43.61C31.5231 43.4293 37.9243 41.8 43.74 38.84L25 6.39001Z" stroke="#808080" strokeWidth="2" strokeMiterlimit="10" />
            <path d="M46.68 1H3.32C2.0387 1 1 2.0387 1 3.32V46.68C1 47.9613 2.0387 49 3.32 49H46.68C47.9613 49 49 47.9613 49 46.68V3.32C49 2.0387 47.9613 1 46.68 1Z" stroke="#666666" strokeWidth="2" strokeMiterlimit="10" />
        </svg>
    );
}