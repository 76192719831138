import { HelperConfig } from '../../../helpers/helper_config/helper_config';
import { HelperUser } from '../../../helpers/helper_user/helper_user';

// Styles
import { useStyles } from './db_styles';

export function DBStatus(props) {
    const classes = useStyles();
    const renderLabel = (string, value, prev_value) => {
        let color = HelperConfig.getStatisticsColor(string);
        let page = ["selected","qc"].includes(string)? "preview" : (string.includes("segmented")? "segmented" : string);
        let className = value? classes.true_state : classes.false_state;
        let style = value? { background: color } : { borderColor: color };
        let validation = false; //HelperUser.getUserRoles().includes("Reviewer")? false : true;
        if (string === "validated") {
            if (validation) {
                const validator = value? props.row.validated_by : "-";
                return (
                    // <div key={string} className={className} title={"Validated by: " + validator} style={style}> </div>
                    <div key={string} className={className} style={style}> </div>
                );
            }
        } else if (string !== "measured") {
            return (
                <div key={string} className={className} style={style} onClick={() => {
                    if (value || prev_value) {
                        props.onactiontoperform("GENERAL", {
                            action: "GO_TO_PAGE", value: {
                                project: props.row.project,
                                id_study: props.row.id_orthanc,
                                id_patient: props.row.patient,
                                page: page,
                                from: "studies",
                                type: props.row.data_type,
                                editor: props.row.editor
                            }
                        });
                    }
                }}> </div>
            );
        }
    }
    const renderStatus = () => {
        const entries = Object.entries(props.status);
        return (entries.map((value, index) => {
            const prev_value = index > 0? entries[index-1][1] : true;
            return (renderLabel(value[0], value[1], prev_value));
        }));
    }

    return (
        <td className="grid-block horizontal align-center ">
            {renderStatus()}
        </td>
    );
}