import React from 'react'

import createPlotlyComponent from 'react-plotly.js/factory';
import Plotly from 'plotly.js-basic-dist';

import { createVolumeFromOnsets, createPlot, createThicknessPlot, createFlowPlot } from './../../../modules/plot_module';

import { findWhere } from 'underscore';

const Plot = createPlotlyComponent(Plotly);

export const PlotComponent = ({
        measures,
        plot_type,
        show_left,
        show_right,
        image,
        modality,
        analysis
    }) => {

    let shapes = []
    let traces = [];

    let plotData = { shapes: [], traces: [] }
    let plotWidth = 550;
    let legend = true;
    if (image) {
        if (plot_type === "Volume(t)") {
            let lv_vol = findWhere(measures, { name: "LV vol (t)" });
            let la_vol = findWhere(measures, { name: "LA vol (t)" });
            plotData = createVolumeFromOnsets(image.cardiac_events, lv_vol, la_vol, measures, traces, shapes);
        } else {
            plotData = createThicknessPlot(image.segmentation, traces, shapes, image);
        }
    } else if (plot_type === "flow") {
        legend = false;
        plotWidth = 1050;
        if (show_left && show_right) {
            plotWidth = 550;
        }
        let id = modality.image_id;
        let images = analysis;
        let image2plot = findWhere(images, { id: id });
        if (measures.length > 0 || (image2plot.measurements && image2plot.measurements.length > 0)) {
            plotData = createFlowPlot(image2plot, measures)
            plot_type = plotData.plot_type;
        }
    }
    shapes = plotData.shapes;
    traces = plotData.traces;
    var layout = createPlot(plotData.shapes, plot_type, plotWidth, plotData.annotations, legend);
    
    if (plotData.traces.length > 0) {
        return (
            <div className="grid-block vertical" style={{ overflow: "hidden" }}>
                <Plot
                    data={traces}
                    layout={layout}
                    revision={1}
                    className="plot_rkt"
                    id='plot_rkt'
                    // onHover={this.getGraphValue.bind(this, "hover", this.state.contour_names)}
                    // onClick={this.getGraphValue.bind(this, "click", this.state.contour_names)}
                    // onUnhover={this.getGraphValue.bind(this, "unhover", this.state.contour_names)}
                    style={{ "margin-bottom": "-30px", "width": "115%", "height": "auto" }}
                />
            </div>
        );
    } else {
        return <div></div>;
    }
}