import { HelperConfig } from './../helper_config/helper_config';
import { HelperImages } from './../helper_images/helper_images';
import { HelperStudies } from './../helper_studies/helper_studies';
import { HelperSegmentation } from './helper_segmentation';

import { getSpline } from './../../modules/spline_module';
import { getNextCyclesPrediction } from './../../modules/prediction_module';

import { findWhere, findIndex } from 'underscore';

export class HelperSegmentationDoppler {

    // INIT METHODS --------------------------

    static init(image, force) {
        const state = {};
        let pattern = HelperConfig.getDefaultPattern(image.type, image.modality);
        if (image.segmentation.length > 0 && image.view !== "Fetal") { // REVIEW image.view !== "Fetal" is a HARDCODE to solve the change of fetal pattern names in MV ... remove after some time
            pattern = image.segmentation[0].lines[0].pattern;
        }
        const shapes = HelperConfig.getShapes(image.type, pattern, image.modality);
        const cps = HelperConfig.getControlPoints(image.type, pattern);
        let current_cycle = 0;
        let current_action = "finished";
        let current_cp = 0;

        const max_events = HelperStudies.getCurrentProjectMaxCycles();
        if (image.cardiac_events.length > max_events) {
            current_action = "selecting-cycles";
            state.cycles_selected = image.cardiac_events.map(cc => false);
        } else if (image.segmentation.length === 0 || force === true) { 
            image.segmentation = [];
            for (let i in image.cardiac_events) {
                image.segmentation.push({ lines: [{
                    name: shapes[0].lines[0],
                    finished: false,
                    pattern: pattern,
                    shape: shapes[0].name,
                    points: []
                }] });
            }
            current_action = "segmenting";
        } else if (!image.is_segmented) {
            let finished = this.checkIfAllFinished(image.segmentation);
            if (finished !== true) {
                current_action = "segmenting";
                current_cp = finished.cp;
                current_cycle = finished.cc;
            } else if (image.segmentation.length < image.cardiac_events.length) {
                let last_cc = image.segmentation.length-1;
                for (let i=last_cc+1; i<image.cardiac_events.length; i++) {
                    image.segmentation.push({ lines: [{
                        name: shapes[0].lines[0],
                        finished: false,
                        pattern: pattern,
                        shape: shapes[0].name,
                        points: []
                    }] });
                }
                getNextCyclesPrediction(image.segmentation, image.cardiac_events, last_cc);
            }
        }

        const flow_region = image.metadata.flow_region;
        state.zero_line = flow_region.reference_y + flow_region.region_location_min_y; 
        state.current_cycle = current_cycle;
        state.current_event = false;
        state.current_action = current_action;
        state.control_points = cps;
        state.current_cp = cps[current_cp];
        state.current_shape = shapes[0];
        state.current_line = shapes[0].lines[0];
        return state;
    }

    // SEGMENTATION METHODS --------------------------

    static checkIfAllFinished(segmentation) {
        let finished = segmentation.length > 0;
        segmentation.some((cc,i) => {
            if (cc.lines[0].points.length === 0) {
                finished = { cc: i, cp: 0 }
                return true;
            }
            return cc.lines[0].points.some((cp,j) => {
                if (cp.x === false || cp.y === false) {
                    finished = { cc: i, cp: j };
                    return true;
                }
            });
        });
        return finished;
    }

    static finish(image, type, state) {
        const config = HelperConfig.getToolConfig("Doppler","normal");
        let current_action = state.current_action;
        let current_cycle = state.current_cycle;
        if (current_action === "selecting-cycles") {
            let selection = state.cycles_selected;
            let sum = selection.filter(cc => cc === true).length;
            const max_events = HelperStudies.getCurrentProjectMaxCycles();
            if (sum === max_events || window.confirm("Are you sure to keep " + sum + " cycles? All other cycles will be removed.")) {
                image.cardiac_events = image.cardiac_events.filter((cc,i) => selection[i]);
                image.segmentation = image.segmentation.filter((cc,i) => selection[i]);
                return false;
            }
        } else if (current_action === "segmenting") {
            const line = HelperSegmentation.getCurrentLine(image, state);
            const cps_completed = state.control_points? state.control_points.length > 0 && state.control_points.length === line.points.length : false;
            if (cps_completed) {
                line.finished = true;
                getNextCyclesPrediction(image.segmentation, image.cardiac_events, state.current_cycle);
                if (config.auto_edit_segmentation) {
                    current_action = "editing-segmentation";
                } else {
                    current_action = "finished";
                }
            }
        } else {
            if (type === "task") {
                if (state.current_action === "editing-segmentation" && state.current_cycle < image.cardiac_events.length-1) {
                    current_cycle++;
                    if (state.predict_cycles === false) {
                        const lines = HelperSegmentation.getSegmentationLines(image);
                        const new_state = {};
                        for (let i=current_cycle; i<image.cardiac_events.length; i++) {
                            lines[i].points = [];
                            lines[i].finished = false;
                        }
                        new_state.current_action = "segmenting";
                        new_state.control_points = HelperConfig.getControlPoints(image.type, lines[current_cycle].pattern);
                        new_state.current_cp = new_state.control_points[0];
                        const lp_prev = lines[current_cycle-1].points[lines[current_cycle-1].points.length-1];
                        if (new_state.current_cp.type === lp_prev.type) {
                            new_state.mouse_allowed = { pixel: { x: lp_prev.x, y: lp_prev.y } };
                            new_state.current_cycle = current_cycle;
                            const s = HelperSegmentation.setControlPoint(image, new_state);
                            new_state.current_cp = s.current_cp;
                        }
                        return new_state;
                    }
                } else {
                    current_action = "finished";
                }
            } else if (type === "all") {
                current_action = "finished";
            }
        }
        return { current_action, current_cycle };
    }

    // EDIT METHODS --------------------------

    static moveSelectedPoint(image, state) {
        const selected = state.element_selected;
        selected.x = Math.round(state.mouse_allowed.pixel.x);
        selected.y = Math.round(state.mouse_allowed.pixel.y);
        if (selected.same_as_end || selected.same_as_start) {
            const lines = HelperSegmentation.getSegmentationLines(image, state.current_cycle, state.current_event);
            const points = findWhere(lines, { name: state.line_selected }).points;        
            // Define starting and ending points of the segmentation
            const first_cp = points[0];
            const last_cp = points[points.length - 1];
            if (!last_cp.forced_to_zero && selected.same_as_end) {
                // Update ending point if required and not forced to zero
                last_cp.y = selected.y;
            }
            if (!first_cp.forced_to_zero && selected.same_as_start) {
                // Update starting point if required and not forced to zero
                first_cp.y = selected.y;
            }
        }
        this.spreadChangesLinkedControlPoints(image, state);
        if (state.current_action === "editing-segmentation") {
            getNextCyclesPrediction(image.segmentation, image.cardiac_events, state.current_cycle);
        }
    }

    static spreadChangesLinkedControlPoints(image, state) {
        const prev = HelperSegmentation.getCurrentLine(image, { current_cycle: state.current_cycle-1 }).points;
        const next = HelperSegmentation.getCurrentLine(image, { current_cycle: state.current_cycle+1 }).points;
        const points = HelperSegmentation.getCurrentLine(image, state).points;
        if (prev && points[0].type === prev[prev.length-1].type) {
            prev[prev.length-1].x = points[0].x;
            prev[prev.length-1].y = points[0].y;
        }
        if (next && points[points.length-1].type === next[0].type) {
            next[0].x = points[points.length-1].x;
            next[0].y = points[points.length-1].y;
        }
    }

    // DRAW METHODS --------------------------

    static checkMouseAllowance(image, state, element) {
        const mouse = { x: Math.round(state.mouse.pixel.x), y: Math.round(state.mouse.pixel.y) };
        const mouse_allowed = { x: Math.round(state.mouse.pixel.x), y: Math.round(state.mouse.pixel.y) };
        const points = HelperSegmentation.getCurrentLine(image, state).points;
        const selected = state.element_selected? state.element_selected : state.current_cp;
        let selected_index = findIndex(points, { id: selected.id });
        selected_index = selected_index === -1? points.length : selected_index;

        // Find previous and next point of the selected point
        const prev_cp = points[selected_index - 1];
        let next_cp = points[selected_index + 1];
        if (!next_cp && selected.same_as_start) {
            const next_points = HelperSegmentation.getCurrentLine(image, { current_cycle: state.current_cycle+1 }).points;
            if (next_points) {
                next_cp = next_points[1];
            }
        }
    
        // Check that X position is correct (after previous point and/or before next point)
        if (prev_cp?.x && mouse.x <= prev_cp.x + 1) {
            mouse_allowed.x = prev_cp.x + 1;
        }
        if (next_cp?.x && mouse.x >= next_cp.x - 1) {
            mouse_allowed.x = next_cp.x - 1;
        }

        // Check constraints
        if (state.constraints) {
            // Constraints in X axis
            if (selected.on_onset) {
                // Do not let onset-fixed points to move in the X axis
                if (state.element_selected) {
                    mouse_allowed.x = selected.x;
                } else {
                    const onsets = image.cardiac_events[state.current_cycle];
                    if (state.current_cp.id === 0) {
                        mouse_allowed.x = onsets.onset_start;
                    } else {
                        mouse_allowed.x = onsets.onset_end;
                    }
                }
            } else if (state.current_action === "segmenting" && state.control_points && state.control_points.at(-1).on_onset) {
                const onset_end = image.cardiac_events[state.current_cycle].onset_end;
                const threshold = HelperConfig.getToolConfig("Doppler", "normal").selection_threshold_cp;
                const min_dist = onset_end - 0.6*threshold*(state.control_points.length-1-selected.id);
                if (mouse.x > min_dist) {
                    mouse_allowed.x = min_dist;
                }
            }
            // Constraints in Y axis
            if (selected.forced_to_zero && !isNaN(state.zero_line)) {
                // Do not let forced_to_zero control points to move in the Y axis
                mouse_allowed.y = state.zero_line;
            }
        }
        const mouse_allowed_canvas = HelperImages.pixelToCanvas(element, { x: mouse_allowed.x, y: mouse_allowed.y });
        return { mouse_allowed: { pixel: mouse_allowed, canvas: mouse_allowed_canvas }};
    }

    static drawSplines(current_action, lines, current_cycle, context, element) {
        const colors = HelperConfig.getStyle("segmentation", "line_colors");
        for (const i in lines) {
            let style = HelperConfig.getStyle("segmentation", "spline");
            let color = colors[0];
            if (current_action === "editing-segmentation") {
                if (i < current_cycle) {
                    style =  HelperConfig.getStyle("segmentation", "spline_inactive");
                    color = style.color;
                } else if (i > current_cycle) {
                    style = HelperConfig.getStyle("segmentation", "spline_predict");
                }
            }
            const spline = getSpline(lines[i].points, "discontinuous");
            lines[i].spline = spline;
            const canvas = [];
            for (const point of spline) {
                canvas.push(HelperImages.pixelToCanvas(element, { x: point.x, y: point.y }));
            }
            this.drawDopplerSpline(context, canvas, color, style);
        }
    }

    static drawDopplerSpline(context, spline, color, style) {
        context.lineWidth = style.width;
        context.strokeStyle = color;
        context.beginPath();
        if (style.dash) {
            context.setLineDash(style.dash);
        } else {
            context.setLineDash([]);
        }    
        for (let i in spline) {
            if (i === 0) {
                context.moveTo(spline[i].x, spline[i].y);
            } else {
                context.lineTo(spline[i].x, spline[i].y);
            }
        }
        context.stroke();
        context.setLineDash([]);
    }

}