import React, { useState, useEffect } from 'react';

// Helpers
import { HelperConfig } from '../../../helpers/helper_config/helper_config';

export const InactivityAlertComponent = ({
        show,
        on_action_to_perform,
        handle_close
    }) => {

    const act_after_seconds = HelperConfig.getInactivityTimeout();
    const inactivity_period = HelperConfig.getInactivityPeriod();
    const [time, setTime] = useState(act_after_seconds);

    useEffect(() => {
        if (show) {
            setTime(act_after_seconds);
        } else {
            return;
        }
        const countdown = setTimeout(() => {
            on_action_to_perform("GENERAL", { action: "GO_TO_PAGE", value: { page: "studies" } });
        }, act_after_seconds * 1000)
        return () => clearTimeout(countdown);
    }, [show]);

    useEffect(() => {
        if (time === 0 || !show) {
            return;
        }
        const timer = setTimeout(() => {
            setTime(time - 1);
        }, 1000);
        return () => clearTimeout(timer);
    }, [time,show]);
    
    return show? (
        <div className="alert-container">
            <div className="alert">
                <span>Your last change was done more than {inactivity_period} minutes ago. You will be redirected to the home page in {time} seconds to allow other editors work on this study.</span>
                <button onClick={()=>handle_close()}>Cancel</button>
            </div>
        </div>
    ) : <></>;
}