import React from 'react';

export const ThumbsDownFilledIcon = ({
    className,
    style,
    width,
    height,
    color
}) => {
    
    return (
        // transform="matrix(1,0,0,-1,0,0)"
        <svg className={className} width={width} height={height} fill={color} style={style} viewBox="0 0 24 24" transform="matrix(-1,1.2246467991473532e-16,-1.2246467991473532e-16,-1,0,0)">
            <path d="M1 21h4V9H1v12zm22-11c0-1.1-.9-2-2-2h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.44-1.06L14.17 1 7.59 7.59C7.22 7.95 7 8.45 7 9v10c0 1.1.9 2 2 2h9c.83 0 1.54-.5 1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73v-2z"></path>
        </svg>
    );
}