import React, { Component } from 'react';

// Components
import { MultiFrameControlsComponent } from './multi_frame_controls_component';
import { SegmentationComponent } from './../../segmentation_component/segmentation_component';
import { ImageMenuComponent } from './../image_menu_component';
import { ActionTitlesComponent } from './../action_titles_component';
import { AlertComponent } from './../alert_component';
import { ConfirmationAlertComponent } from './../../alert_component/confirmation_alert_component';
import { SegmentationEventPickerComponent } from './../../segmentation_component/segmentation_event_picker_component';

// Helpers
import { HelperImages } from './../../../../helpers/helper_images/helper_images';
import { HelperUser } from './../../../../helpers/helper_user/helper_user';

// Actions file
import { onActionToPerform } from './../../../../actions/actions';

export class DicomViewerComponent extends Component {

    constructor() {

        super();

        this.state = {
            multiframe: false,
            number_of_frames: 0,
            is_playing: false,
            current_frame: 0,
            velocity: 40,
            loaded: false,
            alert: false,
            image: false,
            current_action: "finished",
            marked_frames: [],
            element_selected: false,
            mouse: { pixel: 0, canvas: 0 }
        };
        this.is_mounted = false;
        this.action_type = false;
        this.onImageRendered = this.onImageRendered.bind(this);
        this.onNewFrame = this.onNewFrame.bind(this);

        document.addEventListener('contextmenu', event => event.preventDefault());
        document.addEventListener('keydown', event => this.handleKeyboard(event));
        document.addEventListener('mousemove', event => this.onMouseMoveWindow(event));
        document.addEventListener('mousedown', event => this.onMouseDownWindow(event));
    }

    componentDidMount() {
        this.imageDiv.onCornerstoneImageRendered = this.onImageRendered;
        this.action_type = this.props.site ? this.props.site.toUpperCase() : null;
        this.is_mounted = true;
        this.loadAndDisplayImage(this.props.image_to_show);
        if (this.drawingCanvas && this.drawingCanvas.width !== this.imageDiv.clientWidth) {
            this.drawingCanvas.width = this.imageDiv.clientWidth;
            this.drawingCanvas.height = this.imageDiv.clientHeight;
            this.drawingCanvas.addEventListener('mousemove', event => this.onMouseMoveCanvas(event));
            this.drawingCanvas.addEventListener('mousedown', event => this.onMouseDownCanvas(event));
        }
        // REVIEW: NOT WORKING PROPERLY
        // if (this.props.site === "segmentation") {
        //     if (this.props.image_to_show.metadata.correct_aliasing) {
        //         const params = this.props.image_to_show.metadata.correct_aliasing;
        //         this.onActionToPerform({ action: "CORRECT-ALIASING-PARAMS", value: { up: params.up, down: params.down, image: this.props.image_to_show } });
        //     } else {
        //         this.onActionToPerform({ action: "CORRECT-ALIASING-PARAMS", value: { up: 0, down: 0, image: this.props.image_to_show } });
        //     }
        // }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.image_to_show !== this.props.image_to_show) {
            // this.onActionToPerform({ action: "CHECK-IF-FINISHED", value: { image: prevProps.image_to_show } });
            if (this.state.is_playing) {
                this.onActionDicom({ action: "STOP-DICOM", value: {} });
            }
            this.loadAndDisplayImage(this.props.image_to_show);
            if (this.props.site === "segmentation") {
                if (this.props.image_to_show.metadata.correct_aliasing) {
                    const params = this.props.image_to_show.metadata.correct_aliasing;
                    this.onActionToPerform({ action: "CORRECT-ALIASING-PARAMS", value: { up: params.up, down: params.down, image: this.props.image_to_show } });
                } else {
                    this.onActionToPerform({ action: "CORRECT-ALIASING-PARAMS", value: { up: 0, down: 0, image: this.props.image_to_show } });
                }
            }
        }
    }

    componentWillUnmount() {
        // this.onActionToPerform({ action: "CHECK-IF-FINISHED", value: { image: this.props.image_to_show } });
        this.is_mounted = false;
    }

    onActionDicom(action) {
        action.value.element = this.imageDiv;
        onActionToPerform("DICOM", action, this);
    }

    onActionToPerform(action) {
        onActionToPerform(this.action_type, action, this);
    }

    onActionToPerformSegmentation(action) {
        onActionToPerform("SEGMENTATION", action, this);
    }

    onNewFrame(e, data) {
        if (this.state.stack && this.state.is_playing) {
            this.setState({
                current_frame: this.state.stack.currentImageIdIndex
            });
        }
    }

    onMouseDownCanvas(evt) {
        this.onActionToPerform({ action: "MOUSE-DOWN", value: { event: evt, image: this.state.image } });
    }

    onMouseMoveCanvas(evt) {
        if (this.state.image) {
            var rect = this.drawingCanvas.getBoundingClientRect();
            const canvas_pos = {
                x: (evt.clientX - rect.left) / (rect.right - rect.left) * this.drawingCanvas.width,
                y: (evt.clientY - rect.top) / (rect.bottom - rect.top) * this.drawingCanvas.height
            };
            const pixel_pos = HelperImages.canvasToPixel(this.imageDiv, canvas_pos, this.state.image.metadata);
            this.setState({
                mouse: {
                    pixel: pixel_pos,
                    canvas: canvas_pos
                }
            });
            if (this.state.current_action !== "finished") {
                this.onActionToPerform({ action: "DRAW-CANVAS", value: { element: this.imageDiv, canvas: this.drawingCanvas, image: this.state.image } });
            }
            if (this.state.element_selected) {
                this.onActionToPerform({ action: "MOVE-SELECTED", value: { image: this.state.image } });
            }
        }
    }

    onMouseDownWindow(evt) {
        if (this.drawingCanvas) {
            setTimeout(() => {
                if (this.drawingCanvas) {
                    this.onActionToPerform({ action: "DRAW-CANVAS", value: { element: this.imageDiv, canvas: this.drawingCanvas, image: this.state.image } })
                }
            }, 200);
        }
    }

    onMouseMoveWindow(evt) {
        if (this.drawingCanvas) {
            if (this.drawingCanvas.width !== this.imageDiv.clientWidth) {
                this.drawingCanvas.width = this.imageDiv.clientWidth;
                this.drawingCanvas.height = this.imageDiv.clientHeight;
                this.onActionToPerform({ action: "DRAW-CANVAS", value: { element: this.imageDiv, canvas: this.drawingCanvas, image: this.state.image } });
            }
            if (this.state.current_action === "editing-thickness" || (this.state.current_action === "editing" && this.state.image.modality === "2D")) {
                this.onActionToPerform({ action: "DRAW-CANVAS", value: { element: this.imageDiv, canvas: this.drawingCanvas, image: this.state.image } });
            }        
        }
        if (this.correctionCanvas) {
            // Check if aliasing correction canvas needs initialization
            if (this.correctionCanvas.width !== this.imageDiv.clientWidth) {
                this.correctionCanvas.width = this.imageDiv.clientWidth;
                this.correctionCanvas.height = this.imageDiv.clientHeight;
            }
            if (this.state.correct_aliasing_needs_update) {
                this.onActionToPerform({ action: "CORRECT-ALIASING", value: { image: this.state.image, config: this.state.correct_aliasing, canvas: this.correctionCanvas, dicom_div: this.imageDiv } });
            }
            // Check if drawing canvas needs size adjustment
            if (this.drawingCanvas.height !== this.correctionCanvas.height) {
                this.drawingCanvas.height = this.correctionCanvas.height;
                this.onActionToPerform({ action: "DRAW-CANVAS", value: { element: this.imageDiv, canvas: this.drawingCanvas, image: this.state.image } });
            }
        }
    }

    onImageRendered(e, eventData) {
        if (this.correctionCanvas && this.state.correct_aliasing) {
            this.onActionToPerform({ action: "CORRECT-ALIASING", value: { image: this.state.image, config: this.state.correct_aliasing, canvas: this.correctionCanvas, dicom_div: this.imageDiv } });
        }
        if (this.drawingCanvas) {
            this.onActionToPerform({ action: "DRAW-CANVAS", value: { element: this.imageDiv, canvas: this.drawingCanvas, image: this.state.image } });
        }
    }

    loadAndDisplayImage(image) {
        this.setState({ metadata: image.metadata, loaded: false });
        if (image.dicom) {
            this.displayImage(image);
        } else {
            HelperImages.downloadDICOMFromURL([image.urls.DICOM_url], HelperUser.getUser().user_token).then(() => {
                if (image.dicom) {
                    this.displayImage(image);
                }
            });
        }
    }

    displayImage(image) {
        if (this.imageDiv) {
            HelperImages.loadAndDisplay(this.imageDiv, image.dicom.cornerstone_imageId).then(result => {
                this.checkTypeOfViewer(image.dicom.metadata);
                this.onActionToPerform({ action: "INIT", value: { image: this.props.image_to_show } });
                HelperImages.updateImage(this.imageDiv);
                this.setState({ loaded: true });
            });
        }
    }

    checkTypeOfViewer(metadata) {
        let multiframe = false;
        let number_of_frames = metadata.number_of_frames;
        if (number_of_frames > 1 && this.props.play_multiframe) {
            multiframe = true;
            HelperImages.initMultiframeDicom(this.imageDiv, number_of_frames, this);
        }
        this.setState({
            multiframe,
            number_of_frames,
            velocity: metadata.frame_rate? metadata.frame_rate : this.state.velocity
        });
    }

    handleKeyboard(event) {
        if (this.action_type && !["VALIDATION","SELECTION_ANALYSIS"].includes(this.action_type)) {
            onActionToPerform(this.action_type+"-KEY", event, this);
        }
        if (this.drawingCanvas) {
            setTimeout(() => {
                if (this.drawingCanvas) {
                    this.onActionToPerform({ action: "DRAW-CANVAS", value: { element: this.imageDiv, canvas: this.drawingCanvas, image: this.state.image } })
                }
            }, 200);
        }
    }

    handleSnapshot() {
        const handle_cancel = () => {
            this.setState({ show_confirmation_alert: undefined });
        }
        const handle_accept = () => {
            this.setState({ show_confirmation_alert: { message: "Please wait...", options: [] } });
            HelperImages.createDICOMsnapshot(this.props.image_to_show.filename, this.state.current_frame, HelperUser.getUser().user_token).then(result => {
                if (result && result.succeeded) {
                    handle_cancel();
                    this.props.on_action_to_perform("GENERAL", { action: "GO_TO_PAGE", value: { page: "selection", image: result.filename }, type: "refresh" });
                } else {
                    this.setState({ show_confirmation_alert: {
                        message: "Sorry, an error happened while saving the snapshot",
                        options: [{ title: "OK", action: handle_cancel }]
                    } });
                }
            });
        }
        this.setState({ show_confirmation_alert: {
            message: "This will add a new image file in the study, please use this feature in moderation. Are you sure to proceed with the selected frame?",
            options: [{ title: "Yes", action: handle_accept }, { title: "No", action: handle_cancel }]
        } });
    }

    renderCorrectionCanvas() {
        if (this.props.site === "segmentation" && this.state.correct_aliasing) {
            return <canvas style={{position:"absolute"}} ref={(corrCanv) => this.correctionCanvas = corrCanv}></canvas>;
        }              
    }

    renderDrawingCanvas() {
        if (["cycletiming","segmentation","validation"].includes(this.props.site)) {
            return <canvas style={{position:"absolute"}} ref={(drawCanv) => this.drawingCanvas = drawCanv}></canvas>;
        }              
    }

    renderActionTitles() {
        if (this.state.image && !["validation","comments","measurements"].includes(this.props.site)) {
            let cp = this.state.current_cp;
            if (this.state.current_action === "caliper"){
                if (this.state.caliper_tool?.template && this.state.caliper_tool?.current_action !== "finished") {
                    let tool = this.state.caliper_tool;
                    cp = tool.points.length === tool.template.points.length? false : tool.template.points[tool.points.length];
                } else {
                    cp = "NO_TITLE";
                }
            }
            let progress = this.state.current_action === "selecting-cycles"? this.state.cycles_selected : this.state.image;
            return (
                <ActionTitlesComponent
                    site={this.props.site}
                    current={this.state.current_action}
                    progress={progress}
                    shape={this.state.current_shape}
                    cp={cp}
                />
            );
        } else if (this.props.site === "validation") {
            return <div style={{ position: "absolute", fontSize: "12px", top: "5px", right: "5px" }}>{this.state.image.filename}</div>
        }
    }

    renderMultiframeControls() {
        if (this.state.multiframe && this.props.show_player_controls) {
            return (
                <MultiFrameControlsComponent
                    is_playing={this.state.is_playing}
                    marked_frames={this.state.marked_frames}
                    current_frame={this.state.current_frame}
                    number_of_frames={this.state.number_of_frames}
                    on_action_to_perform={this.onActionDicom.bind(this)}
                    velocity={this.state.velocity}
                    show_snapshot={this.props.show_snapshot}
                    handle_snapshot={this.handleSnapshot.bind(this)}
                />
            );
        }
    }

    renderSegmentationExtras() {
        if (this.props.site === "segmentation") {
            return (
                <SegmentationComponent
                    image={this.props.image_to_show}
                    state={this.state}
                    on_action_to_perform={this.onActionToPerform.bind(this)}
                />
            );
        }
    }

    renderImageMenu() {
        if (!["validation","comments","measurements","selection_analysis"].includes(this.props.site)) {
            return (
                <ImageMenuComponent
                    site={this.props.site}
                    image_analysis={this.props.image_to_show}
                    current_action={this.state.current_action}
                    state={this.state}
                    on_action_to_perform={this.onActionToPerform.bind(this)}
                    show_floating_window={this.props.show_floating_window}
                />
            );
        }
    }

    renderAlerts() {
        if (this.props.site !== "validation") {
            return (
                <AlertComponent
                    alert={this.state.alert}
                />
            );
        }
    }

    renderConfirmationAlert() {
        if (this.state.show_confirmation_alert) {
            return (
                <ConfirmationAlertComponent
                    show={true}
                    message={this.state.show_confirmation_alert.message}
                    options={this.state.show_confirmation_alert.options}
                />
            );
        }
    }

    renderValidationExtras() {
        if (this.props.site === "validation" && this.props.image_to_show.modality === "2D") {
            return (
                <div>
                    <SegmentationEventPickerComponent
                        image={this.props.image_to_show}
                        state={this.state}
                        on_action_to_perform={this.onActionToPerformSegmentation.bind(this)}
                        site={this.props.site}
                    />
                </div>
            );
        }
    }

    renderLoading() {
        if (this.state.metadata) {
            let text = this.state.metadata.number_of_frames === undefined? "" : 
                this.state.metadata.number_of_frames === 0? "Single-frame image" :
                "Multi-frame image ("+this.state.metadata.number_of_frames+" frames)"; 
            return (
                <span className="loading-dicom">
                    Loading...
                    <span className="note">{text}</span>
                </span>
            );
        }
    }

    render() {
        return (
            <div className={"analysis viewer"}>
                <div className={"dicomImage " + this.props.site} ref={(imgDiv) => this.imageDiv = imgDiv}>
                    {this.state.loaded === false && this.renderLoading()}
                </div>
                {this.renderCorrectionCanvas()}
                {this.renderDrawingCanvas()}
                {this.renderActionTitles()}
                {this.renderMultiframeControls()}
                {this.renderSegmentationExtras()}
                {this.renderValidationExtras()}
                {this.renderImageMenu()}
                {this.renderAlerts()}
                {this.renderConfirmationAlert()}
            </div>
        );
    }
}