import React, { Component } from 'react';
import { BounceLoader } from 'react-spinners';

export class LoadingComponent extends Component {

    constructor() {

        super()

        this.state = {
            loaded: false,
            hover: false
        };

    }
    renderCancelButton() {
        if (!["studies","review","export","prisma-pages"].includes(this.props.site)) {
            if (this.props.user_type === "Reviewer") {
                return (
                    <div className="cancel-button" onClick={() => {this.props.on_action_to_perform("GENERAL", { action: "GO_TO_PAGE", value: { page: "review", from: "review" } })}}>Cancel</div>
                );
            } else {
                return (
                    <div className="cancel-button" onClick={() => {this.props.on_action_to_perform("GENERAL", { action: "GO_TO_PAGE", value: { page: "studies", from: "studies" } })}}>Cancel</div>
                );
            }
        }
    }
    render() {
        var progress = this.props.progress? this.props.progress + " %" : "";
        return (
            <div className="grid-block vertical pl-component-loading">
                <div className="grid-block">&nbsp;</div>
                <div className="grid-block pl-component-loading-spinner">
                    <BounceLoader
                        sizeUnit={"px"}
                        size={100}
                        color={'#c62828'}
                        loading={true}
                    />
                    <div className="progress_percent">{progress}</div>
                </div>
                {this.renderCancelButton()}
                <div className="grid-block">&nbsp;</div>
                
            </div>
        );
    }
}