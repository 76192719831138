import React, { useState, useEffect } from 'react';

import { HelperStudies } from './../../../helpers/helper_studies/helper_studies';

export const ActionTitlesComponent = ({
        site,
        current,
        progress,
        shape,
        cp
    }) => {
    
    const [current_title, setCurrent_title] = useState("");
    const [current_progress, setCurrent_progress] = useState("");
    
    useEffect(() => {
        let new_cc = 0;
        if (current === "selecting-cycles") {
            new_cc = progress.filter(cc => cc === true).length;
        } else if (site === "cycletiming" && progress.cardiac_events.length > 0) {
            for (const event of progress.cardiac_events) {
                let count = 0;
                Object.keys(event).map(key => {
                    count = event[key] === false? count : count + 1;
                });
                new_cc = count >= 2? new_cc + 1 : new_cc;
            }
        }
        getCurrentTitle();
        getCurrentProgress(new_cc);
    });

    const getCurrentTitle = () => {
        if (current === "annotating-onsets") {
            setCurrent_title("Select new onset");
        } else if (current === "annotating-diastole") {
            setCurrent_title("Select end diastole");
        } else if (current === "annotating-systole") {
            setCurrent_title("Select end systole");
        } else if (current === "selecting-cycles") {
            const max_events = HelperStudies.getCurrentProjectMaxCycles();
            setCurrent_title("Select up to " + max_events + " continuous cycles");
        } else if (current === "segmenting" && cp) {
            setCurrent_title("Select " + cp.name_to_show);
        } else if (current === "segmenting") {
            setCurrent_title("Segmenting " + shape.name);
        } else if (current === "editing-segmentation") {
            setCurrent_title("Editing segmentation of " + shape.name);
        } else if (current === "editing-thickness") {
            setCurrent_title("Adjusting thickness of " + shape.name);
        } else if (current === "editing") {
            setCurrent_title("Editing");
        } else if (current === "finished") {
            setCurrent_title("Finished");
        } else if (current === "caliper" && cp) {
            if (cp === "NO_TITLE") {
                setCurrent_title("");
            } else {
                setCurrent_title("Select " + cp);
            }
        } else if (current === "caliper") {
            setCurrent_title("Save measure");
        } else if (current === "unactive") {
            setCurrent_title("");
        }
    }

    const getCurrentProgress = (cc) => {
        if (site === "cycletiming" || current === "selecting-cycles") {
            setCurrent_progress(cc + " cardiac cycle(s) selected");
        } else {
            setCurrent_progress("");
        }
    }

    return (
        <div className="titles">
            <div>{current_title}</div>
            <div className="right">{current_progress}</div>
        </div>
    );
}