import React, { useEffect, useState } from 'react';

// Components
import { EmptyViewerComponent } from './../data_viewer_component/empty_viewer_component';
import { DicomViewerComponent } from './../data_viewer_component/DICOM/dicom_viewer_component';
import { ImageGridComponent } from './../image_grid_component/image_grid_component';
import { ImageMenuComponent } from './../data_viewer_component/image_menu_component';
import { TopBarComponent } from './top_bar_component';
import { ScoreComponent } from './../score_component/score_component';
import { ChatComponent } from './../chat_component/chat_component';
import { ModalityIcons } from './../icons_component/modality_icons';

// Helpers
import { HelperUser } from './../../../helpers/helper_user/helper_user';
import { HelperConfig } from './../../../helpers/helper_config/helper_config';
import { HelperStudies } from './../../../helpers/helper_studies/helper_studies';
import { HelperImages } from './../../../helpers/helper_images/helper_images';

// Actions file
import { onActionToPerform } from './../../../actions/actions';

// AUX functions
import { findIndex } from 'underscore';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';

export const SelectionComponent = ({
        images,
        analysis_images,
        set_images,
        set_analysis_images,
        on_action_to_perform
    }) => {

    const [index, setIndex] = useState(HelperImages.getImageToShow(images, { is_annotated: false }, null));
    const [indexAnalysis, setIndexAnalysis] = useState(null);
    const [hover, setHover] = useState(null);
    const [showFloatingWindow, setShowFloatingWindow] = useState(false);

    useEffect(() => {
        if (index !== null) {
            let idx_analysis = findIndex(analysis_images, { filename: images[index].filename });
            idx_analysis = idx_analysis >= 0? idx_analysis : null;
            setIndexAnalysis(idx_analysis);
        }
    },[]);

    const modalities = HelperConfig.getProjectModalities(HelperStudies.getDataType());
    const views = HelperConfig.getImageViews(HelperStudies.getDataType());
    const image_types = HelperConfig.getProjectImageTypes(HelperStudies.getDataType());
    const user_type = HelperUser.getUserType();

    const handleImageSelection = (idx) => {
        HelperStudies.saveStudy();
        setIndex(idx);
        let idx_analysis = findIndex(analysis_images, { filename: images[idx].filename });
        idx_analysis = idx_analysis >= 0? idx_analysis : null;
        setIndexAnalysis(idx_analysis);
    }

    const onAction = (action) => {
        onActionToPerform("SELECTION", action, { images, set_images, index, analysis_images, set_analysis_images, indexAnalysis, setIndexAnalysis });
    }

    const renderViewer = () => {
        if (index !== null) {
            let show_snapshot = false;
            if (["Ground-truth generator","Editor"].includes(HelperUser.getUserType())) {
                show_snapshot = analysis_images && typeof indexAnalysis === "number"? analysis_images[indexAnalysis].view === "Sweep" : false;
            }
            return (
                <div className="selection viewer">
                    {/* <img src={images[index].urls.big_icon} alt="" /> */}
                    <DicomViewerComponent
                        site={"selection_analysis"}
                        image_to_show={images[index]}
                        play_multiframe={true}
                        play_by_default={true}
                        show_player_controls={true}
                        show_snapshot={show_snapshot}
                        on_action_to_perform={on_action_to_perform}
                    />
                </div>
            );
        } else {
            return <EmptyViewerComponent />
        }
    }

    const renderHoverView = () => {
        if (hover !== null) {
            return (
                <div className="hover-view">
                    <img src={images[hover].urls.big_icon} alt="" />
                    <span>{hover}</span>
                </div>
            );
        }
    }

    const renderGrid = () => {
        return (
            <ImageGridComponent
                site={"selection"}
                images={images}
                analysis_images={analysis_images}
                image_to_show={images[index]}
                image_types={image_types}
                on_image_selected={handleImageSelection}
                on_action_to_perform={onAction}
                on_hover={setHover}
            />
        );
    }

    const renderAlert = () => {
        if (analysis_images[indexAnalysis]?.type && !analysis_images[indexAnalysis].view) {
            return <div className="view-alert">***Please select a view for this image***</div>;
        }
    }

    const renderTopBar = () => {
        return (
            <TopBarComponent
                image={images[index]}
                image_analysis={analysis_images[indexAnalysis]}
                image_types={image_types}
                views={views}
                modalities={modalities}
                on_action_to_perform={onAction}
            />
        );
    }

    const renderRightMenu = () => {
        return (
            <ImageMenuComponent
                site={"selection"}
                image={images[index]}
                image_analysis={analysis_images[indexAnalysis]}
                on_action_to_perform={onAction}
                show_floating_window={setShowFloatingWindow}
            />
        );
    }

    const renderPreviewButton = () => {
        if (user_type !== "Viewer") {
            return <div className="preview-button" onClick={() => { on_action_to_perform("GENERAL", { action: "GO_TO_PAGE", value: { page: "preview" } }); }}>SHOW ALL IMAGES</div>
        }
    }

    const renderFloatingWindowLabelsArea = () => {
        if (analysis_images[indexAnalysis]) {
            return (
                <div className="labels-area">
                    <ModalityIcons type={analysis_images[indexAnalysis].modality} width={"30px"} height={"30px"} background={false} />
                    <div style={{ fontSize: "13px", marginLeft: "10px" }}>{analysis_images[indexAnalysis].modality}</div>
                    <div className="divider">|</div>
                    <div>{HelperConfig.getImageTypeNameToShow(analysis_images[indexAnalysis].type)} </div>
                    <div className="divider">|</div>
                    <div>{analysis_images[indexAnalysis].view}</div>
                </div>
            );
        }
    }

    const renderFloatingWindow = () => {
        if (showFloatingWindow) {
            return (
                <div className="grid-block" style={{ position: "absolute", width: "100%", height: "100%", background: "#060606c2", overflow: "hidden", zIndex: "10" }}>
                    <div className="grid-block" style={{ display: "flex", flexDirection: "row-reverse", marginRight: "50px", marginTop: "20px" }}>
                        <div className="grid-block shrink" style={{ height: "40px", width: "40px", cursor: "pointer", zIndex: "1000" }}
                            onClick={() => { setShowFloatingWindow(false) }}>
                            <CancelOutlinedIcon fontSize="large" sx={{ fontSize: "40px" }} />
                        </div>
                    </div>
                    <div className="grid-block comment-window">
                        <ScoreComponent
                            image={images[index]}
                            image_analysis={analysis_images[indexAnalysis]}
                            user_type={user_type}
                        />
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
                                <DicomViewerComponent
                                    site={"comments"}
                                    image_to_show={images[index]}
                                    play_multiframe={false}
                                    play_by_default={false}
                                    show_player_controls={false}
                                />
                                {renderFloatingWindowLabelsArea()}
                            </div>
                            <ChatComponent
                                image={images[index]}
                                image_analysis={analysis_images[indexAnalysis]}
                                attribute={"comments"}
                                comment_type={"score-text"}
                                handle_exit={() => { setShowFloatingWindow(false) }}
                                user_type={user_type}
                            />
                        </div>
                    </div>
                </div>
            );
        }
    }

    return (
        <React.Fragment>
            {renderAlert()}
            <div className="selection component">
                {renderTopBar()}
                <div className="child">
                    {renderGrid()}
                    {renderViewer()}
                </div>
                {renderRightMenu()}
                {renderPreviewButton()}
                {renderHoverView()}
                {renderFloatingWindow()}
            </div>
        </React.Fragment>
    );
}
