import Store from './../../models/store';
import { HelperUser } from './../helper_user/helper_user';
import { DataProvider } from '../../dataprovider/dataprovider';

export class HelperConfigStore {
    static init(){
        Store.init();
        return getAllConfigsFromProd().then((e) => {
            return getAllConfigsFromDB();
        }); 
    }
    static getConfigImageTypes() {
        return Store.getConfigImageTypes();
    }
    static getConfigMeasurements() {
        return Store.getConfigMeasurements();
    }
    static getConfigCaliper() {
        return Store.getConfigCaliper();
    }
    static getConfigImageQuality() {
        return Store.getConfigImageQuality();
    }
    static getConfigAPIs() {
        return Store.getConfigAPIs();
    }
    static getCurrentCloud() {
        return Store.getCurrentCloud();
    }
}

function getConfigFromDB(config, user_token) {
    return DataProvider("ADMIN", "GET_CONFIG", {
        platform: "IAP", config: config, db_name:"rkt_admin_db"
    }, user_token);
}

function getAllConfigsFromDB() {
    const user_token = HelperUser.getUser().user_token;
    const config_names = ["echo_image_types","echo_measurements","echo_image_quality","echo_caliper"];
    let requests = [];
    config_names.map((config) => requests.push(getConfigFromDB(config, user_token)));
    return Promise.all(requests).then((configs) => {
        Store.setConfigImageTypes(configs[0][0].data);
        Store.setConfigMeasurements(configs[1][0].data);
        Store.setConfigImageQuality(configs[2][0].data);
        Store.setConfigCaliper(configs[3][0].data);
    });
}

function getAllConfigsFromProd() {
    return fetch("assets/config_APIs.json", { cache: "reload" }).then(response => {
        return response.json().then(data => {
            Store.setCurrentCloud(data.cloud);
            delete data.cloud;
            Store.setConfigAPIs(data);
            return true;
        });
    });
}