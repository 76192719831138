import { dicomUnitsToReal } from "../../modules/dicom_module";

const dicomParser = window.dicomParser;
const cornerstone = window.cornerstone;
const cornerstoneTools = window.cornerstoneTools;
const cornerstoneWADOImageLoader = window.cornerstoneWADOImageLoader;

export class HelperImagesCornerstone {
    static updateImage(element) {
        cornerstone.updateImage(element);
    }
    static parseDicom(dicomFileAsBuffer) {
        return dicomParser.parseDicom(dicomFileAsBuffer);
    }
    static load(img){
        const imageId = img instanceof Blob? cornerstoneWADOImageLoader.fileManager.add(img) : img;
        return cornerstone.loadImage(imageId).then((image) => {
            return image;
        }, function (err) { return false; });
    }
    static display(element, image) {
        if (image) {
            const viewport = cornerstone.getDefaultViewportForImage(element, image);
            cornerstone.displayImage(element, image, viewport);
            cornerstoneTools.mouseInput.enable(element);
            cornerstoneTools.mouseWheelInput.enable(element);
        } else {
            console.log("Error");
        }
        // Remove additional "canvas" elements
        this.clearCanvas(element);
    }
    static loadAndDisplay(element, img) {
        // Load and display image
        cornerstone.enable(element);
        return this.load(img).then((image) => {
            this.display(element, image);
            return true;
        });
    }
    static getImageMetadata(image) {
        let imageMetadata = {};
        if (image.data) {
            const dicomDataset = dicomParser.parseDicom(image.data.byteArray);
            const numFrames = dicomDataset.string('x00280008');
            imageMetadata["number_of_frames"] = numFrames !== undefined? parseInt(numFrames) : 0;
            imageMetadata["frame_rate"] = parseInt(dicomDataset.string('x00082144'));
            if (!imageMetadata["frame_rate"]) {
                imageMetadata["frame_rate"] = parseInt(dicomDataset.string('x00180040'));
            }
            imageMetadata["instance_id"] = dicomDataset.string('x00080018');
            imageMetadata["manufacturer"] = dicomDataset.string('x00080070');
            imageMetadata["width"] = dicomDataset.int16('x00280011');
            imageMetadata["height"] = dicomDataset.int16('x00280010');
            imageMetadata["doppler_regions"] = [];
            if ((!imageMetadata["manufacturer"] || !imageMetadata["manufacturer"].includes("4D flow")) && dicomDataset.elements.x00186011) {
                const dopplerRegions = dicomDataset.elements.x00186011.items;
                for (const region of dopplerRegions) {
                    // IMPORTANT NOTICE: Uint16 parser does not work for negative values! Changed to Int16 on November 2022
                    imageMetadata.doppler_regions.push({
                        region_spatial_format: region.dataSet.int16('x00186012'),
                        region_data_type: region.dataSet.int16('x00186014'),
                        region_flags: region.dataSet.int16('x00186016'),
                        region_location_min_x0: region.dataSet.int16('x00186018'),
                        region_location_min_y0: region.dataSet.int16('x0018601a'),
                        region_location_max_x1: region.dataSet.int16('x0018601c'),
                        region_location_max_y1: region.dataSet.int16('x0018601e'),
                        reference_pixel_x0: region.dataSet.int16('x00186020'),
                        reference_pixel_y0: region.dataSet.int16('x00186022'),
                        physical_delta_x: region.dataSet.double('x0018602c'),
                        physical_delta_y: region.dataSet.double('x0018602e'),
                        physical_units_x: dicomUnitsToReal(region.dataSet.int16('x00186024')),
                        physical_units_y: dicomUnitsToReal(region.dataSet.int16('x00186026'))
                    });
                }
            } else {
                const dataSetJSON = dicomParser.explicitDataSetToJS(dicomDataset, { omitPrivateAttibutes: true, maxElementLength: 128 });
                if (dataSetJSON['x00186011']) {
                    const dopplerRegions = dataSetJSON['x00186011'];
                    for (const region of dopplerRegions) {
                        imageMetadata.doppler_regions.push({
                            region_spatial_format: parseInt(region['x00186012']),
                            region_data_type: parseInt(region['x00186014']),
                            region_flags: parseInt(region['x00186016']),
                            region_location_min_x0: parseInt(region['x00186018']),
                            region_location_min_y0: parseInt(region['x0018601a']),
                            region_location_max_x1: parseInt(region['x0018601c']),
                            region_location_max_y1: parseInt(region['x0018601e']),
                            reference_pixel_x0: parseInt(region['x00186020']),
                            reference_pixel_y0: parseInt(region['x00186022']),
                            physical_delta_x: parseFloat(region['x0018602c']),
                            physical_delta_y: parseFloat(region['x0018602e']),
                            physical_units_x: dicomUnitsToReal(parseInt(region['x00186024'])),
                            physical_units_y: dicomUnitsToReal(parseInt(region['x00186026']))
                        });
                    }
                }
            }
            return imageMetadata;
        } else {
            return false;
        }
    }
    static stopDicomClip(element) {
        cornerstoneTools.stopClip(element);
    }
    static playDicomClip(element, velocity) {
        cornerstoneTools.playClip(element, velocity);
    }
    static initMultiframeDicom(element, number_of_frames, myC) {
        cornerstone.enable(element);
        let imageIds = [];
        for (let i=0; i<number_of_frames; i++) {
            imageIds.push(myC.props.image_to_show.dicom.cornerstone_imageId + '?frame=' + i);
        }
        let stack = {
            currentImageIdIndex: 0,
            imageIds: imageIds
        };
        let initial_frame = 0;
        if (myC.state.current_frame && myC.state.current_frame < imageIds.length) {
            initial_frame = myC.state.current_frame;
        }
        let current_frame = 0;
        if (myC.props.image_to_show.cardiac_events) {
            if(!myC.props.play_by_default && myC.props.image_to_show && myC.props.image_to_show.cardiac_events && myC.props.image_to_show.cardiac_events.length >0 && myC.props.image_to_show.cardiac_events[0].end_diastole){
                stack.currentImageIdIndex = myC.props.image_to_show.cardiac_events[0].end_diastole;
                current_frame = myC.props.image_to_show.cardiac_events[0].end_diastole;
                initial_frame = myC.props.image_to_show.cardiac_events[0].end_diastole;
            }
        }
        element.onCornerstoneNewImage = myC.onNewFrame;
        cornerstone.loadAndCacheImage(imageIds[initial_frame]).then((image) => {
            cornerstone.displayImage(element, image);
            cornerstoneTools.addStackStateManager(element, ['stack', 'playClip']);
            cornerstoneTools.addToolState(element, 'stack', stack);
            cornerstoneTools.mouseInput.enable(element);
            cornerstoneTools.mouseWheelInput.enable(element);
            let is_playing = false;
            if (myC.props.play_by_default) {
                cornerstoneTools.playClip(element, myC.props.image_to_show.dicom.metadata.frame_rate);
                is_playing = true;
            }
            if (myC.state.current_frame) {
                current_frame = myC.state.current_frame;
            }
            myC.setState({
                is_playing,
                current_frame,
                stack
            });
        },
        function (err) {
            console.log(err);
        });
        // Remove additional "canvas" elements
        this.clearCanvas(element);
    }

    static pixelToCanvas(element, point) {
        return cornerstone.pixelToCanvas(element, point);
    }

    static canvasToPixel(canvas, point, metadata) {
        let ratio = metadata.height/canvas.clientHeight;
        if (canvas.clientWidth*ratio > metadata.width) {
            const offset = (canvas.clientWidth*ratio - metadata.width)/2;
            return {
                x: point.x*ratio - offset,
                y: point.y*ratio
            };
        } else {
            ratio = metadata.width/canvas.clientWidth;
            const offset = (canvas.clientHeight*ratio - metadata.height)/2;
            return {
                x: point.x*ratio,
                y: point.y*ratio - offset
            };
        }
    }

    static clearCanvas(element) {
        for (let i=0 ; i<element.children.length ; i++) {
            const canvas = element.children[i];
            if (canvas.nodeName === "CANVAS" && !canvas.getContext('2d').getImageData(0, 0, canvas.width, canvas.height).data.some(channel => channel !== 0)) {
                element.children[i].remove();
            }
        }    
    }
}