import React, { Component } from 'react';

//components
import { HeaderComponent } from './../../components/header_component/header_component';
import { LoadingComponent } from './../../components/loading_component/loading_component';
import { DatabaseComponent } from '../../components/database_component/db_component';

// Helpers
import { HelperUser } from './../../../helpers/helper_user/helper_user'
import { HelperStudies } from '../../../helpers/helper_studies/helper_studies';

// Actions file
import { onActionToPerform } from './../../../actions/actions';

export class TasksPage extends Component {
    constructor() {
        super();
        this.state = {
            site: "",
            current_study_analysis: false,
            export_studies: false,
            currentproject: "",
            projects: [],
            projects_obj: []
        }
        this.user = {};
    }

    componentDidMount() {
        if (!HelperUser.validateUserAuthentication()) {
            this.onActionToPerform("GENERAL", { action: "LOGOUT", value: "" });
        } else {
            HelperStudies.resetStudyInfo();
            HelperUser.getUserProjects().then(projects => {
                if (projects && projects.length > 0) {
                    let currentproject = HelperStudies.setInitialCurrentProject(projects);
                    this.onActionToPerform("GENERAL", { action: "CHANGE_PROJECT", value: currentproject, from: "studies" });
                    let project_promises = [];
                    projects.map((project) => {
                        project_promises.push(HelperStudies.getProject(project));
                    });
                    Promise.all(project_promises).then((projects_obj)=>{
                        this.setState({
                            projects,
                            projects_obj
                        });
                    });
                }
            })
        }
        this.initView();
    }

    initView() {
        this.user = HelperUser.getUser();
        let site = window.location.pathname;
        if (site.indexOf("/") !== -1) {
            site = site.replace("/", "");
        }
        this.setState({
            site: site,
        });
    }

    onActionToPerform(actionType, action) {
        onActionToPerform(actionType, action, this);
    }

    renderStudies() {
        if (this.state.studies_to_show) {
            let page = HelperStudies.getCurrentPage();
            return (
                <DatabaseComponent
                    site={this.state.site}
                    projects={this.state.projects}
                    projects_obj={this.state.projects_obj}
                    onactiontoperform={this.onActionToPerform.bind(this)}
                    currentProject={this.state.currentproject}
                    items={this.state.studies_to_show}
                    initial_page={page? page : 0}
                    sort_by={this.state.sort_by}
                    sort_direction={this.state.sort_direction}
                    from={"studies"}
                    page={"preview"}
                />
            )
        } else {
            return (
                <LoadingComponent
                    site={"tasks"}
                />
            )
        }
    }


    render() {
        return (
            <div className="grid-frame vertical">
                <HeaderComponent
                    mode={"studies"}
                    user={this.user}
                    on_action_to_perform={this.onActionToPerform.bind(this)}
                />
                
                {this.renderStudies()}
            </div>
        );
    }
}