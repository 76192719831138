import { where, findWhere } from 'underscore';
import { HelperConfig } from './../helpers/helper_config/helper_config';
import { getSpline } from './spline_module';

const createTrace = (x, y, name) => {
    var trace = {
        x: x,
        y: y,
        type: 'scatter',
        mode: 'lines',
        name: name, //'Max. diameter'
        hoverinfo: 'none'
    };
    return trace
}
export const createVolumeFromOnsets = (cardiac_events, volumes_LV, volumes_LA, measures, traces, shapes) => {
    let y_max = 2;
    if (volumes_LV && volumes_LV.value.length > 0) {
        volumes_LV = volumes_LV.value
        let times_lv = []
        volumes_LV.map((value, i) => {
            times_lv.push(i)
        })
        let trace_LV_complete = createTrace(times_lv, volumes_LV, "LV");
        traces.push(trace_LV_complete)
        y_max = Math.max(...volumes_LV);
    } else {
        volumes_LV = [];
    }
    if (volumes_LA && volumes_LA.value.length > 0) {
        volumes_LA = volumes_LA.value
        let times_la = []
        volumes_LA.map((value, i) => {
            times_la.push(i)
        })
        let trace_LA_complete = createTrace(times_la, volumes_LA, "LA");
        traces.push(trace_LA_complete)
    } else {
        volumes_LA = [];
    }

    
    let LV_vol_ES = findWhere(measures, {
        "name": "ESV LV"
    });
    let LV_vol_ED = findWhere(measures, {
        "name": "EDV LV"
    })
    let times_LV = []
    let LV_vol = [];
    let LA_vol = [];
    let LA_vol_ES = findWhere(measures, {
        "name": "ESV LA"
    });
    let LA_vol_ED = findWhere(measures, {
        "name": "EDV LA"
    });
    if (!volumes_LV || volumes_LV.length === 0) {
        cardiac_events.map((cc, id) => {
            LV_vol.push(LV_vol_ED.value[id]);
            LV_vol.push(LV_vol_ES.value[id]);
            times_LV.push(cc["end_diastole"]);
            times_LV.push(cc["end_systole"])

            LA_vol.push(LA_vol_ED.value[id]);
            LA_vol.push(LA_vol_ES.value[id]);
        });
    }

    let trace_LV = createTrace(times_LV, LV_vol, "LV");
    y_max = Math.max(...LV_vol);
    traces.push(trace_LV);

    let trace_LA = createTrace(times_LV, LA_vol, "LA");
    traces.push(trace_LA);

    cardiac_events.map((cc, id) => {
        let ED = {
            type: 'line',
            x0: cc["end_diastole"] - 0.01,
            y0: 0,
            x1: cc["end_diastole"],
            y1: y_max + 2,
            line: {
                dash: 'dot',
                color: 'rgb(0,255,255)',
                width: 1,
                dashed: true
            }
        }
        shapes.push(ED);
        let ES = {
            type: 'line',
            x0: cc["end_systole"] - 0.01,
            y0: 0,
            x1: cc["end_systole"],
            y1: y_max + 2,
            line: {
                dash: 'dot',
                color: 'rgb(0, 0,255)',
                width: 1
            }
        }
        shapes.push(ES);
    });

    return {
        traces: traces,
        shapes: shapes
    };
}
export const createFlowPlot = (img, measures) => {
    const config_measurements = HelperConfig.measurementsConfig(img.type);
    let traces = [];
    let shapes = [];
    let y_axis_name = "";
    let annotations = []
    if (img.modality === "2D") {
        console.log("Create 2d plot")
        // console.log(img)
        let cardiac_events = img.onset_array;
        let lv_vol = findWhere(measures, {
            "name": "LV vol (t)"
        });
        let la_vol = findWhere(measures, {
            "name": "LA vol (t)"
        });
        if (lv_vol && la_vol) {
            y_axis_name = "Volume(mL)"
            createVolumeFromOnsets(cardiac_events, lv_vol, la_vol, measures, traces, shapes);
        }
        let thickness = findWhere(measures, {
            "type": "thickness"
        });
        if (thickness) {
            y_axis_name = "Thickness (cm)"
            createThicknessPlot(img.all_control_points, traces, shapes, img)
        }


    } else if (img.modality.includes("Doppler")) {
        y_axis_name = "Velocity (cm/s)"
        let dop = createDopplerTrace(img, traces);
        let y = dop.y;
        let flipped = dop.flipped;
        let y_max = Math.max(...y);
        let physical_delta_x = dop.physical_delta_x;
        let physical_delta_y = dop.physical_delta_y;
        let zero_line = dop.zero_line;
        let real_points = dop.real_points;
        let y_min = Math.min(...y);
        if (img.measurements) {
            img.measurements.map((m) => {
                if (m.value.length > 0) {
                    let m_config = findWhere(config_measurements, {
                        name: m.name
                    });
                    let m_old = m.name;
                    if (m_config) {
                        m.name = m_config.abr;
                    }
                    if (m.type === "event-time") {
                        m.value.map((val, index) => {
                            let roundedValue = Math.round(val * 100) / 100;
                            let cp = findWhere(real_points, {
                                type: m_old,
                                cc: index
                            });

                            let y_text = y_max;
                            let x = ""
                            if (cp) {
                                y_text = (zero_line - cp.y) * physical_delta_y;
                                x = (cp.x * physical_delta_x - 0.02) * 1000;
                            }
                            let data = renderEventTime(roundedValue, m, y_text, y_text, x)
                            traces.push(data.trace)
                            annotations.push(data.h_text)
                        })

                    } else if (m.type === "time") {
                        let data = renderFlowTime(real_points, m, physical_delta_x, physical_delta_y, zero_line, flipped, y)
                        annotations.push(data.text);
                        traces.push(data.tr)
                    } else if (m.type === "velocity") {
                        let h_text = renderVelocityPeakValue(real_points, m, physical_delta_x, physical_delta_y, zero_line, flipped)
                        annotations.push(h_text);
                    }
                }
            })
        }
    } else {
        y_axis_name = "Thickness (cm)"
        let mmode = createMModeTrace(img, traces);
        let physical_delta_x = mmode.physical_delta_x;
        let physical_delta_y = mmode.physical_delta_y;
        let zero_line = mmode.zero_line;
        let x = mmode.x;
        let y = mmode.y;
        let real_points = mmode.real_points;

        measures.map((m) => {
            m.value.map((v, i) => {
                let onset = img.onset_array[i];
                let data = renderMAPSE(onset, m, img, traces, shapes, annotations, physical_delta_x, physical_delta_y, zero_line, x, y, v, real_points);
                traces = data.traces;
                shapes = data.shapes;
                annotations = data.annotations;

            })

        })
    }
    return {
        traces: traces,
        shapes: shapes,
        annotations,
        plot_type: y_axis_name
    };
}

function renderEventTime(val, m, y_max, y_min, x) {
    let h_text = createHorizontalText(x, y_max, m.name, val, m.units);
    if (y_max === 0) {
        h_text = createVerticalText(x, y_max, m.name, val, m.units);
    }
    let trace = {
        type: 'line',
        x: [val - 0.01, val],
        y: [y_min, y_max],
        // text: ["", m.name],
        line: {
            dash: 'dot',
            // color: 'rgb(0,255,255)',
            width: 1,
            dashed: true
        }
    }
    return {
        h_text,
        trace
    }
}

function renderVelocityPeakValue(real_points, m, physical_delta_x, physical_delta_y, zero_line, flipped) {
    let p1 = findWhere(real_points, {
        "type": m.name
    });
    let x1_r = p1.x * physical_delta_x * 1000;
    let y1_r = (zero_line - p1.y) * physical_delta_y;
    let roundedValue = Math.round(m.value * 100) / 100;
    if (flipped) {
        y1_r = Math.abs(y1_r);

    }

    let h_text = createHorizontalText(x1_r, y1_r + 10, m.name, roundedValue, m.units);
    return h_text;
}

function renderFlowTime(real_points, m, physical_delta_x, physical_delta_y, zero_line, flipped, y) {
    let p1 = findWhere(real_points, {
        "type": m.formula[0]
    });
    let x1_r = p1.x * physical_delta_x * 1000;
    let y1_r = (zero_line - p1.y) * physical_delta_y;

    let p2 = findWhere(real_points, {
        "type": m.formula[1]
    });
    let x2_r = p2.x * physical_delta_x * 1000;
    let y2_r = (zero_line - p2.y) * physical_delta_y;
    if (flipped) {
        y1_r = Math.abs(y1_r);
        y2_r = Math.abs(y2_r);
    }

    let x_trace = [x1_r, x2_r];
    let y_trace = [y1_r, y2_r];

    let tr = createDotTrace(x_trace, y_trace, m.name, 2);
    let val_x = (x1_r + x2_r) / 2;
    let val_y = (y1_r + y2_r) / 2;
    let roundedValue = Math.round(m.value[0] * 100) / 100;
    let y_max = Math.max(...y)
    let text = createHorizontalText(val_x, val_y + 10, m.name, roundedValue, m.units);

    return {
        text,
        tr
    }

}

function createDotTrace(x, y, name, width) {
    let trace = {
        type: 'line',
        x: x,
        y: y,
        text: ["", name],
        line: {
            dash: 'dot',
            // color: '#fff',
            width: width,
            dashed: true
        }
    }
    return trace
} {

}

function createMModeTrace(img, traces) {
    let ccs = img.all_control_points;
    let real_points = [];
    ccs.map((cc, index) => {
        cc.control_points.points.map((cp) => {
            let x = cp.x;
            let y = cp.y;
            real_points.push({
                x,
                y,
                type: cp.type
            })
        })
    })
    let sp = getSpline(real_points, "monotone");
    let x = [];
    let y = [];
    let physical_delta_x = img.metadata.doppler_region.spacing_x;
    let physical_delta_y = img.metadata.doppler_region.spacing_y;
    let zero_line = img.metadata.doppler_region.zero_line;
    sp.map((p) => {
        let x_r = p.x * physical_delta_x;
        let y_r = p.y * physical_delta_y;
        x.push(x_r);
        y.push(y_r);
    })
    let flow = createTrace(x, y, "");
    traces.push(flow);
    return {
        x,
        y,
        physical_delta_x,
        physical_delta_y,
        zero_line,
        real_points
    }
}

function createDopplerTrace(img, traces) {
    let ccs = img.all_control_points;
    let x = [];
    let y = [];
    let physical_delta_x = img.metadata.doppler_region.spacing_x;
    let physical_delta_y = img.metadata.doppler_region.spacing_y;
    let zero_line = img.metadata.doppler_region.zero_line;
    let real_points = [];
    let cc = ccs[0];
    ccs.map((cc, index) => {
        cc.map((cp) => {
            let x = cp.x - img.onset_array[0].qrsonset1; // * physical_delta_x;
            let y = cp.y; //(zero_line -cp.y) * physical_delta_y;
            real_points.push({
                x,
                y,
                type: cp.type,
                cc: index
            })
        })
    })
    let flipped = false;
    if (real_points.length > 0) {
        let sp = getSpline(real_points, "discontinuous");
        let y_abs = [];
        sp.map((p) => {
            let x_r = p.x * physical_delta_x * 1000;
            let y_r = (zero_line - p.y) * physical_delta_y;
            x.push(x_r);
            y.push(y_r);
            y_abs.push(Math.abs(y_r))
        })

        if (Math.max(...y) - (Math.max(...y_abs))) {
            flipped = true;
            console.log("FLIP function")
            y = y_abs

        }
        if (x.length > 0) {
            let flow = createTrace(x, y, "");
            traces.push(flow);
        }
    }
    return {
        y,
        flipped,
        physical_delta_x,
        physical_delta_y,
        zero_line,
        real_points
    }

}

function renderMAPSE(onset, m, img, traces, shapes, annotations, physical_delta_x, physical_delta_y, zero_line, x, y, v, points) {
    const end = findWhere(points, {
        x: onset["end_systole"]
    });
    const start = findWhere(points, {
        x: onset["end_diastole_start"]
    });

    let m_x = [start.x * physical_delta_x, end.x * physical_delta_x]
    let m_y = [start.y * physical_delta_y, end.y * physical_delta_y]
    let val_x = (start.x) * physical_delta_x

    let roundedValue = Math.round(v * 100) / 100;
    let mp_y = (m_y[1] + m_y[0]) / 2;
    let mapse_text = createVerticalText(val_x, mp_y, m.name, roundedValue, m.units)
    annotations.push(mapse_text)

    let h_line = createHorizontalLine(m_x, m_y[1], '#f4fc03');
    traces.push(h_line)

    let v_line = createVerticalLine(m_x[0], m_y, '#f4fc03');
    traces.push(v_line)

    return {
        shapes,
        traces,
        annotations
    }
}

function createVerticalLine(x, y, color) {
    let v_line = {
        x: [x, x],
        y: y,
        type: 'scatter',
        mode: 'lines',
        line: {
            dash: 'dot',
            color: color,
            width: 1,
            dashed: true
        }
    }
    return v_line
}

function createHorizontalLine(x, y, color) {
    let h_line = {
        type: 'scatter',
        mode: 'lines',
        x: x,
        y: [y, y],
        line: {
            dash: 'dot',
            color: color,
            width: 1,
            dashed: true
        }
    }
    return h_line;
}

function createParallelText(x, y, name, value, units) {
    var angle = Math.atan2(y[0] - y[1], x[0] - x[1]) * 180 / Math.PI;
    console.log(angle)
    let val_x = (x[1] + x[0]) / 2;
    let val_y = (y[1] + y[0]) / 2;
    let text = {
        x: val_x,
        y: val_y,
        xref: "x",
        yref: "y",
        text: name + ": " + value + " " + units,
        font: {
            color: "#fff"
        },
        showarrow: false,
        arrowhead: 2,
        arrowsize: 1,
        arrowwidth: 2,
        arrowcolor: '#fff',
        textangle: angle,
    }
    return text;
}

function createHorizontalText(x, y, name, value, units) {
    let text = {
        x: x,
        y: y,
        xref: "x",
        yref: "y",
        text: name + ": " + value + " " + units,
        font: {
            color: "#ffff"
        },
        showarrow: false,
        arrowhead: 2,
        arrowsize: 1,
        arrowwidth: 2,
        // arrowcolor: '#823565',
        textposition: 'top'
    }
    return text;
}

function createVerticalText(x, y, name, value, units) {
    let text = {
        x: x - 0.07,
        y: y,
        xref: "x",
        yref: "y",
        text: name + ": " + value + " " + units,
        font: {
            color: "#fff"
        },
        showarrow: false,
        arrowhead: 2,
        arrowsize: 1,
        arrowwidth: 2,
        arrowcolor: '#fff',
        textalign: "left",
        textposition: "top",
        textangle: '-90',
    }
    return text;
}

function linspace(start, stop, num, endpoint = true) {
    const div = endpoint ? (num - 1) : num;
    const step = (stop - start) / div;
    return Array.from({
        length: num
    }, (_, i) => start + step * i);
}
export const createThicknessPlot = (all_cps, traces, shapes, image_from_store) => {
    let thickness_values = where(image_from_store.measurements, {
        "type": "thickness"
    });
    thickness_values.map((th) => {
        let th_array = th.array;
        th_array.map((cc_event_th) => {
            let th = cc_event_th.points;
            let trace_name = cc_event_th.name;
            let x_array = linspace(0, 1, th.length);
            let th_trace = createTrace(x_array, th, trace_name);
            traces.push(th_trace);
        })

    })
    return {
        traces: traces,
        shapes: shapes
    };
}
export const createPlot = (shapes, plot_type, plotWidth, annotations, legend) => {
    let y_axis_name = ""
    if (plot_type.includes("Volume")) {
        y_axis_name = "Volume (mL)"
    } else if (plot_type.includes("Thicness")) {
        y_axis_name = "Thickness (cm)"
    } else {
        y_axis_name = plot_type;
    }
    var layout = {
        hovermode: 'closest',
        paper_bgcolor: 'rgba(0,0,0,0)',
        plot_bgcolor: 'rgba(0,0,0,0)',
        autosize: false,
        width: plotWidth, // big screens 1000
        height: 350, //300,
        color: "white",

        xaxis: {
            visible: false,
            showgrid: false,
            title: {
                text: 't(s)',
                font: {
                    size: 12,
                    color: 'rgb(255, 255, 255)'
                }
            },
            tickfont: {
                family: 'Arial',
                size: 12,
                color: 'rgb(255, 255, 255)'
            },

        },
        yaxis: {
            visible: true,
            showgrid: false,
            title: {
                text: y_axis_name,
                font: {
                    size: 12,
                    color: 'rgb(255, 255, 255)'
                }
            },
            tickfont: {
                family: 'Arial',
                size: 12,
                color: 'rgb(255, 255, 255)'
            }
        },
        legend: {
            font: {
                size: 12,
                color: 'rgb(255, 255, 255)'
            }
        },
        showlegend: legend,

        shapes: shapes,
        annotations: annotations
        // annotations: [D1_text, D2_text, Dmean_text]
    };
    var revision = 1;

    return layout
}