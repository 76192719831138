import {
    makeStyles
} from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    root: {
        height: "100vh",
        overflow: "hidden"
    },
    tittle: {
        paddingTop: "10px",
        paddingLeft: "10px",
        color: "#2196f3"
    },
    finder: {
        paddingLeft: "10px",
        paddingRight: "10px"
    },
    statistics: {
        paddingRight: "10px",
        paddingBottom: "10px"
    },
    bootomRightMenu: {
        position: "absolute",
        zindex: 20,
        right: "0px",
        bottom: "0px",
    },
    searchButton: {
        backgroundColor: "#80808024",
        width: "40px",
        color: "gray",
        paddingLeft: "9px",
        border: "1px solid #80808024",
        borderLeft: "0px",
        "&:hover": {
            "backgroundColor": "#80808024",
            "color": "#2196f3"
        }
    },
    drawer: {
        top: "64px"
    },
    table: {
        width: '100%',
        // background: "#272822",
        color: "white",
        // margin: "10px",
        background: "none !important",
        padding: "10px",
        overflowY: "auto"

    },
    row: {
        cursor: "pointer",
        // background: "#272822",
        color: "white",
        "& span": {
            display: "none",
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(20,20,20,0.7)",
            textShadow: "0 0 20px black"
        },
        "&:hover": {
            backgroundColor: "#00000038",
            fontWeight: "bold"
        },
        "&:hover span": {
            display: "flex"
        },
        padding: "2px",
        margin: "2px",
        "&.validated": {
            backgroundColor: "#600"
        },
        "&.open": {
            outline: "2px solid #ec0",
            outlineOffset: "-10px",
            position: "relative"
        }
    },
    pagination: {
        float: "right",
        color: "white",
        fontSize: "0.875rem",
        marginTop: "-9px",
        "& .MuiInput-root": {
            color: "white",
            fontSize: "0.875rem"
        }
    },
    cell: {
        color: "white",
        // background: "#272822"
        "&.open": {
            color: "#ec0"
        }
    },
    statusText: {
        marginTop: "-10px",
        color: "#6e6d6d",
        fontSize: "12px",
        padding: "3px"
    },
    queryBox: {
        width: "70vw",
        marginLeft: "4vw",
        height: "40px",
        padding: "2px",
        color: "white",
        background: "transparent",
        marginTop: "11px"
    },
    switchText: {
        display: "inline-block",
        width: "26vw",
        paddingRight: "3vw",
        textAlign: "right",
        fontSize: "14px"
    },
    true_state: {
        'border-radius': '100px',
        width: '20px',
        height: '20px',
        margin: "4px",
        cursor: "pointer",
        "&:hover": {
            margin: "2px",
            width: '22px',
            height: '22px',
        }
    },
    false_state: {
        'border-radius': '100px',
        width: '20px',
        height: '20px',
        borderStyle: "solid",
        margin: "4px",
        border: "2px solid",
        cursor: "pointer",
        "&:hover": {
            margin: "2px",
            width: '22px',
            height: '22px',
        }
    },
    statisticsRoot: {
        paddingRight: "20px",
        width: "580px",
        paddingLeft: "20px"
    }

}));