import { DataProvider } from "../../dataprovider/dataprovider";
// import Store from './../../models/store';
// import { findWhere, findIndex } from "underscore";
// import { exportDataToZip } from "../../modules/file_module";

export class HelperExport {
    static getProjectExports(project) {
        let params = {
            "id": String(project)
        }
        let token = localStorage.getItem("token");
        // console.log(params)
        return DataProvider("MINIO", "GET_EXPORT", params, token).then((result) => {
            //  console.log(result)
            return result;
        })



    }
    static downloadZip(minio_object) {
        let params = { bucket: "imageanalysisbucket", object: String(minio_object) }
        let user_token = localStorage.getItem("token");
        return DataProvider("MINIO", "GET_PRESIGNED_URLS_FROM_MINIO", params, user_token).then((urls) => {
            console.log(urls)
            minio_object.replace("/", "_")
            var zip_name = minio_object + ".zip"
            download(urls[0], zip_name)
            return "true"
        })
    }

    // static exportStudy(study_id, editor, studies, project) {
    //     var d = new Date();
    //     let analysis = false;
    //     let patient = "";
    //     studies.map((study) => {
    //         if (study.id === study_id && study.editor === editor) {
    //             analysis = study;
    //         }
    //     })
    //     if (analysis) {
    //         let splitted = study_id.split("_");
    //         for (var i = 0; i < splitted.length - 1; i++) {
    //             patient = patient + splitted[i];
    //         }
    //         return createExport(analysis, study_id, patient, project, editor).then((result)=>{return result;});
    //     }
    // }
}

function download(url, filename) {
    console.log("Downloading zip file")
    fetch(url).then(function (t) {
        return t.blob().then((b) => {
            var a = document.createElement("a");
            a.href = URL.createObjectURL(b);
            a.setAttribute("download", filename);
            a.click();
        }
        );
    });
}

// function filter_control_points_for_csv(control_points) {
//     let new_control_points = [];
//     control_points.forEach(control_point => {
//         if (!control_point.type.includes("mid_control_point")) {
//             control_point.type = control_point.type.toLowerCase();
//             if (control_point.type === "end ar-wave") {
//                 control_point.type = "beginning s-wave";
//             }
//             // Build new control points array merging cps of the same type
//             let index = findIndex(new_control_points, { type: control_point.type });
//             if (index < 0) {
//                 control_point.x = [control_point.x];
//                 control_point.y = [control_point.y];
//                 new_control_points.push(control_point);
//             } else {
//                 new_control_points[index].x.push(control_point.x);
//                 new_control_points[index].y.push(control_point.y);
//             }
//         }
//     });
//     return new_control_points;
// }

// function create_flow_from_control_points(all_control_points) {
//     let flow = {}; flow.x = []; flow.y = [];
//     for (let cycle of all_control_points) {
//         for (let point of cycle) {
//             if (!flow.x.includes(point.x)) {
//                 flow.x.push(point.x);
//                 flow.y.push(point.y);
//             }
//         }
//     }
//     return flow;
// }

// function create_csv(analysis) {
//     let study_id_split = analysis.id.split("_");
//     var csv = "patient_id," + study_id_split[0] + "\nstudy_date," + study_id_split[1] + "\nimage type,event,time,velocity,abs time,pixel x,pixel y\n";

//     analysis.images.forEach(element => {
//         if (element.modality.includes("Doppler")) {
//             let label = element.type;
//             if (element.onset_array.length > 0) {
//                 let onsets = [element.onset_array[0].qrsonset1,element.onset_array[0].qrsonset2];
//                 if (element.onset_array.length > 1) {
//                     for (let i = 1; i < element.onset_array.length; i++) {
//                         onsets.push(element.onset_array[i].qrsonset2);
//                     }
//                     onsets = [...new Set(onsets)];
//                 }
//                 let onsets_abs = onsets.map(p => (p*element.metadata.doppler_region.spacing_x).toFixed(3));
//                 let onsets_time = onsets_abs.map(p => (p-onsets_abs[0]).toFixed(3));
//                 csv += label + ",onsets,\"[" + onsets_time + "]\",,\"[" + onsets_abs + "]\",\"[" + onsets + "]\"\n";
                
//                 if (element.all_control_points.length > 0) {
//                     let flow = create_flow_from_control_points(element.all_control_points);
//                     let control_points = filter_control_points_for_csv(element.all_control_points[0]);
//                     if (element.all_control_points.length > 1) {
//                         for (let i = 1; i < element.all_control_points.length; i++) {
//                             let cycle_cps = filter_control_points_for_csv(element.all_control_points[i]);
//                             for (let point of cycle_cps) {
//                                 let index = findIndex(control_points, { type: point.type });
//                                 if (index >= 0) {
//                                     for (let c in point.x) {
//                                         if (!control_points[index].x.includes(point.x[c])) {
//                                             control_points[index].x.push(point.x[c]);
//                                             control_points[index].y.push(point.y[c]);
//                                         }
//                                     }
//                                 }
//                             }
//                         }
//                     }
//                     control_points.forEach(control_point => {
//                         let pixel_x = control_point.x;
//                         let pixel_y = control_point.y;
//                         let abs_time = pixel_x.map(p => (p*element.metadata.doppler_region.spacing_x).toFixed(3));
//                         let time = abs_time.map(p => (p-onsets_abs[0]).toFixed(3));
//                         let velocity = pixel_y.map(p => (p*element.metadata.doppler_region.spacing_y).toFixed(3));
//                         csv += label + "," + control_point.type + ",\"[" + time + "]\",\"[" + velocity + "]\",\"[" + abs_time + "]\",\"[" + pixel_x + "]\",\"[" + pixel_y + "]\"\n";
//                     });

//                 //     let cycle_end_index = findIndex(control_points, { type: "cycle end" });
//                 //     let cycle_end = control_points.splice(cycle_end_index, 1)[0];
//                 //     csv = csv + label + ",cycle end,\"[" + cycle_end.time + "]\",\"[" + cycle_end.amplitude + "]\",\"[" + cycle_end.abs_time + "]\",\"[" + cycle_end.x + "]\",\"[" + cycle_end.y + "]\"\n";

//                     let flow_abs = flow.x.map(p => (p*element.metadata.doppler_region.spacing_x).toFixed(3));
//                     let flow_time = flow_abs.map(p => (p-onsets_abs[0]).toFixed(3));
//                     let flow_velo = flow.y.map(p => (p*element.metadata.doppler_region.spacing_y).toFixed(3));
//                     csv += label + ",curves,\"[" + flow_time + "]\",\"[" + flow_velo + "]\",\"[" + flow_abs + "]\",\"[" + flow.x + "]\",\"[" + flow.y + "]\"\n";        
//                 }
//             }
//         }
//     });

//     return csv;
// }

// function createExport(analysis, study_id, patient, project, editor) {
//     var images2Download = [];
//     let data_to_export = [];
//     var path = study_id + "/";
//     var data_image_json = { "type": ".json", "name": path + study_id + "_" + editor, "data": analysis };
//     data_to_export.push(data_image_json);
//     var data_image_csv = { "type": ".csv", "name": path + study_id + "_" + editor, "data": create_csv(analysis) };
//     data_to_export.push(data_image_csv);
//     var date = new Date();
//     date = String(date);
//     var zip_name = study_id + "_" + editor + "_" + date;
//     return Store.cloudDownloadImages(study_id, patient, project).then((images) => {
//         analysis.images.map((image, index) => {
//             let a = findWhere(images, { instance_id: image.id });
//             if (a) {
//                 a["name"] = image["type"] + "_" + String(index)
//                 images2Download.push(a)
//                 console.log(a)
//                 let image_name = image["type"] + "_" + String(index);
//                 image_name = image_name.replace("/", "_")
//                 var data_image = { "type": ".dcm", "name": path + image_name, "data": a.file };
//                 data_to_export.push(data_image);
//             }
//         })
//         exportDataToZip(data_to_export, zip_name);
//         return true;
//     })
// }