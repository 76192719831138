import React from 'react';

export const OnsetFlowIcon = ({
        className,
        style,
        width,
        height
    }) => {
    
    return (
        <div className={className} style={style}>Flow</div>
    );
}