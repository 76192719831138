import React from 'react';

import { BModeIcon } from './modality_icons/bmode_icon';
import { BModeColorIcon } from './modality_icons/bmode_color_icon';
import { MModeIcon } from './modality_icons/mmode_icon';
import { PWDopplerIcon } from './modality_icons/pw_doppler_icon';
import { CWDopplerIcon } from './modality_icons/cw_doppler_icon';
import { TdiDopplerIcon } from './modality_icons/tdi_doppler_icon';
import { PseudoDopplerIcon } from './modality_icons/pseudo_doppler_icon';

export const ModalityIcons = ({
        type,
        className,
        style,
        width,
        height,
        background
    }) => {
    
    let icon;
    switch (type) {
        case "2D":
            icon = <BModeIcon className={className} style={style} width={width} height={height} />
            break;
        case "2D Doppler":
            icon = <BModeColorIcon className={className} style={style} width={width} height={height} />
            break;
        case "M-Mode":
            icon = <MModeIcon className={className} style={style} width={width} height={height} />
            break;
        case "PW Doppler":
            icon = <PWDopplerIcon className={className} style={style} width={width} height={height} />
            break;
        case "Doppler":
            icon = <PWDopplerIcon className={className} style={style} width={width} height={height} />
            break;
        case "CW Doppler":
            icon = <CWDopplerIcon className={className} style={style} width={width} height={height} />
            break;
        case "tdi Doppler":
            icon = <TdiDopplerIcon className={className} style={style} width={width} height={height} />
            break;
        case "4Dflow":
            icon = <PseudoDopplerIcon className={className} style={style} width={width} height={height} />
            break;
        case "Pseudo Doppler":
            icon = <PseudoDopplerIcon className={className} style={style} width={width} height={height} />
            break;
        default:
            icon = null;
            break;
    }

    return icon && background ?
                <div className="modality-icon">{icon}</div>
            :
                icon;
}