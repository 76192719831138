import { useStyles } from './db_styles';
import Switch from '@material-ui/core/Switch';

export function DatabaseQuery(props) {
    const classes = useStyles();
    const performQuery = () => {
        let query_cont = document.getElementById("query");
        props.onactiontoperform("GENERAL",{action:"PERFORM_QUERY",value:query_cont.value})
    }
    const renderSwitch = () => {
        if (["review","studies"].includes(props.from)) {
            return (
                <div className={classes.switchText}>
                    Hide completed studies
                    <Switch
                        checked={props.hide_completed}
                        onClick={props.on_change_hide}
                    />
                </div>
            );
        }
    }
    return (
        <div>
            <input className={classes.queryBox} name="query" form="usrform" id="query"
                onChange={() => performQuery()}
                style={{ border: "2px solid #f44336" }}
                placeholder=" Search patient by ID...">
            </input>
            {renderSwitch()}
        </div>
    )
}