import React, { useState, useEffect } from 'react';

// Helpers
import { HelperStudies } from './../../../helpers/helper_studies/helper_studies';

// Components
import { ComplexTableComponent } from './complex_table_component';

export const DataStatistics = ({ 
        project,
        show,
        handle_header
    }) => {

    const [stats, setStats] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        setStats(false);
        HelperStudies.getProjectDataStats().then((result) => {
            setStats(result);
            setLoading(false);
        });    
    }, [project]);

    const handleTitleClick = () => {
        if (show) {
            handle_header(false);
        } else {
            handle_header("data");
        }
    }

    const renderContent = () => {
        if (show) {
            if (stats) {
                return renderDataStats();
            } else if (loading) {
                return <div style={{ margin: "20px" }}>Loading statistics...</div>;
            } else {
                return <div style={{ margin: "20px" }}>There are no statistics for this project yet.</div>;
            }
        }
    }

    const renderDataStats = () => {
        return (
            <div className="block-content data">
                <div>Last update: <span>{stats.date}</span><span style={{fontWeight:"normal",fontSize:"14px"}}>(UTC+0)</span></div>
                <div className="stats-grid">
                    {renderStatsContent()}
                    {/* {renderDataSections()}
                    {renderPlots()} */}
                </div>
            </div>
        );
    }

    // HARDCODED TO LOGICALLY FIT THE SCREEN
    const renderStatsContent = () => {
        return (
            <>
                <div>
                    <div><span>{stats.stats[0].name}</span></div>
                    <ComplexTableComponent columns={stats.stats[0].columns} data={stats.stats[0].data} />
                </div>
                <div>
                    <img src={stats.plots[0]} alt="" />
                </div>
                <div>
                    <img src={stats.plots[1]} alt="" />
                </div>
                <div style={{gridColumnEnd: "span 3"}}>
                    <div><span>{stats.stats[1].name}</span></div>
                    <ComplexTableComponent columns={stats.stats[1].columns} data={stats.stats[1].data} style={{width:"100%"}} />
                </div>
                <div style={{gridColumnEnd: "span 3"}}>
                    <div><span>{stats.stats[2].name}</span></div>
                    <ComplexTableComponent columns={stats.stats[2].columns} data={stats.stats[2].data} style={{width:"100%"}} />
                </div>
            </>
        );
    }

    // const renderDataSections = () => {
    //     return stats.stats.map(section => {
    //         return (
    //             <div key={section.name}>
    //                 <div><span>{section.name}</span></div>
    //                 <ComplexTableComponent columns={section.columns} data={section.data} />
    //             </div>
    //         );
    //     });
    // }

    // const renderPlots = () => {
    //     return stats.plots.map((url,i) => {
    //         return (
    //             <div key={i}>
    //                 <img src={url} style={{maxWidth: "30%"}} alt="" />
    //             </div>
    //         );
    //     });
    // }

    return (
        <>
            <div className="block-header">
                <div className="block-title" onClick={() => handleTitleClick()}>DATA STATISTICS {show? '\u25B2' : '\u25BC'}</div>
            </div>
            {renderContent()}
        </>
    );
}