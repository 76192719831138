import React, { useState, useEffect } from 'react';

import { ImageMenuIcons } from './../../icons_component/image_menu_icons';
import Slider from '@material-ui/core/Slider';
import classNames from 'classnames';

export const MultiFrameControlsComponent = ({
        is_playing,
        marked_frames,
        current_frame,
        number_of_frames,
        on_action_to_perform,
        velocity,
        show_snapshot,
        handle_snapshot
    }) => {

    const [timeline_width, setTimeline_width] = useState(0);

    useEffect(() => {
        setTimeline_width(document.getElementById('timeline').offsetWidth);
    },[]);

    const onPlayOrStop = () => {
        if (is_playing) {
            on_action_to_perform({ action: "STOP-DICOM", value: {} });
        } else {
            on_action_to_perform({ action: "PLAY-DICOM", value: { velocity } });
        }
    }

    const renderMarks = () => {
        if (marked_frames?.length > 0 && timeline_width) {
            const step_width = timeline_width/number_of_frames;
            return (marked_frames.map((mark) => {
                const left = step_width*mark.frame - 9;
                return (
                    <div key={mark.label+mark.cc} className={classNames({"diastole":mark.label==="End diastole"},{"systole":mark.label==="End systole"},"frame-marker")}
                        style={{ left: left+"px" }}
                        onClick={() => { on_action_to_perform({ action: "CHANGE-DICOM-FRAME", value: { frame: mark.frame } }) }}>
                            {mark.label}<br/><b>CC {parseInt(mark.cc)+1}</b>
                    </div>
                );
            }));
        }
    }

    const marks = marked_frames.map(m => { return { value: m.frame, label: "" } });

    return (
        <div className="video-controls">
            <div className="play-stop" onClick={() => onPlayOrStop()}>
                {is_playing ? <i className="icon-pause" /> : <i className="icon-play" />}
            </div>
            <div className="timeline" id="timeline">
                <Slider
                    min={0}
                    max={number_of_frames}
                    step={1}
                    marks={marks}
                    value={current_frame}
                    onChange={(e, val) => { on_action_to_perform({ action: "CHANGE-DICOM-FRAME", value: { frame: val } }) }}
                />
                <div className="markers">
                    {renderMarks()}
                </div>
            </div>
            <div className="frame-counter">
                <span>{current_frame + 1}/{number_of_frames}</span>
            </div>
            <div className={classNames({"active":show_snapshot},"snapshot-button")} onClick={() => { if (show_snapshot) { handle_snapshot() } }}>
                <ImageMenuIcons type={"camera"} width="20px" height="20px" />
            </div> 
        </div>
    );
}