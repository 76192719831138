// Helpers
import { HelperImages } from './../helper_images/helper_images';
import { HelperConfig } from './../helper_config/helper_config';
import { HelperStudies } from './../helper_studies/helper_studies';

// Templates
import template_cardiac_event from './../../templates/template_cardiac_event.json';

import { drawFilledRegion, drawVerticalLine } from './../../modules/draw_module';

export class HelperCycleTiming {

    static addEvent(image, type, value, myC) {
        const check = this.checkNewEvent(image, type, value, myC);
        if (check) {
            const len = image.cardiac_events.length;
            if (type === "onset") {
                const modality = image.modality.includes("Doppler")? "Doppler" : image.modality;
                const new_cc = JSON.parse(JSON.stringify(template_cardiac_event[modality]));
                new_cc.onset_start = value;
                image.cardiac_events.push(new_cc);
                image.is_segmented = false;
                if (len > 0) {
                    image.cardiac_events[len-1].onset_end = value;
                }
            } else {
                let idx;
                if (image.modality === "M-Mode" && type === "end_diastole") {
                    image.cardiac_events.some((event,i) => {
                        if (event.end_diastole_start === false) {
                            idx = i;
                            return true;
                        }
                    });
                    if (idx === undefined) {
                        image.cardiac_events[len-1].end_diastole_end = value;
                    } else {
                        image.cardiac_events[idx].end_diastole_start = value;
                        if (idx > 0) {
                            image.cardiac_events[idx-1].end_diastole_end = value;    
                        }
                    }
                } else {
                    image.cardiac_events.some((event,i) => {
                        if (event[type] === false) {
                            idx = i;
                            return true;
                        }
                    });
                    if (!(image.modality === "2D" && type === "end_diastole" && len > 0 && image.cardiac_events[len-1].end_systole === false)) {
                        if (len > 0 && idx !== undefined) {
                            image.cardiac_events[idx][type] = value;
                        } else if (!(len > 0 && value <= image.cardiac_events[len-1].end_systole)) {
                            const new_cc = JSON.parse(JSON.stringify(template_cardiac_event[image.modality]));
                            new_cc[type] = value;
                            image.cardiac_events.push(new_cc);
                        }
                    }
                }
            }
        }
    }

    static checkNewEvent(image, type, value, myC) {
        const len = image.cardiac_events.length;
        const th_cc = 0.5;
        const th_onset = 10;
        const distance = image.modality === "2D"? 0 : this.getMeanCycleLength(image.cardiac_events, false);
        if (len > 0) {
            if (image.modality === "2D") {
                const check = type === "end_diastole"? "end_systole" : "end_diastole";
                if (value > image.cardiac_events[len-1][check]) {
                    return true;
                } else {
                    myC.setState({ alert: { type: "soft", text: "Please select a later frame" } });
                    return false;
                }
            } else {
                if (type === "end_systole") {
                    if (len > 1 && value <= image.cardiac_events[len-2].end_systole + th_onset) {
                        if (value < image.cardiac_events[len-2].end_systole - th_onset) {
                            myC.setState({ alert: { type: "soft", text: "Please select a later onset" } });
                        }
                        return false;
                    } else {
                        return true;
                    }
                } else {
                    if (value > image.cardiac_events[len-1][type+"_start"] + th_onset) {
                        if (len > 1 && type === "onset") {
                            if (distance && (distance*th_cc > value-image.cardiac_events[len-1][type+"_start"] || distance/th_cc < value-image.cardiac_events[len-1][type+"_start"])) {
                                myC.setState({ alert: { type: "hard", text: "The size of this cycle is more than " + th_cc*100 + "% different than the mean" } });
                            }
                        }
                        return true;
                    } else {
                        if (value < image.cardiac_events[len-1][type+"_start"] - th_onset) {
                            myC.setState({ alert: { type: "soft", text: "Please select a later onset" } });
                        }
                        return false;
                    }
                }
            }
        } else {
            return true;
        }
    }

    static checkExistingEvent(image, onset, myC) {
        const th_cc = 0.5;
        const th_onset = 10;
        const value = myC.state.mouse.pixel.x;
        const len = image.cardiac_events.length;
        const except = onset.event.includes("onset")? onset : false;
        const distance = this.getMeanCycleLength(image.cardiac_events, except);
        let check = true;
        if (onset.cc > 0 || onset.event.includes("_end")) {
            const previous = onset.cc > 0? image.cardiac_events[onset.cc-1][onset.event] : image.cardiac_events[0][onset.event.replace("_end","_start")];
            if (value > previous + th_onset) {
                if (len > 1) {
                    if (distance && (distance*th_cc > value-previous || distance/th_cc < value-previous)) {
                        myC.setState({ alert: { type: "hard", text: "The size of this cycle is more than " + th_cc*100 + "% different than the mean" } });
                    }
                }
                check = true;
            } else {
                myC.setState({ alert: { type: "soft", text: "Please select a later position" } });
                check = false;
            }
        }
        if ((onset.cc < len-1 || onset.event.includes("_start")) && check) {
            const next = len===1? image.cardiac_events[0].onset_end : image.cardiac_events[onset.cc+1][onset.event];
            if (value < next - th_onset) {
                if (len > 1) {
                    if (distance && (distance*th_cc > next-value || distance/th_cc < next-value)) {
                        myC.setState({ alert: { type: "hard", text: "The size of this cycle is more than " + th_cc*100 + "% different than the mean" } });
                    }
                }
                check = true;
            } else {
                myC.setState({ alert: { type: "soft", text: "Please select an earlier position" } });
                check = false;
            }
        }
        return check;
    }

    static getMeanCycleLength(events, except) {
        let distance = 0;
        let count = 0;
        if (except?.event === "onset_start") {
            for (let i=0 ; i<events.length-1 ; i++) {
                distance += events[i+1].onset_end - events[i].onset_end;
                count++;
            }
        } else if (events.length > 1) {
            distance += events[0].onset_end - events[0].onset_start;
            count++; 
            for (let i=0 ; i<events.length-2 ; i++) {
                distance += events[i+1].onset_end - events[i].onset_end;
                count++;
            }
        }
        return distance? distance/count : 0;
    }

    static removeLastEvent(image, action) {
        if (image.modality === "2D") {
            if (action === "annotating-diastole" && image.cardiac_events.length > 0) {
                image.cardiac_events[image.cardiac_events.length-1].end_systole = false;
            } else if (action === "annotating-systole") {
                image.cardiac_events.pop();
            }
        } else {
            if (action === "annotating-onsets") {
                image.cardiac_events.pop();
                if (image.cardiac_events.length > 0) {
                    image.cardiac_events[image.cardiac_events.length-1].onset_end = false;
                }
            } else if (action === "annotating-diastole") {
                let idx;
                image.cardiac_events.some((event,i) => {
                    if (event.end_diastole_end === false) {
                        idx = i;
                        return true;
                    }
                });
                image.cardiac_events[idx].end_diastole_start = false;
                if (idx > 0) {
                    image.cardiac_events[idx-1].end_diastole_end = false;
                }
            } else {
                let idx;
                image.cardiac_events.some((event,i) => {
                    if (event.end_systole === false) {
                        idx = i;
                        return true;
                    }
                });
                if (idx > 0) {
                    image.cardiac_events[idx-1].end_systole = false;
                }
            }
        }
    }

    static isOnsetHovered(events, mouse) {
        let onset = false;
        events.map((event,i) => {
            Object.keys(event).map(key => {
                if (!onset && Math.abs(event[key]-mouse) < 5) {
                    onset = { cc: i, event: key };
                }
            });
        });
        return onset;
    }

    static getCycleHovered(events, mouse) {
        let cycle = false;
        events.map((event,i) => {
            if (mouse > event.onset_start && mouse < event.onset_end) {
                cycle = i;
            }
        });
        return cycle;
    }

    static clickOnSelectCycles(image, myC) {
        const cycles_selected = myC.state.cycles_selected;
        const max_events = HelperStudies.getCurrentProjectMaxCycles();
        const idx = this.getCycleHovered(image.cardiac_events, myC.state.mouse.pixel.x);
        if (idx !== false) {
            let sum = cycles_selected.filter(cc => cc === true).length;
            let discontinuous = (cycles_selected[idx-1] === true && cycles_selected[idx+1] === true) || ([undefined,false].includes(cycles_selected[idx-1]) && [undefined,false].includes(cycles_selected[idx+1]));
            if (!cycles_selected[idx] && sum === max_events) {
                myC.setState({ alert: { type: "hard", text: "Please select a maximum of " + max_events + " cycles" } });
            } else if (sum > 0 && discontinuous) {
                myC.setState({ alert: { type: "hard", text: "Please select continuous cycles" } });
            } else {
                cycles_selected[idx] = !cycles_selected[idx];
                myC.setState({ cycles_selected }); 
            }
        }
    }

    static moveSelectedOnset(image, myC) {
        const onset = myC.state.element_selected;
        const check = this.checkExistingEvent(image, onset, myC);
        if (check) {
            image.cardiac_events[onset.cc][onset.event] = Math.round(myC.state.mouse.pixel.x);
            if (onset.event.includes("_end") && image.cardiac_events.length > onset.cc+1) {
                image.cardiac_events[onset.cc+1][onset.event.replace("_end","_start")] = Math.round(myC.state.mouse.pixel.x);
            }
            if (image.modality.includes("Doppler")) {
                this.spreadChangesToSegmentation(image, onset);
            }
        }
    }

    static spreadChangesToSegmentation(image, onset) {
        let new_x = image.cardiac_events[onset.cc][onset.event];
        let cc_fp = onset.event.includes("_start")? onset.cc : (onset.cc < image.cardiac_events.length-1? onset.cc+1 : false);
        if (image.segmentation[cc_fp]) {
            let f_point = cc_fp !== false? image.segmentation[cc_fp].lines[0].points[0] : false;
            if (f_point.on_onset) { f_point.x = new_x; }
        }
        let cc_lp = onset.event.includes("_start")? false : onset.cc;
        if (image.segmentation[cc_lp]) {
            let l_point = cc_lp !== false? image.segmentation[cc_lp].lines[0].points.at(-1) : false;
            if (l_point.on_onset) { l_point.x = new_x; }
        }
    }

    static getState(image, value) {
        const marked_frames = image.modality==="2D"? this.getBModeMarkedFrames(image.cardiac_events) : [];
        const max_events = HelperStudies.getCurrentProjectMaxCycles();
        const state = {
            marked_frames,
            max_events
        };
        let current_action = image.modality==="2D"? "annotating-diastole" : "annotating-onsets";
        if (value === "editing") {
            current_action = "editing";
        } else if (value === "selecting-cycles") {
            current_action = "selecting-cycles";
        } else if (image.is_annotated) {
            if (image.modality.includes("Doppler") && image.cardiac_events.length > max_events) {
                current_action = "selecting-cycles";
                state.cycles_selected = image.cardiac_events.map(cc => false);
            } else {
                current_action = "finished";
            }
        } else {
            if (image.cardiac_events.length > 0) {
                if (image.modality === "2D") {
                    if (image.cardiac_events.some(event => event.end_systole === false)) {
                        current_action = "annotating-systole";
                    } else if (image.cardiac_events.length === max_events) {
                        current_action = "finished";
                    }
                } else if (image.modality === "M-Mode") {
                    if (!image.cardiac_events.some(event => event.onset_end === false)) {
                        current_action = "annotating-diastole";
                        if (!image.cardiac_events.some(event => event.end_diastole_end === false)) {
                            current_action = "annotating-systole";
                            if (!image.cardiac_events.some(event => event.end_systole === false)) {
                                current_action = "finished";
                                image.is_annotated = true;
                            }
                        }
                    }
                }
            }
        }
        state.current_action = current_action;
        return state;
    }

    static getBModeMarkedFrames(events) {
        const marked_frames = [];
        for (const i in events) {
            if (events[i].end_diastole !== false) {
                marked_frames.push({
                    label: "End diastole",
                    frame: events[i].end_diastole,
                    cc: i
                });    
            }
            if (events[i].end_systole !== false) {
                marked_frames.push({
                    label: "End systole",
                    frame: events[i].end_systole,
                    cc: i
                });    
            }
        }
        return marked_frames;
    }

    static checkIfFinished(image) {
        if (!image.is_annotated && image.cardiac_events.length > 0) {
            let finished = true;
            image.cardiac_events.map((event,i) => {
                Object.keys(event).some(key => {
                    if (event[key] === false) {
                        if (image.modality.includes("Doppler") && key === "onset_end" && i === image.cardiac_events.length-1 && i > 0) {
                            image.cardiac_events.pop();
                            return false;
                        } else {
                            finished = false;
                            return true;
                        }
                    }
                });
            });
            image.is_annotated = finished;
        }
        return image.is_annotated;
    }

    static mouseHoverOnset(events, mouse) {
        let hover = false;
        events.map((event,i) => {
            if (i === 0 && event.onset_start && Math.abs(event.onset_start-mouse) < 5) {
                hover =  true;
            } else if (event.onset_end && Math.abs(event.onset_end-mouse) < 5) {
                hover =  true;
            }
        });
        return hover;
    }

    static drawMouse(current_action, events, selected, mouse, height, context) {
        let show_mouse = false;
        if (current_action === "editing") {
            const max_events = HelperStudies.getCurrentProjectMaxCycles();
            const hover = HelperCycleTiming.mouseHoverOnset(events, mouse.pixel.x);
            if (max_events > events.length && !hover && !selected) {
                show_mouse = true;
            }
        } else if (current_action !== "finished") {
            show_mouse = true;
        }
        if (show_mouse) {
            const line = HelperConfig.getStyle("cycletiming","cursor");
            line.x = mouse.canvas.x;
            drawVerticalLine(line, height, context);
        }
    }

    static drawOnsets(events, height, context, mouse, element, current_action) {
        events.map((event,i) => {
            let line;
            let hover;
            if (i === 0) {
                if (event.onset_start) {
                    hover =  current_action === "editing" && Math.abs(event.onset_start-mouse) < 5;
                    line = hover? HelperConfig.getStyle("cycletiming","onset_hover") : HelperConfig.getStyle("cycletiming","onset");
                    line.x = HelperImages.pixelToCanvas(element,{ x: event.onset_start, y: 0 }).x;
                    drawVerticalLine(line, height, context);
                }
                if (event.end_diastole_start) {
                    hover =  current_action === "editing" && Math.abs(event.end_diastole_start-mouse) < 5;
                    line = hover? HelperConfig.getStyle("cycletiming","end_diastole_hover") : HelperConfig.getStyle("cycletiming","end_diastole");
                    line.x = HelperImages.pixelToCanvas(element,{ x: event.end_diastole_start, y: 0 }).x;
                    drawVerticalLine(line, height, context);
                }
            }
            if (event.onset_end) {
                hover =  current_action === "editing" && Math.abs(event.onset_end-mouse) < 5;
                line = hover? HelperConfig.getStyle("cycletiming","onset_hover") : HelperConfig.getStyle("cycletiming","onset");
                line.x = HelperImages.pixelToCanvas(element,{ x: event.onset_end, y: 0 }).x;
                drawVerticalLine(line, height, context);
            }
            if (event.end_diastole_end) {
                hover =  current_action === "editing" && Math.abs(event.end_diastole_end-mouse) < 5;
                line = hover? HelperConfig.getStyle("cycletiming","end_diastole_hover") : HelperConfig.getStyle("cycletiming","end_diastole");
                line.x = HelperImages.pixelToCanvas(element,{ x: event.end_diastole_end, y: 0 }).x;
                drawVerticalLine(line, height, context);
            }
            if (event.end_systole) {
                hover =  current_action === "editing" && Math.abs(event.end_systole-mouse) < 5;
                line = hover? HelperConfig.getStyle("cycletiming","end_systole_hover") : HelperConfig.getStyle("cycletiming","end_systole");
                line.x = HelperImages.pixelToCanvas(element,{ x: event.end_systole, y: 0 }).x;
                drawVerticalLine(line, height, context);
            }
        });
    }

    static drawCycleRegions(events, regions, height, width, context, mouse, element) {
        let style_inactive = HelperConfig.getStyle("cycletiming","fill_region_inactive");
        let style_hover = HelperConfig.getStyle("cycletiming","fill_region_hover");
        let style_active = HelperConfig.getStyle("cycletiming","fill_region_active");
        for (let i = 0; i < events.length; i++) {
            let start = HelperImages.pixelToCanvas(element,{ x: events[i].onset_start, y: 0 }).x;
            let end = HelperImages.pixelToCanvas(element,{ x: events[i].onset_end, y: 0 }).x;
            if (i === 0) {
                drawFilledRegion(0, 0, start, height, style_inactive, context);
            } else if (i === events.length-1) {
                drawFilledRegion(end, 0, width, height, style_inactive, context);
            }
            if (regions[i]) {
                drawFilledRegion(start, 0, end, height, style_active, context);
            } else {
                drawFilledRegion(start, 0, end, height, style_inactive, context);
            }
            if (mouse > start && mouse < end) {
                drawFilledRegion(start, 0, end, height, style_hover, context);
            }
        }
    }
}