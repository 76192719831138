import React from 'react';
import { useStyles } from './db_styles';

export function DatabaseStatisticsLabel({
        value,
        string,
        color,
        size
    }) {

    const classes = useStyles();

    var headerStyle = {
        color: color,
        fontSize: size
    };
    
    let tab_style = "grid-block vertical shrink pl-component-database-header-label"
    
    return (
        <div key={string} className={tab_style} style={{marginTop:"10px"}}>
            <div className="grid-block align-center shrink"><h4 className="mode_selected" style={headerStyle}>{value}</h4></div>
            <div className={"grid-block align-center shrink " + classes.statusText}>{string}</div>
        </div>
    );
}