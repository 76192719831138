import React from 'react';

import { CrossIcon } from './image_menu_icons/cross_icon';
import { WaveIcon } from './image_menu_icons/wave_icon';
import { RemoveIcon } from './image_menu_icons/remove_icon';
import { EditIcon } from './image_menu_icons/edit_icon';
import { UndoIcon } from './image_menu_icons/undo_icon';
import { LockIcon } from './image_menu_icons/lock_icon';
import { UnlockIcon } from './image_menu_icons/unlock_icon';
import { LegendIcon } from './image_menu_icons/legend_icon';
import { NomagicIcon } from './image_menu_icons/nomagic_icon';
import { NolegendIcon } from './image_menu_icons/nolegend_icon';
import { ReportIcon } from './image_menu_icons/report_icon';
import { NoreportIcon } from './image_menu_icons/noreport_icon';
import { MeasureIcon } from './image_menu_icons/measure_icon';
import { NomeasureIcon } from './image_menu_icons/nomeasure_icon';
import { CheckedIcon } from './image_menu_icons/checked_icon';
import { UncheckedIcon } from './image_menu_icons/unchecked_icon';
import { DoneIcon } from './image_menu_icons/done_icon';
import { RemoveCCIcon } from './image_menu_icons/remove_cc_icon';
import { MagicIcon } from './image_menu_icons/magic_icon';
import { HelpIcon } from './image_menu_icons/help_icon';
import { InfoIcon } from './image_menu_icons/info_icon';
import { ImageIcon } from './image_menu_icons/image_icon';
import { CameraIcon } from './image_menu_icons/camera_icon';
import { DownloadIcon } from './image_menu_icons/download_icon';
import { CommentIcon } from './image_menu_icons/comment_icon';
import { CommentFullIcon } from './image_menu_icons/comment_full_icon';
import { TextIcon } from './image_menu_icons/text_icon';
import { ThumbsUpIcon } from './image_menu_icons/thumbsup_icon';
import { ThumbsUpFilledIcon } from './image_menu_icons/thumbsupfilled_icon';
import { ThumbsDownIcon } from './image_menu_icons/thumbsdown_icon';
import { ThumbsDownFilledIcon } from './image_menu_icons/thumbsdownfilled_icon';

export const ImageMenuIcons = ({
        type,
        className,
        style,
        width,
        color,
        height
    }) => {
    
    let icon;
    switch (type) {
        case "cross":
            icon = <CrossIcon className={className} color={color} style={style} width={width} height={height} />
            break;
        case "wave":
            icon = <WaveIcon className={className} color={color} style={style} width={width} height={height} />
            break;
        case "remove":
            icon = <RemoveIcon className={className} color={color} style={style} width={width} height={height} />
            break;
        case "edit":
            icon = <EditIcon className={className} color={color} style={style} width={width} height={height} />
            break;
        case "undo":
            icon = <UndoIcon className={className} color={color} style={style} width={width} height={height} />
            break;
        case "lock":
            icon = <LockIcon className={className} color={color} style={style} width={width} height={height} />
            break;
        case "unlock":
            icon = <UnlockIcon className={className} color={color} style={style} width={width} height={height} />
            break;
        case "legend":
            icon = <LegendIcon className={className} color={color} style={style} width={width} height={height} />
            break;
        case "nolegend":
            icon = <NolegendIcon className={className} color={color} style={style} width={width} height={height} />
            break;
        case "report":
            icon = <ReportIcon className={className} color={color} style={style} width={width} height={height} />
            break;
        case "noreport":
            icon = <NoreportIcon className={className} color={color} style={style} width={width} height={height} />
            break;
        case "measure":
            icon = <MeasureIcon className={className} color={color} style={style} width={width} height={height} />
            break;
        case "nomeasure":
            icon = <NomeasureIcon className={className} color={color} style={style} width={width} height={height} />
            break;
        case "checked":
            icon = <CheckedIcon className={className} color={color} style={style} width={width} height={height} />
            break;
        case "unchecked":
            icon = <UncheckedIcon className={className} color={color} style={style} width={width} height={height} />
            break;
        case "done":
            icon = <DoneIcon className={className} color={color} style={style} width={width} height={height} />
            break;
        case "removecc":
            icon = <RemoveCCIcon className={className} color={color} style={style} width={width} height={height} />
            break;
        case "magic":
            icon = <MagicIcon className={className} color={color} style={style} width={width} height={height} />
            break;
        case "nomagic":
            icon = <NomagicIcon className={className} color={color} style={style} width={width} height={height} />
            break;
        case "help":
            icon = <HelpIcon className={className} color={color} style={style} width={width} height={height} />
            break;
        case "info":
            icon = <InfoIcon className={className} color={color} style={style} width={width} height={height} />
            break;
        case "image":
            icon = <ImageIcon className={className} color={color} style={style} width={width} height={height} />
            break;
        case "camera":
            icon = <CameraIcon className={className} color={color} style={style} width={width} height={height} />
            break;
        case "download":
            icon = <DownloadIcon className={className} color={color} style={style} width={width} height={height} />
            break;
        case "comment":
            icon = <CommentIcon className={className} color={color} style={style} width={width} height={height} />
            break;
        case "commentfull":
            icon = <CommentFullIcon className={className} color={color} style={style} width={width} height={height} />
            break;
        case "qualitycheck":
            icon = <TextIcon text={"QC"} className={className} color={color} style={style} width={width} height={height} />
            break;
        case "thumbsup":
            icon = <ThumbsUpIcon className={className} color={color} style={style} width={width} height={height} />
            break;
        case "thumbsup-filled":
            icon = <ThumbsUpFilledIcon className={className} color={color} style={style} width={width} height={height} />
            break;
        case "thumbsdown":
            icon = <ThumbsDownIcon className={className} color={color} style={style} width={width} height={height} />
            break;
        case "thumbsdown-filled":
            icon = <ThumbsDownFilledIcon className={className} color={color} style={style} width={width} height={height} />
            break;
        default:
            icon = null;
            break;
    }

    return icon;
}