import { DataProvider } from "../../dataprovider/dataprovider";
export class HelperUserBackend {
    static getUserRolesFromDB(user_email, user_token) {
        return DataProvider("ADMIN","GET_USER_PROJECTS", {
            id: user_email
        }, user_token).then((user) => {
            return user[0];
        });
    }
    static getUserProjects(user_email, user_token){
        return DataProvider("ADMIN","GET_USER_PROJECTS", {
            id: user_email
        }, user_token).then((user) => {
            return user[0].projects;
        });
    }
    static acceptTermsConditions(user_email, version, user_token){
        return DataProvider("ADMIN","ACCEPT_TERMS_CONDITIONS", {
            user: user_email,
            version
        }, user_token);
    }
}