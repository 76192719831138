import React from 'react';
import { HelperStudies } from '../../../helpers/helper_studies/helper_studies';
import { DatabaseStatisticsLabel } from './db_statistics_label';
import { HelperConfig } from '../../../helpers/helper_config/helper_config';
// Styles
import { useStyles } from './db_styles';

export function DBStatistics(props) {

    const classes = useStyles();

    let statistics = HelperStudies.getProjectStatistics(props.items);
    const renderLabel = (string, value) =>{
        let color = HelperConfig.getStatisticsColor(string)
        return (
            <DatabaseStatisticsLabel
                value={value}
                string={string}
                color={color}
                size="26px"
            />
        );
    }
    const renderLabels = () => {
        return (Object.entries(statistics).map(function (value, index) {
            return (renderLabel(value[0],value[1]))
        }));
    }
    return (
        <div className={"grid-block horizontal " + classes.statisticsRoot}>
            {renderLabels()}
        </div>
    );
}