import React from 'react';

export const NoreportIcon = ({
        className,
        style,
        width,
        height
    }) => {
    
    return (
        <svg className={className} width={width} height={height} style={style} viewBox="0 0 44 44">
            <path stroke="none" d="M0,0h44v44H0V0z" fill="none"/>
            <path d="M21.4,39.9H11c-2.8,0-5-2.3-5-5v-22c0-2.8,2.3-5,5-5h3.7c0.8,0,1.4,0.6,1.4,1.4s-0.6,1.4-1.4,1.4H11
                c-1.3,0-2.3,1-2.3,2.3v22c0,1.3,1,2.3,2.3,2.3h10.4c0.8,0,1.4,0.6,1.4,1.4S22.2,39.9,21.4,39.9z"/>
            <path d="M33,23.4c-0.8,0-1.4-0.6-1.4-1.4v-9.2c0-1.3-1-2.3-2.3-2.3h-3.7c-0.8,0-1.4-0.6-1.4-1.4s0.6-1.4,1.4-1.4h3.7
                c2.8,0,5,2.3,5,5V22C34.4,22.8,33.8,23.4,33,23.4z"/>
            <path d="M22,14.2h-3.7c-2.8,0-5-2.3-5-5c0-2.8,2.3-5,5-5H22c2.8,0,5,2.3,5,5C27,11.9,24.8,14.2,22,14.2z M18.3,6.9
                c-1.3,0-2.3,1-2.3,2.3s1,2.3,2.3,2.3H22c1.3,0,2.3-1,2.3-2.3s-1-2.3-2.3-2.3H18.3z"/>
            <path d="M22,21.5h-7.3c-0.8,0-1.4-0.6-1.4-1.4s0.6-1.4,1.4-1.4H22c0.8,0,1.4,0.6,1.4,1.4S22.8,21.5,22,21.5z"/>
            <path d="M20.2,28.9h-5.5c-0.8,0-1.4-0.6-1.4-1.4s0.6-1.4,1.4-1.4h5.5c0.8,0,1.4,0.6,1.4,1.4S20.9,28.9,20.2,28.9z"/>
            <path d="M30.3,38c-3.3,0-6-2.7-6-6c0-3.3,2.7-6,6-6s6,2.7,6,6C36.2,35.4,33.5,38,30.3,38z M30.3,28.9
                c-1.8,0-3.2,1.4-3.2,3.2c0,1.8,1.4,3.2,3.2,3.2s3.2-1.4,3.2-3.2C33.5,30.3,32,28.9,30.3,28.9z"/>
            <path d="M38.5,41.7c-0.4,0-0.7-0.1-1-0.4l-4.6-4.6c-0.5-0.5-0.5-1.4,0-1.9c0.5-0.5,1.4-0.5,1.9,0l4.6,4.6
                c0.5,0.5,0.5,1.4,0,1.9C39.2,41.6,38.9,41.7,38.5,41.7z"/>
            <polygon points="0,41.6 2.4,44 41,5.4 38.6,3" />
        </svg>
    );
}