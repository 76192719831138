import React from 'react';

export const PWDopplerIcon = ({
        className,
        style,
        width,
        height
    }) => {
    
    return (
        <svg className={className} width={width} height={height} style={style} viewBox="0 0 50 50" fill="none">
            <path d="M46.68 1H3.32C2.0387 1 1 2.0387 1 3.32V46.68C1 47.9613 2.0387 49 3.32 49H46.68C47.9613 49 49 47.9613 49 46.68V3.32C49 2.0387 47.9613 1 46.68 1Z" stroke="#71C1E3" strokeWidth="2" strokeMiterlimit="10" />
            <path d="M41.5 25.5L43.5 18.5C43.5 18.3674 43.5527 18.2402 43.6464 18.1464C43.7402 18.0527 43.8674 18 44 18C44.1326 18 44.2598 18.0527 44.3536 18.1464C44.4473 18.2402 44.5 18.3674 44.5 18.5L46 25H49" stroke="#71C1E3" strokeWidth="2" strokeMiterlimit="10" />
            <path d="M29 25L32 9C32 8.86739 32.0527 8.74021 32.1464 8.64645C32.2402 8.55268 32.3674 8.5 32.5 8.5C32.6326 8.5 32.7598 8.55268 32.8536 8.64645C32.9473 8.74021 33 8.86739 33 9L36 25" stroke="#71C1E3" strokeWidth="2" strokeMiterlimit="10" />
            <path d="M5.35001 25C5.35001 25.91 5.98001 31.72 6.00001 32C6.09001 33.34 7.00001 41 8.00001 42C10.5 44.5 17.09 37.19 19 34C20.5029 31.0783 21.8384 28.0734 23 25H27" stroke="#71C1E3" strokeWidth="2" strokeMiterlimit="10" />
            <path d="M1 25H48" stroke="#71C1E3" strokeWidth="2" strokeMiterlimit="10" />
        </svg>
    );
}