import { HelperConfig } from './../helper_config/helper_config';
import { HelperStudies } from './../helper_studies/helper_studies';
import { HelperMeasurementsBMode } from './helper_measurements_bmode';
import { HelperMeasurementsMMode } from './helper_measurements_mmode';
import { HelperMeasurementsDoppler } from './helper_measurements_doppler';
import { HelperUser } from '../helper_user/helper_user';
import { findWhere } from 'underscore';
// import { getRandomColor } from '../../modules/draw_module';


export class HelperMeasurements {

    static getMeasurementsFromImages(data) {
        if (data) {
            const measurements = [];
            const anatomies = HelperConfig.getAnatomies();
            const modalities = HelperConfig.getImageModalities("all");
            for (const image of data) {
                if (image.reference && image.type && image.segmentation.length > 0) {
                    for (const anatomy of anatomies) {
                        if (anatomy.image_types.includes(image.type)) {
                            const modality = findWhere(modalities, { type: image.modality });
                            const result = this.getMeasurementsByAnatomyAndModality(image, anatomy.name_short);
                            const measurements_anatomy = findWhere(measurements, { anatomy: anatomy.name_short });
                            if (measurements_anatomy) {
                                const measurements_modality = findWhere(measurements_anatomy.modalities, { name: image.modality });
                                if (measurements_modality) {
                                    measurements_modality.measurements = [...measurements_modality.measurements, ...result];
                                } else {
                                    measurements_anatomy.modalities.push({
                                        name: image.modality,
                                        color: modality.color,
                                        measurements: result
                                    });
                                }
                            } else {
                                measurements.push({
                                    anatomy: anatomy.name_short,
                                    modalities: [{
                                        name: image.modality,
                                        color: modality.color,
                                        measurements: result
                                    }]
                                });
                            }
                        }
                    }
                }
            }
            let user_type = HelperUser.getUserType();
            if (["Ground-truth generator","Editor"].includes(user_type)) {
                HelperStudies.saveStudy();
            } else if (user_type === "Reviewer") {
                HelperStudies.saveReview();
            } else {
                console.log("Measurements not saved because of user role")
            }
            
            return measurements;
        }
    }

    static getMeasurementsByAnatomyAndModality(image, anatomy) {
        const measures2render = [];
        // let measures = [];
        // if (image.measurements?.length === 0) {
            this.extractMeasurementsFromImage(image, anatomy);
        // }
        // measures = image.measurements.filter(m => m.shape && m.shape.includes(anatomy));
        image.measurements.map((measure) => {
            if (measure.anatomy === anatomy) {
                let new_measure_dict = {
                    "name": measure.name,
                    "value": measure.value,
                    "units": measure.units,
                    "type": measure.class,
                    "from": image.type,
                    "image_id": image.id
                }
                measures2render.push(new_measure_dict)
            }
        });
        return measures2render;
    }

    static extractMeasurementsFromImage(image) {
        const config_measurements = HelperConfig.getMeasurements(image.type);
        if (config_measurements && image?.metadata.flow_region?.spacing_x) {
            if (image.modality === "2D") {
                image.measurements = HelperMeasurementsBMode.get2DMeasurements(config_measurements, image.segmentation, image);
            } else if (image.modality === "M-Mode") {
                image.measurements = HelperMeasurementsMMode.getMModeMeasurements(config_measurements, image.segmentation, image);
            } else {
                image.measurements = HelperMeasurementsDoppler.getDopplerMeasurements(config_measurements, image);
            }
        }
        return image.measurements;
    }

    static getListOfImagesWithColors(measurements) {
        let list = [];
        let colors = HelperConfig.getMeasurementsPreference("images_colors");
        for (const mod of measurements.modalities) {
            for (const meas of mod.measurements) {
                 if (!list.includes(meas.image_id) && meas.value.length > 0) {
                    list.push(meas.image_id);
                }
            }
        }
        list = list.map((im,i) => {
            return { image_id: im, color: colors[i] };
        });
        return list;
    }
}