import {
    HelperSegmentation
} from "../helpers/helper_segmentation/helper_segmentation";

export const measurementsActions = (action, myC) => {
    const image = action.value.image;
    let state = {};

    switch (action.action) {
        case "INIT":
            if (image.is_segmented) {
                state = HelperSegmentation.init(image);
            }
            break;
        case "DRAW-CANVAS":
            if (image.is_segmented) {
                const context = action.value.eventData.canvasContext.canvas.getContext('2d');
                const height = action.value.eventData.image.rows;
                context.beginPath();
                HelperSegmentation.drawOnsets(image, height, context, action.value.eventData.element, myC.state);
                HelperSegmentation.drawSegmentation(image, context, myC.state, action.value.eventData.element);
                HelperSegmentation.drawMeasurements(image, context, myC.state, action.value.eventData.element, height);
                context.closePath();
            }
            break;
        default:
            break;
    }

    state.image = image;
    myC.setState(state);
}