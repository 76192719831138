import { findWhere } from "underscore";

export function getDopplerRegion(us_regions, modality) {
    if (modality === "2D") {
        let region = findWhere(us_regions, { region_data_type: 1 });
        if (!region) {
            region = findWhere(us_regions, { region_data_type: 2 });
        }
        return region;
    } else if (modality === "M-Mode") {
        return findWhere(us_regions, { region_spatial_format: 2 });
    } else {
        let region = findWhere(us_regions, { region_data_type: 3 });
        if (!region) {
            region = findWhere(us_regions, { region_data_type: 4 });
        }
        return region;
    }
}

export function getImageMetadataFromDicom(dicom_metadata, modality) {
    if (modality) {
        const flow_region = getDopplerRegion(dicom_metadata.doppler_regions, modality);
        const metadata = {
            "number_of_frames": dicom_metadata.number_of_frames,
            "width": dicom_metadata.width,
            "height": dicom_metadata.height,
            "flow_region": {}
        };
        if (flow_region) {
            metadata.flow_region = {
                "region_location_min_x": flow_region.region_location_min_x0,
                "region_location_max_x": flow_region.region_location_max_x1,
                "region_location_min_y": flow_region.region_location_min_y0,
                "region_location_max_y": flow_region.region_location_max_y1,
                "spacing_x": flow_region.physical_delta_x,
                "spacing_y": flow_region.physical_delta_y,
                "reference_x": flow_region.reference_pixel_x0,
                "reference_y": flow_region.reference_pixel_y0,
                "units_x": flow_region.physical_units_x,
                "units_y": flow_region.physical_units_y
            };
        }
        return metadata;
    } else {
        return {};
    }
}

export function dicomUnitsToReal(unit) {
    // from https://dicom.innolitics.com/ciods/us-image/general-image/00880200/00280004
    if (unit === 0) { unit = "NA" }
    else if (unit === 1) { unit = "%" }
    else if (unit === 2) { unit = "dB" }
    else if (unit === 3) { unit = "cm" }
    else if (unit === 4) { unit = "s" }
    else if (unit === 5) { unit = "hz" }
    else if (unit === 6) { unit = "dB/s" }
    else if (unit === 7) { unit = "cm/s" }
    else if (unit === 8) { unit = "cm2" }
    else if (unit === 9) { unit = "cm2/s" }
    else if (unit === "A") { unit = "cm3" }
    else if (unit === "B") { unit = "cm3/s" }
    return unit;
}