import * as types from './../actions/actions_types';
import { createStore } from 'redux';

const initialState = {
    interactiveMeasureKey: null,
    //images_loaded:[],
    //image:false,
    images_loaded:0,
    measures:
    {
        AVO: null,
        AVC: null,
        MVO: null,
        MVC: null,
        LAWs: null,
        LAWc: null,
        PVO: null,
        PVC: null,
        TVO: null,
        TVC: null,
        RAWs: null,
        RAWc: null,
    },
    colors:
    {
        AVO: "#cc0000",
        AVC: "#2a9fd6",
        MVO: "#9933cc",
        MVC: "#FF8000",
        LAWs:"#CDCD00",
        LAWc:"#77b300",
        PVO: "#cc0000",
        PVC: "#2a9fd6",
        TVO: "#9933cc",
        TVC: "#FF8000",
        RAWs:"#CDCD00",
        RAWc:"#77b300",
    },
    measurements_location:{
        AVO: "LV",
        AVC: "LV",
        MVO: "LV",
        MVC: "LV",
        LAWs: "LV",
        LAWc: "LV",
        PVO: "RV",
        PVC: "RV",
        TVO: "RV",
        TVC: "RV",
        RAWs: "RV",
        RAWc: "RV",
    }
    
}

export default function Reducer(state = initialState, action) {
    
    let newState;

    switch (action.type) {
        
        case types.SET_TIME_VALUE:
            newState = state;
            newState.measures[action.key] = action.value;
            return Object.assign({}, newState);

        case types.SET_INTERACTIVE_MEASURE:
            return Object.assign({}, state, {
                interactiveMeasureKey: action.key
            })

        case types.INIT_MEASURES:
            newState = state;
            newState.interactiveMeasureKey = null;
            newState.measures.AVO = null;
            newState.measures.AVC = null;
            newState.measures.MVO = null;
            newState.measures.MVC = null;
            newState.measures.LAWs = null;
            newState.measures.LAWc = null;
            newState.measures.PVO = null;
            newState.measures.PVC = null;
            newState.measures.TVO = null;
            newState.measures.TVC = null;
            newState.measures.RAWs = null;
            newState.measures.RAWc = null;
            return Object.assign({}, newState);

        case types.LOAD_IMAGE:
            newState = state;
            newState.images_loaded=newState.images_loaded+1;
            console.log(newState.images_loaded);
            return Object.assign({},state,newState);

        default:
            return Object.assign({},state,state);

    }
}

export const redux_store = createStore(Reducer, initialState)