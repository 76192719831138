import React from 'react';

export const ConfirmationAlertComponent = ({
        show,
        message,
        options
    }) => {
  
    return show? (
        <div className="alert-container">
            <div className="alert">
                <span>{message}</span>
                <div className="options">
                    {options.map((button) => {
                        return <button key={button.title} onClick={() => button.action()}>{button.title}</button>
                    })}
                </div>
            </div>
        </div>
    ) : <></>;
}