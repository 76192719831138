import React from 'react';

export const MeasureIcon = ({
        className,
        style,
        width,
        height
    }) => {
    
    return (
        <svg className={className} width={width} height={height} style={style} viewBox="0 0 24 24">
            <path d="M5.66,0,0,5.66,18.34,24,24,18.34Zm0,2.83,1,1.07L4.58,6l.7.71L7.4,4.61,8.82,6,7.4,7.43l.71.71L9.53,6.73l1.41,1.41L9.53,9.56l.7.7,1.42-1.41,1.41,1.41-2.12,2.12.71.71L13.77,11l1.41,1.41L13.77,13.8l.71.71,1.41-1.42,1.41,1.42-1.41,1.41.71.71L18,15.21l1.42,1.42L17.3,18.75l.71.71,2.12-2.13,1,1-2.83,2.83L2.83,5.66Z"/>
        </svg>
    );
}