import React, { Component } from 'react';

// Components
import { LoadingComponent } from './../../components/loading_component/loading_component';
import { ImageGridComponent } from './../../components/image_grid_component/image_grid_component';

// Helpers
import { HelperConfig } from './../../../helpers/helper_config/helper_config';
import { HelperUser } from './../../../helpers/helper_user/helper_user';
import { HelperImages } from './../../../helpers/helper_images/helper_images';
import { HelperStudies } from './../../../helpers/helper_studies/helper_studies';

// Actions file
import { onActionToPerform } from './../../../actions/actions';

export class PreviewPage extends Component {

    constructor() {
        super();
        this.state = {
            currentproject: "",
            study_id: "",
            patient_id: "",
            data_type: "",
            images_loaded: false,
            images: [],
            images_selected: []
        }
        this.user = {};
    }

    componentDidMount() {
        if (!HelperUser.validateUserAuthentication()) {
            this.onActionToPerform("GENERAL", { action: "LOGOUT", value: "" })
        } else {
            HelperConfig.init().then(() => {
                HelperStudies.initStudy().then(study_info => {
                    this.user = HelperUser.getUser();
                    this.setState(study_info.state);
                    HelperStudies.getFilesSelectedToLoad(study_info.study_id, study_info.editor, this.user).then((result) => {
                        // console.log(result)
                        if ((HelperStudies.getUrlParam("studies") && result) || this.user.user_type === "Viewer") {
                            this.onActionToPerform("GENERAL", { action: "GO_TO_PAGE", value: { page: "selected", from: "studies" } });
                        } else {
                            HelperStudies.getStudyInfo().then((new_study_info) => {
                                if (new_study_info.study_id) {
                                    HelperStudies.loadIcons(study_info, this.user).then((images) => {
                                        const images_selected = result? JSON.parse(JSON.stringify(result)) : [];
                                        this.setState({
                                            images,
                                            images_selected,
                                            images_loaded: true
                                        });
                                    });
                                }    
                            });
                        }
                    });   
                }); 
            });
        }
    }

    onActionToPerform(actionType, action) {
        onActionToPerform(actionType, action, this);
    }

    selectImage(i) {
        const images = this.state.images.all_images;
        let images_selected = this.state.images_selected;
        if (images_selected.includes(images[i].filename)) {
            images_selected = images_selected.filter(im => im !== images[i].filename);
        } else {
            images_selected.push(images[i].filename);
        }
        this.setState({
            images_selected
        });
    }

    doneSelecting() {
        if (HelperStudies.getImagesToLoad() !== this.state.images_selected) {
            const images = this.state.images;
            const urls2load = []; 
            images.all_images.map(im => {
                im.active = this.state.images_selected.includes(im.filename) || this.state.images_selected.length === 0? true : false;
                if (im.active && !im.dicom) {
                    urls2load.push(im.urls.DICOM_url);
                }
            });
            images.loaded_images = images.all_images.filter(im => im.active);
            HelperStudies.setStudyImages(images);
            HelperStudies.setImagesToLoad(this.state.images_selected);
            HelperImages.downloadDICOMFromURL(urls2load, this.user.user_token);
        }
        HelperStudies.updateFilesSelectedToLoad(this.state.study_id, this.user, this.state.images_selected).then((result) => {
            this.onActionToPerform("GENERAL", { action: "GO_TO_PAGE", value: { page: "selected", from: "studies" } });
        });
    }

    renderPreview() {
        if (this.state.images_loaded) {
            return (
                <div>
                    {this.renderImages()}
                    {this.renderDoneButton()}
                </div>
            );
        } else {
            return (
                <LoadingComponent
                    site={"preview"}
                    on_action_to_perform={this.onActionToPerform.bind(this)}
                />
            );
        }
    }

    renderImages() {
        return (
            <ImageGridComponent
                site={"preview"}
                images={this.state.images.all_images}
                images_selected={this.state.images_selected}
                on_image_selected={this.selectImage.bind(this)}
                on_hover={()=>{}}
            />
        );
    }

    renderDoneButton() {
        const selected = this.state.images_selected.length > 0 ? this.state.images_selected.length : this.state.images.all_images.length;
        return (
            <div className="done-button" onClick={() => this.doneSelecting()}>
                <span>
                    <span style={{fontSize:"13px"}}>DONE</span><br/>
                    {selected}/{this.state.images.all_images.length}
                </span>
            </div>
        );
    }

    render() {
        return (
            <div className="grid-frame vertical">
                <div className="preview-header">Please select the files of the study you want to load</div>
                {this.renderPreview()}
            </div>
        );
    }
}