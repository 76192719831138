import React from 'react';

export const PreviousIcon = ({
    className,
    style,
    width,
    height, 
    color
}) => {

    return (
        <svg className={className} width={width} height={height} style={style} viewBox="0 0 24 24">
            <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" fill={color}/>
        </svg>
    );
}