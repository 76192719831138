import React from 'react';

export const OnsetRWaveIcon = ({
        className,
        style,
        width,
        height
    }) => {
    
    return (
        <div className={className} style={style}>R-wave</div>
    );
}