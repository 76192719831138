import React, { useState } from 'react';

// Components
import { QualityStatistics } from './quality_statistics';
import { DataStatistics } from './data_statistics';

export const StatisticsComponent = ({ project }) => {

    const [show, setShow] = useState("data");

    return (
        <div className="stats component">
            <DataStatistics
                project={project}
                show={show==="data"}
                handle_header={setShow}
            />
            <QualityStatistics
                project={project}
                show={show==="quality"}
                handle_header={setShow}
            />
        </div>
    );
}