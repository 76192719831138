import React from 'react';

export const NextIcon = ({
    className,
    style,
    width,
    height,
    color
}) => {

    return (
        <svg className={className} width={width} height={height} style={style} viewBox="0 0 24 24">
            <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" fill={color} />
        </svg>
    );
}