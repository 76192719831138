import React, { useState } from 'react';

// Helpers
import { HelperMeasurements } from '../../../helpers/helper_measurements/helper_measurements';

// Components
import { ToggleMenuComponent } from './../toggle_menu_component/toggle_menu_component';
import { MeasurementsSummary } from './measurements_summary';
import { MeasurementsGrid } from './measurements_grid';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';

export const MeasurementsComponent = ({
        images,
        on_action_to_perform,
        mode,
        editor
    }) => {

    const reports = ["Summary", "Complete"];
    const [reportType, setReportType] = useState("Summary");
    
    const changeReportType = () => {
        if (reportType === "Summary") {
            setReportType("Complete");
        } else {
            setReportType("Summary");
        }
    }

    const measurements = HelperMeasurements.getMeasurementsFromImages(images);
        
    const renderMeasurements = () => {
        if (images.some(img => img.measurements.length > 0)) {
            if (reportType === "Summary") {
                return (
                    <MeasurementsSummary
                        measurements={measurements}
                        images={images}
                    />
                );
            } else {
                return (
                    <MeasurementsGrid
                        measurements={measurements}
                    />
                );
            }
        } else {
            let text = mode === "Reviewer"? "Please go to Validation page to start validating" : "Please go to Selection page to start analysing";
            let value = mode === "Reviewer"? { page: "validation", editor } : { page: "selection" };
            return (
                <div>
                    <Dialog aria-labelledby="customized-dialog-title" open={true}>
                        <DialogTitle id="customized-dialog-title">
                            No measurements found
                        </DialogTitle>
                        <DialogContent dividers>
                            <Typography gutterBottom>
                                {text}
                            </Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button autoFocus onClick={() => { on_action_to_perform("GENERAL", { action: "GO_TO_PAGE", value }) }} color="primary">
                                OK
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            );
        }
    }

    return (
        <div className="measurements component">
            <div className="header-report">
                <div className="menu-title">New Report type:</div>
                <ToggleMenuComponent
                    site={"measurements-header"}
                    data={reports}
                    data_hidden={[]}
                    keys_selected={reportType}
                    on_action_to_perform={changeReportType}
                />
            </div>
            {renderMeasurements()}
        </div>
    );
}
