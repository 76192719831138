import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
//Store
import { HelperUser } from './../../../helpers/helper_user/helper_user';
import { HelperConfig } from './../../../helpers/helper_config/helper_config';
import { HelperJobs } from './../../../helpers/helper_jobs/helper_job';
import { HelperExport } from './../../../helpers/helper_export/helper_export';
const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
        backgroundColor: "#313030",
        color: "white",

    },
    icon2: {
        width: "45px",
        height: "45px",
        stroke: "#1769aa",
        cursor: "pointer"
    },
    container: {
        backgroundColor: "#313030",
        height: window.innerHeight - 78,
        display: "flex",
        justifyContent: "center",
        fontSize: "34px",
        fontWeight: "bold",
        overflow: "auto",
        padding: "10px"
    },
    container_sub: {
        width: "50%",
        textAlign: "center"
    },
    page: {
        width: "100%",
        color: "white",
        backgroundColor: "#313030",
        overflow: "hidden"
    },
    floating_div: {
        display: "none",
        position: "absolute",
        backgroundColor: "#000a",
        height: "100%",
        width: "100%",
        alignItems: "center",
        justifyContent: "center"
    },
    floating_text: {
        height: "300px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#333",
        padding: "50px",
        position: "relative"
    },
    floating_close: {
        position: "absolute",
        height: "30px",
        width: "30px",
        backgroundColor: "red",
        borderRadius: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontWeight: "bold",
        cursor: "pointer",
        top: "20px",
        right: "20px"
    },
    icon_div: {
        padding: "10px",
        overflow: "hidden",
        display: "flex"
    },
    backText: {
        display: "flex",
        alignItems: "center",
        fontSize: "20px",
        marginLeft: "10px"
    },
    exportButton: {
        cursor: "pointer",
        marginLeft: "10px"
    },
    icon: {
        width: "50px",
        height: "50px",
        stroke: "red",
        cursor: "pointer",
        marginTop: "5px",
        "&:hover": {
            stroke: "#ffffff"
        }
    },
    boxtext: {
        marginBottom: "10px",
        backgroundColor: "#444",
        borderRadius: "10px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#999"
        }
    },
    text: {
        color: "white",
        fontSize: "24px",
        display: "flex",
        alignContent: "center",
        alignItems: "center",
        marginLeft: "12px",
        overflow: "hidden"
    },
    description: {
        fontStyle: "itallic",
        fontSize: "16px",
        fontWeight: "normal"
    },
    link:{
        cursor:"pointer",
        color:"white",
        padding:"5px",
        "&:hover": {
            color: "red"
        }
    }
}));

var commentsState = [];
var lastLogin = false;
var projects = [];
var isRunning = true;
var exports = [];

export const ExportPage = (props) => {
    // window.addEventListener("keydown", enterKey);
    const classes = useStyles();
    lastLogin = localStorage.getItem("lastLogin");
    const [isRenderNeeded, renderAgain] = React.useState(false);
    const [exportType, setExportType] = React.useState("segmentations");
    const [images, setImages] = React.useState(false);
    const [singleFile, setSingleFile] = React.useState(false);
    const [editorMode, setEditorMode] = React.useState(false);
    const [studyType, setStudyType] = React.useState(false);
    const [allFiles, setAllFiles] = React.useState(true);
    const [dataType, setDataType] = React.useState("analysis");
    const [normalMeas, setNormalMeas] = React.useState(true);
    const [caliperMeas, setCaliperMeas] = React.useState(true);
    // const [commentsState, setComments] = React.useState([]);
    const goToStudies = () => {
        var site = window.location.href;
        var new_path = site.replace("export", "user-page?stay=true")
        window.location.href = new_path;
    }
    let user = HelperUser.getUser();
    user = user.user_email;
    if (projects.length === 0) {
        HelperConfig.init().then(() => {
            HelperUser.getUserProjects().then(user_projects => {
                projects = user_projects;
                // ---- REVIEW: UNCOMMENT TO GET LIST OF PREVIOUS EXPORTS ----
                // exports = [];
                // user_projects.map((project) => {

                //     HelperExport.getProjectExports(project).then(project_exp => {
                //         // console.log(project_exp)
                //         project_exp.map((exp) => {
                //             exports.push(exp);
                //         })
                //         // console.log(exports)
                //         isRunning = false;
                //         renderAgain("getting exports in minio")
                //     })
                // })
                // ------------------------------------------------------------
                renderAgain("getting projects from user")
            });
        });
    }

    const exportStudy = (project) => {
        console.log("Export study button clicked")
        var editor = document.getElementById("editor_input").value;
        var imtype = document.getElementById("imtype_input").value;
        var count = (editor.match(/@/g)||[]).length;
        if (editor === "" || count === 1) {
            editor = editor === ""? "all" : editor;
            var mode = editorMode? "editor" : "none";
            var params = {
                editor, imtype, mode, dataType, studyType, exportType, images, singleFile, allFiles, normalMeas, caliperMeas
            };
            HelperJobs.computeNew("IAP_Export",project,params);
            document.getElementById("floating_div").style.display = "flex";
            // var myVar = setInterval(function () {
            //     isRunning = true;
            //     renderAgain("Running job")
            //     HelperJobs.getExportStatus().then((status) => {
            //         // console.log(status)
            //         if (status === "finished") {
            //             clearInterval(myVar);
            //             isRunning = false;
            //             renderAgain("Job finished")
            //         }
            //     });

            // }, 5000);
        } else {
            document.getElementById("floating_div_alert").style.display = "flex";
        }
    }

    const closeFloatingDiv = (id) => {
        document.getElementById(id).style.display = "none";
    }
    const renderCreateExportButton = (project) => {
        return (
            <Tooltip title="Export project">
                <div className={classes.exportButton}>

                    <svg className={classes.icon} viewBox="0 0 24 24" strokeWidth="1.5" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <ellipse cx="12" cy="6" rx="8" ry="3" />
                        <path d="M4 6v6c0 1.657 3.582 3 8 3a19.84 19.84 0 0 0 3.302 -.267m4.698 -2.733v-6" />
                        <path d="M4 12v6c0 1.599 3.335 2.905 7.538 2.995m8.462 -6.995v-2m-6 7h7m-3 -3l3 3l-3 3" />
                    </svg>
                </div>
            </Tooltip>
        )
    }
    const renderProject = (project) => {
        return (
            <div className={classes.boxtext} onClick={() => { exportStudy(project) }}>
                <div className={classes.text}>{project}</div>
                {renderCreateExportButton(project)}
            </div>
        )
    }
    const renderProjects = () => {
        // console.log(projects)
        return (<div style={{ padding: "20px" }}>
            {projects.map((project) => {
                return (renderProject(project))
            })}</div>
        )

    }
    const renderCalculatingModal = () => {
        if (isRunning) {
            return (<div className="loader"></div>)
        }
    }
    const downloadFromMinio = (minio_name) =>{
        isRunning = true;
        renderAgain("download zip")
        HelperExport.downloadZip(minio_name).then((result)=>{
            renderAgain("finished");
            isRunning= false;
        })
    }
    const renderExport = (exp) => {
        // console.log(exp)
        return (
            <div className={classes.link} onClick={()=>{downloadFromMinio(exp["minio_name"])}}>
                {exp["minio_name"]}
            </div>
        )
    }
    const renderListOfExports = () => {
        // console.log(exports)
        return (
            <div style={{ paddingTop: "20px" }}>
                {exports.map((exp) => { return (renderExport(exp)) }
                )}
            </div>
        )
    }
    // console.log(commentsState)
    return (
        <div className={classes.page}>
            <div className={classes.floating_div} id="floating_div">
                <div className={classes.floating_text}>
                    <span style={{fontSize:"25px",fontWeight:"bold",marginBottom:"20px"}}>Export in progress!</span><br/>You will receive an e-mail in {user} when it is ready to download
                    <div className={classes.floating_close} onClick={() => { closeFloatingDiv("floating_div") }}>
                        X
                    </div>
                </div>
            </div>
            <div className={classes.floating_div} id="floating_div_alert">
                <div className={classes.floating_text}>
                    <span style={{fontSize:"25px",fontWeight:"bold",marginBottom:"20px"}}>Incorrect editor</span><br/>Please write a user e-mail to export his/her analysis or leave the field empty to export all users' analysis
                    <div className={classes.floating_close} onClick={() => { closeFloatingDiv("floating_div_alert") }}>
                        X
                    </div>
                </div>
            </div>
            <div className={classes.icon_div} onClick={() => { goToStudies() }}> 
                <div>
                    <svg className={classes.icon} viewBox="0 0 24 24" fill="none" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" />
                        <line x1="5" y1="12" x2="19" y2="12" />
                        <line x1="5" y1="12" x2="11" y2="18" />
                        <line x1="5" y1="12" x2="11" y2="6" />
                    </svg>
                </div>
                <div className={classes.backText}>Back to user page</div>
            </div>
            <div className={classes.container}>
                <div className={classes.container_sub}>
                    <div style={{display:"flex", marginBottom: "50px"}}>
                        <div style={{width:"100%"}}>
                            <div>
                                <span style={{fontSize:"18px"}}>Export data from a single editor:</span>
                                <input id="editor_input" type="text" style={{width:"300px",margin:"10px auto 20px"}} placeholder="Leave empty to export all editors" />
                            </div>
                            <div>
                                <span style={{fontSize:"18px"}}>Export a single image type:</span>
                                <input id="imtype_input" type="text" style={{width:"300px",margin:"10px auto 20px"}} placeholder="Leave empty to export all image types" />
                            </div>
                            <div>
                                <span style={{fontSize:"18px"}}>Type of data:</span>
                                <div style={{fontSize:"16px",fontWeight:"normal",lineHeight:"17px",width:"220px",margin:"0 auto"}}>
                                    <div style={{display: "flex",gridGap:"10px",marginTop:"15px"}}>
                                        <input
                                            type="radio"
                                            value="analysis"
                                            name="data"
                                            checked={dataType==="analysis"}
                                            onChange={(e) => setDataType("analysis")} />
                                        <span>Export original analyses</span>
                                    </div>
                                    <div style={{display: "flex",gridGap:"10px",marginTop:"10px"}}>
                                        <input
                                            type="radio"
                                            value="reviews"
                                            name="data"
                                            checked={dataType==="reviews"}
                                            onChange={(e) => setDataType("reviews")} />
                                        <span>Export reviews</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{width: "100%"}}>
                            <span style={{fontSize:"18px"}}>Type of export:</span>
                            <div style={{fontSize:"16px",fontWeight:"normal",lineHeight:"17px",width:"220px",margin:"0 auto 10px"}}>
                                <div style={{display: "flex",gridGap:"10px",marginTop:"15px",marginLeft:"10px"}}>
                                    <input
                                        type="radio"
                                        value="segmentations"
                                        name="export"
                                        checked={exportType==="segmentations"}
                                        onChange={(e) => setExportType("segmentations")} />
                                    <span>Export segmentations</span>
                                </div>
                                <div style={{display: "flex",gridGap:"10px",marginTop:"10px",marginLeft:"10px"}}>
                                    <input
                                        type="radio"
                                        value="measurements"
                                        name="export"
                                        checked={exportType==="measurements"}
                                        onChange={(e) => setExportType("measurements")} />
                                    <span>Export measurements</span>
                                </div>
                            </div>
                            <span style={{fontSize:"18px"}}>Options:</span>
                            <div id="options_segmentations" style={exportType!=="segmentations"? { display: "none" } : {}}>
                                <div style={{fontSize:"16px",fontWeight:"normal",lineHeight:"17px",width:"310px",margin:"0 auto 10px"}}>
                                    <div style={{display: "flex", margin: "15px 0 0 20px"}}>
                                        <input
                                            id="editor_mode_input"
                                            type="checkbox"
                                            checked={editorMode}
                                            onChange={(e) => setEditorMode(e.target.checked)} />
                                        <span style={{fontSize:"16px",fontWeight:"normal",marginLeft:"5px"}}>Include only the most recent analysis</span>
                                    </div>
                                    <div style={{display: "flex", margin: "15px 0 0 20px"}}>
                                        <input
                                            id="only_study_type"
                                            type="checkbox"
                                            checked={studyType}
                                            onChange={(e) => setStudyType(e.target.checked)} />
                                        <span style={{fontSize:"16px",fontWeight:"normal",marginLeft:"5px"}}>Include only studies with "study type"</span>
                                    </div>
                                    <div style={{display: "flex", margin: "15px 0 0 20px"}}>
                                        <input
                                            id="images_input"
                                            type="checkbox"
                                            checked={images}
                                            onChange={(e) => setImages(e.target.checked)} />
                                        <span style={{fontSize:"16px",fontWeight:"normal",marginLeft:"5px"}}>Include images</span>
                                    </div>
                                    <div style={{display: "flex", margin: "15px 0 0 20px"}}>
                                        <input
                                            id="single_file_input"
                                            type="checkbox"
                                            checked={singleFile}
                                            onChange={(e) => setSingleFile(e.target.checked)} />
                                        <span style={{fontSize:"16px",fontWeight:"normal",marginLeft:"5px"}}>Include single file export</span>
                                    </div>
                                    <div style={{display: "flex", margin: "15px 0 0 20px"}}>
                                        <input
                                            id="all_files_input"
                                            type="checkbox"
                                            checked={allFiles}
                                            onChange={(e) => setAllFiles(e.target.checked)} />
                                        <span style={{fontSize:"16px",fontWeight:"normal",marginLeft:"5px"}}>Include export files for each study</span>
                                    </div>
                                </div>
                            </div>
                            <div id="options_measurements" style={exportType!=="measurements"? { display: "none" } : {}}>
                                <div style={{fontSize:"16px",fontWeight:"normal",lineHeight:"17px",width:"310px",margin:"0 auto 10px"}}>
                                    <div style={{display: "flex", margin: "15px 0 0 20px"}}>
                                        <input
                                            id="editor_mode_input"
                                            type="checkbox"
                                            checked={editorMode}
                                            onChange={(e) => setEditorMode(e.target.checked)} />
                                        <span style={{fontSize:"16px",fontWeight:"normal",marginLeft:"5px"}}>Include only the most recent analysis</span>
                                    </div>
                                    <div style={{display: "flex", margin: "15px 0 0 20px"}}>
                                        <input
                                            id="normal_meas_input"
                                            type="checkbox"
                                            checked={normalMeas}
                                            onChange={(e) => setNormalMeas(e.target.checked)} />
                                        <span style={{fontSize:"16px",fontWeight:"normal",marginLeft:"5px"}}>Include normal measurements</span>
                                    </div>
                                    <div style={{display: "flex", margin: "15px 0 0 20px"}}>
                                        <input
                                            id="caliper_meas_input"
                                            type="checkbox"
                                            checked={caliperMeas}
                                            onChange={(e) => setCaliperMeas(e.target.checked)} />
                                        <span style={{fontSize:"16px",fontWeight:"normal",marginLeft:"5px"}}>Include caliper measurements</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    Export project
                    {renderProjects()}
                </div>
                {/* <div className={classes.container_sub}>
                    Previous exports
                    <div className={classes.description}>{renderListOfExports()}</div>
                    {renderCalculatingModal()}
                </div> */}
            </div>
        </div>
    )
}