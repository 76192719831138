import React from 'react';

export const BModeColorIcon = ({
        className,
        style,
        width,
        height
    }) => {
    
    return (
        <svg className={className} width={width} height={height} style={style} viewBox="0 0 50 50" fill="none">
            <path d="M46.68 1H3.32C2.0387 1 1 2.0387 1 3.32V46.68C1 47.9613 2.0387 49 3.32 49H46.68C47.9613 49 49 47.9613 49 46.68V3.32C49 2.0387 47.9613 1 46.68 1Z" stroke="#666666" strokeWidth="2" strokeMiterlimit="10" />
            <path d="M25 6.39L6.25999 38.84C6.25999 38.84 16 43.61 25 43.61C31.5231 43.4293 37.9243 41.8 43.74 38.84L25 6.39Z" stroke="#808080" strokeWidth="2" strokeMiterlimit="10" />
            <path d="M33 35C30.3621 35.5328 27.6879 35.8671 25 36C22.3121 35.8671 19.6379 35.5328 17 35L22.5 22C23.2492 22.5139 24.103 22.8554 25 23C25.897 22.8554 26.7508 22.5139 27.5 22L33 35Z" fill="url(#paint0_linear)" stroke="#808080" strokeWidth="2" strokeMiterlimit="10" />
            <defs>
                <linearGradient id="paint0_linear" x1="17" y1="29" x2="33" y2="29" gradientUnits="userSpaceOnUse">
                    <stop offset="0.11" stopColor="#FF0000" />
                    <stop offset="0.31" stopColor="#BF0D35" />
                    <stop offset="0.56" stopColor="#781B6F" />
                    <stop offset="0.76" stopColor="#44259A" />
                    <stop offset="0.92" stopColor="#242CB5" />
                    <stop offset="1" stopColor="#182EBF" />
                </linearGradient>
            </defs>
        </svg>
    );
}