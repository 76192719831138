import React from 'react';

import { CheckIcon } from './general_icons/check_icon';
import { ExportIcon } from './general_icons/export_icon';
import { OnsetQRSIcon } from './general_icons/onset_qrs_icon';
import { OnsetRWaveIcon } from './general_icons/onset_rwave_icon';
import { OnsetFlowIcon } from './general_icons/onset_flow_icon';
import { PreviousIcon } from './general_icons/previous_icon';
import { NextIcon} from './general_icons/next_icon';
import { EyeIcon } from './general_icons/eye_icon';
import { ThumbsUpIcon } from './general_icons/thumbsup_icon';
import { ThumbsUpFilledIcon } from './general_icons/thumbsupfilled_icon';

export const GeneralIcons = ({
        type,
        className,
        style,
        width,
        height,
        color
    }) => {
    
    let icon;
    switch (type) {
        case "previous":
            icon = <PreviousIcon className={className} color={color} style={style} width={width} height={height} />
            break;
        case "next":
            icon = <NextIcon className={className} color={color} style={style} width={width} height={height} />
            break;
        case "eye":
            icon = <EyeIcon className={className} color={color} style={style} width={width} height={height} />
            break;
        case "thumbsup":
            icon = <ThumbsUpIcon className={className} color={color} style={style} width={width} height={height} />
            break;
        case "thumbsup-filled":
            icon = <ThumbsUpFilledIcon className={className} color={color} style={style} width={width} height={height} />
            break;
        case "check":
            icon = <CheckIcon className={className} style={style} width={width} height={height} />
            break;
        case "export":
            icon = <ExportIcon className={className} style={style} width={width} height={height} />
            break;
        case "onset_QRS":
            icon = <OnsetQRSIcon className={className} style={style} width={width} height={height} />
            break;
        case "onset_R-wave":
            icon = <OnsetRWaveIcon className={className} style={style} width={width} height={height} />
            break;
        case "onset_Flow":
            icon = <OnsetFlowIcon className={className} style={style} width={width} height={height} />
            break;
        default:
            icon = null;
            break;
    }

    return icon;
}