import React, { useState } from 'react';

// Helpers
import { HelperConfig } from '../../../helpers/helper_config/helper_config';
import { HelperUser } from './../../../helpers/helper_user/helper_user';
import { HelperStudies } from './../../../helpers/helper_studies/helper_studies';

// AUX functions
import classNames from 'classnames';
import { findWhere } from 'underscore';

export const ScoreComponent = ({
        image,
        image_analysis,
        user_type
    }) => {

    const [score, setScore] = useState(image? (image.score? image.score : (image_analysis?.type? HelperConfig.getImageScoreTemplate(image_analysis.type) : false)) : false);
    const [done, setDone] = useState(image? (image.score? true : false) : false);
    const [changes, setChanges] = useState(false);

    const sendEvaluation = () => {
        if (!done || changes) {
            image.score = score;
            image_analysis.score = score;
            HelperStudies.saveQCform(image_analysis);
            setDone(true);
            setChanges(false);
        }
    }

    const updateValue = (section, item, value) => {
        if (user_type !== "Viewer" && image_analysis) {
            const score_copy = JSON.parse(JSON.stringify(score));
            const item_obj = findWhere(score_copy[section], { name: item });
            item_obj.value = value;
            setScore(score_copy);
            setChanges(true);    
        }
    }

    const renderItemOptions = (section, item) => {
        return item.options.map((option,i) => {
            const active = option === item.value;
            return (
                <div className={classNames({"selected":active},{"bad":i%2},"item-option")}
                    onClick={() => { if(!active) { updateValue(section, item.name, option); } }}
                >
                    {option}
                </div>
            );
        });
    }

    const renderSectionItems = (section, items) => {
        return items.map((item,i) => {
            return (
                <div className={classNames({"odd":i%2},"item")}>
                    <div className="item-name">{item.name}</div>
                    {renderItemOptions(section, item)}
                </div>
            );
        });
    }

    const renderSections = () => {
        return Object.keys(score).map((section) => {
            return (
                <>
                    <div className="section">{section}</div>
                    {renderSectionItems(section, score[section])}
                </>
            );
        });
    }

    const renderContent = () => {
        if (user_type === "Viewer") {
            return done? <div>{renderSections()}</div> : "This image has not been evaluated yet.";
        } else {
            if (image_analysis?.type && image_analysis.reference) {
                if (score) {
                    const text = done? "This image has already been evaluated" : "This image has not been evaluated yet";
                    const button = done? (changes? "Save changes" : "No changes") : "Send evaluation";
                    if (user_type === "Viewer") {
                        return (
                            <div>
                                {renderSections()}
                            </div>
                        );    
                    } else {
                        return (
                            <>
                                <div className={classNames({"yes":done},{"no":!done},"notice")}>{text}</div>
                                <div>
                                    {renderSections()}
                                </div>
                                <div className={classNames({"disabled":done&&!changes},"submit-button")} onClick={() => sendEvaluation()}>{button}</div>
                            </>
                        );    
                    }
                } else {
                    return "Sorry, no quality assessment template exists for this image type yet.";
                }
            } else {
                const text = done? "Please, label and reference this image first to update the evaluation" : "Please, label and reference this image first to assess its quality.";
                return done? <><div className="no notice">{text}</div><div>{renderSections()}</div></> : text;
            }
        }
    }

    return (
        <div className="score component">
            <div className="title">Image quality assessment panel</div>
            <div className="evaluation-box">
                {renderContent()}
            </div>
        </div>
    );
}