import { HelperImages } from "../../helpers/helper_images/helper_images";
import { FileModule } from "../../modules/file_module";
import { dumpDataSet } from "./dumpDataset";
export class DICOM_loader {
    static fromBlob(blob, filename) {
        return new Promise((resolve, reject) => {
            // isDicom
            this.isDicomFile(blob, (isDicom, parsed_dicom) => {
                if (isDicom) {
                    // loadDicom
                    HelperImages.load(blob).then(image => {
                        if (image) {
                            // getMetadata
                            let full_metadata = dumpDataSet(parsed_dicom);
                            let metadata = HelperImages.getImageMetadata(image);
                            let image_dict = {
                                file: blob,
                                metadata,
                                full_metadata,
                                cornerstone_imageId: image.imageId,
                                instance_id: metadata.instance_id
                            }
                            resolve(image_dict);
                        } else {
                            resolve(false);
                        }
                    });
                }
            });
        });
    }
    static isDicomFile(blob, callback) {
        try {
            FileModule.read(blob, function (dicomFileAsBuffer) {
                try {
                    const parsed_dicom = HelperImages.parseDicom(dicomFileAsBuffer);
                    callback(true, parsed_dicom);
                } catch (exceptionParseDicom) {
                    callback(false);
                }
            });
        } catch (exceptionFileReader) {
            callback(false);
        }
    }
}
