export class HelperStudiesLocalStorage{
    static setStudyId(study_id){
        if (study_id) {
            localStorage.setItem("study_id", study_id);
        }
    }
    static setPatientId(patient_id){
        if (patient_id) {
            localStorage.setItem("patient_id", patient_id);
        }
    }
    static setDataType(data_type){
        if (data_type) {
            localStorage.setItem("data_type", data_type);
        }
    }
}