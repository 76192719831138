import React from 'react';

export const RemoveCCIcon = ({
        className,
        style,
        width,
        height
    }) => {
    
    return (
        <svg className={className} width={width} height={height} style={style} viewBox="0 0 42.51 52.47">
            <path d="M38,28.15V10h2V6H28V4a4.56,4.56,0,0,0-1.1-2.9A3.91,3.91,0,0,0,24,0H16a4.56,4.56,0,0,0-2.9,1.1A3.91,3.91,0,0,0,12,4V6H0v4H2V42a6,6,0,0,0,6,6H18.24A14,14,0,1,0,38,28.15ZM16,4h8V6H16ZM8,44a2,2,0,0,1-2-2V10H34V25.56a13.85,13.85,0,0,0-5.51-1.13A14,14,0,0,0,15.62,44Zm20.43,5.53a11,11,0,1,1,11-11A11,11,0,0,1,28.43,49.53Z"/>
            <path d="M24.1,35.6a2.38,2.38,0,0,1,1.2.3,1.79,1.79,0,0,1,.9,1,1.54,1.54,0,0,0,.4.6.76.76,0,0,0,.5.2c.3,0,.4,0,.6-.2a1.42,1.42,0,0,0,.2-.6,2.35,2.35,0,0,0-.5-1.3A5.26,5.26,0,0,0,26,34.5a4.23,4.23,0,0,0-2-.5,4.67,4.67,0,0,0-1.7.3,7.1,7.1,0,0,0-1.4,1,3.77,3.77,0,0,0-.9,1.5,6.45,6.45,0,0,0-.3,2,5.28,5.28,0,0,0,.1,1.3,4.23,4.23,0,0,0,.4,1.2,3.33,3.33,0,0,0,.6,1,6.47,6.47,0,0,0,.9.8,3.58,3.58,0,0,0,1.1.4,5.28,5.28,0,0,0,1.3.1,4.67,4.67,0,0,0,1.7-.3,3.6,3.6,0,0,0,1.2-.8,3,3,0,0,0,.7-1,4.4,4.4,0,0,0,.2-1c0-.3,0-.5-.2-.4a1.42,1.42,0,0,0-.6-.2c-.3,0-.5,0-.6.2l-.3.6a2,2,0,0,1-.8,1.2,2.3,2.3,0,0,1-1.4.4,2.84,2.84,0,0,1-1.3-.4,2.44,2.44,0,0,1-.9-1.1,4.84,4.84,0,0,1-.3-1.8,3.73,3.73,0,0,1,.7-2.5A2.32,2.32,0,0,1,24.1,35.6Z"/>
            <path d="M37.4,40.1a1.42,1.42,0,0,0-.6-.2c-.3,0-.5,0-.6.2l-.3.6a2,2,0,0,1-.8,1.2,2.3,2.3,0,0,1-1.4.4,2.84,2.84,0,0,1-1.3-.4,2.44,2.44,0,0,1-.9-1.1,4.84,4.84,0,0,1-.3-1.8,3.73,3.73,0,0,1,.7-2.5,2.32,2.32,0,0,1,1.9-.9,2.38,2.38,0,0,1,1.2.3,1.79,1.79,0,0,1,.9,1,1.54,1.54,0,0,0,.4.6.76.76,0,0,0,.5.2c.3,0,.4,0,.6-.2a1.42,1.42,0,0,0,.2-.6,2.35,2.35,0,0,0-.5-1.3,5.26,5.26,0,0,0-1.4-1.1,4.23,4.23,0,0,0-2-.5,4.67,4.67,0,0,0-1.7.3,7.1,7.1,0,0,0-1.4,1,3.77,3.77,0,0,0-.9,1.5,6.45,6.45,0,0,0-.3,2,5.28,5.28,0,0,0,.1,1.3,4.23,4.23,0,0,0,.4,1.2,3.33,3.33,0,0,0,.6,1,6.47,6.47,0,0,0,.9.8,3.58,3.58,0,0,0,1.1.4,5.28,5.28,0,0,0,1.3.1,4.67,4.67,0,0,0,1.7-.3,3.6,3.6,0,0,0,1.2-.8,3,3,0,0,0,.7-1,4.4,4.4,0,0,0,.2-1C37.6,40.2,37.5,40,37.4,40.1Z"/>
        </svg>
    );
}