import React from 'react';

export const MModeIcon = ({
        className,
        style,
        width,
        height
    }) => {
    
    return (
        <svg className={className} width={width} height={height} style={style} viewBox="0 0 50 50" fill="none">
            <path d="M46.68 1H3.32C2.0387 1 1 2.0387 1 3.32V46.68C1 47.9613 2.0387 49 3.32 49H46.68C47.9613 49 49 47.9613 49 46.68V3.32C49 2.0387 47.9613 1 46.68 1Z" stroke="#ED4C79" strokeWidth="2" strokeMiterlimit="10" />
            <path d="M7.41 23.05L13.62 20.1C15.49 19.27 20.18 19.31 21.84 20.17L26.1 22.38C27.76 23.24 32.45 23.28 34.32 22.45L39.61 20.1C40.5721 19.7472 41.5856 19.5547 42.61 19.53" stroke="#ED4C79" strokeMiterlimit="10" />
            <path d="M43 30.45L36.81 27.5C34.94 26.66 30.25 26.7 28.59 27.56L24.32 29.78C22.66 30.64 17.98 30.68 16.1 29.84L10.82 27.5C9.59411 27.0679 8.29932 26.8645 7 26.9" stroke="#ED4C79" strokeMiterlimit="10" />
            <path d="M6.5 11H43.5" stroke="#ED4C79" strokeWidth="2" strokeMiterlimit="10" />
            <path d="M6.5 39H43.5" stroke="#ED4C79" strokeWidth="2" strokeMiterlimit="10" />
            <path d="M6.5 32.5H43.5" stroke="#ED4C79" strokeMiterlimit="10" />
            <path d="M43 17.79L36.79 17.55C34.92 17.48 30.23 17.48 28.57 17.55L24.3 17.73C22.64 17.8 17.96 17.81 16.08 17.73L10.8 17.54C9.93001 17.54 8.46001 17.49 6.96001 17.49" stroke="#ED4C79" strokeMiterlimit="10" />
        </svg>
    );
}