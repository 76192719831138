// Helpers
import { HelperSegmentationBMode } from './helper_segmentation_bmode';
import { HelperSegmentationMMode } from './helper_segmentation_mmode';
import { HelperSegmentationDoppler } from './helper_segmentation_doppler';
import { HelperConfig } from './../helper_config/helper_config';
import { HelperStudies } from './../helper_studies/helper_studies';
import { HelperImages } from './../helper_images/helper_images';

import Store from './../../models/store';

import { drawVerticalLine, drawArc, drawLine, drawSegment, drawPoint, drawLabel, drawPlusSign, getRandomColor } from './../../modules/draw_module';
import { mouseIsOverCurve, getNewPointPosition, getNextEvent, getSplineRealValues } from './../../modules/spline_module';
import { predictLastControlPoint, getNextCyclesPrediction } from './../../modules/prediction_module';
import { anglesFromPoints, getPhysicalDeltas } from './../../modules/geometry_module';
import { euclideanDistance } from './../../modules/math_module';
import { findWhere, findIndex, range } from 'underscore';
import { Point, Line, Segment, Circle } from '@flatten-js/core';

export class HelperSegmentation {

    // INIT METHODS --------------------------

    static init(image, state) {
        if (image.dicom) {
            if (image.modality === "2D") {
                return HelperSegmentationBMode.init(image, state);
            } else if (image.modality === "M-Mode") {
                return HelperSegmentationMMode.init(image);
            } else {
                return HelperSegmentationDoppler.init(image);
            }
        } else {
            return {};
        }
    }

    static initTimeEvent(image, cycle, event, state) {
        if (["2D","M-Mode"].includes(image.modality)) {
            let new_state = {};
            if (image.segmentation.length > 0) {
                const current_event = event? image.segmentation[cycle][event] : image.segmentation[cycle];
                const shapes = HelperConfig.getShapes(image.type, "normal", false);
                new_state = {
                    current_action: "finished",
                    current_shape: shapes[0],
                    current_line: shapes[0].lines[0]
                }
                current_event.lines.some(line => {
                    if (line.finished === false) {
                        new_state.current_action = "segmenting";
                        new_state.current_shape = findWhere(shapes, { name: line.shape });
                        new_state.current_line = line.name;
                        return true;
                    }
                });
                if (state?.control_points && state.control_points.length > 0) {
                    new_state.control_points = state.control_points;
                    new_state.current_cp = state.control_points[0];
                } else {
                    new_state.control_points = false;
                    new_state.current_cp = false;
                }
                if (image.modality === "2D") {
                    const config = HelperConfig.getToolConfig("2D","normal");
                    new_state.thickness = config.default_epi_thickness;
                }
            }
            new_state.current_cycle = cycle;
            new_state.current_event = event;
            if (state?.current_action === "caliper") { new_state.current_action = "caliper"; }
            return new_state;
        } else {
            return {};
        }
    }

    // TOOLS METHODS --------------------------

    static clickOnCaliper(image, state, type) {
        let caliper = state.caliper_tool;
        const pixel_mouse = state.mouse_allowed? state.mouse_allowed.pixel : state.mouse.pixel;
        if (type === "left" && caliper.current_action === "measuring") {
            const real_mouse = getSplineRealValues([pixel_mouse], false, image, "B-mode Y")[0];
            const n = caliper.points.length;
            if (n === 0 || n === caliper.template.points.length) {
                caliper.points = [{ real: real_mouse, pixel: pixel_mouse }];
                caliper.dists = [];
                caliper.angles = [];
            } else {
                caliper.points.push({ real: real_mouse, pixel: pixel_mouse });
                if (caliper.template.angles) {
                    if (caliper.points.length === caliper.template.points.length) {
                        const angles = anglesFromPoints(caliper.points.map(p => p.pixel));
                        caliper.angles = caliper.template.angles.map((a,i) => a? angles[i] : false);
                    }
                } else {
                    const new_dist = caliper.template.dists[caliper.dists.length]? euclideanDistance(caliper.points[n-1].real, caliper.points[n].real) : false;
                    caliper.dists.push(new_dist);    
                }
            }
            return { caliper_tool: caliper };
        } else if (type === "right" && image.measurements_caliper?.length > 0) {
            const measurements = image.measurements_caliper.filter(m => m.cycle === state.current_cycle && m.event === state.current_event);
            measurements.some(m => {
                m.points.some(p => {
                    if (euclideanDistance(pixel_mouse, p.pixel) < 5) {
                        this.removeCaliperMeasure(image, caliper, m, state.caliper_select);
                        return true;
                    }    
                });
            });
        }
        return {};
    }

    static removeCaliperMeasure(image, caliper_state, measure, callback) {
        let ind = image.measurements_caliper.indexOf(measure);
        image.measurements_caliper.splice(ind, 1);
        HelperStudies.saveStudy(true);
        if ([measure.type, undefined].includes(caliper_state.type)) {
            const template = findWhere(caliper_state.measures, { type: measure.type });
            callback(image, template, caliper_state.measures);
        }
    }
    
    static saveCaliperMeasure(image, caliper_state, cycle, event, callback) {
        if (caliper_state.points.length === caliper_state.template.points.length) {
            let measure = {
                type: caliper_state.type,
                cycle: cycle,
                event: event,
                points: caliper_state.points,
                points_name: caliper_state.template.points
            };
            if (caliper_state.template.angles) {
                measure.angles = caliper_state.angles;
                measure.angles_name = caliper_state.template.angles;
            } else {
                measure.dists = caliper_state.dists;
                measure.dists_name = caliper_state.template.dists;
            }
            if (image.measurements_caliper) {
                image.measurements_caliper.push(measure);
            } else {
                image.measurements_caliper = [measure];
            }
            HelperStudies.saveStudy(true);
            let next_measure = false;
            let current_idx = findIndex(caliper_state.measures, { type: measure.type });
            let meas_order = range(current_idx,caliper_state.measures.length).concat(range(current_idx));
            meas_order.some(idx => {
                const measures_event = image.measurements_caliper.filter(m => m.cycle === cycle && m.event === event);
                const done = findWhere(measures_event, { type: caliper_state.measures[idx].type });
                if (!done) {
                    next_measure = caliper_state.measures[idx];
                    return true;
                }
            });
            if (next_measure) {
                callback(image, next_measure, caliper_state.measures);
            } else {
                return { points: [], dists: [], angles: [], current_action: "finished", measures: caliper_state.measures };
            }
        }
    }

    // SEGMENTATION METHODS --------------------------

    static getSegmentationLines(image, cycle, event) {
        if (image.segmentation?.length > 0) {
            if (image.modality === "2D") {
                return image.cardiac_events.length === 0? image.segmentation[0].lines : event? image.segmentation[cycle][event].lines : [];
            } else if (image.modality === "M-Mode") {
                return image.segmentation[0].lines;
            } else {
                const lines = [];
                for (const cycle of image.segmentation) {
                    lines.push(...cycle.lines);
                }
                return lines;
            }
        } else {
            return [];
        }
    }

    static getCurrentLine(image, state) {
        if (image.segmentation?.length > 0) {
            if (["2D","M-Mode"].includes(image.modality)) {
                const lines = this.getSegmentationLines(image, state.current_cycle, state.current_event);
                return findWhere(lines, { name: state.current_line });
            } else {
                return image.segmentation[state.current_cycle]? image.segmentation[state.current_cycle].lines[0] : false;
            }
        } else {
            return [];
        }
    }

    static setControlPoint(image, state) {
        const line = this.getCurrentLine(image, state).points;
        const where = state.mouse_allowed? state.mouse_allowed.pixel : state.mouse.pixel;
        let new_state = {};
        if (state.current_cp) {
            let point = JSON.parse(JSON.stringify(state.current_cp));
            point.x = Math.round(where.x);
            point.y = Math.round(where.y);
            if (state.control_points.length === line.length) {
                line[point.id] = point
                let finished = HelperSegmentationDoppler.checkIfAllFinished(image.segmentation);
                new_state = finished === true? { current_cp: "finished" } : { current_cp: state.control_points[finished.cp], current_cycle: finished.cc };
            } else {
                new_state.current_cp = point.id < state.control_points.length-1? state.control_points[point.id + 1] : "finished";
                line.push(point); 
            }
            if (image.modality.includes("Doppler")) {
                // Check if the current control point is the last one and can be predicted
                const total_cycles = image.cardiac_events.length;
                const known_point = new_state.current_cp.on_onset && new_state.current_cp.forced_to_zero;
                if (known_point) {
                    state.mouse_allowed = { pixel: { x: image.cardiac_events[state.current_cycle].onset_end, y: line[0].y }};
                    state.current_cp = new_state.current_cp;
                    return this.setControlPoint(image, state);
                } else if (total_cycles > 1 && state.current_cycle !== total_cycles-1 && new_state.current_cp.id === state.control_points[state.control_points.length-1].id && new_state.current_cp.type === line[0].type) {
                    state.mouse_allowed = predictLastControlPoint(image, line);
                    state.current_cp = new_state.current_cp;
                    return this.setControlPoint(image, state);
                }
            }
        } else {
            let point = HelperConfig.getSegmentationTemplate("general","control_point");
            point.x = Math.round(where.x);
            point.y = Math.round(where.y);
            point.id = line.length;
            point.type = "user-defined_" + line.length;
            line.push(point);
            if (image.modality === "2D") {
                HelperSegmentationBMode.checkPositionPointsOfInterest(line, state.current_shape.points_of_interest);
            } else if (image.modality === "M-Mode") {
                const events_of_interest = ["end_diastole_start","end_diastole_end","end_systole"];
                const next_event = getNextEvent(image.cardiac_events, point.x, events_of_interest);
                if (!next_event.type) {
                    new_state.current_cp = "finished";
                }
            }
        }
        return new_state;
    }

    static changeShape(image, state, shape, instruction) {
        if (["2D","M-Mode"].includes(image.modality)) {
            const shapes = HelperConfig.getShapes(image.type, "normal", false);
            let index = findIndex(shapes, { name: shape });
            if (instruction === "previous" && index > 0) {
                index--;
            } else if (instruction === "next" && index < shapes.length-1) {
                index++;
            }
            const new_state = { current_shape: shapes[index], current_line: shapes[index].lines[0] };
            const lines = this.getSegmentationLines(image, state.current_cycle, state.current_event);
            const line = findWhere(lines, { name: new_state.current_line });
            if (!line.finished) {
                new_state.current_action = "segmenting";
            }
            return new_state;
        }
    }

    static changePattern(image, cycle, pattern) {
        const lines = this.getSegmentationLines(image);
        const new_state = {};
        if (lines[cycle].pattern !== pattern.type) {
            for (let i=cycle; i<image.cardiac_events.length; i++) {
                lines[i].points = [];
                lines[i].pattern = pattern.type;
                lines[i].finished = false;
            }
            new_state.current_action = "segmenting";
            new_state.control_points = HelperConfig.getControlPoints(image.type, pattern.type);
            new_state.current_cp = new_state.control_points[0];
            if (cycle > 0) {
                const lp_prev = lines[cycle-1].points[lines[cycle-1].points.length-1];
                if (new_state.current_cp.type === lp_prev.type) {
                    new_state.mouse_allowed = { pixel: { x: lp_prev.x, y: lp_prev.y } };
                    new_state.current_cycle = cycle;
                    const s = this.setControlPoint(image, new_state);
                    new_state.current_cp = s.current_cp;
                }
            }
        }
        return new_state;
    }
    
    static undo(image, state) {
        const line = this.getCurrentLine(image, state).points;
        line.pop();
        if (state.current_cp) {
            return {
                current_cp: state.control_points[line.length]
            }
        } else {
            return {};
        }
    }
    
    static predictFromPreviousCC(image, cycle, event) {
        if (image.modality === "2D") {
            HelperSegmentationBMode.predictFromPreviousCC(image, cycle, event);
        }
    }

    static predictEpiFromEndo(image, state, endo_name, type, thickness) {
        if (image.modality === "2D") {
            HelperSegmentationBMode.predictEpiFromEndo(image, state, endo_name, type, thickness);
        }
    }

    static finish(image, type, state, finished) {
        if (image.modality === "2D") {
            return HelperSegmentationBMode.finish(image, type, state);
        } else if (image.modality === "M-Mode") {
            return HelperSegmentationMMode.finish(image, type, state, finished);
        } else {
            return HelperSegmentationDoppler.finish(image, type, state);
        }
    }

    static checkIfAllFinished(segmentation) {
        let all_lines = [];
        for (let cycle of segmentation) {
            if (cycle.end_diastole) {
                all_lines.push(...cycle.end_diastole.lines);
                all_lines.push(...cycle.end_systole.lines);
            } else {
                all_lines.push(...cycle.lines);
            }
        }
        return !all_lines.some(line => !line.finished);
    }

    static remove(image, type, shape, state) {
        const shapes = HelperConfig.getShapes(image.type, "normal", image.modality);
        const lines = this.getSegmentationLines(image, state.current_cycle, state.current_event);
        let new_state = {};
        image.is_segmented = false;
        for (let line of lines) {
            if (line.shape === shape || type === "all") {
                line.points = [];
                line.finished = false;
            }
        }
        if (shape === state.current_shape.name || type === "all") {
            new_state.current_action = "segmenting";
            if (state.control_points?.length > 0) {
                new_state.current_cp = state.control_points[0];
            }
        }
        const lines_finished = lines.some((l) => l.finished);
        if (type === "shape" && image.modality === "2D" && shapes.length > 1 && lines_finished) {
            const POIs = findWhere(shapes, { name: shape }).points_of_interest;
            const shapePOIs = shapes.filter(s => s.name !== shape && s.points_of_interest.map(p => p.name).includes(POIs[0].name))[0];
            const linePOIs = findWhere(lines, { shape: shapePOIs.name });
            HelperSegmentationBMode.definePointsOfInterest(linePOIs.points, shapePOIs, lines, image.type);
        } else if (!lines_finished) {
            for (let line of lines) {
                line.points = [];
            }
        }
        if (image.modality.includes("Doppler")) {
            new_state.current_cycle = 0;
            HelperSegmentationDoppler.init(image, true);
        } else { //if (type === "all" || state.current_action === "finished") {
            return this.initTimeEvent(image, state.current_cycle, state.current_event, state);
        }
        return new_state;
    }

    // EDIT METHODS --------------------------
    
    static clickOnEdit(image, state, type) {
        const tool = image.modality.includes("Doppler")? "Doppler" : image.modality;
        const curve = image.modality.includes("Doppler")? "discontinuous" : "cardinal";
        let lines = this.getSegmentationLines(image, state.current_cycle, state.current_event);
        let cycle = false;
        if (state.current_action === "editing-segmentation") {
            lines = [ this.getCurrentLine(image, state) ];
            cycle = state.current_cycle;
        }
        const is_over = mouseIsOverCurve(lines, state.mouse.pixel, HelperConfig.getToolConfig(tool,"normal"), curve, cycle);
        if (type === "left") {
            if (!state.element_selected) {
                if (is_over) {
                    if (is_over.type === "spline") { // Create mid_control_point
                        if (!state.current_shape.block_mid_points && !is_over.line.includes("epi")) {
                            let points = state.current_action === "editing-segmentation"? lines[0].points : lines[is_over.line_index].points;
                            const id = Math.max(...points.map(p => p.id)) + 1;
                            const new_point = {
                                x: Math.round(state.mouse.pixel.x),
                                y: Math.round(state.mouse.pixel.y),
                                id,
                                type: "mid_control_point"
                            };
                            const pos_index = getNewPointPosition(points, state.mouse.pixel, curve);
                            points.splice(pos_index, 0, new_point);
                            const new_state = { element_selected: new_point, line_selected: is_over.line };
                            if (image.modality.includes("Doppler")) {
                                new_state.current_cycle = is_over.line_index;
                            }
                            return new_state;
                        }
                    } else { // Select control point
                        const new_state = { element_selected: is_over.point, line_selected: is_over.line };
                        if (image.modality.includes("Doppler")) {
                            new_state.current_cycle = is_over.line_index;
                        }
                        return new_state;
                    }
                }
            } else {
                return { element_selected: false, line_selected: false };
            }
        } else if (type === "right" && !state.element_selected) {
            if (is_over && (is_over.type === "mid" || is_over.point.type.includes("user-defined")) && !is_over.line.includes("epi")) {
                let points = state.current_action === "editing-segmentation"? lines[0].points : lines[is_over.line_index].points;
                const index = points.indexOf(is_over.point)
                points.splice(index,1);
                if (state.current_action === "editing-segmentation" && image.modality.includes("Doppler")) {
                    getNextCyclesPrediction(image.segmentation, image.cardiac_events, state.current_cycle);
                } else if (is_over.line.includes("endo") && findWhere(lines, { name: is_over.line.replace("endo","epi") })) {
                    this.predictEpiFromEndo(image, state, is_over.line, "scale", 1);
                }
            } 
        }
        return {};
    }

    static moveSelectedPoint(image, state) {
        if (image.modality === "2D") {
            HelperSegmentationBMode.moveSelectedPoint(image, state);
        } else if (image.modality === "M-Mode") {
            HelperSegmentationMMode.moveSelectedPoint(image, state);
        } else {
            HelperSegmentationDoppler.moveSelectedPoint(image, state);
        }
    }

    // DRAW METHODS --------------------------

    static checkMouseAllowance(image, state, element) {
        if (state.loaded && (["segmenting","caliper"].includes(state.current_action) || state.element_selected)) {
            if (image.modality === "2D") {
                return HelperSegmentationBMode.checkMouseAllowance(image, state, element);
            } else if (image.modality === "M-Mode") {
                return HelperSegmentationMMode.checkMouseAllowance(image, state, element);
            } else {
                return HelperSegmentationDoppler.checkMouseAllowance(image, state, element);
            }
        } else {
            return { mouse_allowed: false };
        }
    }

    static drawMouse(image, state, context, mouse_allowed, screen_size, element) {
        let lines = this.getSegmentationLines(image, state.current_cycle, state.current_event);
        let tool_config;
        let curve;
        if (image.modality === "2D") {
            tool_config = HelperConfig.getToolConfig("2D","normal");
            curve = "cardinal";
        } else if (image.modality === "M-Mode") {
            tool_config = HelperConfig.getToolConfig("M-Mode","normal");
            curve = "cardinal";
        } else {
            tool_config = HelperConfig.getToolConfig("Doppler","normal");
            curve = "discontinuous";
        }
        let style = HelperConfig.getStyle("segmentation","cursor");
        let draw = false;
        if (state.current_action === "segmenting") {
            draw = true;
        } else if (state.current_action === "caliper") {
            style = HelperConfig.getStyle("caliper","cursor");
            draw = state.caliper_tool.current_action === "measuring" && state.show_caliper_options.cursors? true : false;
        } else if (state.current_action.includes("editing")) {
            if (state.current_action === "editing-segmentation") {
                lines = [ this.getCurrentLine(image, state) ];
            }
            const is_over = mouseIsOverCurve(lines, state.mouse.pixel, tool_config, curve);
            if (is_over) {
                draw = true;
                if (["cp","mid"].includes(is_over.type)) {
                    style.color = HelperConfig.getControlPointColor(is_over.point.type);
                    if (!style.color) {
                        const colors = HelperConfig.getStyle("segmentation", "line_colors");
                        const shape_lines = HelperConfig.getLines(image.type, "normal", image.modality);
                        style.color = colors[shape_lines.indexOf(is_over.line)];
                    }
                } else if (!state.current_shape.block_mid_points) {
                    style = HelperConfig.getStyle("segmentation","cursor_mid");
                } else {
                    draw = false;
                }
            }
        }
        if (draw) {
            const mouse = mouse_allowed? mouse_allowed.canvas : state.mouse.canvas;
            if (state.cursor_guide === "time") {
                const line = HelperConfig.getStyle("segmentation","cursor_time_guide");
                line.x = mouse.x;
                drawVerticalLine(line, screen_size.height, context);
                context.closePath();
                context.beginPath();
            }
            if (state.caliper_tool?.template?.points[state.caliper_tool.points.length] === "line slope") {
                style = HelperConfig.getStyle("segmentation","cursor_time_guide");
                const prev_point = state.caliper_tool.points.at(-1);
                drawLine(HelperImages.pixelToCanvas(element, prev_point.pixel), mouse, screen_size, style, context);
            } else {
                drawPlusSign(mouse, style, context);
            }
        }
    }

    static drawOnsets(image, height, context, element, state) {
        if (image.modality !== "2D" && image.cardiac_events?.length > 0) {
            image.cardiac_events.map((event,i) => {
                let line = HelperConfig.getStyle("segmentation","onset");
                if (image.modality.includes("Doppler") && ["segmenting","editing-segmentation"].includes(state.current_action) && state.current_cycle === i) {
                    line = HelperConfig.getStyle("segmentation","onset_current");
                }
                if (i === 0) {
                    if (event.onset_start) {
                        line.x = HelperImages.pixelToCanvas(element,{ x: event.onset_start, y: 0 }).x;
                        drawVerticalLine(line, height, context);
                    }
                    if (event.end_diastole_start) {
                        line = HelperConfig.getStyle("segmentation","end_diastole");
                        line.x = HelperImages.pixelToCanvas(element,{ x: event.end_diastole_start, y: 0 }).x;
                        drawVerticalLine(line, height, context);
                    }
                }
                if (event.onset_end) {
                    line = HelperConfig.getStyle("segmentation","onset");
                    if (image.modality.includes("Doppler") && ["segmenting","editing-segmentation"].includes(state.current_action) && (state.current_cycle-1 === i || state.current_cycle === i)) {
                        line = HelperConfig.getStyle("segmentation","onset_current");
                    }
                    line.x = HelperImages.pixelToCanvas(element,{ x: event.onset_end, y: 0 }).x;
                    drawVerticalLine(line, height, context);
                }
                if (event.end_diastole_end) {
                    line = HelperConfig.getStyle("segmentation","end_diastole");
                    line.x = HelperImages.pixelToCanvas(element,{ x: event.end_diastole_end, y: 0 }).x;
                    drawVerticalLine(line, height, context);
                }
                if (event.end_systole) {
                    line = HelperConfig.getStyle("segmentation","end_systole");
                    line.x = HelperImages.pixelToCanvas(element,{ x: event.end_systole, y: 0 }).x;
                    drawVerticalLine(line, height, context);
                }
            });
        }
    }

    static drawSegmentation(image, context, state, element) {
        if (state.current_action !== "caliper") {
            // Draw lines
            const lines = this.getSegmentationLines(image, state.current_cycle ? state.current_cycle : 0, state.current_event);
            if (image.modality === "2D") {
                let lines_to_plot = lines ? lines : this.getSegmentationLines(image, 0, "end_diastole")
                HelperSegmentationBMode.drawSplines(image.type, state.mouse.canvas, state.current_action === "finished", lines_to_plot, context, element);
            } else if (image.modality === "M-Mode") {
                HelperSegmentationMMode.drawSplines(lines, context, element);
            } else {
                HelperSegmentationDoppler.drawSplines(state.current_action, lines, state.current_cycle, context, element);
            }
            // Draw points
            if (image.modality !== "2D" || state.current_action !== "finished") {
                const line_colors = HelperConfig.getStyle("segmentation", "line_colors");
                const cp_colors = HelperConfig.getStyle("segmentation", "cp_colors");
                const sAngle = 0;
                const eAngle = 2 * Math.PI;
                const style = HelperConfig.getStyle("segmentation","point");
                const style_mid = HelperConfig.getStyle("segmentation","point_mid");
                for (const line of lines) {
                    for (const point of line.points) {
                        let color;
                        if (point.type.includes("user-defined")) {
                            color = line_colors[findIndex(lines, { name: line.name })];
                        } else {
                            const cp_color = findWhere(cp_colors, { type: point.type });
                            color = cp_color? cp_color.color : "white";
                        }
                        const canvas = HelperImages.pixelToCanvas(element, { x: point.x, y: point.y });
                        drawPoint(canvas, sAngle, eAngle, color, point.type === "mid_control_point"? style_mid : style, context);
                        if (point.show_label_in_image) {
                            const label = point.abbreviation? point.abbreviation : HelperConfig.getAbbreviationFromControlPoint(image.type, point.type);
                            const location_above = state.zero_line? state.zero_line > point.y : true;
                            drawLabel(canvas, color, label, style, context, location_above);
                        }
                    }
                }
            }
        }
    }

    static drawMeasurements(image, context, state, element, screen_size) {
        if (state.current_action === "caliper") {
            let style_point = HelperConfig.getStyle("caliper","cursor");
            let style_line = HelperConfig.getStyle("segmentation","cursor_time_guide");
            for (const i in state.caliper_tool.points) {
                if (state.caliper_tool.template?.points[i] !== "line slope" && state.show_caliper_options.cursors) {
                    drawPlusSign(HelperImages.pixelToCanvas(element, state.caliper_tool.points[i].pixel), style_point, context);
                }
            }
            if (state.caliper_tool.points.length > 1) {
                if (state.caliper_tool.template.angles) {
                    this.drawMeasurementAngles(element, state.caliper_tool.points, state.caliper_tool.template.angles, state.show_caliper_options, style_line, style_point, screen_size, context);
                } else {
                    this.drawMeasurementLines(element, state.caliper_tool.points, state.caliper_tool.template.dists, style_line, context);
                }
            }
            if (image.measurements_caliper?.length > 0 && state.show_caliper_options.measures) {
                const measurements = image.measurements_caliper.filter(m => m.cycle === state.current_cycle && m.event === state.current_event);
                const colors = HelperConfig.getMeasurementsPreference("images_colors");
                measurements.map((measure,i) => {
                    context.closePath();
                    context.beginPath();
                    style_point.color = i < colors.length? colors[i] : getRandomColor();
                    for (const i in measure.points) {
                        if ((!measure.points_name || measure.points_name[i] !== "line slope") && !measure.type.includes("label") && state.show_caliper_options.cursors) {
                            drawPlusSign(HelperImages.pixelToCanvas(element, measure.points[i].pixel), style_point, context);
                        }
                    }
                    if (measure.angles) {
                        this.drawMeasurementAngles(element, measure.points, measure.angles_name, state.show_caliper_options, style_line, style_point, screen_size, context);
                    } else {
                        if (measure.type.includes("label")) {
                            drawLabel(HelperImages.pixelToCanvas(element, measure.points[0].pixel), style_point.color, measure.points_name[0], style_point, context, true);
                        } else if (state.show_caliper_options.labels) {
                            drawLabel(HelperImages.pixelToCanvas(element, measure.points[0].pixel), style_point.color, measure.type, style_point, context, true);
                        }
                        this.drawMeasurementLines(element, measure.points, measure.dists, style_line, context);
                    }
                });
            }
        } else if (state.show_measurements) {
            const slopes = image.measurements.filter(m => m.type.includes("slope-derivative"));
            if (slopes.length > 0) {
                let style_line = HelperConfig.getStyle("segmentation","cursor_time_guide");
                slopes.map(measure => {
                    style_line.color = measure.type.includes("_30")? "lime" : "yellow";
                    for (let cc in image.cardiac_events) {
                        if (typeof measure.value[cc] === "number") {
                            const points = image.segmentation[cc].lines[0].points;
                            const initial_point = findWhere(points, { type: measure.formula[0] });
                            const final_point = findWhere(points, { type: measure.formula[1] });
                            const [ physical_delta_x, physical_delta_y ] = getPhysicalDeltas(image);
                            let slope = (measure.value[cc]/measure.unit_scale) * physical_delta_x / physical_delta_y;
                            slope = final_point.y > initial_point.y? slope : -slope;
                            const end = { x: (final_point.y-initial_point.y)/slope+initial_point.x, y: final_point.y };
                            const a = HelperImages.pixelToCanvas(element, initial_point);
                            const b = HelperImages.pixelToCanvas(element, end);
                            drawSegment(a, b, style_line, context);
                        }
                    }
                });
            }
        }
    }

    static drawMeasurementLines(element, points, dists, style, context) {
        for (var i = 0; i < points.length-1; i++) {
            if (dists[i]) {
                const a = HelperImages.pixelToCanvas(element, points[i].pixel);
                const b = HelperImages.pixelToCanvas(element, points[i+1].pixel);
                drawSegment(a, b, style, context);      
            }
        }
    }

    static drawMeasurementAngles(element, points, angles, show_options, style_line, style_point, screen_size, context) {
        const line = [false,false];
        for (var i of [0,1]) {
            if (points[2*i] && points[2*i+1]) {
                line[i] = new Line(new Point(points[2*i].pixel.x, points[2*i].pixel.y), new Point(points[2*i+1].pixel.x, points[2*i+1].pixel.y));
                drawLine(HelperImages.pixelToCanvas(element, points[2*i].pixel), HelperImages.pixelToCanvas(element, points[2*i+1].pixel), screen_size, style_line, context); 
            }
        }
        if (line[0] && line[1]) {
            const center = line[0].intersect(line[1])[0];
            const center_canvas = HelperImages.pixelToCanvas(element, center);
            let dists = points.map(p => euclideanDistance(p.pixel,center));
            const radius = Math.min(...dists) > 140? Math.round(Math.min(...dists) - 80) : 80;
            const radius_canvas = HelperImages.pixelToCanvas(element, { x: 0, y: radius });
            for (var i of [0,1]) {
                if (angles[i]) {
                    const rr = i===0? 1 : 0.8;
                    const circ = new Circle(new Point(center.x, center.y), rr*radius);
                    let arc_l0 = circ.intersect(line[0]), arc_l1 = circ.intersect(line[1]);
                    dists = arc_l0.map(p => euclideanDistance(p, points[0].pixel));
                    arc_l0 = { in: arc_l0[dists.indexOf(Math.min(...dists))], out: arc_l0[dists.indexOf(Math.max(...dists))] };
                    dists = arc_l1.map(p => euclideanDistance(p, points[2].pixel));
                    arc_l1 = { in: arc_l1[dists.indexOf(Math.min(...dists))], out: arc_l1[dists.indexOf(Math.max(...dists))] };
                    for (var j of [0,1]) {
                        const p1 = j===0? arc_l0.in : arc_l0.out, 
                            p2 = i===j? arc_l1.in : arc_l1.out;
                        const a1 = Math.atan2(p1.y - center.y, p1.x - center.x),
                            a2 = Math.atan2(p2.y - center.y, p2.x - center.x);
                        drawPoint(HelperImages.pixelToCanvas(element, new Segment(p1,p2).middle()), 0, 2*Math.PI, style_point.color, style_point, context);
                        if (show_options.labels) {
                            drawLabel(HelperImages.pixelToCanvas(element, new Segment(p1,p2).middle()), style_point.color, angles[i], style_point, context, true);
                        }
                        drawArc(center_canvas, rr*radius_canvas.y, Math.min(a1,a2), Math.max(a1,a2), style_point, context);
                    }
                }
            }
        }
    }

    // STORE VARIABLES --------------------------

    static setCurrentAction(current_action){
        Store.setCurrentAction(current_action);
    }

    static getCurrentAction(){
        return Store.getCurrentAction();
    }

}