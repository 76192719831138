import React, { useEffect } from 'react';

export const AlertComponent = ({
        alert
    }) => {
    
    useEffect(() => {
        if (alert && document.getElementById("alert")) {
            document.getElementById("alert").style.display = "block";
            setTimeout(() => {
                if (document.getElementById("alert")) {
                    document.getElementById("alert").style.display = "none";
                }
            }, 4000);
        }
    },[alert]);

    return <div className={alert.type} id="alert">{alert.text}</div>;
}