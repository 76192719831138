import { HelperStudies } from "../helper_studies/helper_studies";

import { where, findWhere, findIndex } from "underscore";
import { getSplineRealValues, getSplineIndexByCoordinate } from "../../modules/spline_module";
import { fromPixelsToReal, fromPixelsToRealArray } from "../../modules/geometry_module";
import { meanValue } from "../../modules/math_module";

export class HelperMeasurementsDoppler {

    static getDopplerMeasurements(measurements, image) {
        const flow_region = image.metadata.flow_region;
        const zero_line = flow_region.reference_y + flow_region.region_location_min_y;
        let measures2render = [];
        measurements.map((measure) => {
            let measure2render = [];
            if (!measure.unit_scale) {
                measure.unit_scale = 1;
            }
            measure2render = this.getValues(measure, zero_line, image);
            measure.value = JSON.parse(JSON.stringify(measure2render));
            measures2render.push(measure);
        });
        return measures2render;
    }

    static getValues(measure, zero_line, image) {
        const name = measure.name; const type = measure.type; const formula = measure.formula; const scale = measure.unit_scale; const combined = measure.combined;
        const values = [];

        if (type === "time-combined" && combined !== "itself" && image.is_segmented) {
            let value = getTimeCombined(image, combined, formula);
            if (value) {
                values.push(value * scale);    
            }
        } else {
            image.segmentation.map((cc, i) => {
                if (cc.lines.length > 0) {
                    const points = cc.lines[0].points;
                    const spline = cc.lines[0].spline;
                    let ref_point = [];
                    let ref_value_pixels = false;
                    let current_onsets = image.cardiac_events[i];

                    if (type === "velocity") {
                        if (formula.cp_value) {
                            formula.cp_value.map(cp => {
                                if (ref_point.length === 0 && findWhere(points, { type: cp })) {
                                    ref_point.push(findWhere(points, { type: cp }));
                                }
                            });
                        } else {
                            ref_point.push(findWhere(points, { type: formula.replace("cp value: ","") }));
                            if (i === image.segmentation.length - 1) {
                                ref_point = where(points, { type: formula.replace("cp value: ","") });
                            }    
                        }
                        ref_point.map((point) => {
                            if (point) {
                                ref_value_pixels = point.y;
                                let value = fromPixelsToReal(ref_value_pixels, "Y", zero_line, image);
                                if (value !== false) {
                                    values.push(value);    
                                }
                            }
                        });
                    
                    } else if (type === "velocity-curve") {
                        let points_section = [];
                        if (Array.isArray(formula) && formula.length === 2) {
                            let start = findIndex(points, { type: formula[0] });
                            if (start > -1) {
                                let end = findIndex(points.slice(start+1), { type: formula[1] });
                                if (end > -1) {
                                    end += start+1;
                                    points_section = points.slice(start, end+1);
                                }
                            }
                        } else {
                            points_section = points;
                        }
                        let spline = getSplineRealValues(points_section, zero_line, image, "Y");
                        let y = spline.map((p) => Math.abs(p.y));
                        if (y.length > 0) {
                            let value = name.includes("max")? Math.max(...y) : name.includes("mean")? meanValue(y) : 0;
                            if (value) {
                                values.push(value);    
                            }
                        }
                        
                    } else if (type === "event-time" && current_onsets) {
                        ref_point.push(findWhere(points, { type: formula.replace("cp value: ","") }));
                        if (i === image.segmentation.length - 1) {
                            ref_point = where(points, { type: formula.replace("cp value: ","") });
                        }
                        ref_point.map((point, j) => {
                            if (point) {
                                ref_value_pixels = point.x - current_onsets.onset_start;
                                if (i === image.segmentation.length - 1 && j > 0) {
                                    ref_value_pixels = point.x - current_onsets.onset_end;
                                }
                                let value = fromPixelsToReal(ref_value_pixels, "X", zero_line, image);
                                if (value !== false) {
                                    values.push(value * scale);    
                                }
                            }
                        });
        
                    } else if (type === "time") {
                        let value = getTimeDuration(points, formula, image);
                        if (value) {
                            values.push(value * scale);    
                        }

                    } else if (type === "time-combined" && combined === "itself") {
                        let value = getTimeCombinedItself(points, formula, current_onsets, image);
                        if (value) {
                            values.push(value * scale);    
                        }
        
                    } else if (type === "time-percentage" && current_onsets) {
                        const value = getTimeDuration(points, formula, image);
                        const total = getCycleLength(current_onsets, image);
                        if (value && total) {
                            values.push(100*value/total);
                        }

                    } else if (type === "pulsatility") {
                        const spline = getSplineRealValues(points, zero_line, image, "Y");
                        const velos = spline.map(p => Math.abs(p.y));
                        values.push((Math.max(...velos)-Math.min(...velos))/meanValue(velos));

                    } else if (type === "auc") {
                        const value = getAUC(points, formula, zero_line, image);
                        if (value) {
                            values.push(value);    
                        }
        
                    } else if (type === "pressure-gradient") {
                        const value = getPressureGradient(points, formula, name, zero_line, image);
                        if (value) {
                            values.push(value * scale);    
                        }
        
                    } else if (type === "ratio") {
                        let value = getRatio(points, formula, zero_line, image);
                        if (value) {
                            values.push(value);    
                        }

                    } else if (type === "slope") {
                        let value = getSlope(points, formula, zero_line, image);
                        if (value) {
                            values.push(value);    
                        }

                    } else if (type.includes("slope-derivative")) {
                        let width = type.includes("_")? parseInt(type.split("_")[1]) : 100;
                        let value = getSlopeDerivative(points, spline, formula, width, zero_line, image);
                        if (value) {
                            values.push(value * scale);
                        }
        
                    } else if (type === "Cycle length" && current_onsets) {
                        let value = getCycleLength(current_onsets, image);
                        if (value) {
                            values.push(value * scale);    
                        }
                    } else if (type === "Heart rate" && current_onsets) {
                        let value = getCycleLength(current_onsets, image);
                        if (value) {
                            values.push(60 / value);    
                        }
                    }
                }
            });
        }
        return values;
    }    

}

const getTimeDuration = (points, formula, image) => {
    let value1;
    let value2;
    if (formula.length >= 2) {
        let point1 = findWhere(points, { type: formula[0] });
        if (!point1 && formula.length === 4) {
            point1 = findWhere(points, { type: formula[2] });
        }
        if (point1) {
            let points2 = where(points, { type: formula[1] });
            if (points2.length === 0 && formula.length === 4) {
                points2 = where(points, { type: formula[3] });
            }
            let point2;
            for (const p of points2) {
                if (p.id > point1.id && p.x > point1.x) {
                    point2 = p;
                }
            }
            if (point2) {
                value1 = fromPixelsToReal(point1.x, "X", 0, image);
                value2 = fromPixelsToReal(point2.x, "X", 0, image);
            }
        }
    }
    if (value1 && value2) {
        return value2 - value1;
    } else {
        return false;
    }
}

const getTimeCombinedItself = (points, formula, current_onsets, image) => {
    if (points.length > 0) {
        let own_part = 0;
        let comb_part = 0;
        for (let f=1 ; f>=0 ; f--) {
            let value_own = false;
            let value_comb = false;
            if (["onset_start","onset_end"].includes(formula.own[f])) {
                value_own = current_onsets[formula.own[f]];
            } else {
                let poss_points = where(points, { type: formula.own[f] });
                if (poss_points.length > 0) {
                    value_own = f === 1? poss_points[0].x : poss_points[poss_points.length-1].x;
                }
            }
            if (own_part === false || value_own === false) {
                own_part = false;
            } else {
                own_part = f === 1? value_own : own_part - value_own;
            }
            if (["onset_start","onset_end"].includes(formula.combined[f])) {
                value_comb = current_onsets[formula.combined[f]];
            } else {
                let poss_points = where(points, { type: formula.combined[f] });
                if (poss_points.length > 0) {
                    value_comb = f === 1? poss_points[0].x : poss_points[poss_points.length-1].x;
                }
            }
            if (comb_part === false || value_comb === false) {
                comb_part = false;
            } else {
                comb_part = f === 1? value_comb : comb_part - value_comb;
            }
        }

        let result = comb_part+formula.sign*own_part;
        let value = fromPixelsToReal(result, "X", 0, image);
        return value;

    } else {
        return false;
    }
}

const getTimeCombined = (image, combined, formula) => {
    const images = HelperStudies.getStudyImages().analysis_images;
    const combined_images = where(images, { type: combined, modality: image.modality }).filter(img => img.reference && img.is_segmented && img.dicom);
    let result = false;
    let diff = 10000;
    for (const combined_img of combined_images) {
        for (let i in combined_img.cardiac_events) {
            const len_cycle_comb = getCycleLength(combined_img.cardiac_events[i], combined_img);
            for (let j in image.cardiac_events) {
                const len_cycle_own = getCycleLength(image.cardiac_events[j], image);
                if (Math.abs(len_cycle_comb-len_cycle_own) < diff && combined_img.segmentation[i] && image.segmentation[j]) {
                    const points_comb = combined_img.segmentation[i].lines[0].points;
                    const points_own = image.segmentation[j].lines[0].points;
                    if (points_comb.length > 0 && points_own.length > 0) {
                        let own_part = 0;
                        let comb_part = 0;
                        for (let f=1 ; f>=0 ; f--) {
                            let value_own = false;
                            let value_comb = false;
                            if (["onset_start","onset_end"].includes(formula.own[f])) {
                                value_own = image.cardiac_events[j][formula.own[f]];
                            } else {
                                let poss_points = where(points_own, { type: formula.own[f] });
                                if (poss_points.length > 0) {
                                    value_own = f === 1? poss_points[0].x : poss_points[poss_points.length-1].x;
                                }
                            }
                            if (own_part === false || value_own === false) {
                                own_part = false;
                            } else {
                                own_part = f === 1? value_own : own_part - value_own;
                            }
                            if (["onset_start","onset_end"].includes(formula.combined[f])) {
                                value_comb = combined_img.cardiac_events[i][formula.combined[f]];
                            } else {
                                let poss_points = where(points_comb, { type: formula.combined[f] });
                                if (poss_points.length > 0) {
                                    value_comb = f === 1? poss_points[0].x : poss_points[poss_points.length-1].x;
                                }
                            }
                            if (comb_part === false || value_comb === false) {
                                comb_part = false;
                            } else {
                                comb_part = f === 1? value_comb : comb_part - value_comb;
                            }
                        }
                        if (own_part !== false && comb_part !== false) {
                            own_part = fromPixelsToReal(own_part, "X", 0, image);
                            comb_part = fromPixelsToReal(comb_part, "X", 0, combined_img);
                            result = comb_part+formula.sign*own_part;
                            diff = Math.abs(len_cycle_comb-len_cycle_own);
                        }
                    }
                }
            }
        }
    }
    return result;
}

const getCycleLength = (onsets, image) => {
    let onset_end = fromPixelsToReal(onsets.onset_end, "X", 0, image);
    let onset_start = fromPixelsToReal(onsets.onset_start, "X", 0, image);
    return onset_end - onset_start;
}

const getSlope = (points, formula, zero_line, image) => {
    let ind = findIndex(points, { type: formula[0] });
    if (ind < 0 && formula.length === 4) {
        ind = findIndex(points, { type: formula[2] });
    }
    if (ind >= 0) {
        let point1 = points[ind];
        let point2 = findWhere(points.slice(ind), { type: formula[1] });
        if (!point2 && formula.length === 4) {
            point2 = findWhere(points.slice(ind), { type: formula[3] });
        }
        if (point2 && point2.x > point1.x) {
            let [p1_real,p2_real] = fromPixelsToRealArray([point1,point2], "Y", zero_line, image);
            const slope = (p2_real.y-p1_real.y)/(p2_real.x-p1_real.x);
            return (point1.y >= zero_line && point2.y >= zero_line)? slope : -slope;
        }    
    } else {
        return false;
    }
}

const getSlopeDerivative = (points, spline, formula, width, zero_line, image) => {
    let p1 = findIndex(points, { type: formula[0] });
    let p2 = findIndex(points.slice(p1), { type: formula[1] });
    if (spline && p1 >= 0 && p2 >= 0) {
        let y1 = points[p1].y;
        let y2 = width < 100? (points[p2].y-y1)*(width/100)+y1 : points[p2].y;
        let ind1 = getSplineIndexByCoordinate(spline, "x", points[p1].x);
        let ind2 = getSplineIndexByCoordinate(spline, "x", points[p2].x);
        ind2 = getSplineIndexByCoordinate(spline.slice(ind1,ind2), "y", y2);
        let spline_section = fromPixelsToRealArray(spline.slice(ind1, ind2), "Y", zero_line, image);
        let slopes = [];
        for (let i=0; i<spline_section.length-1; i++) {
            slopes.push((spline_section[i+1].y-spline_section[i].y)/(spline_section[i+1].x-spline_section[i].x));
        }
        return (y2 > zero_line)? -meanValue(slopes) : meanValue(slopes);    
    } else {
        return false;
    }
}

const getRatio = (points, formula, zero_line, image) => {
    let value1 = false;
    let value2 = false;
    formula.map((cp, i) => {
        let ref_point = findWhere(points, { type: cp });
        if (ref_point) {
            let ref_value_pixels = ref_point.y;
            let value = fromPixelsToReal(ref_value_pixels, "Y", zero_line, image);
            if (value && i === 0) {
                value1 = value;
            } else if (value && i === 1) {
                value2 = value;
            }
        }
    });
    if (value1 && value2) {
        return value1 / value2;
    } else {
        return false;
    }
}

const getAUC = (points, formula, zero_line, image) => {
    let points_section = [];
    if (Array.isArray(formula) && formula.length >= 2) {
        let start = findIndex(points, { type: formula[0] });
        if (start < 0 && formula.length === 4) {
            start = findIndex(points, { type: formula[2] });
        }
        if (start > -1) {
            let end = findIndex(points.slice(start+1), { type: formula[1] });
            if (end < 0 && formula.length === 4) {
                end = findIndex(points.slice(start+1), { type: formula[3] });
            }
            if (end > -1) {
                end += start+1;
                points_section = points.slice(start, end+1);
            }
        }
    } else {
        points_section = points;
    }
    const spline = getSplineRealValues(points_section, zero_line, image, "Y");
    let auc = 0;
    if (formula === 1) {
        for (let i = 0; i < spline.length-1; i++) {
            if (spline[i].y >= 0) {
                auc += (spline[i+1].x - spline[i].x) * meanValue([spline[i].y,spline[i+1].y]);
            }
        }
    } else if (formula === -1) {
        for (let i = 0; i < spline.length-1; i++) {
            if (spline[i].y < 0) {
                auc += Math.abs((spline[i+1].x - spline[i].x) * meanValue([spline[i].y,spline[i+1].y]));
            }
        }
    } else {
        for (let i = 0; i < spline.length-1; i++) {
            auc += Math.abs((spline[i+1].x - spline[i].x) * meanValue([spline[i].y,spline[i+1].y]));
        }
    }
    return auc;
}

const getPressureGradient = (points, formula, name, zero_line, image) => {
    let points_section = [];
    if (Array.isArray(formula) && formula.length === 2) {
        let start = findIndex(points, { type: formula[0] });
        if (start > -1) {
            let end = findIndex(points.slice(start+1), { type: formula[1] });
            if (end > -1) {
                end += start+1;
                points_section = points.slice(start, end+1);
            }
        }
    } else {
        points_section = points;
    }
    const spline = getSplineRealValues(points_section, zero_line, image, "Y");
    if (spline.length > 0) {
        const gradients = spline.map(p => 4*Math.pow(p.y,2));
        if (name.includes("mean")) {
            return meanValue(gradients);
        } else if (name.includes("peak")) {
            return Math.max(...gradients);
        }
    } else {
        return false;
    }
}