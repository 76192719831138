import { HelperImages } from "../helpers/helper_images/helper_images";
import { HelperConfig } from "../helpers/helper_config/helper_config";
import { HelperSegmentation } from "../helpers/helper_segmentation/helper_segmentation";
import { HelperCycleTiming } from "../helpers/helper_cycletiming/helper_cycletiming";
import { HelperMeasurements } from "../helpers/helper_measurements/helper_measurements";

import { findIndex, findWhere } from 'underscore';

export const segmentationActions = (action, myC) => {
    const image = action.value.image;
    let state = {};
    switch (action.action) {
        case "INIT":
            state = HelperSegmentation.init(image, myC.state);
            state.show_legend = true;
            // state.show_measurements = true;
            state.constraints = true;
            state.show_caliper_options = { labels: false, measures: true, cursors: true };
            state.caliper_only = state.current_action === "caliper"? true : false;
            if (image.modality.includes("Doppler")) {
                state.predict_cycles = !HelperConfig.getToolConfig("Doppler","avoid_cycle_prediction").includes(image.type);
                state.cursor_guide = HelperConfig.getToolConfig("Doppler","time_cursor").includes(image.type)? "time" : false;
            }
            if (state.current_event && myC.state.stack) {
                myC.onActionDicom({ action: "CHANGE-DICOM-FRAME", value: { frame: image.cardiac_events[state.current_cycle][state.current_event] } });
            }
            break;
        case "CHANGE-TIME-EVENT":
            myC.onActionDicom({ action: "CHANGE-DICOM-FRAME", value: { frame: action.value.frame } });
            state = HelperSegmentation.initTimeEvent(image, action.value.cycle, action.value.event, myC.state);
            break;
        case "CHANGE-SHAPE":
            state = HelperSegmentation.changeShape(image, myC.state, action.value.shape, action.value.instruction);
            break;
        case "CHANGE-THICKNESS":    
            if (myC.state.current_shape.type === "endo-epi" && myC.state.current_line.includes("endo")) {
                let scale = action.value.thickness / myC.state.thickness;
                HelperSegmentation.predictEpiFromEndo(image, myC.state, myC.state.current_line, "scale", scale);
                state.thickness = action.value.thickness;
            }
            break;
        case "CHANGE-PATTERN":
            state = HelperSegmentation.changePattern(image, myC.state.current_cycle, action.value.pattern);
            break;
        case "SET-CONTROL-POINT":
            state = HelperSegmentation.setControlPoint(image, myC.state);
            if (state.current_cp === "finished") {
                state.current_cp = false;
                action.action = "FINISH";
                action.value.finished = true;
                segmentationActions(action, myC);
            }
            break;
        case "PREDICT-FROM-PREVIOUS":
            if (myC.state.current_action === "segmenting" && image.cardiac_events.length > 0) {
                HelperSegmentation.predictFromPreviousCC(image, myC.state.current_cycle, myC.state.current_event);
                state = HelperSegmentation.initTimeEvent(image, myC.state.current_cycle, myC.state.current_event);
            }
            break;
        case "UNDO":
            state = HelperSegmentation.undo(image, myC.state);
            break;
        case "FINISH-ALL":
            state = HelperSegmentation.finish(image, "all", myC.state);
            if (HelperSegmentation.checkIfAllFinished(image.segmentation)) {
                image.is_segmented = true;
            };
            break;
        case "FINISH":
            state = HelperSegmentation.finish(image, "task", myC.state, action.value.finished);
            if (!state) {
                state = {};
                action.action = "INIT";
                segmentationActions(action, myC);
            } else if (HelperSegmentation.checkIfAllFinished(image.segmentation)) {
                image.is_segmented = true;
            };
            break;
        case "EDIT":
            state.current_action = "editing";
            break;
        case "REMOVE-ALL":
            state = HelperSegmentation.remove(image, "all", action.value.shape, myC.state);
            break;
        case "REMOVE-SHAPE":
            state = HelperSegmentation.remove(image, "shape", action.value.shape, myC.state);
            break;
        case "REMOVE-LAST-CC":
            if (image.modality.includes("Doppler") && image.cardiac_events.length > 1) {
                if (image.cardiac_events.length === image.segmentation.length) {
                    image.cardiac_events.pop();
                    image.segmentation.pop();
                } else if (image.cardiac_events.length > image.segmentation.length) {
                    image.cardiac_events.pop();
                } else if (image.cardiac_events.length < image.segmentation.length) {
                    image.segmentation.pop();
                }
            }
            break;
        case "TOGGLE-PREDICT-CYCLES":
            state.predict_cycles = !myC.state.predict_cycles;
            break;
        case "TOGGLE-CURSOR-GUIDE":
            state.cursor_guide = myC.state.cursor_guide? false : "time";
            break;
        case "TOGGLE-CALIPER":
            if (myC.state.current_action !== "caliper") {
                HelperSegmentation.setCurrentAction(myC.state.current_action);
                state.current_action = "caliper";
                state.caliper_tool = { points: [], dists: [], angles: [] };
            } else if (!myC.state.caliper_only) {
                state.current_action = HelperSegmentation.getCurrentAction();
                state.caliper_tool = { points: [], dists: [], angles: [] };
            }
            break;
        case "SET-CALIPER-FUNCTION":
            state.caliper_select = action.value.callback;
            break;
        case "INIT-CALIPER":
            if (action.value.template) {
                let measures_event = [];
                if (image.measurements_caliper) {
                    measures_event = image.measurements_caliper.filter(m => m.cycle === myC.state.current_cycle && m.event === myC.state.current_event);
                }
                const done = findWhere(measures_event, { type: action.value.template.type });
                const current_action = done? "finished" : "measuring";
                state.caliper_tool = { points: [], dists: [], angles: [],
                    type: action.value.template.type, template: action.value.template,
                    measures: action.value.measures, current_action };
            } else {
                state.caliper_tool = { points: [], dists: [], angles: [] };
            }
            break;
        case "SAVE-CALIPER-MEASURE":
            let new_state = HelperSegmentation.saveCaliperMeasure(image, myC.state.caliper_tool, myC.state.current_cycle, myC.state.current_event, myC.state.caliper_select);
            if (new_state) {
                state.caliper_tool = new_state;
            }
            break;
        case "REMOVE-CALIPER-MEASURE":
            HelperSegmentation.removeCaliperMeasure(image, myC.state.caliper_tool, action.value.measure, myC.state.caliper_select);
            break;
        case "SHOW-CALIPER-OPTIONS":
            state.show_caliper_options = myC.state.show_caliper_options;
            state.show_caliper_options[action.value.type] = !state.show_caliper_options[action.value.type];
            break;
        case "SHOW-LEGEND":
            state.show_legend = action.value.type === "on"? true : false;
            break;
        case "SHOW-MEASUREMENTS":
            state.show_measurements = action.value.type === "on"? true : false;
            break;
        case "CONSTRAINTS":
            state.constraints = action.value.type === "on"? true : false;
            break;
        case "CORRECT-ALIASING-PARAMS":
            let params = {};
            params.up = action.value.up !== undefined? action.value.up : myC.state.correct_aliasing? myC.state.correct_aliasing.up : 0;
            params.down = action.value.down !== undefined? action.value.down : myC.state.correct_aliasing? myC.state.correct_aliasing.down : 0;
            image.metadata.correct_aliasing = params;
            state.correct_aliasing = params;
            state.correct_aliasing_needs_update = true;
            break;
        case "CORRECT-ALIASING":
            if (image.metadata.flow_region) {
                const config = action.value.config;
                const doppler_max_y = HelperImages.pixelToCanvas(action.value.dicom_div,{ x: 0, y: image.metadata.flow_region.region_location_max_y }).y;
                const doppler_min_y = HelperImages.pixelToCanvas(action.value.dicom_div,{ x: 0, y: image.metadata.flow_region.region_location_min_y }).y;
                const original_canvas = action.value.dicom_div.children[action.value.dicom_div.children.length-1];
                const context = action.value.canvas.getContext('2d');
                context.clearRect(0,0,action.value.canvas.width,action.value.canvas.height);
                for (const side of Object.keys(config)) {
                    if (config[side] > 0) {
                        const height = HelperImages.pixelToCanvas(action.value.dicom_div,{ x: 0, y: config[side] }).y;
                        // Get original image crop
                        const original_context = original_canvas.getContext('2d');
                        const orig_startY = side === "up"? doppler_max_y - height - 1
                                            : doppler_min_y + 1;
                        const imageData = original_context.getImageData(0, orig_startY, original_canvas.width, height);
                        // Paste crop in the correction canvas
                        if (side === "down" && action.value.canvas.height < original_canvas.height + height) {
                            action.value.canvas.height = original_canvas.height + height;
                        }
                        const dest_startY = side === "up" ? doppler_min_y - height
                                            : doppler_max_y - 1;
                        createImageBitmap(imageData).then(function(imgBitmap) {
                            context.drawImage(imgBitmap, 0, dest_startY);
                        });
                    }
                }
                state.correct_aliasing_needs_update = false;
            }
            break;
        case "DRAW-CANVAS":
            if (myC.state.current_action) {
                const element = action.value.element;
                const context = action.value.canvas.getContext('2d');
                const screen_size = { height: action.value.canvas.height, width: action.value.canvas.width };
                state = HelperSegmentation.checkMouseAllowance(image, myC.state, element);
                context.clearRect(0, 0, action.value.canvas.width, action.value.canvas.height);
                context.beginPath();
                if (myC.state.current_action === "caliper") {
                    HelperSegmentation.drawMeasurements(image, context, myC.state, element, screen_size);
                    HelperSegmentation.drawMouse(image, myC.state, context, state.mouse_allowed, screen_size, element);
                } else if (myC.state.current_action === "selecting-cycles") {
                    HelperSegmentation.drawSegmentation(image, context, myC.state, element);
                    HelperCycleTiming.drawCycleRegions(image.cardiac_events, myC.state.cycles_selected, screen_size.height, screen_size.width, context, myC.state.mouse.canvas.x, element);
                    HelperSegmentation.drawOnsets(image, screen_size.height, context, element, myC.state);                   
                } else if (image.segmentation && image.segmentation.length > 0) {
                    HelperSegmentation.drawOnsets(image, screen_size.height, context, element, myC.state);
                    HelperSegmentation.drawSegmentation(image, context, myC.state, element);
                    HelperSegmentation.drawMeasurements(image, context, myC.state, element, screen_size);
                    context.closePath();
                    context.beginPath();
                    HelperSegmentation.drawMouse(image, myC.state, context, state.mouse_allowed, screen_size, element);
                }
                context.closePath();
            }
            break;
        case "MOVE-SELECTED":
            HelperSegmentation.moveSelectedPoint(image, myC.state);
            break;
        case "MOUSE-DOWN":
            if (action.value.event.which === 1) {
                if (myC.state.current_action === "segmenting") {
                    action.action = "SET-CONTROL-POINT";
                } else if (myC.state.current_action.includes("editing")) {
                    state = HelperSegmentation.clickOnEdit(image, myC.state, "left");
                } else if (myC.state.current_action === "selecting-cycles") {
                    HelperCycleTiming.clickOnSelectCycles(image, myC);
                } else if (myC.state.current_action === "caliper" && myC.state.caliper_tool.type) {
                    state = HelperSegmentation.clickOnCaliper(image, myC.state, "left");                    
                }
            } else if (action.value.event.which === 3) {
                if (["editing","editing-segmentation"].includes(myC.state.current_action)) {
                    state = HelperSegmentation.clickOnEdit(image, myC.state, "right");
                } else if (myC.state.current_action === "caliper") {
                    state = HelperSegmentation.clickOnCaliper(image, myC.state, "right");                    
                } else if (myC.state.current_action !== "finished") {
                    action.action = "FINISH";
                }
            }
            if (action.action !== "MOUSE-DOWN") {
                segmentationActions(action, myC);
            }
            break;
        default:
            break;
    }
    HelperMeasurements.extractMeasurementsFromImage(image);
    state.image = image;
    myC.setState(state);
}

export const segmentationKeyboardActions = (event, myC) => {
    let action;
    if (myC.is_mounted && (myC.state.current_action !== "caliper" || (event.ctrlKey && event.key === 'c'))) {

        if (event.ctrlKey && event.key === 'f' && myC.state.current_action !== "finished") { // FINISH ALL
            event.preventDefault();
            action = { action: "FINISH-ALL", value: { image: myC.state.image }};

        } else if (event.key === 'f' && myC.state.current_action !== "finished") { // FINISH TASK
            if (myC.state.image.modality.includes("Doppler")) {
                action = { action: "FINISH-ALL", value: { image: myC.state.image }};
            } else {
                action = { action: "FINISH", value: { image: myC.state.image }};
            }

        } else if (event.ctrlKey && event.key === 'z' && myC.state.current_action === "segmenting") { // UNDO
            event.preventDefault();
            action = { action: "UNDO", value: { image: myC.state.image }};

        } else if (event.ctrlKey && event.key === 'c' && myC.state.image.modality === "2D") { // TOGGLE CALIPER TOOL
            event.preventDefault();
            action = { action: "TOGGLE-CALIPER", value: { image: myC.state.image }};

        } else if (event.key === 't' && myC.state.image.modality !== "2D") { // CURSOR TIME GUIDE
            action = { action: "TOGGLE-CURSOR-GUIDE", value: { image: myC.state.image }};

        } else if (event.key === 'e' && myC.state.current_action === "finished") { // EDIT
            action = { action: "EDIT", value: { image: myC.state.image }};

        } else if (event.ctrlKey && event.key === 'r' && myC.state.current_action !== "selecting-cycles") { // REMOVE ALL
            event.preventDefault();
            action = { action: "REMOVE-ALL", value: { image: myC.state.image }};
        
        } else if (event.ctrlKey && event.key === 'l' && myC.state.current_action !== "selecting-cycles") { // REMOVE LAST CC
            event.preventDefault();
            action = { action: "REMOVE-LAST-CC", value: { image: myC.state.image }};
        
        } else if (event.key === 'r' && !myC.state.image.modality.includes("Doppler")) { // REMOVE CURRENT SHAPE
            action = { action: "REMOVE-SHAPE", value: { image: myC.state.image, shape: myC.state.current_shape.name }};
        
        } else if (event.key === 'm') { // SHOW/HIDE MEASUREMENTS
            action = { action: "SHOW-MEASUREMENTS", value: { image: myC.state.image }};
        
        } else if (event.key === 'p' && myC.state.current_cycle > 0) { // PREDICT SEGMENTATION FROM PREVIOUS CYCLE
            action = { action: "PREDICT-FROM-PREVIOUS", value: { image: myC.state.image }};
        
        } else if (event.ctrlKey && event.key === 'ArrowRight' && myC.state.image.modality === "2D") { // MOVE TO NEXT CARDIAC EVENT
            const image = myC.state.image;
            if (!(myC.state.current_cycle === image.segmentation.length-1 && myC.state.current_event === "end_systole")) {
                if (myC.state.current_event === "end_diastole") {
                    action = { action: "CHANGE-TIME-EVENT", value: { image, cycle: myC.state.current_cycle, event: "end_systole", frame: image.cardiac_events[myC.state.current_cycle].end_systole }};
                } else if (myC.state.current_event === "end_systole") {
                    action = { action: "CHANGE-TIME-EVENT", value: { image, cycle: myC.state.current_cycle+1, event: "end_diastole", frame: image.cardiac_events[myC.state.current_cycle+1].end_diastole }};
                }
            }
        
        } else if (event.ctrlKey && event.key === 'ArrowLeft' && myC.state.image.modality === "2D") { // MOVE TO PREVIOUS CARDIAC EVENT
            const image = myC.state.image;
            if (!(myC.state.current_cycle === 0 && myC.state.current_event === "end_diastole")) {
                if (myC.state.current_event === "end_diastole") {
                    action = { action: "CHANGE-TIME-EVENT", value: { image, cycle: myC.state.current_cycle-1, event: "end_systole", frame: image.cardiac_events[myC.state.current_cycle-1].end_systole }};
                } else if (myC.state.current_event === "end_systole") {
                    action = { action: "CHANGE-TIME-EVENT", value: { image, cycle: myC.state.current_cycle, event: "end_diastole", frame: image.cardiac_events[myC.state.current_cycle].end_diastole }};
                }
            }
        
        } else if (event.key === 'ArrowRight') { // MOVE TO NEXT SHAPE / CYCLE
            if (["2D","M-Mode"].includes(myC.state.image.modality) && ["finished","editing"].includes(myC.state.current_action)) {
                action = { action: "CHANGE-SHAPE", value: { image: myC.state.image, shape: myC.state.current_shape.name, instruction: "next" }};
            } else if (myC.state.current_action !== "selecting-cycles") {
                action = { action: "FINISH", value: { image: myC.state.image }};
            }

        } else if (event.key === 'ArrowLeft') { // MOVE TO PREVIOUS SHAPE
            if (["2D","M-Mode"].includes(myC.state.image.modality) && ["finished","editing"].includes(myC.state.current_action)) {
                action = { action: "CHANGE-SHAPE", value: { image: myC.state.image, shape: myC.state.current_shape.name, instruction: "previous" }};
            }
        }

    } else {

        if (event.key === 'Enter') { // SAVE CALIPER MEASURE
            event.preventDefault();
            action = { action: "SAVE-CALIPER-MEASURE", value: { image: myC.state.image }};

        } else if ((event.key === 'ArrowUp' || event.key === 'ArrowDown') && myC.state.caliper_tool?.points.length === 0) { // MOVE TO PREVIOUS/NEXT CALIPER MEASURE
            event.preventDefault();
            if (myC.state.caliper_tool.measures?.length > 1) {
                let measures = myC.state.caliper_tool.measures;
                let index = findIndex(measures, { "type": myC.state.caliper_tool.template.type });
                let new_index = event.key === 'ArrowUp'? (index > 0? index-1 : measures.length-1) : (index < measures.length-1? index+1 : 0);
                if (new_index !== false) {
                    myC.state.caliper_select(myC.state.image, measures[new_index], measures);
                }    
            }
        }
    }

    if (action) {
        segmentationActions(action, myC);
    }
}