import React from 'react';

export const ToggleMenuComponent = ({
        site,
        data,
        data_hidden,
        keys_selected,
        on_action_to_perform,
        parameter
    }) => {

    const renderTags = () => {
        if (data.length > 0) {
            return data.map(tag => {
                if (!data_hidden.includes(tag)) {
                    if (keys_selected.includes(tag)) {
                        return <a key={tag} onClick={() => on_action_to_perform({ action: "UNSELECT_TAG", value: tag, parameter })} className="selected">{tag}</a>;
                    } else {
                        return <a key={tag} onClick={() => on_action_to_perform({ action: "SELECT_TAG", value: tag, parameter })} className="unselected">{tag}</a>;
                    }
                } else {
                    return null;
                }
            });
        }
    }

    return (
        <div className={site+" toggle-menu"}>
            {renderTags()}
        </div>
    );
}