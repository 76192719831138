import { generalActions } from './general_actions';
import { dicomActions } from './dicom_actions';
import { selectionActions } from './selection_actions';
import { cycletimingActions } from './cycletiming_actions';
import { cycletimingKeyboardActions } from './cycletiming_actions';
import { segmentationActions } from './segmentation_actions';
import { segmentationKeyboardActions } from './segmentation_actions';
import { validationActions } from './validation_actions';
import { validationKeyboardActions } from './validation_actions';
import { measurementsActions } from './measurements_actions';
export const onActionToPerform = (actionType, action, myC) => {
    switch(actionType){
        case "GENERAL":
            generalActions(action, myC);
            break;
        case "DICOM":
            dicomActions(action, myC);
            break;
        case "SELECTION":
            selectionActions(action, myC);
            break;
        case "CYCLETIMING":
            cycletimingActions(action, myC);
            break;
        case "CYCLETIMING-KEY":
            cycletimingKeyboardActions(action, myC);
            break;
        case "SEGMENTATION":
            segmentationActions(action, myC);
            break;
        case "SEGMENTATION-KEY":
            segmentationKeyboardActions(action, myC);
            break;
        case "VALIDATION":
            validationActions(action, myC);
            break;
        case "VALIDATION-KEY":
            validationKeyboardActions(action, myC);
            break;
        case "MEASUREMENTS":
            measurementsActions(action, myC);
            break;
        default:
            break;
    }
}