import React, { useState } from 'react';

// Components
import { DicomViewerComponent } from './../data_viewer_component/DICOM/dicom_viewer_component';
import { EmptyViewerComponent } from './../data_viewer_component/empty_viewer_component';
import { ImageGridComponent } from './../image_grid_component/image_grid_component';
import { ChatComponent } from './../chat_component/chat_component';

// Helpers
import { HelperUser } from './../../../helpers/helper_user/helper_user';
import { HelperImages } from './../../../helpers/helper_images/helper_images';
import { HelperStudies } from './../../../helpers/helper_studies/helper_studies';

// AUX functions
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import { findWhere } from 'underscore';

export const AnalysisComponent = ({
        site,
        parameter,
        on_action_to_perform,
        images
    }) => {

    const [index, setIndex] = useState(HelperImages.getImageToShow(images, parameter, 0));
    const [showFloatingWindow, setShowFloatingWindow] = useState(false);
    const user_type = HelperUser.getUserType();

    const handleImageSelection = (ind) => {
        HelperStudies.saveStudy();
        setIndex(ind);
    }

    const renderGrid = () => {
        return (
            <ImageGridComponent
                site={site}
                images={images}
                image_to_show={images[index]}
                on_image_selected={handleImageSelection}
                on_hover={()=>{}}
            />
        );
    }

    const renderViewer = () => {
        if (index !== null && index >= 0) {
            return (
                <DicomViewerComponent
                    site={site}
                    image_to_show={images[index]}
                    play_multiframe={true}
                    play_by_default={false}
                    show_player_controls={site==="cycletiming"}
                    show_floating_window={setShowFloatingWindow}
                    on_action_to_perform={on_action_to_perform}
                />
            );
        } else {
            return (
                <EmptyViewerComponent
                    on_action_to_perform={on_action_to_perform}
                    redirect_to={site === "cycletiming"? "selection" : site === "segmentation"? "cycletiming" : false}
                />
            );
        }
    }

    const renderFloatingWindow = () => {
        if (showFloatingWindow) {
            const image = findWhere(HelperStudies.getStudyImages().loaded_images, { filename: images[index].filename });
            return (
                <div className="grid-block" style={{ position: "absolute", width: "100%", height: "100%", background: "#060606c2", overflow: "hidden" }}>
                    <div className="grid-block" style={{ display: "flex", flexDirection: "row-reverse", marginRight: "50px", marginTop: "20px" }}>
                        <div className="grid-block shrink" style={{ height: "40px", width: "40px", cursor: "pointer", zIndex: "1000" }}
                            onClick={() => { setShowFloatingWindow(false) }}>
                            <CancelOutlinedIcon fontSize="large" sx={{ fontSize: "40px" }} />
                        </div>
                    </div>
                    <div className="grid-block comment-window">
                        <ChatComponent
                            image={image}
                            image_analysis={images[index]}
                            attribute={"comments"}
                            comment_type={site}
                            user_type={user_type}
                        />
                    </div>
                </div>
            );
        }
    }

    return (
        <div className="analysis component">
            {renderGrid()}
            {renderViewer()}
            {renderFloatingWindow()}
        </div>
    );
}