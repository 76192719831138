import React, { useState } from 'react';

// Components
import { ImageGridComponent } from './../image_grid_component/image_grid_component';

export const OverviewComponent = ({
        images
    }) => {

    const [index, setIndex] = useState(0);

    const renderViewer = () => {
        if (images.length > 0) {
            return (
                <div className="overview viewer">
                    <img src={images[index].urls.big_icon} alt="" />
                    {/* TODO image menu */}
                </div>
            );
        }
    }

    const renderGrid = () => {
        return (
            <ImageGridComponent
                site={"overview"}
                images={images}
                image_to_show={images[index]}
                on_image_selected={setIndex}
                on_hover={()=>{}}
            />
        );
    }

    return (
        <div className="grid-block vertical">
            {renderViewer()}
            {renderGrid()}
        </div>
    );
}
